<div class="modal-header mb-0" style="margin-bottom: 0 !important;">
    <h4 class="modal-title">{{objArr[0]}}</h4>
</div>
<div class="modal-body scrollModal pt-4">
    <form [formGroup]="addExceptionsform" role="form" class="singleLineForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Tenant ID*</label>
                        <select formControlName="tenant_id" class="form-control" placeholder="Tenant ID">
                            <option hidden value="" selected>Tenant ID</option>
                            <option *ngFor="let organization of organizationList" [value]="organization.tenant_id">
                                {{organization.tenant_id}}
                            </option>
                        </select>
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.tenant_id.errors?.required && addExceptionsform.controls.tenant_id.touched">
                            Tenant ID is required
                        </small>
                        <small class="text-danger" *ngIf="err">
                            Can't update exceptions some error happened.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <div class="sLine">
                        <label for="category-name" class="control-label">User Name</label>
                        <input formControlName="user_name" type="text" class="form-control" placeholder="User Name">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.user_name.errors?.required && addExceptionsform.controls.user_name.touched">
                            User Name is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Playbook Id*</label>
                        <!-- <input formControlName="playbook_id" type="text" class="form-control" placeholder="Playbook Id"> -->
                        <select formControlName="playbook_id" class="form-control" placeholder="Playbook ID">
                            <option hidden value="" selected>Playbook Id</option>
                            <option *ngFor="let organization of playbookIdData" [value]="organization.playbook_id">
                                {{organization.playbook_id}}-{{organization.playbook_name}}
                            </option>
                        </select>
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.playbook_id.errors?.required && submited">
                            Playbook Id is required
                        </small>
                    </div>
                </div>
                <!-- <div class="form-group" *ngIf="type=='UEBA'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">UEBA Id*</label>
                        <select formControlName="playbook_id" class="form-control" placeholder="UEBA ID">
                            <option hidden value="" selected>UEBA Id</option>
                            <option *ngFor="let organization of label_list" [value]="organization.ueba_id">
                                {{organization.playbook_name}}-{{organization.ueba_id}}
                            </option>
                        </select>
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.playbook_id.errors?.required && submited">
                            UEBA Id is required
                        </small>
                    </div>
                </div> -->
                <div class="form-group">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Host Name</label>
                        <input formControlName="host_name" type="text" class="form-control" placeholder="Host Name">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.host_name.errors?.required && addExceptionsform.controls.host_name.touched">
                            Host Name is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Source Ip</label>
                        <input formControlName="source_ip" type="text" class="form-control" placeholder="Source Ip">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.source_ip.errors?.required && addExceptionsform.controls.source_ip.touched">
                            Source Ip required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Process Name</label>
                        <input formControlName="process_name" type="text" class="form-control"
                            placeholder="Process Name">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.process_name.errors?.required && addExceptionsform.controls.process_name.touched">
                            Process Name is required
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group" *ngIf="type=='UEBA'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">UEBA Id*</label>
                        <!-- <input formControlName="playbook_id" type="text" class="form-control" placeholder="Playbook Id"> -->
                        <select formControlName="playbook_id" class="form-control" placeholder="UEBA ID">
                            <option hidden value="" selected>UEBA Id</option>
                            <option *ngFor="let organization of label_list" [value]="organization.ueba_id">
                                {{organization.playbook_name}}-{{organization.ueba_id}}
                            </option>
                        </select>
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.playbook_id.errors?.required && submited">
                            UEBA Id is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Process Executable</label>
                        <input formControlName="process_executable" type="text" class="form-control"
                            placeholder="Process Executable">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.process_executable.errors?.required && addExceptionsform.controls.process_executable.touched">
                            Process Executable is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Process Hash</label>
                        <input formControlName="process_hash" type="text" class="form-control"
                            placeholder="Process Hash">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.process_hash.errors?.required && addExceptionsform.controls.process_hash.touched">
                            Process Hash is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Parent Process Name</label>
                        <input formControlName="parent_process_name" type="text" class="form-control"
                            placeholder="Parent Process Name">
                    </div>
                    <div class="error-div" *ngIf="type=='Investigate'">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.parent_process_name.errors?.required && addExceptionsform.controls.parent_process_name.touched">
                            Parent Process Name is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Parent Process Executable</label>
                        <input formControlName="parent_process_executable" type="text" class="form-control"
                            placeholder="Parent Process Executable">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.parent_process_executable.errors?.required && addExceptionsform.controls.parent_process_executable.touched">
                            Parent Process Executable is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Url</label>
                        <input formControlName="url" type="text" class="form-control" placeholder="Url">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.url.errors?.required && addExceptionsform.controls.url.touched">
                            Url is required
                        </small>
                    </div>
                </div>
                <div class="form-group" *ngIf="type=='Investigate'">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Dest Ip</label>
                        <input formControlName="dest_ip" type="text" class="form-control" placeholder="Dest Ip">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.dest_ip.errors?.required && addExceptionsform.controls.dest_ip.touched">
                            Dest Ip is required
                        </small>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="category-name" class="control-label">Comments</label>
                    <textarea formControlName="comment" class="form-control" placeholder="Comment">
                        </textarea>
                </div>
                <div class="error-div">
                    <small class="text-danger" *ngIf="dataError && submited">
                        Fill any other data with Tenant ID and Playbook Id
                    </small>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer"> <button type="button" id="closebutton" class="btn btn-danger"
        (click)="clearForm()">Close</button>
    <button type="button" class="btn btn-success" (click)="exceptionsCreation()">{{btnName}}
        <i class="fab fa-telegram-plane ml-1"></i></button>
</div>