
import { Component, Input, OnInit } from '@angular/core';

import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ChartType } from '../dashboard/dashboard.model';
@Component({
  selector: 'cybot-investigation-vt-view',
  templateUrl: './investigation-vt-view.component.html',
  styleUrls: ['./investigation-vt-view.component.scss']
})
export class InvestigationVTViewComponent implements OnInit {
  hunt_detection
  user_name
  @Input() set DetectionId(val) {
    // console.log("analyticalData", val)
    this.hunt_detection = val
  }
  @Input() set UserName(val) {
    this.user_name = val;
  }
  data: any;
  revenueChart: ChartType;
  term: any;
  titleArray: any;
  api: any;
  query: any = '';
  method: any;
  dataTemp: any = []
  expectedValues: any
  datas: any;
  series: any = [];
  labels: any = [];
  type: any = []
  colors: any = [];
  itemVal = [];
  loding: boolean = true;
  count
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  constructor(public dashboardService: DashboardService) { }
  col12items = ['hash', 'url','ip']
  ngOnInit(): void {
    this.query = this.query;
    this.dashboardService.dashboardApiCYBOT(this.replaceDataObjects(this.query), this.api, this.method).subscribe(
      (result: any) => {
        // console.log("app-stat-count", result)
        this.loding = true;
        if (result) {
          if (result.hits) {
            let data = result.hits.hits;
            this.count = result.hits.total.value;
            if (data && data.length) {
              data.forEach(it => {
                this.expectedValues.forEach(element => {
                  // console.log("app-stat-count", element)
                  this.itemVal.push({
                    name: element,
                    val: it?._source[element],
                    color: element == 'vt_malicious' ? 'red' : element == 'vt_suspicious' ? '#b7b700' : element == 'vt_harmless' ? 'green' : '#c5c5c5'
                  })

                });
              });

            }
            this.loding = false;
            // console.log("itemVal", this.itemVal)
          } else {
            this.count = 0
            this.loding = false;
          }

        }
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }

  getRandomColor(): any {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  replaceDataObjects(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.match_phrase && it.match_phrase["hunt_detection_id"]) {
          it.match_phrase["hunt_detection_id"] = this.hunt_detection;
        }
      });
    }
    return data;
  }
}
