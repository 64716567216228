import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
const apiUrl = environment.apiUrl;
const loginUrl = environment.loginUrl;
const apiSsoUrl = environment.apiSsoUrl;
const apiSsoDataUrl = environment.apiSsoDataUrl;

@Injectable({
  providedIn: 'root'
})
export class RuleStudioService {
  HttpOptions: any;
  constructor(private http: HttpClient, private router: Router) { }

  getCatalogueMaster() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcatalogue?type=master',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getCatalogueTenant() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcatalogue?type=tenant',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getRefreshMaster() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/refresh/master',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getRefreshTenant() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/refresh/tenantcatalogue',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  AddAlerttoCollection(id?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/addalerttocollection?invid=' + id,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  removeAlertCollection(id?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/alertremovefromcollection?invid=' + id,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }




  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    }
    else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message ? error.message : 'No error message'}`;
    }
    console.log(error);
    return throwError(errorMessage);
  }

  getModulesInfo(id?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getmodulesinfo',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  createCustomRuleStudio(body: any, invid, type) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/invcreatealertinfo?invid=' + invid + '&type=' + type + '&masterkey=na', body,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getCustomRuleStudioByID(invid: string, type: string) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/invgetalertinfo?invid=' + invid + '&type=' + type,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  //hunt

  getHuntCatalogueMaster() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/getcatalogue?type=master',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getHuntCatalogueTenant() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/getcatalogue?type=tenant',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getHuntByID(invid: string) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/gethuntinfo?huntid=' + invid,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  createCustomRuleStudioHunt(body: any, huntid) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/hunt/createhuntinfo?huntid=' + huntid + '&masterkey=na', body,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  getHuntRefreshMaster() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/refreshmaster',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getHuntRefreshTenant() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/refreshtenant',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  AddHunttoCollection(id?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/addtocollection?huntid=' + id,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  removeHuntCollection(id?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/hunt/removefromcollection?huntid=' + id,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


}
