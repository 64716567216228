import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';

import { PagesRoutingModule } from './pages-routing.module';

import { NgbNavModule, NgbDropdownModule, NgbTooltipModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DndModule } from 'ngx-drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartModule } from './chart/chart.module';
import { TablesModule } from './tables/tables.module';
import { DataGridComponent } from './data-grid/data-grid.component';
import { ChartDonutComponent } from './chart-donut/chart-donut.component';
import { ChartBarComponent } from './chart-bar/chart-bar.component';
import { ChartDashboardComponent } from './chart-dashboard/chart-dashboard.component';
import { ShowItemComponent } from './show-item/show-item.component';
import { StatCountComponent } from './stat-count/stat-count.component';
import { HostNameFilterComponent } from './host-name-filter/host-name-filter.component';
import { DashbordTestComponent } from './dashbord-test/dashbord-test.component';
import { CybotDashbordComponent } from './cybot-dashbord/cybot-dashbord.component';
import { DataTableComponent } from './data-table/data-table.component';
import { SettingsComponent } from './settings/settings.component';
import { GaugeComponent } from './gauge/gauge.component';
import { TagCloudComponent } from './tag-cloud/tag-cloud.component';
import { UserCheckComponent } from './user-check/user-check.component';
import { DataStaticTableComponent } from './data-static-table/data-static-table.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { DashbordListComponent } from './dashbord-list/dashbord-list.component';
import { HomePageComponent } from './home-page/home-page.component';
import { CybotAnalyticsComponent } from './cybot-analytics/cybot-analytics.component';
import { SharedModule } from '../layouts/shared/shared.module';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { TextGridComponent } from './text-grid/text-grid.component';
import { DataTablesModule } from "angular-datatables";
import { CommunityThreatComponent } from './community/community-threat/community-threat.component';
import { ManagementSummaryComponent } from './management-summary/management-summary.component';
import { GeochartComponent } from './geochart/geochart.component';
import { ActivbytesThreadComponent } from './activbytes-thread/activbytes-thread.component';
import { VulnerabilitiesSummaryComponent } from './vulnerabilities-summary/vulnerabilities-summary.component';
import { SecurityIncidentsIntelligenceComponent } from './security-incidents-intelligence/security-incidents-intelligence.component';
import { ViewEventDetailsComponent } from './community/view-event-details/view-event-details.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CybotDynamicTableComponent } from './cybot-dynamic-table/cybot-dynamic-table.component';
import { CybotInvestigationViewComponent } from './cybot-investigation-view/cybot-investigation-view.component';
import { CybotDashboardViewComponent } from './cybot-dashboard-view/cybot-dashboard-view.component';
import { CybotDetectedObservablesComponent } from './cybot-detected-observables/cybot-detected-observables.component';
import { CybotProcessInformationObtainedFromDatalakeComponent } from './cybot-process-information-obtained-from-datalake/cybot-process-information-obtained-from-datalake.component';
import { HeadlinesComponent } from './headlines/headlines.component';
import { VulnerabilitiesExploitComponent } from './vulnerabilities-exploit/vulnerabilities-exploit.component';
import { InvestigationPieChartComponent } from './investigation-pie-chart/investigation-pie-chart.component';
import { InvestigationAVViewComponent } from './investigation-av-view/investigation-av-view.component';
import { InvestigationVTViewComponent } from './investigation-vt-view/investigation-vt-view.component';
import { InvestigationGaugeChartComponent } from './investigation-gauge-chart/investigation-gauge-chart.component';
import { InvestigationViewComponent } from './investigation-view/investigation-view.component';
import { AutomationExceptionsComponent } from './components/automation-exceptions/automation-exceptions.component';
import { AddExceptionsComponent } from './components/add-exceptions/add-exceptions.component';

import { CorrelationStudioComponent } from './correlation-studio/correlation-studio.component';
import { NgxPaginationModule } from 'ngx-pagination';
 
import { AddIntegrationsComponent } from './components/add-integrations/add-integrations.component';
import { ListIntegrationsComponent } from './components/list-integrations/list-integrations.component';
import { AddExceptionsUebaComponent } from './components/add-exceptions-ueba/add-exceptions-ueba.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent, ViewEventDetailsComponent, SecurityIncidentsIntelligenceComponent, VulnerabilitiesSummaryComponent, ActivbytesThreadComponent, GeochartComponent, ManagementSummaryComponent, DataGridComponent, ChartDonutComponent, ChartBarComponent, ChartDashboardComponent, ShowItemComponent, StatCountComponent, HostNameFilterComponent, DashbordTestComponent, CybotDashbordComponent, DataTableComponent, SettingsComponent, GaugeComponent, TagCloudComponent, UserCheckComponent, DataStaticTableComponent, UserSettingsComponent, DashbordListComponent, HomePageComponent, CybotAnalyticsComponent, TextGridComponent, CommunityThreatComponent, CybotDynamicTableComponent, CybotInvestigationViewComponent, CybotDashboardViewComponent, CybotDetectedObservablesComponent, CybotProcessInformationObtainedFromDatalakeComponent, HeadlinesComponent, VulnerabilitiesExploitComponent, CybotDynamicTableComponent, CybotInvestigationViewComponent, InvestigationPieChartComponent, InvestigationAVViewComponent, InvestigationVTViewComponent, InvestigationGaugeChartComponent, InvestigationViewComponent, AutomationExceptionsComponent, AddExceptionsComponent, CorrelationStudioComponent, AddIntegrationsComponent, ListIntegrationsComponent, AddExceptionsUebaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    NgxPaginationModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    DataTablesModule,
    NgbTooltipModule,
    NgApexchartsModule,
    PerfectScrollbarModule,
    DndModule,
    FullCalendarModule,
    NgbAccordionModule,
    LeafletModule,
    WidgetModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports:[AutomationExceptionsComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe
  ]
})
export class PagesModule { }
