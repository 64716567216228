import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { DatePipe } from "@angular/common";
import { CommonService } from "src/app/core/services/common.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { ActivatedRoute, Router } from "@angular/router";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";

@Component({
  selector: "app-vulnerabilities-exploit",
  templateUrl: "./vulnerabilities-exploit.component.html",
  styleUrls: ["./vulnerabilities-exploit.component.scss"],
})
export class VulnerabilitiesExploitComponent implements OnInit {
  constructor(
    private datePipe: DatePipe,
    private common: ThreatIntelligenceService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) {
    route.queryParams.subscribe((res) => {
      if (res.severity === "low") {
        this.getSeverityLow();
      }else if(res.severity === "medium"){
        this.getSeverityMediumOnly();
      }else if(res.severity === "high"){
        this.getSeverityHignOnly();
      }else if(res.severity === "critical"){
        this.getSeverityCriticalOnly();
      }
    });
  }

  vulnerabilitiesExploitData = [];
  dtTriggerVulnerabilitiesExploit: Subject<any> = new Subject<any>();

  selectedContent: any;

  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 20,
    lengthMenu: [5, 10, 25],
    processing: true,
    order: [],
  };
  period = {
    gte: "",
    lte: "",
  };

  modalId: number;
  countries = [];
  captureScreenModel;

  ngOnInit(): void {
    if (localStorage.getItem("isDateRange") == "true") {
      this.period = {
        gte: localStorage.getItem("startDate"),
        lte: localStorage.getItem("endDate"),
      };
    } else {
      this.period = {
        gte: localStorage.getItem("period"),
        lte: "now",
      };
    }

    // this.getVulnerabilitiesExploitData();
  }

  isVulnerabilitiesExploit = false;
  // getVulnerabilitiesExploitData() {
  //   let data = {
  //     size: 10000,
  //     query: {
  //       bool: {
  //         must: [],
  //         filter: [
  //           { match_all: {} },
  //           {
  //             match_phrase: {
  //               feed_name:
  //                 "Activebytes Innovations - Threat Intelligence Services",
  //             },
  //           },
  //           { match_phrase: { event_category: "Exploit" } },
  //           {
  //             range: {
  //               attribute_timestamp: {
  //                 gte: this.period.gte,
  //                 lte: this.period.lte,
  //                 format: "strict_date_optional_time",
  //               },
  //             },
  //           },
  //         ],
  //         should: [],
  //         must_not: [],
  //       },
  //     },
  //     aggs: {
  //       "1": {
  //         top_hits: {
  //           docvalue_fields: [
  //             {
  //               field: "attribute_timestamp",
  //               format: "date_time",
  //             },
  //           ],
  //           _source: "attribute_timestamp",
  //           size: 1,
  //           sort: [{ attribute_timestamp: { order: "desc" } }],
  //         },
  //       },
  //     },
  //   };
  //   this.isVulnerabilitiesExploit = true;
  //   this.common.getCommunityFeedChart(data).subscribe((response: any) => {
  //     this.isVulnerabilitiesExploit = false;
  //     if (response) {
  //       let hits = response.hits.hits;
  //       this.vulnerabilitiesExploitData = [];
  //       for (let i = 0; i < hits.length; i++) {
  //         this.vulnerabilitiesExploitData.push({
  //           event_name: hits[i]._source.event_name,
  //           event_description: hits[i]._source.event_description,
  //           cve: hits[i]._source.cve,
  //           tlp: hits[i]._source.tlp,
  //           cvss: hits[i]._source.cvss,
  //           vendor: hits[i]._source.vendor,
  //           attribute_timestamp: this.timeConvert1(
  //             hits[i]._source.attribute_timestamp
  //           ),
  //           severity: hits[i]._source.severity,
  //           // location: hits[i]._source.location.split(','),
  //         });
  //       }

  //       if (
  //         this.vulnerabilitiesExploitData &&
  //         this.vulnerabilitiesExploitData.length
  //       ) {
  //         this.vulnerabilitiesExploitData =
  //           this.vulnerabilitiesExploitData.reverse();
  //       }

  //       this.dtTriggerVulnerabilitiesExploit.next();
  //     }
  //   });
  //   (error) => {
  //     this.isVulnerabilitiesExploit = false;
  //     console.log("error", error);
  //   };
  // }

  // ///////////////////  low only
  getSeverityLow() {
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],

          filter: [
            {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            feed_name:
                              "Activebytes Innovations - Threat Intelligence Services",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            event_category: "Exploit",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            severity: "Low",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },
                ],
              },
            },
          ],

          should: [],

          must_not: [],
        },
      },
    };

    this.isVulnerabilitiesExploit = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      // console.log("getVulnerabilitiesExploitData", response);
      this.isVulnerabilitiesExploit = false;
      if (response) {
        let hits = response.hits.hits;
        this.vulnerabilitiesExploitData = [];
        for (let i = 0; i < hits.length; i++) {
          this.vulnerabilitiesExploitData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            cve: hits[i]._source.cve,
            tlp: hits[i]._source.tlp,
            cvss: hits[i]._source.cvss,
            vendor: hits[i]._source.vendor,
            attribute_timestamp: this.timeConvert1(
              hits[i]._source.attribute_timestamp
            ),
            severity: hits[i]._source.severity,
            // location: hits[i]._source.location.split(','),
          });
        }

        if (
          this.vulnerabilitiesExploitData &&
          this.vulnerabilitiesExploitData.length
        ) {
          this.vulnerabilitiesExploitData =
            this.vulnerabilitiesExploitData.reverse();
        }

        this.dtTriggerVulnerabilitiesExploit.next();
      }
    });
    (error) => {
      this.isVulnerabilitiesExploit = false;
      console.log("error", error);
    };
  }


  // medium only

  getSeverityMediumOnly(){
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],

          filter: [
            {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            feed_name:
                              "Activebytes Innovations - Threat Intelligence Services",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            event_category: "Exploit",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            severity: "Medium",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },
                ],
              },
            },
          ],

          should: [],

          must_not: [],
        },
      },
    };

    this.isVulnerabilitiesExploit = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      // console.log("getVulnerabilitiesExploitData", response);
      this.isVulnerabilitiesExploit = false;
      if (response) {
        let hits = response.hits.hits;
        this.vulnerabilitiesExploitData = [];
        for (let i = 0; i < hits.length; i++) {
          this.vulnerabilitiesExploitData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            cve: hits[i]._source.cve,
            tlp: hits[i]._source.tlp,
            cvss: hits[i]._source.cvss,
            vendor: hits[i]._source.vendor,
            attribute_timestamp: this.timeConvert1(
              hits[i]._source.attribute_timestamp
            ),
            severity: hits[i]._source.severity,
            // location: hits[i]._source.location.split(','),
          });
        }

        if (
          this.vulnerabilitiesExploitData &&
          this.vulnerabilitiesExploitData.length
        ) {
          this.vulnerabilitiesExploitData =
            this.vulnerabilitiesExploitData.reverse();
        }

        this.dtTriggerVulnerabilitiesExploit.next();
      }
    });
    (error) => {
      this.isVulnerabilitiesExploit = false;
      console.log("error", error);
    };
  }


  // High only

  getSeverityHignOnly(){
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],

          filter: [
            {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            feed_name:
                              "Activebytes Innovations - Threat Intelligence Services",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            event_category: "Exploit",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            severity: "High",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },
                ],
              },
            },
          ],

          should: [],

          must_not: [],
        },
      },
    };

    this.isVulnerabilitiesExploit = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      // console.log("getVulnerabilitiesExploitData", response);
      this.isVulnerabilitiesExploit = false;
      if (response) {
        let hits = response.hits.hits;
        this.vulnerabilitiesExploitData = [];
        for (let i = 0; i < hits.length; i++) {
          this.vulnerabilitiesExploitData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            cve: hits[i]._source.cve,
            tlp: hits[i]._source.tlp,
            cvss: hits[i]._source.cvss,
            vendor: hits[i]._source.vendor,
            attribute_timestamp: this.timeConvert1(
              hits[i]._source.attribute_timestamp
            ),
            severity: hits[i]._source.severity,
            // location: hits[i]._source.location.split(','),
          });
        }

        if (
          this.vulnerabilitiesExploitData &&
          this.vulnerabilitiesExploitData.length
        ) {
          this.vulnerabilitiesExploitData =
            this.vulnerabilitiesExploitData.reverse();
        }

        this.dtTriggerVulnerabilitiesExploit.next();
      }
    });
    (error) => {
      this.isVulnerabilitiesExploit = false;
      console.log("error", error);
    };
  }



  // critical filter

  getSeverityCriticalOnly(){
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],

          filter: [
            {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            feed_name:
                              "Activebytes Innovations - Threat Intelligence Services",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            event_category: "Exploit",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            severity: "Critical",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },
                ],
              },
            },
          ],

          should: [],

          must_not: [],
        },
      },
    };

    this.isVulnerabilitiesExploit = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      // console.log("getVulnerabilitiesExploitData", response);
      this.isVulnerabilitiesExploit = false;
      if (response) {
        let hits = response.hits.hits;
        this.vulnerabilitiesExploitData = [];
        for (let i = 0; i < hits.length; i++) {
          this.vulnerabilitiesExploitData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            cve: hits[i]._source.cve,
            tlp: hits[i]._source.tlp,
            cvss: hits[i]._source.cvss,
            vendor: hits[i]._source.vendor,
            attribute_timestamp: hits[i]._source.attribute_timestamp,
            severity: hits[i]._source.severity,
            // location: hits[i]._source.location.split(','),
          });
          
        }
        let sortedTimeStamp  = this.vulnerabilitiesExploitData.sort((a,b):any=>{

          let dateA = new Date(a.attribute_timestamp * 1000);
          let dateB = new Date(b.attribute_timestamp * 1000);

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        }).reverse()

        
        

        // if (this.vulnerabilitiesExploitData && this.vulnerabilitiesExploitData.length) {
        //   this.vulnerabilitiesExploitData = this.vulnerabilitiesExploitData.sort((a, b) =>
        //     a > b ? 1 : -1
        //   );
        // }

        // if (
        //   this.vulnerabilitiesExploitData &&
        //   this.vulnerabilitiesExploitData.length
        // ) {
        //   this.vulnerabilitiesExploitData =
        //     this.vulnerabilitiesExploitData.reverse();
        // }
      

        this.dtTriggerVulnerabilitiesExploit.next();
      }
    });
    (error) => {
      this.isVulnerabilitiesExploit = false;
      console.log("error", error);
    };
  }


  // PDF section

  openModal(viewmodal: any, content: any, modalId: number) {
    this.countries = [["Country"]];
    if (content.location) {
      for (let i in content.location) {
        this.countries.push([content.location[i]]);
      }
    }
    this.captureScreenModel = modalId;
    this.modalId = modalId;
    this.selectedContent = content;
    this.modalService.open(viewmodal, { size: "lg", centered: true });
  }

  captureScreen3() {
    var data = document.getElementById("contentToConvert3");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image();
      img.src = "assets/images/logo-dark.png";
      pdf.addImage(img, "png", 150, 5, 50, 18);
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name + ".pdf"); // Generated PDF
    });
  }

  captureScreenModelPrint() {
    if (this.captureScreenModel == 4) this.captureScreen3();
  }

  sortFn(){
    
  }

  timeConvert1(t) {
    let time = this.datePipe.transform(t * 1000, "medium");
    return time;
  }

  goToBack() {
    this.router.navigate(["/management-summary"]);
  }
}
