<!-- <app-titlebarwithdesc *ngIf="allowedWidget.includes(item.type)" [title]="item.analyticalData.title"
    [desc]="item.analyticalData.titleBody"></app-titlebarwithdesc> -->

<div class="card h-100">
    <div class="card-body">
        <app-chart-donut [analyticalData]="item.analyticalData" *ngIf="item.type=='donut'"></app-chart-donut>
        <app-chart-bar [analyticalData]="item.analyticalData" *ngIf="item.type=='bar'"></app-chart-bar>
        <app-data-grid [analyticalData]="item.analyticalData" *ngIf="item.type=='data'"></app-data-grid>
        <app-data-static-table [analyticalData]="item.analyticalData" *ngIf="item.type=='data_static'"></app-data-static-table>
        <app-stat-count [analyticalData]="item.analyticalData" *ngIf="item.type=='stat'"></app-stat-count>
        <!-- <app-host-name-filter [analyticalData]="item.analyticalData" *ngIf="item.type=='filter'"></app-host-name-filter> -->
        <app-gauge [analyticalData]="item.analyticalData" *ngIf="item.type=='gauge'"></app-gauge>
        <app-tag-cloud [analyticalData]="item.analyticalData" *ngIf="item.type=='tag_cloud'"></app-tag-cloud>
        <app-text-grid [analyticalData]="item.analyticalData" *ngIf="item.type=='text_grid'"></app-text-grid>
    </div>
</div>