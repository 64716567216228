<!-- <div class="table-responsive table-rev">
    <table class="table table-centered">
        <tbody>
            <tr *ngFor="let row of titleArray;let i = index">
                <td>{{row}}:</td>
                <td>{{filteredData[0][i]}}</td>
            </tr>
        </tbody>
    </table>
</div> -->

<div *ngIf="!isLoader">
    <div>
        <h5 class="font-size-14" [class]="datas?.title=='Detected Observables'?'title-main':''">{{datas?.title}}</h5>
        <p>{{datas.description}}</p>
        <div class="row custDetCard bg-card col-md-12 m-0 pb-3">
            <h5 class="font-size-13 col-md-12"></h5>
            <div class="col-md-6 py-0 my-0" *ngFor="let row of titleArray;let i = index">
                <div class="row custText">
                    <div class="col-md-4">{{row}}</div>
                    <div class="col-md-8 word-wrap"><span>{{': '+filteredDataFn(filteredData,i)}}</span></div>
                </div>
                <!-- <p class="text-muted mb-0 card-text1 custTextValues">
                    <span></span>
                </p> -->
            </div>
            <div class="col-md-6 py-0 my-0"></div>
        </div>
    </div>
</div>
<div class="loader" *ngIf="isLoader"></div>