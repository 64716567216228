import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartType } from '../dashboard/dashboard.model';
import { ActivatedRoute, Router } from "@angular/router";
import { UebaService } from 'src/app/core/services/ueba.service';
import * as moment from 'moment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as echarts from 'echarts';

import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexPlotOptions,
  ApexLegend,
  ChartComponent
} from "ng-apexcharts";
import { CorrelationStudioService } from 'src/app/core/services/correlation-studio.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
};

@Component({
  selector: 'app-correlation-studio',
  templateUrl: './correlation-studio.component.html',
  styleUrls: ['./correlation-studio.component.scss']
})
export class CorrelationStudioComponent implements OnInit {

  userName: string;
  hostName: string;
  isGeneral = false;
  correlationShow = false;
  isUser = true;
  selectedHost: string;
  selectedUser: string;
  userList = [];
  hostList = [];
  period = {
    gte: '',
    lte: ''
  };
  ismostCommonDetections = false;
  isleastCommonDetections = false;
  istacticsDetected = false;
  istacticsName = false;
  pidLists = ["MITRE-001", "MITRE-002", "MITRE-003", "MITRE-004", "MITRE-005", "MITRE-007", "MITRE-008", "MITRE-009", "MITRE-015", "MITRE-016", "MITRE-028", "MITRE-031", "MITRE-047", "MITRE-048", "MITRE-030", "MITRE-032", "MITRE-033", "MITRE-036"
    , "MITRE-014", "MITRE-017", "MITRE-020", "MITRE-021", "MITRE-023", "MITRE-027", "MITRE-057", "MITRE-059"
    , "MITRE-063", "MITRE-064", "MITRE-065", "MITRE-066", "MITRE-067", "MITRE-068", "MITRE-069", "MITRE-070", "MITRE-071", "MITRE-073", "MITRE-075", "MITRE-076", "MITRE-022", "MITRE-029", "MITRE-024", "MITRE-019", "MITRE-013",
    "MITRE-010", "MITRE-011", "MITRE-012", "MITRE-038", "MITRE-042", "MITRE-043", "MITRE-046", "MITRE-049", "MITRE-050", "MITRE-051", "MITRE-053", "MITRE-061", "MITRE-062", "MITRE-074", "MITRE-078",
    , "MITRE-006", "MITRE-034", "MITRE-037", "MITRE-039", "MITRE-040", "MITRE-041", "MITRE-025", "MITRE-026", "MITRE-035", "MITRE-052", "MITRE-072", "MITRE-044", "MITRE-045", "MITRE-054", "MITRE-055", "MITRE-060", "MITRE-077",
    "MITRE-AA01", "MITRE-AA03", "MITRE-AA04", "MITRE-AA06", "MITRE-AA07", "MITRE-AA02", "MITRE-TI01", "MITRE-TI02", "MITRE-TI03", "TI-01", "TI-02", "TI-03", "AA-01", "AA-03", "AA-04", "AA-06", "AA-02", "AA-05", "AA-07", "MITRE-079", "MITRE-080", "MITRE-081", "MITRE-082", "MITRE-083"];
  @ViewChild("chart") chart: ChartComponent;
  treeChartData: any = []
  public chartOptions: Partial<ChartOptions> = {
    series: [
      {
        data: [
          {
            x: "New Delhi",
            y: 218
          },
          {
            x: "Kolkata",
            y: 149
          },
          {
            x: "Mumbai",
            y: 184
          },
          {
            x: "Ahmedabad",
            y: 55
          },
          {
            x: "Bangaluru",
            y: 84
          },
          {
            x: "Pune",
            y: 31
          },
          {
            x: "Chennai",
            y: 70
          },
          {
            x: "Jaipur",
            y: 30
          },
          {
            x: "Surat",
            y: 44
          },
          {
            x: "Hyderabad",
            y: 68
          },
          {
            x: "Lucknow",
            y: 28
          },
          {
            x: "Indore",
            y: 19
          },
          {
            x: "Kanpur",
            y: 29
          }
        ]
      }
    ],
    legend: {
      show: false
    },
    // title: {
    //   text: "Distibuted Treemap (different color for each cell)",
    //   align: "center"
    // },
    chart: {
      height: 350,
      // type: 'treemap'
      type: 'bar'
    },
    colors: [
      "#3B93A5",
      "#F7B844",
      "#ADD8C7",
      "#EC3C65",
      "#CDD7B6",
      "#C1F666",
      "#D43F97",
      "#1E5D8C",
      "#421243",
      "#7F94B0",
      "#EF6537",
      "#C0ADDB"
    ],
    // plotOptions: {
    //   treemap: {
    //     distributed: true,
    //     enableShades: false
    //   }
    // }
  };
  tactics_chart: ChartType = {
    series: [{
      data: [],
    }],
    chart: {
      height: 250,
      type: 'line',
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (event, chartContext, obj) {
          return document.location.href = obj.w.config.series[obj.seriesIndex].link[obj.dataPointIndex];
        }
      }
    },

    plotOptions: {
      bar: {
        barHeight: "30%",
        horizontal: false,
        dataLabels: {
          hideOverflowingLabels: true,
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["#1cbb8c", "#5664d2", "#fcb92c"],
    labels: [],
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true,
        maxWidth: 30,
      },
    },
  };
  constructor(private router: Router, private route: ActivatedRoute, public correlation: CorrelationStudioService, private modalService: NgbModal) {



  }
  mostCommon = [];
  leastCommon = [];
  techniqNames = [];
  selectedTitle = '';
  selectedName = '';
  isLoadingNames = false;
  isLoadingDetails = false;
  techDetails = [];
  mychart: any;
  ngOnInit(): void {
    if (localStorage.getItem('isDateRange') == "true") {
      this.period = {
        gte: localStorage.getItem('startDate'),
        lte: localStorage.getItem('endDate')
      }
    } else {
      this.period = {
        gte: localStorage.getItem('period'),
        lte: 'now'
      }
    }

    this.hostName = this.route.snapshot.paramMap.get("host");
    this.userName = this.route.snapshot.paramMap.get("user");
    console.log(this.hostName)
    console.log(this.userName)
    let urlArray = this.router.url.split("/");
    if (urlArray[urlArray.length - 1] != undefined) {
      let id = urlArray[urlArray.length - 1];
      console.log("id", id)
      if (this.hostName != undefined && this.userName != undefined) {
        this.correlationShow = false
        this.isGeneral = true;
        this.selectedUser = this.userName
        this.selectedHost = this.hostName
        this.mostCommonDetections(this.userName);
        this.leastCommonDetections(this.userName);
        this.tacticsDetected(this.userName);
        this.tacticsName(this.userName);
      } else if (id != 'correlation-studio') {
        this.correlationShow = true
        this.userName = id;
        this.isGeneral = true;
        this.mostCommonDetections();
        this.leastCommonDetections();
        this.tacticsDetected();
        this.tacticsName();
      } else {
        this.correlationShow = false
        this.getUsers();
        this.getHosts();
      }
    }
    // var domChart = document.getElementById('chart-containertree')!
    // this.mychart = echarts.init(domChart, 'canvas');


    // http://localhost:4200/correlation-studio/ASUS

  }

  tabsList = [];

  advancedHunts = {
    completed: 0,
    inProgress: 0,
    error: 0,
  };
  isAdvHunts: boolean = false;
  advancedTableData = [];

  intelligentHunts = {
    completed: 0,
    inProgress: 0,
    error: 0,
  };
  isIntHunts: boolean = false;
  intelligentTableData = [];

  showTab(i) {
    this.tabsList.forEach((ae, j) => {
      if (i == j) ae.show = true;
      else ae.show = false;
    })
  }
  public generateData(count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
      var x = "w" + (i + 1).toString();
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y
      });
      i++;
    }
    return series;
  }

  mostCommonDetections(name?) {
    console.log("name", name)
    let passname: any;
    if (name) {
      if (this.isUser) passname = { "user": this.selectedUser }
      if (!this.isUser) passname = { "host": this.selectedHost }
    } else passname = { "user": this.userName }
    this.ismostCommonDetections = true;
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "filter": [{
            "match_all": {}
          }, {
            "match_phrase": passname
          }, {
            "range": {
              "current_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }]
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "detection_name",
            "order": {
              "_count": "desc"
            },
            "size": 5
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        this.ismostCommonDetections = false;
        this.mostCommon = data.aggregations[2]?.buckets;
      },
      error => {
        this.ismostCommonDetections = false;
        console.log(error);
      })
  }

  leastCommonDetections(name?) {
    let passname: any;
    if (name) {
      if (this.isUser) passname = { "user": this.selectedUser }
      if (!this.isUser) passname = { "host": this.selectedHost }
    } else passname = { "user": this.userName }
    this.isleastCommonDetections = true;
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "filter": [{
            "match_all": {}
          }, {
            "match_phrase": passname
          }, {
            "range": {
              "current_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }]
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "detection_name",
            "order": {
              "_count": "asc"
            },
            "size": 5
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        this.isleastCommonDetections = false;
        this.leastCommon = data.aggregations[2]?.buckets;
      },
      error => {
        this.isleastCommonDetections = false;
        console.log(error);
      })
  }

  tacticsDetected(name?) {
    let passname: any;
    if (name) {
      if (this.isUser) passname = { "user": this.selectedUser }
      if (!this.isUser) passname = { "host": this.selectedHost }
    } else passname = { "user": this.userName }
    this.istacticsDetected = true;
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "must": [],
          "filter": [{
            "match_all": {}
          }, {
            "match_phrase": passname
          }, {
            "range": {
              "detection_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }],
          "should": [],
          "must_not": []
        }
      },
      "aggs": {
        "2": {
          "date_histogram": {
            "field": "detection_timestamp",
            "calendar_interval": "1d",
            "time_zone": "Asia/Kolkata",
            "min_doc_count": 1
          },
          "aggs": {
            "3": {
              "terms": {
                "field": "tactic_name",
                "order": {
                  "_count": "desc"
                },
                "size": 1000
              }
            }
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        this.istacticsDetected = false;
        let details = data.aggregations[2]?.buckets;
        this.tactics_chart.series[0].data = [];
        this.tactics_chart.labels = [];
        details.forEach(ao => {
          this.tactics_chart.series[0].data.push(ao.doc_count);
          this.tactics_chart.labels.push(moment(ao.key_as_string).lang("en").format('MMMM DD'));
        });
      },
      error => {
        this.istacticsDetected = false;
        console.log(error);
      })
  }


  tacticsName(name?) {
    let passname: any;
    // this.mychart.showLoading();

    if (name) {
      if (this.isUser) passname = { "user": this.selectedUser }
      if (!this.isUser) passname = { "host": this.selectedHost }
    } else passname = { "user": this.userName }
    this.istacticsDetected = true;
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "filter": [{
            "match_all": {}
          }, {
            "match_phrase": passname
          }, {
            "range": {
              "current_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }]
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "tactic_name",
            "order": {
              "_count": "desc"
            },
            "size": 1000
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        this.istacticsName = false;
        this.tabsList = [];
        let d = data?.aggregations[2]?.buckets;
        d.forEach((ae, i) => {
          this.tabsList.push({
            name: ae.key,
            count: ae.doc_count,
            show: i == 0 ? true : false
          })
          if (i == 0) {
            this.selectedTitle = this.tabsList[0].name;
            if (this.userName) this.getTechniqNames(this.tabsList[0]);
            else this.getTechniqNames(this.tabsList[0], this.isUser ? this.selectedUser : this.selectedHost)
          }
        })
        // this.tabsList.forEach(async (value) => {
        //   var child = []
        //   await this.getTechniqNames(value)
        // })

      },
      error => {
        this.istacticsName = false;
        console.log(error);
      })
  }

  getTechniqNames(item, name?) {
    let passname: any;
    if (name) {
      if (this.isUser) passname = { "user": this.selectedUser }
      if (!this.isUser) passname = { "host": this.selectedHost }
    } else passname = { "user": this.userName }
    this.techniqNames = [];
    this.selectedTitle = item.name;
    this.isLoadingNames = true;
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "filter": [{
            "match_all": {}
          }, {
            "match_phrase": passname
          }, {
            "match_phrase": {
              "tactic_name": item.name
            }
          }, {
            "range": {
              "current_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }]
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "technique_name",
            "order": {
              "_count": "desc"
            },
            "size": 1000
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        this.isLoadingNames = false;
        console.log("getTechniqNames", data)
        let d = data?.aggregations[2]?.buckets;
        d.forEach((ae, i) => {
          this.techniqNames.push({
            name: ae.key,
            count: ae.doc_count
          })
        })
        // var child = []
        // d.forEach((val) => {
        //   child.push(
        //     { 'name': val.key, 'value': val.doc_count })
        // })
        // this.minmaptreedata.children.push({
        //   'name': item.name + ' (' + item.count + ')',
        //   "children": child
        // })
        // if (this.tabsList.indexOf(item) == this.tabsList.length - 1)
        //   this.loadtreechart()
      },
      error => {
        this.isLoadingNames = false;
        console.log(error);
      })
  }


  getTechniqDetails(detail, content: any, name?) {
    let passname: any;
    if (name) {
      if (this.isUser) passname = { "user": this.selectedUser }
      if (!this.isUser) passname = { "host": this.selectedHost }
    } else passname = { "user": this.userName }
    this.selectedName = detail.name;
    // this.modalService.open(content, {
    //   size: 'lg', windowClass: 'modal-holder', centered: true
    // });
    this.isLoadingDetails = true;
    this.techDetails = [];
    let data = {
      "size": 1000,
      "query": {
        "bool": {
          "must": [],
          "filter": [{
            "match_all": {}
          }, {
            "match_phrase": {
              "technique_name": detail.name
            }
          }, {
            "match_phrase": passname
          }, {
            "range": {
              "current_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }],
          "should": [],
          "must_not": []
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        console.log("techDetails", data);
        this.isLoadingDetails = false;
        let d = data?.hits?.hits;
        d.forEach(ae => {
          this.techDetails.push({
            detection_time: ae._source.detection_timestamp,
            detection_name: ae._source.detection_name,
            hunt_detection_id: ae._source.hunt_detection_id,
            playbook_id: ae._source.playbook_id,
          });
        })
        console.log("techDetails", this.techDetails)
      },
      error => {
        console.log(error);
        this.isLoadingDetails = false;
      })
  }

  viewDashbordModal(id, pid) {
    console.log(id, pid);
  }
  // viewDashbord(tech, id, pid) {
  // if (this.pidLists.includes(pid)) {
  //   let a = btoa(id);
  //   let aa = "/hunt-summary/" + pid + "/" + a
  //   window.open(aa, "_blank");
  // } else {
  //   let a = btoa(id);
  //   let aa = "/hunt-summary/dashboard/" + a;
  //   window.open(aa, "_blank");
  // }
  // }
  viewDashbord(content, hunt_detection_id, playbook_id) {
    console.log("content", content)
    console.log("host", this.hostName)
    console.log("user", this.userName)
    this.router.navigate([
      "/cybot-dashboard/" + content.hunt_detection_id,
      {
        host: this.hostName,
        // hash: content.hash,
        // ip: content.ip,       
        user: this.userName,
        hunt_detection_id: content.hunt_detection_id,
        playbook_id: content.playbook_id,
        group_id: content.grp_id
      },
    ]);
  }

  getUsers() {
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "filter": [{
            "match_all": {}
          }, {
            "range": {
              "detection_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }]
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "user",
            "order": {
              "_count": "desc"
            },
            "size": 10000
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        if (data.aggregations) {
          let d = data.aggregations[2].buckets;
          d.forEach(ae => {
            this.userList.push(ae.key);
          });
          if (this.userList.length > 0) this.selectedUser = this.userList[0];
        }     
        this.callFunctions(this.selectedUser);
      },
      error => {
        console.log(error);
        this.isLoadingDetails = false;
      })
  }

  getHosts() {
    let data = {
      "size": 0,
      "query": {
        "bool": {
          "filter": [{
            "match_all": {}
          }, {
            "range": {
              "detection_timestamp": {
                "gte": this.period.gte,
                "lte": this.period.lte,
                "format": "strict_date_optional_time"
              }
            }
          }]
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "host",
            "order": {
              "_count": "desc"
            },
            "size": 10000
          }
        }
      }
    }
    this.correlation.thpEnrichedObservables(data).subscribe(
      (data: any) => {
        if (data.aggregations) {
          let d = data.aggregations[2].buckets;
          d.forEach(ae => {
            this.hostList.push(ae.key)
          });
          if (this.hostList.length > 0) this.selectedHost = this.hostList[0];
        }
      },
      error => {
        console.log(error);
        this.isLoadingDetails = false;
      })
  }

  callFunctions(name?) {
    console.log("name", name)
    console.log("isUser", this.isUser)
    console.log("this.userList[0]", this.userList[0])
    console.log("this.hostList[0]", this.hostList[0])
    if (name == undefined) {
      name = this.isUser ? this.userList[0] : this.hostList[0];
    }
    this.mostCommonDetections(name);
    this.leastCommonDetections(name);
    this.tacticsDetected(name);
    this.tacticsName(name);
  }

  // minmaptreedata: any = {
  //   "name": "Tactics",
  //   "children": [

  //   ]
  // };

  // loadtreechart() {
  //   echarts.use([TooltipComponent, TreeChart, CanvasRenderer]);

  //   var option;



  //   this.mychart.hideLoading();
  //   this.minmaptreedata.children.forEach(function (datum, index) {
  //     index % 2 === 0 && (datum.collapsed = true);
  //   });
  //   this.mychart.setOption(
  //     (option = {
  //       tooltip: {
  //         trigger: 'item',
  //         triggerOn: 'mousemove'
  //       },
  //       series: [
  //         {
  //           type: 'tree',
  //           data: [this.minmaptreedata],
  //           top: '1%',
  //           left: '7%',
  //           bottom: '1%',
  //           right: '20%',
  //           symbolSize: 7,
  //           label: {
  //             position: 'left',
  //             verticalAlign: 'middle',
  //             align: 'right',
  //             fontSize: 9
  //           },
  //           leaves: {
  //             label: {
  //               position: 'right',
  //               verticalAlign: 'middle',
  //               align: 'left'
  //             }
  //           },
  //           emphasis: {
  //             focus: 'descendant'
  //           },
  //           expandAndCollapse: true,
  //           animationDuration: 550,
  //           animationDurationUpdate: 750
  //         }
  //       ]
  //     })
  //   );




  // }

  goToBack() {
    this.router.navigate(["/investigation"]);
  }
}
