export class User {
    id: number;
    username: string;
    password: string;
    firstName?: string;
    lastName?: string;
    token?: string;
    email: string;
}

export const DateFormatArray=[
    "MMM d, y",
    "MM/dd/yyy",
    "MM-dd-yyy"
]

export const TimePeriod=[
    "12 Hour",
    "24 Hour"
]
