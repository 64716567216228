<cybot-dynamic-table [UserName]="user_name" [DetectionId]="hunt_detection_id" [value]="values"
    *ngIf="values['model']==='Table'">
</cybot-dynamic-table>
<cybot-investigation-pie-chart [UserName]="user_name" [DetectionId]="hunt_detection_id" [analyticalData]="values"
    *ngIf="values['model']==='pie'">
</cybot-investigation-pie-chart>
<cybot-investigation-av-view [UserName]="user_name" [DetectionId]="hunt_detection_id" [analyticalData]="values"
    *ngIf="values['model']==='av'">
</cybot-investigation-av-view>
<cybot-investigation-vt-view [UserName]="user_name" [DetectionId]="hunt_detection_id" [analyticalData]="values"
    *ngIf="values['model']==='vt'">
</cybot-investigation-vt-view>
<app-investigation-gauge-chart [UserName]="user_name" [DetectionId]="hunt_detection_id" [analyticalData]="values"
    *ngIf="values['model']==='gauge'">
</app-investigation-gauge-chart>
<app-investigation-view [UserName]="user_name" [DetectionId]="hunt_detection_id" [value]="values"
    *ngIf="values['model']==='View'">
</app-investigation-view>