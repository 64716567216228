import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cybot-detected-observables',
  templateUrl: './cybot-detected-observables.component.html',
  styleUrls: ['./cybot-detected-observables.component.scss']
})
export class CybotDetectedObservablesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
