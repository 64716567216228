import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, switchMap } from "rxjs/operators";
import { RuleStudioService } from "../../../core/services/rule-studio.service"

@Component({
  selector: 'app-hunt-list',
  templateUrl: './hunt-list.component.html',
  styleUrls: ['./hunt-list.component.scss']
})
export class HuntListComponent implements OnInit {

  editFlag = false
  user_prv;
  masterCatalogue: InvestigateCatalog[] = []
  tenantCatalogue: InvestigateCatalog[] = [];
  isLoaderShow: boolean = false;
  isOutputShow: boolean = false;
  isInputShow: boolean = false;
  constructor(private ruleStudioService: RuleStudioService, private router: Router) { }

  ngOnInit(): void {
    this.getData();
    this.user_prv = localStorage.getItem("user_prv_data") ? localStorage.getItem("user_prv_data") : "r";
    if (this.user_prv == 'rw') {
      this.editFlag = true
    } else {
      this.editFlag = false
    }

  }

  getData() {
    this.isLoaderShow = true;
    this.tenantCatalogue = [];
    this.masterCatalogue = [];
    this.ruleStudioService.getHuntCatalogueTenant().pipe(
      switchMap((res: any) => {
        res.forEach(data => {
          let tenant = new InvestigateCatalog();
          tenant.data = data;
          this.tenantCatalogue.push(tenant);
        });
        return this.ruleStudioService.getHuntCatalogueMaster()
      }),
      catchError((e) => {
        console.log(e)
        // handle e and return a safe value or re-throw
        return this.ruleStudioService.getHuntCatalogueMaster()
      })
    ).subscribe((res: any) => {
      res.forEach(data => {
        let catalogue = new InvestigateCatalog();
        catalogue.data = data;
        if (this.tenantCatalogue.find(v => v.data[0] == data[0])) {
          catalogue.isDisabled = true;
        }
        this.masterCatalogue.push(catalogue);
      });
      this.isLoaderShow = false;
    })
  }

  onAdd(inputCatalogue) {
    if (!inputCatalogue.isDisabled) {
      this.isInputShow = true;
      this.ruleStudioService.AddHunttoCollection(inputCatalogue.data[0])
        .subscribe((_) => {
          this.isInputShow = false;
          this.getData();
        }, err => this.isInputShow = false
        )
    }
  }

  onRemove(outPutCatalogue) {
    if (!outPutCatalogue.isDisabled) {
      this.isOutputShow = true;
      this.ruleStudioService.removeHuntCollection(outPutCatalogue.data[0]).subscribe((_) => {
        this.isOutputShow = false;
        this.getData();
      }, err => this.isOutputShow = false
      )
    }
  }
  onRefresh() {
    this.isLoaderShow = true;
    this.ruleStudioService.getHuntRefreshTenant().pipe(
      switchMap(_ => {
        return this.ruleStudioService.getHuntRefreshMaster()
      })
    ).subscribe((_) => {
      this.getData();
    })
  }
  gotoCustom() {
    this.router.navigateByUrl('rule-studio/custom-hunter');
  }
  onEdit(outPutCatalogue) {
    this.router.navigateByUrl('rule-studio/custom-hunter-edit/' + outPutCatalogue.data[0]);
  }
}

export class InvestigateCatalog {
  data: any;
  isDisabled: boolean;

  constructor(InvestigateCatalog: InvestigateCatalog = null) {
    if (InvestigateCatalog != null) {
      this.isDisabled = InvestigateCatalog.isDisabled;
      this.data = InvestigateCatalog.data;
    } else {
      this.isDisabled = false;
    }
  }
}
