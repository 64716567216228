<!-- <div class="">
    <div class="card-body"> -->
<div class="dropdown float-right" ngbDropdown>
    <a href="javascript: void(0);" class="dropdown-toggle arrow-none card-drop" ngbDropdownToggle data-toggle="dropdown"
        aria-expanded="false">
        <i class="mdi mdi-dots-vertical"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item">Sales Report</a>
        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item">Export Report</a>
        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item">Profit</a>
        <!-- item-->
        <a href="javascript:void(0);" class="dropdown-item">Action</a>
    </div>
</div>

<!-- <h1 class="statCount">{{datas.statCount}}</h1> -->

<div class="row">
    <div class="col-md-9">
        <h4 class="card-title mb-4"><b>{{datas.title}}</b>{{datas.titleBody}}</h4>
    </div>

    <div class="col-md-3">
        <div class="progressCard">
            <div class="w3-light-grey w3-round-xlarge">
                <div class="w3-container w3-green w3-round-xlarge" [ngStyle]="{'width': getStyle('confirmity') }">
                </div>
            </div>
            <span>
                Conformity
                {{this.maxConfirmity-this.unconformity}} / {{this.maxConfirmity}}
            </span>
        </div>

        <div class="progressCard">
            <div class="w3-light-grey w3-round-xlarge">
                <div class="w3-container w3-danger w3-round-xlarge" [ngStyle]="{'width': getStyle() }">
                </div>
            </div>
            <span>
                Unconformity
                {{this.unconformity}} / {{this.maxConfirmity}}</span>
        </div>


    </div>
</div>

<div class="table-responsive">

    <!-- <div class="tab">
        <button [class.active]="tab === selTab" class="tablinks" *ngFor="let tab of datas.tabs"
            (click)="getTabItems(tab)">{{tab}}</button>
    </div> -->
    <table class="table table-centered control-view-table">
        <thead class="thead-light">
            <tr>
                <th scope="col" *ngFor="let col of tableVal?.titleData">{{col}}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let colValArr of tableVal?.data">
                <tr class="main_heading" *ngIf="colValArr.heading">
                    <td *ngFor="let col of colValArr.heading;let i = index">{{col}}</td>
                    <td></td>
                </tr>
                <tr (click)="showDetails(colValArr)">
                    <td *ngFor="let col of colValArr.col;let i = index">{{col}}</td>
                    <td *ngIf="colValArr.details">
                        {{colValArr.showDetails?"&#800;":"&#43;"}}</td>
                </tr>
                <ng-container *ngFor="let subArray of colValArr.details">
                    <tr *ngIf="colValArr.showDetails" (click)="showDetails1(subArray)" class="inner">
                        <td *ngFor="let col of subArray.col;let i = index">{{col}}</td>
                        <td>
                            <ng-container *ngIf="subArray.details">
                                {{subArray.showDetails?"&#9650;":"&#9660;"}}
                            </ng-container>
                        </td>
                    </tr>
                    <tr *ngIf="subArray.showDetails">
                        <td [attr.colspan]="tableVal?.titleData?.length+1" class="inner_details">
                            <form [formGroup]="unconformityForm" class="w-100" autocomplete="off">
                                <textarea formControlName="details" style="width: 200px;">
                                    {{subArray.details}}
                                </textarea>
                                <button (click)="saveDataDb(subArray.details)">
                                    Update
                                </button>
                            </form>
                        </td>
                    </tr>
                </ng-container>

            </ng-container>
        </tbody>
    </table>
</div>
<!-- </div>
</div> -->