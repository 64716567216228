<app-topbar>
</app-topbar>
<div class="page-content huntingModule">
<div class="container pt-2">

    <!-- two tabs -->

    <!-- <div class="community-container flex">
        <div class="col-md-6  dash-item flex community-card">
            <div class="card">
                <div class="overlay"></div>
                <img class="dashboard-img" src="assets/images/analysis.png">
            </div>
            <h2 class="dashboard-card-title">Community Event</h2>
        </div>
    <div class="col-md-6  dash-item flex community-card">
        <div class="card">
            <div class="overlay"></div>
            <img class="dashboard-img" src="assets/images/analysis.png">
        </div>
    <h2 class="dashboard-card-title">Community Feed</h2>
    </div>
    </div> -->

    <!-- <app-pagetitle title="Community Threat Feeds" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
    <!-- <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
        <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
        <button class="btn btn-warning" (click)="openModal(viewModal,1)" [disabled]="isLoading"><i class="fa fa-print"
                [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-file-pdf': !isLoading}"></i> {{button}}</button>
    </p> -->
    <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h2 class="mb-0">Community Intelligence</h2>
            <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
                <button class="btn btn-themePrint" (click)="getPdf()" [disabled]="isLoading"><i class="fa fa-print"
                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-file-pdf': !isLoading}"></i> {{button}}</button>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pb-2">
            <div class="card card-body h-95">
                <h2 class="card-title mt-0 sub-heading">Community Intelligence overview</h2>
                <p class="card-text paragraph-clr">The threat intelligence sharing platform is configured to sync information with
                    various community threat intelligence sources. These contain information about specific threats and
                    acts as a database for Indicators of compromise to help
                    with threat hunting and other activities requiring threat intelligence. The below visualizations
                    show summary of the data that has been acquired from all the community sources. It includes the
                    breakdown which shows the distribution
                    of feeds, the timeline of data that has arrived, the type of Indicators/Attributes that have been
                    obtained etc.
                </p>
            </div>
        </div>

        <div class="col-md-6 pb-2">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading">Community Feeds</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">Distribution of communities from which feeds are fetched</p>
                    <div class="d-flex pabs100 mt-9em"><img class="m-auto" width="200" *ngIf="isCommunityFeedLoading"
                            src="assets/images/fileloading.gif" /></div>
                    <div style="display: none;" echarts [options]="communityFeedChart" class="demo-chart" id="communityPie"></div>
                    <apx-chart *ngIf="!isCommunityFeedLoading" id="mostAnomalousPieHost" dir="ltr"
                                class="apex-charts" [series]="communitybarChart.series"
                                [chart]="communitybarChart.chart" [legend]="communitybarChart.legend"
                                [colors]="communitybarChart.colors" [labels]="communitybarChart.labels"
                                [dataLabels]="communitybarChart.dataLabels"
                                [plotOptions]="communitybarChart.plotOptions" [xaxis]="communitybarChart.xaxis" [yaxis]="communitybarChart.yaxis">
                    </apx-chart>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-7 pb-2" id="contentToConvert1">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading">Feeds distribution over time</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">This panel shows the feeds fetched by communities over time</p>
                    <div class="d-flex pabs100 mt-9em"><img class="m-auto" width="200"   *ngIf="isEventDisribution1"
                            src="assets/images/fileloading.gif" /></div>
                    <apx-chart id="feedDisribution" class="apex-charts" dir="ltr" *ngIf="!isEventDisribution1" [series]="feedDisribution.series"
                        [chart]="feedDisribution.chart" [plotOptions]="feedDisribution.plotOptions"
                        [yaxis]="feedDisribution.yaxis" [grid]="feedDisribution.grid"
                        [tooltip]="feedDisribution.tooltip" [stroke]="feedDisribution.stroke"
                        [dataLabels]="feedDisribution.dataLabels" [xaxis]="feedDisribution.xaxis"
                        [colors]="feedDisribution.colors" [fill]="feedDisribution.fill"></apx-chart>
                </div>
            </div>
        </div> -->

        <div class="col-md-6 pb-2">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading">Attribute category distribution</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">This panel depicts the categories of attributes recieved from various communities</p>
                    <div class="d-flex pabs100 mt-9em"><img class="m-auto" width="200" *ngIf="isAttributeFeedLoading"
                            src="assets/images/fileloading.gif" /></div>
                    <div style="display: none;" echarts [options]="attributeFeedChart" class="demo-chart" id="attributePie"></div>
                    <apx-chart *ngIf="!isAttributeFeedLoading" dir="ltr"
                                class="apex-charts" [series]="attributebarChart.series"
                                [chart]="attributebarChart.chart" [legend]="attributebarChart.legend"
                                [colors]="attributebarChart.colors" [labels]="attributebarChart.labels"
                                [dataLabels]="attributebarChart.dataLabels"
                                [plotOptions]="attributebarChart.plotOptions" [xaxis]="attributebarChart.xaxis" [yaxis]="attributebarChart.yaxis">
                    </apx-chart>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-7 pb-2" id="contentToConvert2">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading">Attribute category distribution over time</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">This panel depicts the distribution of different category of attribute over time</p>
                    <div class="d-flex pabs100 mt-9em"><img class="m-auto" width="200"   *ngIf="isAttributeFeedLoading"
                            src="assets/images/fileloading.gif" /></div>
                    <apx-chart id="attributeCategory" class="apex-charts" dir="ltr" *ngIf="!isAttributeFeedLoading" [series]="attributeCategory.series"
                        [chart]="attributeCategory.chart" [plotOptions]="attributeCategory.plotOptions"
                        [yaxis]="attributeCategory.yaxis" [grid]="attributeCategory.grid"
                        [tooltip]="attributeCategory.tooltip" [stroke]="attributeCategory.stroke"
                        [dataLabels]="attributeCategory.dataLabels" [xaxis]="attributeCategory.xaxis"
                        [colors]="attributeCategory.colors" [fill]="attributeCategory.fill"></apx-chart>
                </div>
            </div>
        </div> -->

        
        <div class="col-md-12 pb-4" id="contentToConvert">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading">Events</h4>
                    <p class="paragraph-clr">The below panel shows the top events which have occured according to various community feeds,
                        Click on the view event button to view the summary of individual events and get detailed views
                        of their IOCs.</p>
                    <h5 *ngIf="tableLoader" class="mb-0 text-center"><i
                            class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i></h5>
                    <table datatable class="row-border hover scroll custWidth" [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger">
                        <thead *ngIf="!tableLoader">
                            <tr class="set-width">
                                <th>Event Time</th>
                                <th>Event Name</th>
                                <th>Feed Name</th>
                                <!-- <th>Event Id</th> -->
                                <th>Severity</th>
                                <th>Event Drilldown</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of detectionTableData">
                                <td>{{item.attribute_timestamp * 1000| date: 'medium'}}</td>
                                <td>{{item.event}}</td>
                                <td>{{item.feed}}</td>
                                <!-- <td>{{item.eventid}}</td> -->
                                <td>{{item.severity | titlecase}}</td>
                                <td class="td-btn"><a class="viewBtn" [routerLink]="['/view-event-details']" [queryParams]="{id: item.eventid}"><i
                                    class="fa fa-eye font-size-18"></i>View Event</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 spinner-loader" *ngIf="!printClick">
        <h5 class="mb-0 text-center">
            <img src="assets/images/new-loader.gif" width="100%">
        </h5>
    </div>
</div>