<div class="row">
  <div class="container-fluid" style="margin:auto 2%">
    <div class="row">
      <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
    <div class="row">
      <div class="col-md-6"><h2>{{title}}</h2></div>
      <div class="col-md-6">
        <div class="text-right d-flex justify-content-between align-items-end">
          <p class="mb-0">
            <span *ngIf="donutVal" class="bg-dark text-light pl-2 py-1 m-1"> {{donutValElmt }} : {{donutVal }}<span
                class="bg-danger text-dark px-2 py-1" (click)="clear()"> X</span></span>
            <!-- <span class="bg-dark text-light pl-2 py-1 m-1"> User Name : inspire <span class="bg-danger text-dark px-2 py-1"> X</span></span>  -->
            <!-- <span class="bg-dark text-light pl-2 py-1 m-1"> Host Name : VIRTUAL-MACHINE <span class="bg-danger text-dark px-2 py-1"> X</span></span> -->

          </p>
          <button (click)="topdf()" class="btn btn-info"><i class="fa fa-download" aria-hidden="true"></i>
            Download PDF</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="container-fluid" style="margin:auto 2%">
    <div class="row" id="dashboard">
      <div class="{{chart.class}}" *ngFor="let chart of chartDetails">
        <app-show-item [item]="chart"></app-show-item>
      </div>
    </div>
  </div>
</div>