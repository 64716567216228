import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { LanguageService } from '../../../core/services/language.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import * as localization from 'moment/locale/fr';
// import { ConsoleReporter } from 'jasmine';
moment.updateLocale('fr', localization);

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  element: any;
  configData: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  selected: any = {};
  isCalClick;
  initLoad = true;
  maxDate = moment();
  showSearchPages: any = ['/ueba/dashboard', 'investigation', 'view-event-details', 'dashboard', 'alert', '/hunt-summary-groping', 'ueba/dashboard', 'huntings-summary', '/ueba/use-case', 'ueba/use-case', '/ueba', 'ueba', 'management-summary-print', '/management-summary-print', 'view-hunt-status', 'activebytes-threat', 'community-threat', 'management-summary', 'security-incidents', 'vulnerabilities-summary', 'vulnerabilities-exploit', '/huntings-summary', '/view-hunt-status', 'headlines', '/activebytes-threat', '/community-threat', '/management-summary', '/security-incidents', '/vulnerabilities-summary', 'mssp-view', '/mssp-view', 'correlation-studio', '/correlation-studio', 'hunter', '/hunter'];
  // showSearchPages: any = ['/ueba/dashboard','investigation', 'view-event-details', 'dashboard', 'alert', '/hunt-summary-groping', 'ueba/dashboard', 'huntings-summary', '/ueba/use-case', 'ueba/use-case', '/ueba', 'ueba', 'management-summary-print', '/management-summary-print', 'view-hunt-status', 'activebytes-threat', 'community-threat', 'management-summary', 'security-incidents', 'vulnerabilities-summary', '/huntings-summary', '/view-hunt-status', '/activebytes-threat', '/community-threat', '/management-summary', '/security-incidents', '/vulnerabilities-summary', 'mssp-view', '/mssp-view', 'correlation-studio', '/correlation-studio'];
  hideFilterPages: any = ['/ueba/detection-summary','cybot-dashboard'];
  showFilter: boolean = false;
  currentPage: string = '';
  period = 'now-7d';
  userNameLetter;
  user_name;
  isUTC:boolean = true;
  periodList = [
    { name: '1D', value: 'now-1d' },
    { name: '7D', value: 'now-7d' },
    { name: '30D', value: 'now-30d' },
    { name: '1Y', value: 'now-365d' },
  ];

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  public ranges: any = {
    'Today': [moment(), moment()],
    'This Week': [moment().subtract(6, 'days'), moment()],
    'This Month': [moment().subtract(29, 'days'), moment()],
    'Last 90 Days': [moment().subtract(90, 'days'), moment()],
    'Last Year': [moment().subtract(12, 'month'), moment()],
  }


  public locale: any = {
    applyLabel: 'Select',
    format: 'DD/MM/YYYY',
    daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"],
  }

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService, private authFackservice: AuthfakeauthenticationService, public languageService: LanguageService, public cookiesService: CookieService, private activateRoute: ActivatedRoute) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
        this.currentPage = this.router.url;
        // console.log(this.currentPage)
        let s = this.currentPage.split("/");
        // console.log("sssssssssss", s)
        // debugger;
        if (s[1] != undefined) {
          this.showFilter = this.showSearchPages.includes(s[1]);
        } else {
          this.showFilter = this.showSearchPages.includes(this.currentPage);
        }
      }
    });
  }
  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
              }
            }
          }
        }
      }
    };


    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }
  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter();
  routerUrl = "";
  isShow = false;
  ngOnInit(): void {
    this.element = document.documentElement;
    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };
    this.setDatePeriod();
    if (localStorage.getItem('selected')) {
      this.selected = {
        startDate: moment(localStorage.getItem('startDate')),
        endDate: moment(localStorage.getItem('endDate'))
      };
      this.activateRoute.queryParamMap
        .subscribe((params: any) => {
          if (params.params.timestamp) {
            this.period = '';
          }

        });
    }
    if (localStorage.getItem('isUTC') === null) {
      localStorage.setItem('isUTC', 'true')
    } else {
      this.isUTC = JSON.parse(localStorage.getItem('isUTC'));
    }
    this.cookieValue = this.cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.routerUrl = this.router.url;
    if (this.routerUrl) {
      this.activateMenu();
      this.currentPage = this.routerUrl;
      let s = this.currentPage.split("/");
      // debugger;
      if (s[1] != undefined && s[1] != '') {
        this.showFilter = this.showSearchPages.includes(s[1]);
        if (s[2] && this.hideFilterPages.includes(this.currentPage)) {
          this.showFilter = false;
        }
      } else {
        this.showFilter = this.showSearchPages.includes(this.currentPage);
      }
    }
    if (this.routerUrl.includes("/alert/") || this.routerUrl.includes("/dashboard/") || this.routerUrl.includes("/controlview/")) {
      this.isShow = true;
    }
    if (this.routerUrl.includes("/controlview/")) {
      this.isShow = false;
    }
    if (localStorage.getItem("USER_NAME")) {
      this.userNameLetter = localStorage.getItem("USER_NAME").charAt(0).toUpperCase();
    }
    let str = localStorage.getItem("USER_NAME");
    // console.log("str", str)
    if (str != null) {
      this.user_name = str.charAt(0).toUpperCase() + str.slice(1);
      this.element = document.documentElement;
      this.configData = {
        suppressScrollX: true,
        wheelSpeed: 0.3,
      };
    }
  }

  changeUTCType(){
    localStorage.setItem('isUTC', this.isUTC.toString());
    setTimeout(()=>{
      let currentURL = this.router.url.substr(1).replace(/%20/gi, ' ');
      // currentURL = this.removeParamFromUrl(currentURL, 'timestamp');
      this.router.navigateByUrl('/dashboard/settings', { skipLocationChange: true }).then(() =>
        this.router.navigateByUrl(currentURL));
    })
  }

  setDatePeriod() {
    if (localStorage.getItem('isDateRange') == 'true') {
      this.selected = {
        startDate: moment(localStorage.getItem('startDate')),
        endDate: moment(localStorage.getItem('endDate'))
      };
      this.isCalClick = true;
      this.period = "now-3650d"
    } else {
      if (localStorage.getItem('period')) {
        this.period = localStorage.getItem('period');
      }
      localStorage.setItem('period', this.period);
    }
  }

  changePeriod(period) {
    localStorage.setItem('isDateRange', "false");
    if (period == 'now-1d') {
      this.selected = {
        startDate: moment(),
        endDate: moment()
      };
    } else if (period == 'now-7d') {
      this.selected = {
        startDate: moment().subtract(6, 'days'),
        endDate: moment()
      };
    } else if (period == 'now-30d') {
      this.selected = {
        startDate: moment().subtract(30, 'days'),
        endDate: moment()
      };
    } else if (period == 'now-90d') {
      this.selected = {
        startDate: moment().subtract(90, 'days'),
        endDate: moment()
      };
    } else if (period == 'now-365d') {
      this.selected = {
        startDate: moment().subtract(12, 'month'),
        endDate: moment()
      };
    } else if (period == '5Y') {
      this.selected = {
        startDate: moment().subtract(5, 'year'),
        endDate: moment()
      };
    }
    localStorage.setItem('period', period);
    localStorage.setItem('selected', JSON.stringify(this.selected));
    localStorage.setItem('startDate', moment(this.selected.startDate).format('YYYY-MM-DD'));
    localStorage.setItem('endDate', moment(this.selected.endDate).format('YYYY-MM-DD'));
    let s = moment(this.selected.startDate).format('YYYY-MM-DDTHH:MM:SS');
    localStorage.setItem('startDateZ', s.toString() + ".150Z");
    let e = moment(this.selected.endDate).format('YYYY-MM-DDTHH:MM:SS');
    localStorage.setItem('endDateZ', e.toString() + ".150Z");
    let currentURL = this.router.url.substr(1).replace(/%20/gi, ' ');
    currentURL = this.removeParamFromUrl(currentURL, 'timestamp');
    this.router.navigateByUrl('/dashboard/settings', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(currentURL));

  }

  removeParamFromUrl(currentURL, word): any {
    if (currentURL.indexOf(word) !== -1) {
      var index1 = currentURL.indexOf('&' + word) !== -1 ? currentURL.indexOf(word) : currentURL.indexOf('&' + word)
      var index2 = currentURL.indexOf('&', index1 + 1)
      return currentURL.substr(0, index1 - 1) + (index2 != -1 ? currentURL.substr(index2) : '')
    }
    return currentURL
  }

  rangeClicked(value) {
    // console.log("rangeClicked", value)
    let startDate = value?.startDate?.$d;
    let endDate = value?.endDate?.$d;
    if (this.initLoad) {
      this.initLoad = false;
    } else if (startDate && endDate) {
      let diff = moment(endDate).diff(startDate, 'days');
      // diff = diff + 1
      let currentURL = this.router.url.substr(1).replace(/%20/gi, ' ');
      currentURL = this.removeParamFromUrl(currentURL, 'timestamp');

      // localStorage.setItem('period', 'now-' + diff.toString() + 'd');
      localStorage.setItem('period', 'now-' + diff.toString() + 'd');
      localStorage.setItem('isDateRange', "true");
      localStorage.setItem('startDate', moment(startDate).format('YYYY-MM-DD'));
      localStorage.setItem('endDate', moment(endDate).format('YYYY-MM-DD'));
      let s = moment(this.selected.startDate.$d).format('YYYY-MM-DDTHH:MM:SS');
      localStorage.setItem('startDateZ', s.toString() + ".150Z");
      let e = moment(this.selected.endDate.$d).format('YYYY-MM-DDTHH:MM:SS');
      localStorage.setItem('endDateZ', e.toString() + ".150Z");
      //localStorage.setItem('endDateZ', moment(endDate).format('YYYY-MM-DDTHH:MM:SS.150Z'));
      this.router.navigateByUrl('/dashboard/settings', { skipLocationChange: true }).then(() =>
        this.router.navigateByUrl(currentURL));
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Translate language
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }


  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }
}
