import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ThreatIntelligenceService } from 'src/app/core/services/threat-intelligence.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { AddExceptionsComponent } from '../components/add-exceptions/add-exceptions.component';
interface moduleData {
  "inv_id": "string",
  "inv_name": "string",
  "technique_id": "string",
  "tactic_name": "string",
  "technique_name": "string",
  "sub-technique": "string",
  "Description": "string",
  "modules": [],
  "schedule": "string",
  "log": "string",
  "suppress": "string",
  "conclution": "string"
  "detaild_description": "string",
  "conclusion": "string",
  "detailed_description": "string"
}
@Component({
  selector: 'app-cybot-dashboard-view',
  templateUrl: './cybot-dashboard-view.component.html',
  styleUrls: ['./cybot-dashboard-view.component.scss']
})

export class CybotDashboardViewComponent implements OnInit {

  @Input() item;
  selectedDetections
  closeCommentFalg
  currentPage
  api
  detectionTableData
  closeCommentform: FormGroup;
  //Title and description allowed for selected widget type
  allowedWidget = ['donut', 'bar', 'stat', 'filter', 'gauge']
  query
  isLoader = false
  host;
  hash;
  period = {
    gte: '',
    lte: ''
  };
  ip;
  user;
  hunt_detection_id;
  playbook_id;
  group_id
  from = 'investigation'
  title
  getData: moduleData
  productForm: FormGroup;
  isExpert: boolean = true;
  num: number = 0;
  Arr = Array;
  sub_url_flge = false
  isLoading = false
  soarListData = [];
  respondData
  responce_submit
  doDataAll: any;
  doData: any = {};
  btnGroupVerticalDrop1 = 'Api-Integration';
  btnGroupVerticalDrop2 = 'Observables';
  hunt_id
  constructor(public dashboardService: DashboardService, public common: CommonService, private formBuilder: FormBuilder, public threatervice: ThreatIntelligenceService, private modalService: NgbModal, private navigateRouter: Router, private route: ActivatedRoute,
    private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentPage = this.router.url;
        // console.log("this.currentPage select", this.currentPage)
        let s = this.currentPage.split("/");
        // console.log("s", s)
      }
    });
    this.productForm = this.formBuilder.group({
      textValue: '',
      quantities: this.formBuilder.array([]),
    });
  }


  ngOnInit(): void {
    // console.log("item", this.item);
    this.populateData()

    this.closeCommentform = this.formBuilder.group({
      comment: ["", Validators.required],
    });
  }

  populateData() {
    this.group_id = this.route.snapshot.paramMap.get("group_id");
    this.host = this.route.snapshot.paramMap.get("host");
    this.hash = this.route.snapshot.paramMap.get("hash");
    this.ip = this.route.snapshot.paramMap.get("ip");
    this.user = this.route.snapshot.paramMap.get("user");
    this.hunt_detection_id = this.route.snapshot.paramMap.get("hunt_detection_id");
    this.from = this.route.snapshot.paramMap.get("form");
    this.hunt_id = this.route.snapshot.paramMap.get("hunt_id");
    // console.log(" this.hunt_detection_id", this.hunt_detection_id)
    this.detectedObservables()
    this.playbook_id = this.route.snapshot.paramMap.get("playbook_id");
    this.getHighPriorityDetectionTable()
    this.soarList()
    if (this.hunt_detection_id)
      this.dashboardService.getCybotDashboedDetails(this.playbook_id).subscribe(
        (result: any) => {
          // console.log("result", result)
          this.getData = result
          // console.log("get Cybot Dashboed Details", this.getData)
          if (result) {

          }
          this.isLoader = false;
        },
        (inerror) => {
          console.log("error", inerror);
        })

  }

  goToBack() {
    console.log("this.from", this.from)
    // console.log("this.from", this.from)
    if (this.from == 'investigation-repetition')
      this.router.navigate([
        "/investigate/repetition",
        {
          host: this.host,
          user: this.user,
          hunt_detection_id: this.hunt_detection_id,
          playbook_id: this.playbook_id,
          group_id: this.group_id
        },
      ]);
    else if (this.from == 'hunter')
      this.router.navigate([
        "/investigate/repetition",
        {
          host: this.host,
          user: this.user,
          hunt_detection_id: this.hunt_detection_id,
          playbook_id: this.playbook_id,
          group_id: this.group_id,
          form: 'hunter',
          hunt_id: this.hunt_id,
          type: 'hunter'
        },
      ]);
    else {
      this.navigateRouter.navigate(["investigation"]);
    }
  }
  topdf() {
    const dashboard = document.getElementById('dashboard');
    const dashboardHeight = dashboard.clientHeight;
    const dashboardWidth = dashboard.clientWidth;
    const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

    domtoimage.toPng(dashboard, options).then((imgData) => {
      const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save('Dashboard for hyperpanels.pdf');
    });
  }


  // openModal(viewmodal: any, content: any, is_respond?: boolean) {
  //   this.selectedDetections = content;
  //   this.modalService.open(viewmodal, { size: "lg", centered: true });
  // }

  openModal(viewmodal: any, content: any, is_respond?: boolean) {
    this.selectedDetections = content;
    //console.log("modal", viewmodal, content);
    if (content.detection_status == "new") {
      let str = localStorage.getItem("USER_NAME");
      let date = new Date().toISOString();
      this.selectedDetections.opened_by = str;
      this.selectedDetections.opened_at = date;
      this.selectedDetections.detection_status = "open";
      if (
        this.selectedDetections.id != "" &&
        this.selectedDetections.id != undefined
      ) {
        let data = {
          doc: {
            opened_by: str,
            opened_at: date,
            detection_status: "open",
          },
        };
        let id = this.selectedDetections.id;
        let index = this.selectedDetections.index;
        this.common.addOpenCommentAdd(data, id, index).subscribe(
          (response: any) => {
            if (response.result == "updated") {
              //close modal
              this.modalService.open(viewmodal, { size: "lg", centered: true });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else {
        //console.log("id not get");
      }
    } else if (is_respond) {
      let modal = this.modalService.open(viewmodal, { size: "md", centered: true });
      modal.result.then(() => { console.log('When user closes'); }, () => { this.clearFormArray(this.productForm.get("quantities") as FormArray); this.num = 0 })
    }
    else {
      this.modalService.open(viewmodal, { size: "lg", centered: true });
    }
  }
  closeCommentAdd() {
    if (
      this.selectedDetections.id != "" &&
      this.selectedDetections.id != undefined
    ) {
      let comment = this.closeCommentform.value.comment;
      let str = localStorage.getItem("USER_NAME");
      let date = new Date().toISOString();
      // //console.log("comment", comment);
      let data = {
        doc: {
          closing_comment: comment,
          closed_by: str,
          closed_at: date,
          detection_status: "closed",
        },
      };
      let id = this.selectedDetections.id;
      let index = this.selectedDetections.index;
      // //console.log("id", id);
      this.common.addCloseCommentAdd(data, id, index).subscribe(
        (response: any) => {
          // //console.log("Table data", response);
          if (response.result == "updated") {
            //close modal
            this.selectedDetections.closing_comment = comment;
            this.selectedDetections.closed_by = str;
            this.selectedDetections.closed_at = date;
            this.selectedDetections.detection_status = "closed";
            this.closeCommentFalg = false;
            this.closeCommentform.controls["comment"].setValue("");
            let i = Number(this.selectedDetections.i);
            this.detectionTableData = this.selectedDetections;
            this.modalService.dismissAll("test");
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      //console.log("id not get");
    }
  }

  closeCommentClickOpen() {
    this.closeCommentFalg = true;
  }
  closeCommentClickClose() {
    this.closeCommentFalg = false;
    this.modalService.dismissAll('Ok click')
  }

  getHighPriorityDetectionTable() {
    let data = {
      size: 500,
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_phrase: {
                hunt_detection_id: this.hunt_detection_id,
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.common.getViewDashboardData(data).subscribe(
      (response: any) => {
        // console.log("getHighPriorityDetectionTable", response);
        if (response) {
          let hits = response?.hits?.hits;
          for (let i = 0; i < hits.length; i++) {
            this.detectionTableData = {
              i: i,
              time: hits[i]._source.current_timestamp,
              tactic_name: hits[i]._source.tactic_name,
              hunt_detection_id: hits[i]._source.hunt_detection_id,
              detection_name: hits[i]._source.detection_name,
              hunt_type: hits[i]._source.hunt_type,
              technique_id: hits[i]._source.technique_id,
              technique_name: hits[i]._source.technique_name,
              detection_timestamp: hits[i]._source.detection_timestamp,
              view_dashboard: hits[i]._source.url,
              view_repetition: hits[i]._source.repetition_link,
              id: hits[i]._id,
              index: hits[i]._index,
              detection_status: hits[i]._source.detection_status,
              ip_threat_status: hits[i]._source.ip_threat_status,
              opened_by:
                hits[i]._source.opened_by != undefined
                  ? hits[i]._source.opened_by
                  : "",
              opened_at:
                hits[i]._source.opened_at != undefined
                  ? hits[i]._source.opened_at
                  : "",
              closing_comment:
                hits[i]._source.closing_comment != undefined
                  ? hits[i]._source.closing_comment
                  : "",
              closed_by:
                hits[i]._source.closed_by != undefined
                  ? hits[i]._source.closed_by
                  : "",
              closed_at:
                hits[i]._source.closed_at != undefined
                  ? hits[i]._source.closed_at
                  : "",
            };
          }

        }
        // console.log("this.detectionTableData", this.detectionTableData)
      },
      (error) => {
        console.log("error", error);
      }
    );
  }


  async responceClose() {
    this.sub_url_flge = false
    this.num = 0;
    await this.clearFormArray(this.productForm.get("quantities") as FormArray)
    this.modalService.dismissAll('Ok click')
  }
  respondSubmite() {
    let d = this.respondData
    let data = this.productForm.value.quantities
    let textValue = this.productForm.value.textValue
    let valueCount = data.length
    let sendData = {
      id: d._id,
      index: d._index,
      doc: d._doc,
      count: d.count,
      api_name: d.api_name,
      method: d.method,
      url: d.url,
      api_key: d.api_key,
      header: d.header.replace(/\\/, ""),
      body: d.body.replace(/\\/, "")
    }
    let body = d.body
    for (let index = 0; index < data.length; index++) {
      let param = data[index].select
      let pa = "#param" + (index + 1) + "#"
      body = body.replace(pa, param);
      if (index + 1 == data.length) {
        let pa = "#param" + (index + 1) + "#"
        body = body.replace(pa, textValue);
        let pa1 = "param" + (index + 1)
        body = body.replace(pa1, textValue);
      }
    }
    sendData.body = body
    this.common.respondSubmiteUrl(d, body).subscribe(
      (response: any) => {
        console.log("response", response)
        this.sub_url_flge = true
        this.responce_submit = "Responce submit successfully"
      },
      (error) => {
        this.responce_submit = "Something went wrong"
        console.log("error", error);
      }
    );
  }

  async asyncForEach(count) {
    await this.clearFormArray(this.productForm.get("quantities") as FormArray)
    // console.log("this.num", this.num)
    for (let index = 0; index < count; index++) {
      // console.log("addQuantity")
      this.addQuantity()
    }
    this.num = count
    return
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }
  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }

  newQuantity(): FormGroup {
    return this.formBuilder.group({
      data: '',
      select: '',
    })
  }
  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
  async btnGroupVerticalClick(event) {
    let id = event.target.value;
    let data = this.soarListData.find(element => element.id == id);
    this.respondData = data
    let count = parseInt(data.count);
    // console.log("data", data)
    // console.log("count", count)
    if (this.num == 0) {
      for (let index = 0; index < count; index++) {
        this.addQuantity()
      }
      this.num = count
    } else {
      //console.log(count)
      await this.asyncForEach(count);
    }
  }

  soarList() {
    let data = {
      query: {
        match_all: {},
      },
    };
    this.common.apiListAll(data).subscribe(
      (response: any) => {
        let data = response?.hits?.hits;
        this.soarListData = [];
        // console.log("soarList", data);
        data.forEach((item) => {
          if (item?._source?.count != undefined) {
            let t = {
              id: item._id,
              index: item._index,
              doc: item._doc,
              count: item._source.count,
              api_name: item._source.api_name,
              method: item._source.method,
              url: item._source.url,
              api_key: item._source.api_key,
              header: item._source.header,
              body: item._source.body,
            };
            this.soarListData.push(t);
          }
        });
      },
      (error) => {
        console.log("error", error);
      }
    );

  }
  detectedObservables() {
    let data = {
      "query": {
        "bool": {
          "must": [],
          "filter": [
            {
              "match_all": {}
            },
            {
              "match_phrase": {
                "hunt_detection_id": this.hunt_detection_id
              }
            },
            {
              "range": {
                "current_timestamp": {
                  "gte": "now-3650d",
                  "lte": "now",
                  "format": "strict_date_optional_time"
                }
              }
            }
          ],
          "should": [],
          "must_not": []
        }
      }
    };
    this.isLoading = false
    this.common.getDetectedObservables(data).subscribe(
      (response: any) => {
        // console.log("detectedObservables", response);
        if (response?.hits?.hits[0] != undefined) {
          let d = response.hits?.hits[0]?._source;
          this.doDataAll = response?.hits?.hits[0];
          // console.log("d", d)
          if (d?.source_ip)
            this.doData.SourceIP = d.source_ip;
          if (d.user_name)
            this.doData.UserName = d.user_name;
          if (d.playbook_id)
            this.doData.playbook_id = d.playbook_id;
          if (d.process_name)
            this.doData.ProcessName = d.process_name;
          if (d.dest_ip)
            this.doData.DestinationIP = d.dest_ip;
          if (d.host_name)
            this.doData.HostName = d.host_name;
          if (d.process_pid)
            this.doData.ProcessID = d.process_pid;
          if (d.process_commandline)
            this.doData.ProcessCommand = d.process_commandline;
          if (d.detection_timestamp)
            this.doData.Lastdetection = d.detection_timestamp;
          if (d.url)
            this.doData.URL = d.url;
          if (d.process_hash)
            this.doData.Hash = d.process_hash;
          if (d.detection_name)
            this.doData.detection_name = d.detection_name;
          if (d.dest_ip)
            this.doData.dest_ip = d.dest_ip;
          // if (d.dest_ip) { this.doData.dest_ips.push(d.dest_ip); }
          // this.doData.dest_ip1 = d.dest_ip1;
          // if (d.dest_ip1) { this.doData.dest_ips.push(d.dest_ip1); }
          // this.doData.dest_ip2 = d.dest_ip2;
          // if (d.dest_ip2) { this.doData.dest_ips.push(d.dest_ip2); }
          // this.doData.dest_ip3 = d.dest_ip3;
          // if (d.dest_ip3) { this.doData.dest_ips.push(d.dest_ip3); }
          // this.doData.dest_ip4 = d.dest_ip4;
          // if (d.dest_ip4) { this.doData.dest_ips.push(d.dest_ip4); }
          // this.doData.url = d.url;
          // if (d.url) { this.doData.urls.push(d.url); }
          // this.doData.url1 = d.url1;
          // if (d.url1) { this.doData.urls.push(d.url1); }
          // this.doData.url2 = d.url2;
          // if (d.url2) { this.doData.urls.push(d.url2); }
          // this.doData.url3 = d.url3;
          // if (d.url3) { this.doData.urls.push(d.url3); }
          // this.doData.url4 = d.url4;
          // if (d.url4) { this.doData.urls.push(d.url4); }

          // for (let i = 0; i < this.doData.dest_ips.length; i++) {
          //   let dest = '';
          //   if (this.doData.dest_ips[i] != 'No IP') {
          //     if (i == 0) dest = 'dest_ip';
          //     else {
          //       dest = 'dest_ip' + i;
          //     }
          //     // this.IPIntelligenceIPThreatScore(dest, i);
          //   }
          // }

          // for (let i = 0; i < this.doData.urls.length; i++) {
          //   let url = '';
          //   if (this.doData.urls[i] != 'No URL') {
          //     if (i == 0) url = 'url';
          //     else {
          //       url = 'url' + i;
          //     }
          //     // this.ProcessIntelligenceURLThreatScore(url, i);
          //   }
          // }


          // console.log("ips", this.doData.dest_ips, this.doData.urls);
          this.doData.process_commandline = d.process_commandline;
          this.doData.process_parent_name = d.process_parent_name;
          this.doData.user_domain = d.user_domain;
          this.doData.process_parent_exec = d.process_parent_exec;
          this.doData.process_pid = d.process_pid;
          this.doData.process_signer = d.process_signer;
          this.doData.process_hash = d.process_hash;

        }
        // this.RecentAuthenticationsinHostNew()
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  addExceptions() {
    // console.log("addExceptions", this.doDataAll)
    if (this.doDataAll == undefined) return
    let data: any = this.doDataAll;
    let objArr = [];
    objArr[0] = "Exceptions Add";
    objArr[1] = data;
    objArr[2] = "Add";
    objArr[4] = "Investigate";
    let r = this.doDataAll._index;
    let a = r.split("_");
    objArr[3] = a[a.length - 1];
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      windowClass: "modal-dialog-all common-modal",
    };
    const modalRef = this.modalService.open(
      AddExceptionsComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.objArr = objArr;
    modalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason.status == "update") {
          let item = reason.data;
          // //console.log(item);
        }
      }
    );
  }
  Investigating_the_Hash_check() {
    return true
    // process_information_obtained_from_datalake
    // Hash_Threat_Score
    // hash_reputation_check_on_alienVault_OTX
    // hash_reputation_check_on_virustotal



  }
  Investigating_the_URL_check() {
    return true
    // URL_Threat_Score
    // URL_reputation_check_on_alienVault_OTX
    // URL_reputation_check_on_virusTotal

  }
  Investigating_the_IP_check() {
    return true
    // traffic_from_other_hosts_to_detected_IP
    // IP_reputation_check_on_alienVault_OTX
    // IP_reputation_check_on_virustotal
    // IP_Threat_Score
  }
  Investigation_on_Host_and_User_check() {
    return true
    // hosts_logged_into_by_user
    // processes_run_by_detected_host
    // processes_run_by_detected_user
    // recent_authentications_in_host
    // recent_commandlines_run_by_user_on_the_host
    // recent_file_creation
    // recent_file_deletion
    // uncommon_processes_run_by_detected_user
  }


  gotoCorrelation() {
    this.router.navigate([
      "/correlation-studio",
      {
        host: this.host,
        user: this.user,

      },
    ]);
  }

  addName(data) {
    if (data) {
      data = data.replace("/*host_name*/", this.doData.UserName);
      data = data.replace("/*user_name*/", this.doData.HostName);
      data = data.replace("/*detection_timestamp*/", this.doData.Lastdetection);
    }
    return data
  }

}
