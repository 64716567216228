import { Component, Input, OnInit } from '@angular/core';

import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ChartType } from '../dashboard/dashboard.model';
@Component({
  selector: 'cybot-investigation-av-view',
  templateUrl: './investigation-av-view.component.html',
  styleUrls: ['./investigation-av-view.component.scss']
})
export class InvestigationAVViewComponent implements OnInit {
  hunt_detection
  user_name
  @Input() set DetectionId(val) {
    // console.log("analyticalData", val)
    this.hunt_detection = val
  }
  @Input() set UserName(val) {
    this.user_name = val;
  }
  data: any;
  revenueChart: ChartType;
  term: any;
  titleArray: any;
  api: any;
  query: any = '';
  method: any;
  dataTemp: any = []
  expectedValues: any
  datas: any;
  series: any = [];
  labels: any = [];
  type: any = []
  colors: any = []
  loding: boolean = true;
  count
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  constructor(public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.query = this.query;
    this.dashboardService.dashboardApiCYBOT(this.replaceDataObjects(this.query), this.api, this.method).subscribe(
      (result: any) => {
        // console.log("app-stat-count", result)
        this.loding = true;
        if (result) {
          if (result.hits) {
            let data = result.hits.total
            // console.log("app-stat-count", data)
            this.count = data.value
            // console.log("app-stat-count", this.count)
            this.loding = false;
          } else {
            this.count = 0
            this.loding = false;
          }

        }
        if (result) {
          if (result.hits) {
            let data = result.hits.hits;
            let k = 0
            data.forEach(data => {
              let dataSub = []
              dataSub.push(k + 1)
              this.expectedValues.forEach(element => {
                let tempData = data?._source
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  tempData = tempData[expectedValuesArray[i]]
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              this.dataTemp.push(dataSub)
              k++
            });
            // console.log(this.dataTemp)
            // this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
          } else {
            // this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
          }

        }
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  replaceDataObjects(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.match_phrase && it.match_phrase["hunt_detection_id"]) {
          it.match_phrase["hunt_detection_id"] = this.hunt_detection;
        }
      });
    }
    return data;
  }
  displayData(data) {
    if (data[0]) {
      if (data[0][2]) {
        return data[0][2]
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
}
