import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RuleStudioService } from "../../../core/services/rule-studio.service"
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rule-studio-custom',
  templateUrl: './rule-studio-custom.component.html',
  styleUrls: ['./rule-studio-custom.component.scss']
})
export class RuleStudioCustomComponent implements OnInit {
  edr = [];
  selectededr = [];
  multiSelect = new FormControl();
  multiSelect1 = new FormControl();
  infoArray: number[] = [];
  moduletabindex: { [key: number]: number } = {};
  selectedModuleIndex: number = 0;
  selectedTabItem: any;
  loadTab = false;
  infoArrayIndex: number = 0;
  queryStr: any;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  dropdownSettingsmodule: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  public Editor = ClassicEditor;
  ruleStudioCustom: FormGroup;
  logcategorySelectedItems = [];

  // convenience getter for easy access to form fields
  get f() { return this.ruleStudioCustom.controls; }
  get infomations() {
    return this.ruleStudioCustom.controls["infos"] as FormArray;
  }
  infoForm: any;
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  ckeditorData;
  logcategoryList: { selected: false, items: [{ item_id: string, item_text: string }], itemsSelected: any[] };
  ModuleList: { selected: false, items: [{ item_id: string, item_text: string }], itemsSelected: any[] };
  scheduleList: any[] = [];
  modules: any[] = [];
  isLoaderShow: boolean = false;
  logList: string[] = [];
  modList = [];
  submitted = false
  constructor(private ruleStudioService: RuleStudioService,
    private router: Router,
    private formBuilder: FormBuilder) {

  }
  moduleList = [];


  ngOnInit(): void {
    this.ruleStudioCustom = this.formBuilder.group({
      inv_id: ['', [Validators.required]],
      inv_name: ['', [Validators.required]],
      schedule: [''],
      log: ['', [Validators.required]],
      mod: ['', [Validators.required]],
      expected_out: ['', [Validators.required]],
      suppress: ['', [Validators.required]],
      infos: this.formBuilder.array([]),
      description: ['', Validators.required],
      detailed_description: ['', Validators.required],
      conclution: ['', Validators.required],
      query: ['', Validators.required],
      variables: this.formBuilder.array([])
    });
    this.infoForm = this.formBuilder.group({
      infoVal: new FormControl('', [Validators.required]),
      infoText: new FormControl('', [Validators.required]),
    });
    this.getRuleStudioCustomData();
    this.ckeditorData = ''
    this.addInfo()
  }

  changeModuleTab(j, index, item) {
    //this.modules[j].modules.forEach(v=> v.selected=false);
    item.selected = true;
    this.loadTab = true
    this.moduletabindex[j] = index;
    this.selectedTabItem = item;
    this.selectedModuleIndex = j;
    //this.multiSelect.setValue(item.itemsSelected)
    setTimeout(() => {
      this.loadTab = false;
    }, 1000);

  }
  onItemSelect(data: any, isAdd: boolean) { 
   
  }
  getRuleStudioCustomData() {
    this.isLoaderShow = true;
    this.ruleStudioService.getModulesInfo().subscribe((res: any) => {
      let log_category: [{ item_id: string, item_text: string }] = [{ item_id: null, item_text: null }]
      log_category.pop();
      res.log_category.forEach(v => {
        log_category.push({ item_id: v.value, item_text: v.text });
      });

      this.logcategoryList = { selected: false, items: log_category, itemsSelected: [] };

      this.scheduleList = res.schedule;
      this.modules = res.module;
      let items: [{ item_id: string, item_text: string }] = [{ item_id: null, item_text: null }];
      items.pop();
      res.expected_values.forEach(v => {
        items.push({ item_id: v.value, item_text: v.text })
        this.edr.push(v.text)
      })
      this.ModuleList = { selected: false, items: items, itemsSelected: [] };
      this.modules.forEach((module, i) => {
        module.selected = false;
        module.modules.forEach(v => {
          v.selected = false;
          v.itemsSelected = [];
          v.items = items;
          this.moduletabindex[i] = 0;
        });
        setTimeout(() => {
          this.isLoaderShow = false;
        }, 100);
      });
    })
  }

  onItemSelectmodule(i: number, data: any, isAdd: boolean) {
    if (!isAdd) {
      if (!this.modules[i].modules[this.moduletabindex[i]].itemsSelected?.find(data)){
        this.modules[i].modules[this.moduletabindex[i]].itemsSelected.push(data);
       
      }
    } else {
      const index: number = this.modules[i].modules[this.moduletabindex[i]].itemsSelected.indexOf(data);
      if (index !== -1) {
        this.modules[i].modules[this.moduletabindex[i]].itemsSelected.splice(index, 1);
      }
    }
    if(this.modules[i].modules[this.moduletabindex[i]].itemsSelected.length!=0){
      this.ruleStudioCustom.controls.mod.setErrors(null); 
    }
  }

  onItemLogmodule1(data: any, isAdd: boolean) {
    if (isAdd) {
      if (!this.ModuleList.itemsSelected.find(v => v == data)) {
        this.ModuleList.itemsSelected.push(data);
        this.ruleStudioCustom.controls.mod.setErrors(null);
      }
    }
    else {
      const index: number = this.ModuleList.itemsSelected.indexOf(data);
      if (index !== -1) {
        this.ModuleList.itemsSelected.splice(index, 1);
      }
    }
    this.modList = [];
    this.ModuleList.itemsSelected.forEach(v => {
      let k: any = this.ModuleList.items.find(vp => vp.item_text == v)
      if (k)
        if (k.item_id.constructor === Array) {
          this.modList.concat(k.item_id);
          k.item_id.forEach(vv => {
            this.modList.push(vv)
          })
        } else {
          this.modList.push(k.item_id)
        }
    });
    if(this.ModuleList.itemsSelected.length!=0){
      this.ruleStudioCustom.controls.mod.setErrors(null); 
    }
  }
  onItemLogmodule(data: any, isAdd: boolean) {
    if (isAdd) {
      if (!this.logcategoryList.itemsSelected.find(v => v == data)) {
        this.logcategoryList.itemsSelected.push(data);
        this.ruleStudioCustom.controls.log.setValue(this.logcategoryList.itemsSelected);
        this.ruleStudioCustom.controls.mod.setErrors(null);
      }
    }
    else {
      const index: number = this.logcategoryList.itemsSelected.indexOf(data);
      if (index !== -1) {
        this.logcategoryList.itemsSelected.splice(index, 1);
      }
    }
    this.logList = [];
    this.logcategoryList.itemsSelected.forEach(v => {
      this.logList.push(v.item_id)
    });
  }

  addMore(event) {
    this.infoArrayIndex++;
    this.infoArray = Array(this.infoArrayIndex).fill(1).map((x, i) => i);
    this.infomations.push(this.infoForm);
    event?.stopPropagation();
  }
  removeOtherInfo(i, event) {
    this.infoArrayIndex--;
    this.infoArray = Array(this.infoArrayIndex).fill(1).map((x, i) => i);
    this.infomations.removeAt(i - 1);
    event.stopPropagation();
  }

  onSubmit() {
    let info = {};
    this.f.infos.value.forEach(v => {
      info[v.infoVal] = v.infoText
    });
    let modules: any[] = [];
    this.modules.forEach(v => {
      v.modules.forEach(item => {
        if (item.selected == true) {
          let value = {};
          value[item.value] = "true";
          value["input"] = []
          item.itemsSelected?.forEach(v => {
            value["input"].push(v.item_id)
          })
          modules.push(value);
        }
      })
    });
    if (this.ruleStudioCustom.valid) {
      let info = {};
      this.f.infos.value.forEach(v => {
        info[v.infoText] = v.infoVal
      });
      let data = {
        inv_id: this.f.inv_id.value,
        inv_name: this.f.inv_name.value,
        schedule: this.f.schedule.value,
        log: this.logList,
        suppress: this.f.suppress.value,
        Description: this.f.description.value,
        detailed_description: this.f.detailed_description.value,
        conclution: this.f.conclution.value,
        modules: modules
      }
      data = Object.assign(data, info);
      var query = Object.assign(this.queryStr,{extract_values_from_query_output: this.modList})
    
      this.ruleStudioService.createCustomRuleStudio(data, this.f.inv_id.value, "desc")
        .pipe(switchMap((_) => {
          return this.ruleStudioService.createCustomRuleStudio(query, this.f.inv_id.value, "query")
        }))
        .subscribe((_) => {
          this.router.navigate(['rule-studio/investigation']);
        });

    }

  }
  queryValidate() {
    try {
      let query= this.f.query.value.trim();
      if(!query.startsWith('{')){
       query="{"+query+"}";
      }
      this.queryStr = JSON.parse(query.replace(/\s+/g, ' ').trim());
    } catch (e) {
      this.f.query.setErrors({ require: true });
    }
  }
  get info(): FormArray {
    return this.ruleStudioCustom.get("infos") as FormArray
  }
  newInfo(): FormGroup {
    return this.formBuilder.group({
      infoVal: '',
      infoText: '',
      new: true
    })
  }

  addInfo() {
    this.info.push(this.newInfo());
  }
  removeInfo(i: number) {
    this.info.removeAt(i);
  }
  cancel() {
    this.router.navigate(['rule-studio/investigation']);
  }
}

