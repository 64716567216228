import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const apiUrl = environment.apiUrl;
const loginUrl = environment.loginUrl;
const apiSsoUrl = environment.apiSsoUrl;
const apiSsoDataUrl = environment.apiSsoDataUrl;
@Injectable({
  providedIn: 'root'
})
export class SsoService {
  HttpOptions: any;
  constructor(private http: HttpClient, private router: Router) { }
  testBackEndWithOutAuth() {
    return this.http
      .get<object>("https://analyticsapi.active-bytes.com/echo")
      .pipe(catchError(this.handleError));
  }
  ssoLogin() {
    return this.http
      .get<object>("https://sso.active-bytes.com/login?response_type=token&client_id=6c1u7u3cgfesn59cva5aog6tpj&redirect_uri=http://localhost:4200&scope=openid+profile")
      .pipe(catchError(this.handleError));
  }
  getLoggedInUserDetails() {
    let data = {}
    let authorizationData: any =
      localStorage.getItem('id_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        apiSsoUrl + '/userInfo',
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  

  getuserprivs() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getuserprivs',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getSystemvArs() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getsystemvars',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  setSystemvArs(sendData) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/setsystemvars',
        sendData,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDashboards() {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getdashboards',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  setDashboards(a) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/setdashboards',
        a,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    }
    else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message ? error.message : 'No error message'}`;
    }
    console.log(error);
    return throwError(errorMessage);
  }
  getComplianceArs(std?, control?) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcomplianceinfo?std=' + std + '&control=' + control,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  setComplianceArs(std?,
    control?, sendData?) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/setcomplianceinfo?std=' + std + '&control=' + control,
        sendData,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  getComplianceinfo(std?: any, control?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcomplianceinfo?std=' + std + '&control=' + control,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getlicenseinfo(std?: any, control?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getlicenseinfo',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getCybotvars(std?: any, control?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcybotvars',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  setComplianceinfo(std?: any, control?: any, data?: any) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/setcomplianceinfo?std=' + std + '&control=' + control,
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setComplianceCount(std?: any) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcompliancesummary?std=' + std,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  setcybotvarsro(std?, control?, sendData?) {

    let sendData1 = {
      "cyboturl": "https://datalakedev.active-bytes.com:9202",
      "cybotuname": "adminro",
      "cybotpass": "passwrod123"
    }
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        apiSsoDataUrl + '/setcybotvarsro',
        sendData1,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  cybot_login(data, url) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http
      .post<object>(
        url + '/authenticate',
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  setCompliancesummary(std) {
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcompliancesummary?std='+std,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  getcompliancesummary(std?: any) {
    let data = []
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiSsoDataUrl + '/getcompliancesummary?std=iso27001',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
    apiListAll(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_api*/_search",
      data,
      this.HttpOptions
    );
  }
  apiList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_api_" + tenantID + "/_search",
      data,
      this.HttpOptions
    );
  }
}

