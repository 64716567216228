import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-titlebarwithdesc',
  templateUrl: './titlebarwithdesc.component.html',
  styleUrls: ['./titlebarwithdesc.component.scss']
})
export class TitlebarwithdescComponent implements OnInit {
  @Input() title: string;
  @Input() desc: string;
  constructor() { }

  ngOnInit(): void {
  }

}
