<section class="shop-reg-wrapper">

    <div class="row" style="margin-right: 0px;">
        <div class="col-md-12" style="    overflow-y: auto;">
            <div class="d-flex pabs100 mt-9"><img class="m-auto" width="400" *ngIf="isActivePie"
                    src="assets/images/loading.gif" /></div>

            <div class="container-fluid p-0 view-tiles" *ngIf="!isActivePie"
                style="min-height: 75vh; display: flex; justify-content: center;">
                <div class="col-md-9 d-flex justify-content-center align-items-center mgg">
                    <div class="row dashboard-card justify-content-center w-100">
                        <div class="col-md-4 dash-item" (click)="selectCard('analytics_dashboard')"
                            *ngIf="analytics!='false' && (this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard')">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/analysis-img.png">
                            </div>
                            <h2 class="dashboard-card-title">Analytics</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('compliance')"
                            *ngIf="compliance!='false' && this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard'">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/complaints.png">
                            </div>
                            <h2 class="dashboard-card-title">Compliance</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('threat')"
                            *ngIf="ti!='false' && this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard'">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/threat-inteligence.png">
                            </div>
                            <h2 class="dashboard-card-title">Threat Intelligence</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('ueba')"
                            *ngIf="ueba!='false' && this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard'">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/ueba.png">
                            </div>
                            <h2 class="dashboard-card-title">UEBA</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('investigate')"
                            *ngIf="investigate!='false' && this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard'">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/InvsetigateIcon.png">
                            </div>
                            <h2 class="dashboard-card-title">Investigate</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('hunter')"
                            *ngIf="rulesstudio!='false' && this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard'">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/hunter.png">
                            </div>
                            <h2 class="dashboard-card-title">Hunter</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('rule-studio')"
                            *ngIf="rulesstudio!='false' && this.selectedItem!='compliance'&&this.selectedItem!='threat'&&this.selectedItem!='analytics_dashboard'">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/ruleStudio-icon.png">
                            </div>
                            <h2 class="dashboard-card-title">Rule Studio</h2>
                        </div>

                       
                        <!-- <div *ngIf="this.selectedItem">
                            <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
                                <button (click)="goToBack1()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
                                </button>
                            </p>
                        </div> -->

                        <!-- Threat intelligence -->

                        <div class="ti-main-container col-md-12">
                            <div class="threat-container">
                                <div class=" threat-card" (click)="selectCard('community-threat')"
                                    *ngIf="this.selectedItem==='threat'">
                                    <div class="card">
                                        <div class="overlay"></div>
                                        <img class="dashboard-img" src="../../../assets/images/machine-learning.png">
                                    </div>
                                    <h2 class="dashboard-card-title">Community Intelligence</h2>
                                </div>
                                <div class=" threat-card" (click)="selectCard('management-summary')"
                                    *ngIf="this.selectedItem==='threat'">
                                    <div class="card">
                                        <div class="overlay"></div>
                                        <img class="dashboard-img" src="../../../assets/images/project-management.png">
                                    </div>
                                    <h2 class="dashboard-card-title">ActiveBytes Intelligence</h2>
                                </div>
                                <!-- <div class=" threat-card" (click)="selectCard('activebytes-threat')"
                                    *ngIf="this.selectedItem==='threat'">
                                    <div class="card">
                                        <div class="overlay"></div>
                                        <img class="dashboard-img" src="../../../assets/images/research.png">
                                    </div>
                                    <h2 class="dashboard-card-title">Activebytes Intelligence</h2>
                                </div> -->
                            </div>


                            <div class="headline-container" *ngIf="this.selectedItem==='threat'">
                                <div class="top-section">
                                    <div class="section-title">
                                        <h2>Headlines</h2>
                                    </div>
                                    <div class="news-filter">
                                        <ul>
                                            <!-- <li *ngFor="let time of setTime">
                                                <a (click)="filterTime(time)">{{time}}</a>
                                            </li> -->

                                            <!-- <li>
                                                <a class="active" id="filterNewsBtn" (click)="ShowLatestNews('Latest')">Latest</a>
                                            </li> -->

                                            <li>
                                                <a id="filterNewsBtn" (click)="filterTimeByHours('3H')">3H</a>
                                            </li>

                                            <li>
                                                <a id="filterNewsBtn" (click)="filterTimeByHours('6H')">6H</a>
                                            </li>

                                            <li>
                                                <a id="filterNewsBtn" (click)="filterTimeByHours('12H')">12H</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="headline-contents">
                                    <img class="loader" src="../../../assets/images/loading.gif" *ngIf="loader" alt="">
                                    <div class="single-head" *ngFor="let news of instanceDataTime">
                                        <div class="timestamp">{{news.attribute_timestamp}}</div>
                                        <div class="news-heading">
                                            <h5>{{news.event_name}}</h5>
                                        </div>
                                        <div class="news-link">
                                            <a [href]="news.reference_link" target="_blank">Continue Reading</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="read-more-btn">
                                    <button routerLink="/headlines">Read More</button>
                                </div>
                            </div>
                        </div>

                        <!-- compliance dashboard -->
                        <!-- <div class="col-md-4 dash-item" (click)="selectCard('dashboard')" -->
                        <div class="col-md-4 dash-item" (click)="showIFrameCompliance=true"
                            *ngIf="this.selectedItem=='compliance'&&compliance_dashboard">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/dashboard-img.png">
                            </div>
                            <h2 class="dashboard-card-title">Compliance Dashboards</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('alert')"
                            *ngIf="this.selectedItem=='compliance'&&compliance_alerts">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/unlock.png">
                            </div>
                            <h2 class="dashboard-card-title">Alerts</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="selectCard('controlview')"
                            *ngIf="this.selectedItem=='compliance'&&compliance_ctrl_view">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/control-view.png">
                            </div>
                            <h2 class="dashboard-card-title">Control View</h2>
                        </div>

                        <!-- Analytics dashboard -->
                        <div class="col-md-4 dash-item" (click)="showIFrame=true"
                            *ngIf="this.selectedItem=='analytics_dashboard'&&analytics_privilege.analytics_secuirity">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/secure-data.png">
                            </div>
                            <h2 class="dashboard-card-title">Security Analytics</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="showiframeUrlAnalytics_ciso=true"
                            *ngIf="this.selectedItem=='analytics_dashboard'&&analytics_privilege.analytics_ciso">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/data-security.png">
                            </div>
                            <h2 class="dashboard-card-title">CISO</h2>
                        </div>
                        <div class="col-md-4 dash-item" (click)="showiframeUrlAnalytics_mngmt=true"
                            *ngIf="this.selectedItem=='analytics_dashboard'&&analytics_privilege.analytics_mngmt">
                            <div class="card">
                                <div class="overlay"></div>
                                <img class="dashboard-img" src="assets/images/cyber-security.png">
                            </div>
                            <h2 class="dashboard-card-title">Management</h2>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div style="
position: absolute;
top: 90px;
left: 15px;" class="btn btn-sm btn-warning" (click)="showIFrame=true">Investigate</div> -->
<div *ngIf="showIFrame" style="position: fixed;
background-color: white;
top: 0;
width: 100%;
height: 100vh;
left: 0;
z-index: 9999; ">
    <button style="position: absolute; bottom: 1rem; left: 1rem;" class="btn btn-sm backtoCybot"
        (click)="showIFrame=false">
        <img class="dashboard-img" src="assets/images/logo-icon.png">
    </button>
    <iframe scrolling="yes" [src]="iframeUrl" target="_parent" width="100%" height="100%"
        style="border:none;overflow:hidden" frameborder="0" allowTransparency="true"></iframe>
</div>
<div *ngIf="showIFrameCompliance" style="position: fixed;
background-color: white;
top: 0;
width: 100%;
height: 100vh;
left: 0;
z-index: 9999; ">
    <button style="position: absolute; bottom: 1rem; left: 1rem;" class="btn btn-sm backtoCybot"
        (click)="showIFrameCompliance=false">
        <img class="dashboard-img" src="assets/images/logo-icon.png">
    </button>
    <iframe scrolling="yes" [src]="iframeUrlCompliance" target="_parent" width="100%" height="100%"
        style="border:none;overflow:hidden" frameborder="0" allowTransparency="true"></iframe>
</div>


<div *ngIf="showiframeUrlAnalytics_ciso" style="position: fixed;
background-color: white;
top: 0;
width: 100%;
height: 100vh;
left: 0;
z-index: 9999; ">
    <button style="position: absolute; bottom: 1rem; left: 1rem;" class="btn btn-sm backtoCybot"
        (click)="showiframeUrlAnalytics_ciso=false">
        <img class="dashboard-img" src="assets/images/logo-icon.png">
    </button>
    <iframe scrolling="yes" [src]="iframeUrl_analytics_ciso" target="_parent" width="100%" height="100%"
        style="border:none;overflow:hidden" frameborder="0" allowTransparency="true"></iframe>
</div>
<div *ngIf="showiframeUrlAnalytics_mngmt" style="position: fixed;
background-color: white;
top: 0;
width: 100%;
height: 100vh;
left: 0;
z-index: 9999; ">
    <button style="position: absolute; bottom: 1rem; left: 1rem;" class="btn btn-sm backtoCybot"
        (click)="showiframeUrlAnalytics_mngmt=false">
        <img class="dashboard-img" src="assets/images/logo-icon.png">
    </button>
    <iframe scrolling="yes" [src]="iframeUrl_analytics_mngmt" target="_parent" width="100%" height="100%"
        style="border:none;overflow:hidden" frameborder="0" allowTransparency="true"></iframe>
</div>