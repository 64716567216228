
<div class="row">
  <div class="col-sm-7 col-md-7">
    <div class="d-flex tableHeader align-items-center">
      <h4 class="card-title mb-2">{{datas.title}}</h4>
      <h2 class="statCount" *ngIf="datas.statCount">{{datas.statCount}}</h2>
    </div>
   
  </div>
  <div class="col-sm-5 col-md-5">
    <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
        class="d-inline-flex align-items-center">Search:
        <input type="text" name="searchTerm" class="form-control form-control-sm ml-2" aria-controls="tickets-table"
          [(ngModel)]="term" (ngModelChange)="search($event)" /></label>
        </div>
       
  </div>
  <div class="col-md-10 mt-0">
    <div>{{datas.titleBody}}</div>
    
  </div>
  <div class="col-md-2 pl-0">
    <div (click)="downloadCSV(filteredData,titleArray)" *ngIf="filteredData.length" class="download">Export Excel</div>
  </div>
</div>
<div class="table-responsive table-rev">
  <table class="table table-centered">
    <thead class="thead-light">
      <tr>
        <th scope="col" *ngFor="let col of titleArray">{{col}}</th>
      </tr>
    </thead>
    <tbody *ngIf="filteredData">
      <tr *ngFor="let row of filteredData">
        <th *ngFor="let col of titleArray; let i = index">{{row[i]}}</th>
      </tr>
    </tbody>
  </table>
</div>
<div class="loader" *ngIf="isLoader"></div>