import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/core/services/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "src/app/core/services/settings.service";
import { RuleStudioService } from "src/app/core/services/rule-studio.service";

@Component({
  selector: 'app-add-exceptions',
  templateUrl: './add-exceptions.component.html',
  styleUrls: ['./add-exceptions.component.scss']
})
export class AddExceptionsComponent implements OnInit {

  @Input() objArr: any = [];
  organizationList = [];
  addExceptionsform: FormGroup;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  err = false;
  passwordShow = false;
  submited = false;
  dataError = false;
  btnName = "Create";
  playbookIdData = [];
  tenantID;
  tenantCatalogue
  type
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private ruleStudioService: RuleStudioService
  ) { }
  label_list = [{
    "ueba_id": "UEBA-001",
    "playbook_name": "Logon detected outside of usual time for host"
  },
  {
    "ueba_id": "UEBA-002",
    "playbook_name": "Logon detected outside of usual time for user"
  },
  {
    "ueba_id": "UEBA-003",
    "playbook_name": "Logon detected to unusual host for user "
  },
  {
    "ueba_id": "UEBA-004",
    "playbook_name": "File activities detected outside of usual time for user "
  },
  {
    "ueba_id": "UEBA-005",
    "playbook_name": "Management activities detected outside of usual time for user"
  },
  {
    "ueba_id": "UEBA-006",
    "playbook_name": "Unusually high number of file modification activities detected for user"
  },
  {
    "ueba_id": "UEBA-008",
    "playbook_name": "Unusually high number of management activities detected for user "
  },
  {
    "ueba_id": "UEBA-009",
    "playbook_name": "Unusually high number of file deletion activities detected for user "
  },
  {
    "ueba_id": "UEBA-010",
    "playbook_name": "Unusually high number of account lockout activity detected for domain"
  },
  {
    "ueba_id": "UEBA-011",
    "playbook_name": "Unusually high number of file activities detected for user "
  },
  {
    "ueba_id": "UEBA-012",
    "playbook_name": "Unusually high number of logon failures detected for host"
  },
  {
    "ueba_id": "UEBA-013",
    "playbook_name": "Unusually high number of logon failures detected for user "
  },
  {
    "ueba_id": "UEBA-014",
    "playbook_name": "Internal network transfer detected outside of usual time for host "
  },
  {
    "ueba_id": "UEBA-015",
    "playbook_name": "Internal network transfer detected outside of usual time for user "
  },
  {
    "ueba_id": "UEBA-016",
    "playbook_name": "External network transfer detected outside of usual time for host"
  },
  {
    "ueba_id": "UEBA-017",
    "playbook_name": "External network transfer detected outside of usual time for user"
  }];
  ngOnInit(): void {
    this.tenantID = this.objArr[3];
    this.type = this.objArr[4];
    this.getData()
    this.addExceptionsform = this.formBuilder.group({
      playbook_id: ["", Validators.required],
      user_name: [""],
      host_name: [""],
      source_ip: [""],
      process_name: [""],
      process_executable: [""],
      process_hash: [""],
      parent_process_name: [""],
      parent_process_executable: [""],
      url: [""],
      dest_ip: [""],
      tenant_id: [""],
      comment: [""],
    });
    this.addExceptionsform.valueChanges.subscribe(() => {
      this.err = false;
    });
    this.getLicenseDetailsList();
    this.btnName = this.objArr[2];
    if (this.objArr[0] == "Exceptions Edit") {
      if (this.type == 'Investigate') {
        this.addExceptionsform.controls["playbook_id"].setValue(
          this.objArr[1].playbook_id
        );
      } else {
        this.addExceptionsform.controls["ueba_id"].setValue(
          this.objArr[1].ueba_id
        );
      }
      this.addExceptionsform.controls["user_name"].setValue(
        this.objArr[1].user_name
      );
      this.addExceptionsform.controls["host_name"].setValue(
        this.objArr[1].host_name
      );
      this.addExceptionsform.controls["source_ip"].setValue(
        this.objArr[1].source_ip
      );
      this.addExceptionsform.controls["process_name"].setValue(
        this.objArr[1].process_name
      );
      this.addExceptionsform.controls["process_executable"].setValue(
        this.objArr[1].process_executable
      );
      this.addExceptionsform.controls["process_hash"].setValue(
        this.objArr[1].process_hash
      );
      this.addExceptionsform.controls["parent_process_name"].setValue(
        this.objArr[1].parent_process_name
      );
      this.addExceptionsform.controls["parent_process_executable"].setValue(
        this.objArr[1].parent_process_executable
      );
      this.addExceptionsform.controls["url"].setValue(this.objArr[1].url);
      this.addExceptionsform.controls["dest_ip"].setValue(
        this.objArr[1].dest_ip
      );
      this.addExceptionsform.controls["tenant_id"].setValue(
        this.objArr[1].tenant_id
      );
      this.addExceptionsform.controls["comment"].setValue(
        this.objArr[1].comment
      );
    } else if (this.objArr[0] == "Exceptions Add") {
      // this.doData.ProcessID = d.process_pid;
      // this.doData.ProcessCommand = d.process_commandline;
      // this.doData.Lastdetection = d.detection_timestamp;
      this.addExceptionsform.controls["user_name"].setValue(
        this.objArr[1]._source.user_name
      );
      this.addExceptionsform.controls["host_name"].setValue(
        this.objArr[1]._source.host_name
      );
      this.addExceptionsform.controls["source_ip"].setValue(
        this.objArr[1]._source.source_ip
      );
      this.addExceptionsform.controls["process_name"].setValue(
        this.objArr[1]._source.process_name
      );
      this.addExceptionsform.controls["dest_ip"].setValue(
        this.objArr[1]._source.dest_ip
      );
      this.addExceptionsform.controls["tenant_id"].setValue(this.objArr[3]);
    } else {
      if (this.objArr[2] == "Create from UEBA") {
        // this.addExceptionsform.controls["user_name"].setValue(this.objArr[1]._source.user_name);
        this.addExceptionsform.patchValue({
          user_name: this.objArr[1].user_name,
          host_name: this.objArr[1].host_name,
          playbook_id: this.objArr[1].ueba_id,
          tenant_id: this.objArr[3],
        });
      }
      this.addExceptionsform.controls["tenant_id"].setValue(this.objArr[3]);
    }
  }

  ngAfterViewInit() {
    console.log("tenantID", this.tenantID);
    // this.playbookIdList();
  }

  exceptionsCreation() {
    this.submited = true
    let a = ["thp_exceptions_" + this.addExceptionsform.value.tenant_id];
    let data;
    let tenantID = this.addExceptionsform.value.tenant_id;
    let str = localStorage.getItem("USER_NAME");
    let date = new Date().toISOString();
    let obj
    console.log("this.addExceptionsform.value.playbook_id", this.addExceptionsform.value.playbook_id)
    if (this.type == 'Investigate') {
      obj = this.playbookIdData.find((o, i) => {
        if (o.playbook_id === this.addExceptionsform.value.playbook_id) {
          return o; // stop searching
        }
      });
      console.log("obj", obj)
    }
    else {
      obj = this.label_list.find((o, i) => {
        if (o.ueba_id === this.addExceptionsform.value.playbook_id) {
          return o; // stop searching
        }
      });
      console.log("obj", obj)
    }


    if (!this.addExceptionsform.valid) {
      return
    }
    if (this.objArr[0] == "Exceptions Edit") {

      if (this.type == 'Investigate') {
        let id = this.objArr[1].id;
        data = {
          playbook_name: obj.playbook_name,
          playbook_id: this.addExceptionsform.value.playbook_id,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          source_ip: this.addExceptionsform.value.source_ip,
          process_name: this.addExceptionsform.value.process_name,
          process_executable: this.addExceptionsform.value.process_executable,
          process_hash: this.addExceptionsform.value.process_hash,
          parent_process_name: this.addExceptionsform.value.parent_process_name,
          parent_process_executable:
            this.addExceptionsform.value.parent_process_executable,
          url: this.addExceptionsform.value.url,
          dest_ip: this.addExceptionsform.value.dest_ip,
          tenant_id: this.addExceptionsform.value.tenant_id,
          comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment,
        let data1 = {
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          source_ip: this.addExceptionsform.value.source_ip,
          process_name: this.addExceptionsform.value.process_name,
          process_executable: this.addExceptionsform.value.process_executable,
          process_hash: this.addExceptionsform.value.process_hash,
          parent_process_name: this.addExceptionsform.value.parent_process_name,
          parent_process_executable:
            this.addExceptionsform.value.parent_process_executable,
          url: this.addExceptionsform.value.url,
          dest_ip: this.addExceptionsform.value.dest_ip,
          comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment

        let data2 = {
          user_name: "",
          host_name: "",
          source_ip: "",
          process_name: "",
          process_executable: "",
          process_hash: "",
          parent_process_name: "",
          parent_process_executable: "",
          url: "",
          dest_ip: "",
          comment: "",
        };
        // comment: "",

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }
        let d1 = {
          doc: data,
        };

        this.settingsService.exceptionsUpdate(d1, tenantID, id).subscribe(
          (response: any) => {
            if (response.result == "updated") {
              let data = {
                playbook_id: this.addExceptionsform.value.playbook_id,
                playbook_name: obj.playbook_name,
                created_by: this.objArr[1].created_by,
                created_at: this.objArr[1].created_at,
                id: response._id,
                index: response._index,
                type: this.type,
                user_name: this.addExceptionsform.value.user_name,
                host_name: this.addExceptionsform.value.host_name,
                source_ip: this.addExceptionsform.value.source_ip,
                process_name: this.addExceptionsform.value.process_name,
                process_executable: this.addExceptionsform.value.process_executable,
                process_hash: this.addExceptionsform.value.process_hash,
                parent_process_name: this.addExceptionsform.value.parent_process_name,
                parent_process_executable:
                  this.addExceptionsform.value.parent_process_executable,
                url: this.addExceptionsform.value.url,
                dest_ip: this.addExceptionsform.value.dest_ip,
                comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "update",
                data: data,
                index: this.objArr[3],
              });
            } else {
              this.err = true;
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else if (this.type == 'UEBA') {
        let id = this.objArr[1].id;
        data = {
          detection_name: obj.playbook_name,
          ueba_id: this.addExceptionsform.value.playbook_id,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          // source_ip: this.addExceptionsform.value.source_ip,
          // process_name: this.addExceptionsform.value.process_name,
          // process_executable: this.addExceptionsform.value.process_executable,
          // process_hash: this.addExceptionsform.value.process_hash,
          // parent_process_name: this.addExceptionsform.value.parent_process_name,
          // parent_process_executable:
          //   this.addExceptionsform.value.parent_process_executable,
          // url: this.addExceptionsform.value.url,
          // dest_ip: this.addExceptionsform.value.dest_ip,
          // tenant_id: this.addExceptionsform.value.tenant_id,
          comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment,
        let data1 = {
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          // source_ip: this.addExceptionsform.value.source_ip,
          // process_name: this.addExceptionsform.value.process_name,
          // process_executable: this.addExceptionsform.value.process_executable,
          // process_hash: this.addExceptionsform.value.process_hash,
          // parent_process_name: this.addExceptionsform.value.parent_process_name,
          // parent_process_executable:
          //   this.addExceptionsform.value.parent_process_executable,
          // url: this.addExceptionsform.value.url,
          // dest_ip: this.addExceptionsform.value.dest_ip,
          comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment

        let data2 = {
          user_name: "",
          host_name: "",
          // source_ip: "",
          // process_name: "",
          // process_executable: "",
          // process_hash: "",
          // parent_process_name: "",
          // parent_process_executable: "",
          // url: "",
          // dest_ip: "",
          comment: "",
        };
        // comment: "",

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }
        let d1 = {
          doc: data,
        };

        this.settingsService.exceptionsUpdateUEBA(d1, tenantID, id).subscribe(
          (response: any) => {
            if (response.result == "updated") {
              let data = {
                detection_name: obj.playbook_name,
                ueba_id: this.addExceptionsform.value.playbook_id,
                created_by: this.objArr[1].created_by,
                created_at: this.objArr[1].created_at,
                id: response._id,
                index: response._index,
                type: this.type,
                user_name: this.addExceptionsform.value.user_name,
                host_name: this.addExceptionsform.value.host_name,
                // source_ip: this.addExceptionsform.value.source_ip,
                // process_name: this.addExceptionsform.value.process_name,
                // process_executable: this.addExceptionsform.value.process_executable,
                // process_hash: this.addExceptionsform.value.process_hash,
                // parent_process_name: this.addExceptionsform.value.parent_process_name,
                // parent_process_executable:
                //   this.addExceptionsform.value.parent_process_executable,
                // url: this.addExceptionsform.value.url,
                // dest_ip: this.addExceptionsform.value.dest_ip,
                comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "update",
                data: data,
                index: this.objArr[3],
              });
            } else {
              this.err = true;
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else {
        let id = this.objArr[1].id;
        data = {
          playbook_name: obj.playbook_name,
          playbook_id: this.addExceptionsform.value.playbook_id,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          source_ip: this.addExceptionsform.value.source_ip,
          process_name: this.addExceptionsform.value.process_name,
          process_executable: this.addExceptionsform.value.process_executable,
          process_hash: this.addExceptionsform.value.process_hash,
          parent_process_name: this.addExceptionsform.value.parent_process_name,
          parent_process_executable:
            this.addExceptionsform.value.parent_process_executable,
          url: this.addExceptionsform.value.url,
          dest_ip: this.addExceptionsform.value.dest_ip,
          tenant_id: this.addExceptionsform.value.tenant_id,
          comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment,
        let data1 = {
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          source_ip: this.addExceptionsform.value.source_ip,
          process_name: this.addExceptionsform.value.process_name,
          process_executable: this.addExceptionsform.value.process_executable,
          process_hash: this.addExceptionsform.value.process_hash,
          parent_process_name: this.addExceptionsform.value.parent_process_name,
          parent_process_executable:
            this.addExceptionsform.value.parent_process_executable,
          url: this.addExceptionsform.value.url,
          dest_ip: this.addExceptionsform.value.dest_ip,
          comment: this.addExceptionsform.value.comment,
        };
        // comment: this.addExceptionsform.value.comment

        let data2 = {
          user_name: "",
          host_name: "",
          source_ip: "",
          process_name: "",
          process_executable: "",
          process_hash: "",
          parent_process_name: "",
          parent_process_executable: "",
          url: "",
          dest_ip: "",
          comment: "",
        };
        // comment: "",

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }
        let d1 = {
          doc: data,
        };

        this.settingsService.exceptionsUpdate(d1, tenantID, id).subscribe(
          (response: any) => {
            if (response.result == "updated") {
              let data = {
                playbook_id: this.addExceptionsform.value.playbook_id,
                playbook_name: obj.playbook_name,
                created_by: this.objArr[1].created_by,
                created_at: this.objArr[1].created_at,
                id: response._id,
                index: response._index,
                type: this.type,
                user_name: this.addExceptionsform.value.user_name,
                host_name: this.addExceptionsform.value.host_name,
                source_ip: this.addExceptionsform.value.source_ip,
                process_name: this.addExceptionsform.value.process_name,
                process_executable: this.addExceptionsform.value.process_executable,
                process_hash: this.addExceptionsform.value.process_hash,
                parent_process_name: this.addExceptionsform.value.parent_process_name,
                parent_process_executable:
                  this.addExceptionsform.value.parent_process_executable,
                url: this.addExceptionsform.value.url,
                dest_ip: this.addExceptionsform.value.dest_ip,
                comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "update",
                data: data,
                index: this.objArr[3],
              });
            } else {
              this.err = true;
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      }
    } else if (this.objArr[0] == "Exceptions Creation") {
      if (this.type == 'Investigate') {
        data = {
          playbook_name: obj.playbook_name,
          comment: this.addExceptionsform.value.comment,
          playbook_id: this.addExceptionsform.value.playbook_id,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          source_ip: this.addExceptionsform.value.source_ip,
          process_name: this.addExceptionsform.value.process_name,
          process_executable: this.addExceptionsform.value.process_executable,
          process_hash: this.addExceptionsform.value.process_hash,
          parent_process_name: this.addExceptionsform.value.parent_process_name,
          parent_process_executable:
            this.addExceptionsform.value.parent_process_executable,
          url: this.addExceptionsform.value.url,
          dest_ip: this.addExceptionsform.value.dest_ip,
          tenant_id: this.addExceptionsform.value.tenant_id,
          created_by: str,
          created_at: date,
        };
        let data1 = {
          comment: this.addExceptionsform.value.comment,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          source_ip: this.addExceptionsform.value.source_ip,
          process_name: this.addExceptionsform.value.process_name,
          process_executable: this.addExceptionsform.value.process_executable,
          process_hash: this.addExceptionsform.value.process_hash,
          parent_process_name: this.addExceptionsform.value.parent_process_name,
          parent_process_executable:
            this.addExceptionsform.value.parent_process_executable,
          url: this.addExceptionsform.value.url,
          dest_ip: this.addExceptionsform.value.dest_ip,
        };

        let data2 = {
          user_name: "",
          host_name: "",
          source_ip: "",
          process_name: "",
          process_executable: "",
          process_hash: "",
          parent_process_name: "",
          parent_process_executable: "",
          url: "",
          dest_ip: "",
          comment: "",
        };

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }

        this.settingsService.exceptionsCreation(data, tenantID).subscribe(
          (response: any) => {
            if (response.result == "created") {
              let data = {
                playbook_name: obj.playbook_name,
                playbook_id: this.addExceptionsform.value.playbook_id,
                id: response._id,
                index: response._index,
                type: response._type,
                created_by: str,
                created_at: date,
                user_name: this.addExceptionsform.value.user_name,
                host_name: this.addExceptionsform.value.host_name,
                source_ip: this.addExceptionsform.value.source_ip,
                process_name: this.addExceptionsform.value.process_name,
                process_executable: this.addExceptionsform.value.process_executable,
                process_hash: this.addExceptionsform.value.process_hash,
                parent_process_name: this.addExceptionsform.value.parent_process_name,
                parent_process_executable:
                  this.addExceptionsform.value.parent_process_executable,
                url: this.addExceptionsform.value.url,
                dest_ip: this.addExceptionsform.value.dest_ip,
                comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "created",
                data: data,
              });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else if (this.type == 'UEBA') {
        data = {
          detection_name: obj.playbook_name,
          comment: this.addExceptionsform.value.comment,
          ueba_id: this.addExceptionsform.value.playbook_id,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          // source_ip: this.addExceptionsform.value.source_ip,
          // process_name: this.addExceptionsform.value.process_name,
          // process_executable: this.addExceptionsform.value.process_executable,
          // process_hash: this.addExceptionsform.value.process_hash,
          // parent_process_name: this.addExceptionsform.value.parent_process_name,
          // parent_process_executable:
          //   this.addExceptionsform.value.parent_process_executable,
          // url: this.addExceptionsform.value.url,
          // dest_ip: this.addExceptionsform.value.dest_ip,
          // tenant_id: this.addExceptionsform.value.tenant_id,
          created_by: str,
          created_at: date,
        };
        let data1 = {
          comment: this.addExceptionsform.value.comment,
          user_name: this.addExceptionsform.value.user_name,
          host_name: this.addExceptionsform.value.host_name,
          // source_ip: this.addExceptionsform.value.source_ip,
          // process_name: this.addExceptionsform.value.process_name,
          // process_executable: this.addExceptionsform.value.process_executable,
          // process_hash: this.addExceptionsform.value.process_hash,
          // parent_process_name: this.addExceptionsform.value.parent_process_name,
          // parent_process_executable:
          //   this.addExceptionsform.value.parent_process_executable,
          // url: this.addExceptionsform.value.url,
          // dest_ip: this.addExceptionsform.value.dest_ip,
        };

        let data2 = {
          user_name: "",
          host_name: "",
          // source_ip: "",
          // process_name: "",
          // process_executable: "",
          // process_hash: "",
          // parent_process_name: "",
          // parent_process_executable: "",
          // url: "",
          // dest_ip: "",
          comment: "",
        };

        if (JSON.stringify(data1) === JSON.stringify(data2)) {
          this.dataError = true
          return
        } else {
          this.dataError = false
        }
        this.settingsService.exceptionsCreationUEBA(data, tenantID).subscribe(
          (response: any) => {
            if (response.result == "created") {
              let data = {
                detection_name: obj.playbook_name,
                ueba_id: this.addExceptionsform.value.playbook_id,
                id: response._id,
                index: response._index,
                type: response._type,
                created_by: str,
                created_at: date,
                user_name: this.addExceptionsform.value.user_name,
                host_name: this.addExceptionsform.value.host_name,
                // source_ip: this.addExceptionsform.value.source_ip,
                // process_name: this.addExceptionsform.value.process_name,
                // process_executable: this.addExceptionsform.value.process_executable,
                // process_hash: this.addExceptionsform.value.process_hash,
                // parent_process_name: this.addExceptionsform.value.parent_process_name,
                // parent_process_executable:
                //   this.addExceptionsform.value.parent_process_executable,
                // url: this.addExceptionsform.value.url,
                // dest_ip: this.addExceptionsform.value.dest_ip,
                comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "created",
                data: data,
              });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else {
        this.settingsService.exceptionsCreation(data, tenantID).subscribe(
          (response: any) => {
            if (response.result == "created") {
              let data = {
                playbook_name: obj.playbook_name,
                playbook_id: this.addExceptionsform.value.playbook_id,
                id: response._id,
                index: response._index,
                type: response._type,
                created_by: str,
                created_at: date,
                user_name: this.addExceptionsform.value.user_name,
                host_name: this.addExceptionsform.value.host_name,
                // source_ip: this.addExceptionsform.value.source_ip,
                // process_name: this.addExceptionsform.value.process_name,
                // process_executable: this.addExceptionsform.value.process_executable,
                // process_hash: this.addExceptionsform.value.process_hash,
                // parent_process_name: this.addExceptionsform.value.parent_process_name,
                // parent_process_executable:
                //   this.addExceptionsform.value.parent_process_executable,
                // url: this.addExceptionsform.value.url,
                // dest_ip: this.addExceptionsform.value.dest_ip,
                comment: this.addExceptionsform.value.comment,
              };
              this.submited = false
              this.modalService.dismissAll({
                status: "created",
                data: data,
              });
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      }

    } else {
      data = {
        playbook_name: obj.playbook_name,
        comment: this.addExceptionsform.value.comment,
        playbook_id: this.addExceptionsform.value.playbook_id,
        user_name: this.addExceptionsform.value.user_name,
        host_name: this.addExceptionsform.value.host_name,
        source_ip: this.addExceptionsform.value.source_ip,
        process_name: this.addExceptionsform.value.process_name,
        process_executable: this.addExceptionsform.value.process_executable,
        process_hash: this.addExceptionsform.value.process_hash,
        parent_process_name: this.addExceptionsform.value.parent_process_name,
        parent_process_executable:
          this.addExceptionsform.value.parent_process_executable,
        url: this.addExceptionsform.value.url,
        dest_ip: this.addExceptionsform.value.dest_ip,
        tenant_id: this.addExceptionsform.value.tenant_id,
        created_by: str,
        created_at: date,
      };
      // comment: this.addExceptionsform.value.comment,


      let data1 = {
        comment: this.addExceptionsform.value.comment,
        user_name: this.addExceptionsform.value.user_name,
        host_name: this.addExceptionsform.value.host_name,
        source_ip: this.addExceptionsform.value.source_ip,
        process_name: this.addExceptionsform.value.process_name,
        process_executable: this.addExceptionsform.value.process_executable,
        process_hash: this.addExceptionsform.value.process_hash,
        parent_process_name: this.addExceptionsform.value.parent_process_name,
        parent_process_executable:
          this.addExceptionsform.value.parent_process_executable,
        url: this.addExceptionsform.value.url,
        dest_ip: this.addExceptionsform.value.dest_ip,
      };
      // comment: this.addExceptionsform.value.comment

      let data2 = {
        user_name: "",
        host_name: "",
        source_ip: "",
        process_name: "",
        process_executable: "",
        process_hash: "",
        parent_process_name: "",
        parent_process_executable: "",
        url: "",
        dest_ip: "",
        comment: "",
      };
      // comment: "",

      if (JSON.stringify(data1) === JSON.stringify(data2)) {
        this.dataError = true
        return
      } else {
        this.dataError = false
      }
      // return
      // dataError
      this.settingsService.exceptionsCreation(data, tenantID).subscribe(
        (response: any) => {
          if (response.result == "created") {
            let data = {
              playbook_name: obj.playbook_name,
              playbook_id: this.addExceptionsform.value.playbook_id,
              id: response._id,
              index: response._index,
              type: response._type,
              created_by: str,
              created_at: date,
              user_name: this.addExceptionsform.value.user_name,
              host_name: this.addExceptionsform.value.host_name,
              source_ip: this.addExceptionsform.value.source_ip,
              process_name: this.addExceptionsform.value.process_name,
              process_executable: this.addExceptionsform.value.process_executable,
              process_hash: this.addExceptionsform.value.process_hash,
              parent_process_name: this.addExceptionsform.value.parent_process_name,
              parent_process_executable:
                this.addExceptionsform.value.parent_process_executable,
              url: this.addExceptionsform.value.url,
              dest_ip: this.addExceptionsform.value.dest_ip,
              comment: this.addExceptionsform.value.comment,
            };
            this.submited = false
            this.modalService.dismissAll({
              status: "created",
              data: data,
            });
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }
  clearForm() {
    this.modalService.dismissAll({
      status: "not-created",
    });
  }

  getLicenseDetailsList() {
    let data = {
      query: {
        match_all: {},
      },
    };
    this.settingsService.getLicenseDetailsList(data).subscribe(
      (response: any) => {
        let data = response.hits.hits;
        this.organizationList = [];
        data.forEach((item) => {
          let t = {
            start_date: item._source.start_date,
            end_date: item._source.end_date,
            id: item._id,
            tenant_id: item._source.tenant_id,
            organization_name: item._source.organization_name,
            index: item._index,
          };
          if (item._source.tenant_id)
            this.organizationList.push(t);
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  playbookIdList() {
    let data = {
      match_all: {}
    };
    let tenantID = this.objArr[3];
    // let tenantID = "ab2";
    this.settingsService.scheduleList(data, tenantID).subscribe(
      (response: any) => {
        let data = response.hits.hits;
        this.playbookIdData = [];
        data.forEach((item) => {
          if (item._source.playbook_id != undefined) {
            let t = {
              id: item._id,
              index: item._index,
              playbook_id: item._source.playbook_id,
              playbook_name: item._source.playbook_name,
              status: item._source.status,
            };
            this.playbookIdData.push(t);
          }
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  getData() {
    this.tenantCatalogue = [];
    this.ruleStudioService.getCatalogueTenant().subscribe(
      (res: any) => {
        // console.log("res", res)
        // this.playbookIdData = res;
        res.forEach(data => {
          // console.log("data", data)
          let t = {
            playbook_id: data[0],
            playbook_name: data[1],
          };
          this.playbookIdData.push(t);

          // this.tenantCatalogue.push(data);
        });
        // console.log("this.playbookIdData", this.playbookIdData)
      },
      (error) => {
        console.log("error", error);
      }
    );

  }

}

