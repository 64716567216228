<div class="modal-header mb-0" style="margin-bottom: 0 !important;">
    <h4 class="modal-title">{{objArr[0]}}</h4>
</div>
<div class="modal-body scrollModal pt-4">
    <form [formGroup]="addExceptionsform" role="form" class="singleLineForm">
        <div class="row">
            <div class="col-md-6">

                <div class="form-group">
                    <div class="sLine">
                        <label for="category-name" class="control-label">User Name</label>
                        <input formControlName="user_name" type="text" class="form-control" placeholder="User Name">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.user_name.errors?.required && addExceptionsform.controls.user_name.touched">
                            User Name is required
                        </small>
                    </div>
                </div>


                <div class="form-group">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Host Name</label>
                        <input formControlName="host_name" type="text" class="form-control" placeholder="Host Name">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.host_name.errors?.required && addExceptionsform.controls.host_name.touched">
                            Host Name is required
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <div class="sLine">
                        <label for="category-name" class="control-label">UEBA Id*</label>
                        <!-- <input formControlName="playbook_id" type="text" class="form-control" placeholder="Playbook Id"> -->
                        <select formControlName="ueba_id" class="form-control" placeholder="UEBA ID">
                            <option hidden value="" selected>UEBA Id</option>
                            <option *ngFor="let organization of label_list" [value]="organization.ueba_id">
                                {{organization.playbook_name}}-{{organization.ueba_id}}
                            </option>
                        </select>
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.ueba_id.errors?.required && submited">
                            UEBA Id is required
                        </small>
                    </div>
                </div>

                <!-- <div class="form-group">
                    <div class="sLine">
                        <label for="category-name" class="control-label">Time Period</label>
                        <input formControlName="time" type="datetime-local" class="form-control"  placeholder="Time Period">
                    </div>
                    <div class="error-div">
                        <small class="text-danger"
                            *ngIf="addExceptionsform.controls.time.errors?.required && addExceptionsform.controls.time.touched">
                            Time is required
                        </small>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="category-name" class="control-label">Comments</label>
                    <textarea formControlName="comment" class="form-control" placeholder="Comment">
                            </textarea>
                </div>
                <div class="error-div">
                    <small class="text-danger" *ngIf="dataError && submited">
                        Fill any other data with Tenant ID and Playbook Id
                    </small>
                </div>
            </div>
        </div>

    </form>
</div>
<div class="modal-footer"> <button type="button" id="closebutton" class="btn btn-danger"
        (click)="clearForm()">Close</button>
    <button type="button" class="btn btn-success" (click)="exceptionsCreation()">{{btnName}}
        <i class="fab fa-telegram-plane ml-1"></i></button>
</div>