import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
declare var google: any;

@Component({
  selector: 'app-geochart',
  templateUrl: './geochart.component.html',
  styleUrls: ['./geochart.component.scss']
})
export class GeochartComponent implements OnInit {

  constructor() { }

  chartData: any;
  options: any;
  noCountries = false;
  isLoadingChart: boolean = true;
  @ViewChild('geoChart', { static: false }) geoChart: ElementRef;
  @Input() countries: any[];

  ngOnInit(): void {
    this.options = {
      // region: 'AE',
      // displayMode: 'regions',
      // resolution: 'provinces',
      height: 500
    };
    if (this.countries[1] == 'N/A') {
      this.noCountries = true;
    } else {
      this.noCountries = false;
    }
    let $this = this;
    setTimeout(function () {
      $this.isLoadingChart = false;
      $this.drawChart();
    }, 1000);
  }

  drawChart() {
    this.chartData = new google.visualization.GeoChart(this.geoChart.nativeElement);
    let dynaVisual = google.visualization.arrayToDataTable(this.countries);
    this.options.colors = ['#cccccc', '#63b7af', '#C7CEEA', '#ff3cc9', '#dc3545', '#FFB7B2', '#9400D3']

    this.chartData.draw(dynaVisual, this.options);
  }

  ngAfterViewInit() {
    google.load('visualization', '1', { 'packages': ['geochart'] });
  }

}
