import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  term: any;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  dataTemp: any = []
  expectedValues: any
  type: any
  @Input() set analyticalData(val) {
    // console.log("analyticalData", val)
    this.titleArray = val.titleData
    this.datas = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  datas: any;
  constructor(public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.query = JSON.parse(this.query)
    this.dashboardService.dashboardApi(this.query, this.api, this.method).subscribe(
      (result: any) => {
        console.log("result", result)
        if (result) {
          if (this.type == 2) {
            let data = result.hits.hits
            let k = 0
            data.forEach(data => {
              let dataSub = []
              dataSub.push(k + 1)
              this.expectedValues.forEach(element => {
                let tempData = data?._source
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  tempData = tempData[expectedValuesArray[i]]
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              this.dataTemp.push(dataSub)
              k++
            });
            this.datas.statCount = this.dataTemp.length;
          } else if (this.type == 1) {
            let data = result.hits.hits
            let k = 0
            data.forEach(data => {
              let dataSub = []
              dataSub.push(k + 1)
              this.expectedValues.forEach(element => {
                let tempData = data?._source
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  tempData = tempData[expectedValuesArray[i]]
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              this.dataTemp.push(dataSub)
              k++
            });
            this.datas.statCount = this.dataTemp.length;
          } else {
            console.log("DataGridComponent", result)
          }

        }
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }

}
