import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import { Observable, Subject } from "rxjs";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CommonService } from "src/app/core/services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-investigation-repetition',
  templateUrl: './investigation-repetition.component.html',
  styleUrls: ['./investigation-repetition.component.scss']
})
export class InvestigationRepetitionComponent implements OnInit {

  constructor(
    public common: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) { }
  huntSummaryData = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  host;
  hash;
  period = {
    gte: '',
    lte: ''
  };
  ip;
  user;
  hunt_detection_id;
  playbook_id;
  group_id
  type
  hunt_id
  groupedField: any
  pidLists = ["MITRE-001", "MITRE-002", "MITRE-003", "MITRE-004", "MITRE-005", "MITRE-007", "MITRE-008", "MITRE-009", "MITRE-015", "MITRE-016", "MITRE-028", "MITRE-031", "MITRE-047", "MITRE-048", "MITRE-030", "MITRE-032", "MITRE-033", "MITRE-036"
    , "MITRE-014", "MITRE-017", "MITRE-020", "MITRE-021", "MITRE-023", "MITRE-027", "MITRE-057", "MITRE-059"
    , "MITRE-063", "MITRE-064", "MITRE-065", "MITRE-066", "MITRE-067", "MITRE-068", "MITRE-069", "MITRE-070", "MITRE-071", "MITRE-073", "MITRE-075", "MITRE-076", "MITRE-022", "MITRE-029", "MITRE-024", "MITRE-019", "MITRE-013",
    "MITRE-010", "MITRE-011", "MITRE-012", "MITRE-038", "MITRE-042", "MITRE-043", "MITRE-046", "MITRE-049", "MITRE-050", "MITRE-051", "MITRE-053", "MITRE-061", "MITRE-062", "MITRE-074", "MITRE-078",
    , "MITRE-006", "MITRE-034", "MITRE-037", "MITRE-039", "MITRE-040", "MITRE-041", "MITRE-025", "MITRE-026", "MITRE-035", "MITRE-052", "MITRE-072", "MITRE-044", "MITRE-045", "MITRE-054", "MITRE-055", "MITRE-060", "MITRE-077"
    , "MITRE-AA01", "MITRE-AA02", "MITRE-AA03", "MITRE-AA04", "MITRE-AA05", "MITRE-AA06", "MITRE-AA07", "MITRE-TI01", "MITRE-TI02", "MITRE-TI03", "TI01", "TI02", "TI03", "TI-01", "TI-02", "TI-03", "AA-01", "AA-03", "AA-04", "AA-06", "AA-02", "AA-05", "AA-07", "MITRE-079", "MITRE-080", "MITRE-081", "MITRE-082", "MITRE-083"];


  total$: Observable<number>;
  service = {
    page: 1,
    pageSize: 4,
  };

  timelineData = [
    {
      date: "28 April",
      title: "Timeline event One",
      // tslint:disable-next-line: max-line-length
      text: "It will be as simple as occidental in fact. To an english person, it will seem like simplified English, as a skeptical friend",
    },
    {
      align: "left",
      date: "21 April",
      title: "Timeline event Two",
      text: "To achieve this, it would be necessary to have more common words.",
    },
    {
      date: "15 April",
      title: "Timeline event Three",
      text: "The new common language will be more simple and regular than the existing European languages be as simple as occidental",
      images: [
        "assets/images/small/img-2.jpg",
        "assets/images/small/img-3.jpg",
        "assets/images/small/img-4.jpg",
      ],
    },
    {
      align: "left",
      date: "09 April",
      title: "Timeline event Four",
      // tslint:disable-next-line: max-line-length
      text: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, ab illo inventore veritatis et",
    },
    {
      date: "02 April",
      title: "Timeline event Five",
      text: "Itaque earum rerum hic tenetur a sapiente delectus, ut aut doloribus asperiores.",
    },
  ];

  ngOnInit(): void {
    if (localStorage.getItem('isDateRange') == "true") {
      this.period = {
        gte: localStorage.getItem('startDate'),
        lte: localStorage.getItem('endDate')
      }
    } else {
      this.period = {
        gte: localStorage.getItem('period'),
        lte: 'now'
      }
    }
    this.group_id = this.route.snapshot.paramMap.get("group_id");
    this.hunt_id = this.route.snapshot.paramMap.get("hunt_id");
    this.host = this.route.snapshot.paramMap.get("host");
    this.hash = this.route.snapshot.paramMap.get("hash");
    this.ip = this.route.snapshot.paramMap.get("ip");
    this.user = this.route.snapshot.paramMap.get("user");
    this.hunt_detection_id = this.route.snapshot.paramMap.get("hunt_detection_id");
    this.playbook_id = this.route.snapshot.paramMap.get("playbook_id");
    this.type = this.route.snapshot.paramMap.get("type");
    if (this.group_id == 'undefined')
      this.gethuntSummaryTable();
    else
      this.getGroupedRepetitionHundSummary()
  }

  gethuntSummaryTable() {
    console.log("this.group_id ", this.group_id);
    console.log("this.host ", this.host);
    console.log("this.hash ", this.hash);
    console.log("this.ip ", this.ip);
    console.log("this.user ", this.user);
    let data = {
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_all: {},
            },
            {
              match_phrase: {
                host: this.host,
              },
            },
            {
              match_phrase: {
                hash: this.hash,
              },
            },
            {
              match_phrase: {
                ip: this.ip,
              },
            },
            {
              match_phrase: {
                user: this.user,
              },
            },

            {
              range: {
                current_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    let data2 = { "size": 500, "query": { "bool": { "filter": [{ "match_all": {} }, { "match_phrase": { "group_id": this.group_id } }, { "range": { "current_timestamp": { "gte": this.period.gte, "lte": this.period.lte, "format": "strict_date_optional_time" } } }] } } }
    this.common.getViewDashboardData(data).subscribe((response: any) => {
      console.log("Table data", response);
      if (response) {
        console.log("New", response);
        let hits = []
        if (response.hits)
          hits = response.hits.hits;
        this.huntSummaryData = [];
        for (let i = 0; i < hits.length; i++) {
          if (this.type == 'investigate-name') {
            console.log("investigate-name")
            this.huntSummaryData.push({
              time_stamp: hits[i]._source.detection_timestamp,
              host_name: hits[i]._source.host,
              user_name: hits[i]._source.user,
              technique_name: hits[i]._source.detection_name,
              tactic_names: hits[i]._source.tactic_name,
              hunt_detection_id: hits[i]._source.hunt_detection_id,
              grp_id: hits[i]._source.grp_id,
              detection_name: hits[i]._source.detection_name,
              tactic_name: hits[i]._source.tactic_name,
              technique_id: hits[i]._source.technique_id,
              detection_timestamp: hits[i]._source.detection_timestamp,
              repetition_count: hits[i]._source.repetition_count,
              investigation_status: hits[i]._source.investigation_status,
              playbook_id: hits[i]._source.playbook_id,
              user: hits[i]._source.user,
              host: hits[i]._source.host,
              ip: hits[i]._source.ip,
              file: hits[i]._source.file,
              hash: hits[i]._source.hash,
              url: hits[i]._source.url,
            });
          } else {
            console.log(" else investigate-name")
            this.huntSummaryData.push({
              time_stamp: hits[i]._source.detection_timestamp,
              host_name: hits[i]._source.host_name,
              user_name: hits[i]._source.user_name,

              technique_name: hits[i]._source.detection_name,
              tactic_names: hits[i]._source.tactic_name,
              hunt_detection_id: hits[i]._source.hunt_detection_id,
              grp_id: hits[i]._source.grp_id,
              detection_name: hits[i]._source.detection_name,
              tactic_name: hits[i]._source.tactic_name,
              technique_id: hits[i]._source.technique_id,
              detection_timestamp: hits[i]._source.detection_timestamp,
              repetition_count: hits[i]._source.repetition_count,
              investigation_status: hits[i]._source.investigation_status,
              user: hits[i]._source.user,
              host: hits[i]._source.host,
              playbook_id: hits[i]._source.playbook_id,
              ip: hits[i]._source.ip,
              file: hits[i]._source.file,
              hash: hits[i]._source.hash,
              url: hits[i]._source.url,
            });
          }

        }
        console.log(this.huntSummaryData);
        // this.huntSummaryData = [...new Map(this.huntSummaryData.map(item => [JSON.stringify(item), item])).values()];
        for (let i = 0; i < this.huntSummaryData.length; i++) {
          this.huntSummaryData[i].align = i % 2 == 1 ? "left" : "";
        }
        const sortedDesc = this.huntSummaryData.sort(
          (objA, objB) => objB.time_stamp.getTime() - objA.time_stamp.getTime(),
        );

        this.dtTrigger.next();
        console.log(this.huntSummaryData);
        console.log(sortedDesc);
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  getGroupedRepetitionHundSummary() {
    console.log("getGroupedRepetitionHundSummary")
    if (this.type == 'hunter') {
      var data = { "size": 100, "query": { "bool": { "filter": [{ "match_phrase": { "group_id": this.group_id } }] } } };
      this.common.thp_false_positive_hunter(data).subscribe((response: any) => {
        if (response) {
          console.log("New grouped hunter", response);
          let hits = []
          if (response.hits)
            hits = response.hits.hits;
          this.huntSummaryData = [];
          for (let i = 0; i < hits.length; i++) {
            console.log(" getGroupedRepetitionHundSummary if")
            this.groupedField = {
              "user_name": hits[i]._source.user_name
            }
            this.huntSummaryData.push({
              time_stamp: hits[i]._source.detection_timestamp,
              hunt_detection_id: hits[i]._source.hunt_detection_id,
              grp_id: hits[i]._source.grp_id,
              host_name: hits[i]._source.host,
              user_name: hits[i]._source.user,
              technique_name: hits[i]._source.detection_name,
              tactic_names: hits[i]._source.tactic_name,
              playbook_id: hits[i]._source.playbook_id,
              detection_name: hits[i]._source.detection_name,
              tactic_name: hits[i]._source.tactic_name,
              technique_id: hits[i]._source.technique_id,
              detection_timestamp: hits[i]._source.detection_timestamp,
              repetition_count: hits[i]._source.repetition_count,
              investigation_status: hits[i]._source.investigation_status,
              user: hits[i]._source.user,
              host: hits[i]._source.host,
              ip: hits[i]._source.ip,
              file: hits[i]._source.file,
              hash: hits[i]._source.hash,
              url: hits[i]._source.url,
            });
          }
          this.huntSummaryData = this.huntSummaryData.sort((a, b) =>
            a.time_stamp > b.time_stamp ? -1 : 1
          );
          for (let i = 0; i < this.huntSummaryData.length; i++) {
            this.huntSummaryData[i].align = i % 2 == 1 ? "left" : "";
          }

          this.dtTrigger.next();
          console.log(this.huntSummaryData);
          console.log("this.groupedField", this.groupedField);
        }
      });
      (error) => {
        console.log("error", error);
      };
    } else {
      var data1 = { "size": 100, "query": { "bool": { "filter": [{ "match_phrase": { "grp_id": this.group_id } }] } } };
      this.common.thp_false_positive(data1).subscribe((response: any) => {
        console.log("Table data", response);
        if (response) {
          console.log("New grouped hunter else", response);
          let hits = []
          if (response.hits)
            hits = response.hits.hits;
          this.huntSummaryData = [];
          console.log(" getGroupedRepetitionHundSummary else")
          for (let i = 0; i < hits.length; i++) {
            this.groupedField = hits[i]._source.field
            this.huntSummaryData.push({
              time_stamp: hits[i]._source.detection_timestamp,
              hunt_detection_id: hits[i]._source.hunt_detection_id,
              grp_id: hits[i]._source.grp_id,
              host_name: hits[i]._source.host,
              user_name: hits[i]._source.user,
              technique_name: hits[i]._source.detection_name,
              tactic_names: hits[i]._source.tactic_name,
              playbook_id: hits[i]._source.playbook_id,
              detection_name: hits[i]._source.detection_name,
              tactic_name: hits[i]._source.tactic_name,
              technique_id: hits[i]._source.technique_id,
              detection_timestamp: hits[i]._source.detection_timestamp,
              repetition_count: hits[i]._source.repetition_count,
              investigation_status: hits[i]._source.investigation_status,
              user: hits[i]._source.user,
              host: hits[i]._source.host,
              ip: hits[i]._source.ip,
              file: hits[i]._source.file,
              hash: hits[i]._source.hash,
              url: hits[i]._source.url,
            });
          }
          this.huntSummaryData = this.huntSummaryData.sort((a, b) =>
            a.time_stamp > b.time_stamp ? -1 : 1
          );
          for (let i = 0; i < this.huntSummaryData.length; i++) {
            this.huntSummaryData[i].align = i % 2 == 1 ? "left" : "";
          }

          this.dtTrigger.next();
          console.log(this.huntSummaryData);
          console.log("this.groupedField", this.groupedField);
          if (!this.groupedField) {
            this.groupedField = {
              "user_name": this.huntSummaryData[0].user_name
            }
          }
        }
      });
      (error) => {
        console.log("error", error);
      };
    }
  }


  viewDashbordModal(id, pid) {

    // if (pid == "MITRE-001") {
    //   this.modalService.dismissAll("test");
    //   let a = btoa(id);
    //   this.router.navigate(["/hunt-summary/MITRE-001/" + a]);
    // } else if (pid == "MITRE-015") {
    //   let a = btoa(id);
    //   this.router.navigate(["/hunt-summary/MITRE-015/" + a]);
    // } else if (pid == "MITRE-028") {
    //   let a = btoa(id);
    //   this.router.navigate(["/hunt-summary/MITRE-028/" + a]);
    // } else {
    //   this.modalService.dismissAll("test");
    //   let a = btoa(id);
    //   this.router.navigate(["/hunt-summary/dashboard/" + a]);
    // }

    if (this.pidLists.includes(pid)) {
      this.modalService.dismissAll("test");
      let a = btoa(id);
      this.router.navigate(["/hunt-summary/" + pid + "/" + a]);
    } else {
      this.modalService.dismissAll("test");
      let a = btoa(id);
      this.router.navigate(["/hunt-summary/dashboard/" + a]);
    }
    // this.router.navigate(["/hunt-summary-dashboard/" + a]);
  }

  viewCybotDashbord(content) {
    console.log("content", content)
    if (this.type == 'hunter') {
      this.router.navigate([
        "/cybot-dashboard/" + content.hunt_detection_id,
        {
          host: content.host_name,
          // hash: content.hash,
          // ip: content.ip,
          user: content.user_name,
          hunt_detection_id: content.hunt_detection_id,
          playbook_id: content.playbook_id,
          group_id: this.group_id,
          form: "hunter",
          hunt_id: this.hunt_id
        },
      ]);
    }
    else {
      this.router.navigate([
        "/cybot-dashboard/" + content.hunt_detection_id,
        {
          host: content.host_name,
          // hash: content.hash,
          // ip: content.ip,
          user: content.user_name,
          hunt_detection_id: content.hunt_detection_id,
          playbook_id: content.playbook_id,
          group_id: content.grp_id,
          form: "investigation-repetition"
        },
      ]);
    }


  }
  goToBack() {
    if (this.type == 'hunter') {
      this.router.navigate([
        "/hunter/detection/" + this.hunt_id,
      ]);
    }
    else {
      this.router.navigate(["/investigation"]);
    }
  }
}
