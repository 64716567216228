import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-cloud',
  templateUrl: './tag-cloud.component.html',
  styleUrls: ['./tag-cloud.component.scss']
})
export class TagCloudComponent implements OnInit {
  term: any;
  loding = true;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  dataTemp: any = []
  expectedValues: any
  type: any;
  datas: any;
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.datas = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
