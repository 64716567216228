import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { RuleStudioService } from 'src/app/core/services/rule-studio.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-rule-studio-hunter-edit',
  templateUrl: './rule-studio-hunter-edit.component.html',
  styleUrls: ['./rule-studio-hunter-edit.component.scss']
})
export class RuleStudioHunterEditComponent implements OnInit {
  isLoaderShow = false
  load=false
  hunt_id
  hunt_data
  tenantCatalogue
  playbookIdData
  ckeditorData;
  listofItems=[];
  hunterCustom = this.fb.group({
    hunt_id: ['', Validators.required],
    hunt_name: ['', Validators.required],
    description: [''],
    notify:['', Validators.required],
    detailed_description: [''],
    hunt_step: this.fb.array([])
  })
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private ruleStudioService: RuleStudioService, private router: Router) {
    this.route.params.subscribe(params => {
      this.hunt_id = params['id']
    }).unsubscribe();
  }
  get hunt_step(): FormArray {
    return this.hunterCustom.get('hunt_step') as FormArray
  }
  get f() { return this.hunterCustom.controls; }
  public Editor = ClassicEditor;
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  addStep() {
    this.hunt_step.push(this.fb.group({
      name: ['', Validators.required],
      description: [''],
      list: ['']
    }))
    console.log(this.hunt_step);

  }
  removeStep(i: number) {
    this.hunt_step.removeAt(i)
  }
  ngOnInit(): void {
    this.ckeditorData = ''
    this.getData()
    this.addStep()
    this.getRuleStudioCustomData()
  }


  onSubmit() {
    if (this.hunterCustom.valid) {
      let data = {
        hunt_hunt_idd: this.f.hunt_id.value,
        hunt_name: this.f.hunt_name.value,
        Description: this.f.description.value,
        detailed_description: this.f.detailed_description.value,
        notify: 2,
        steps: []
      }
      console.log("data", data)

      let info = {};
      let k = 1
      let step = []
      this.f.hunt_step.value.forEach(v => {
        let obj
        obj = this.playbookIdData.find((o, i) => {
          if (o.playbook_id === v.list) {
            return o; // stop searching
          }
        });
        console.log("obj", obj)
        let kk = []
        kk.push(v.list)
        let val = {
          step: k,
          name: v.name,
          description: v.description,
          inv_playbook: kk
        }
        data.steps.push(val)
        // let n = 'step_' + k
        // info[n] = val
        k++
      });
      console.log("info", info)

      // let sendData = {
      //   ...data,
      //   ...info
      // };
      // console.log("sendData", sendData)
      this.ruleStudioService.createCustomRuleStudioHunt(data, this.f.hunt_id.value)
        .subscribe((data) => {
          console.log("data", data)
          this.router.navigate(['rule-studio/hunter']);
        });
    }
    else {
      return
    }
  }

  getRuleStudioCustomData() {
    this.isLoaderShow = true;
    let staticFields = ["hunt_id", "hunt_name", "hunt_description"];

    this.ruleStudioService.getHuntByID(this.hunt_id).subscribe((res: any) => {
      this.hunt_data = res
      this.hunterCustom.controls['hunt_id'].setValue(this.hunt_id);
      this.hunterCustom.controls['hunt_name'].setValue(res.hunt_name);
      this.hunterCustom.controls['description'].setValue(res.Description);
      this.hunterCustom.controls['detailed_description'].setValue(res.detailed_description);
      this.hunterCustom.controls['notify'].setValue(res.notify);     
      let first = true
      // Object.keys(res).forEach(value => {
      //   console.log("value", value)
      //   if (!staticFields.includes(value)) {
      //     if (first) {
      //       this.info.removeAt(0);
      //     }
      //     this.info.push(this.newInfoWithValue(res[value]['description'], res[value]['list'], res[value]['name']));
      //     first = false
      //   }
      // })
      if (typeof (res) == "object")
        res.steps.forEach(element => {
          if (first) {
            this.info.removeAt(0);
          }
          this.info.push(this.newInfoWithValue(element.description, element.inv_playbook, element.name));
          first = false

        });
      this.isLoaderShow = false

    })
  }
  cancel() {
    this.router.navigate(['rule-studio/hunter']);
  }
  get info(): FormArray {
    return this.hunterCustom.get("hunt_step") as FormArray
  }
  newInfoWithValue(description, list, name): FormGroup {
    return this.fb.group({
      description: description,
      list: list,
      name: name
    })
  }

  getData() {
    this.tenantCatalogue = [];
    this.ruleStudioService.getCatalogueTenant().subscribe(
      (res: any) => {
        this.playbookIdData = res;
        res.forEach(data => {
          this.tenantCatalogue.push(data);
          this.listofItems.push(`${data[1]} - ${data[0]}`);
        });
        this.load = true;
      },
      (error) => {
        console.log("error", error);
        this.load = true;
      }
    );

  }
}


