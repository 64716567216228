import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import { Observable, Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/core/services/common.service";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { GeochartComponent } from "../geochart/geochart.component"
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BarChartType,
  ChartType,
  Stat,
  EchartType,
} from "../../pages/community/view-dashboard.model";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";

@Component({
  selector: "app-security-incidents-intelligence",
  templateUrl: "./security-incidents-intelligence.component.html",
  styleUrls: ["./security-incidents-intelligence.component.scss"],
})
export class SecurityIncidentsIntelligenceComponent implements OnInit {
  constructor(public common: ThreatIntelligenceService, private router: Router, private route:ActivatedRoute , private modalService: NgbModal,
    private datePipe: DatePipe) {

      route.queryParams.subscribe((res)=>{
        if(res.severity === 'low'){
          this.getSeverityLow();
        }else if(res.severity === "medium"){
          this.getSeverityMediumOnly();
        }else if(res.severity === "high"){
          this.getSeverityHignOnly();
        }else if(res.severity === "critical"){
          this.getSeverityCriticalOnly();
        }
      })

     }

    // severity counts
    severityCounts = {
      low: 0,
      medium: 0,
      high: 0,
    }

  feedDistributionData = [];
  securityEventData = [];
  headlinesData = [];
  vulnerabilitiesData = [];
  vulnerabilitiesExploitData = [];
  communityIocsData = [];
  period = {
    gte : '',
    lte : ''
  };
  countries = [];
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 20,
    lengthMenu: [5, 10, 25],
    processing: true,
    order: []
  };
  dtTriggerSecurity: Subject<any> = new Subject<any>();
  dtTriggerHeadlines: Subject<any> = new Subject<any>();
  dtTriggerVulnerabilities: Subject<any> = new Subject<any>();
  dtTriggerVulnerabilitiesExploit: Subject<any> = new Subject<any>();
  selectedContent: any;
  modalId: number;
  stats = {
    vulnerabilities: 0,
    vulnerabilities_exploit: 0,
    security_events: 0,
    headlines: 0,
  };

  total$: Observable<number>;
  service = {
    page: 1,
    pageSize: 4,
  };

  breadCrumbItems: Array<{}>;
  captureScreenModel
  vset: Stat[] = [
  {
    icon: "ri-stack-line",
    title: "Vulnerabilities",
    value: "5",
  },
  {
    icon: "ri-stack-line",
    title: "Vulnerabilities with exploit",
    value: "10",
  },
  ];

  cSet: Stat[] = [
  {
    icon: "ri-stack-line",
    title: "Community IOCs",
    value: "10",
  },
  ];

  donughnutChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Defense Evasion", "Privilege Escalation"],
      textStyle: { color: "#8791af" },
    },
    color: ["#556ee6", "#f1b44c"],
    series: [
    {
      name: "Tactics detected",
      type: "pie",
      radius: ["50%", "70%"],
      avoidLabelOverlap: false,
      label: {
        normal: {
          show: false,
          position: "center",
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: "20",
            fontWeight: "bold",
          },
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [
      { value: 20, name: "Defense Evasion" },
      { value: 70, name: "Privilege Escalation" },
      ],
    },
    ],
  };
  criticalobservables: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Hash", "IP", "URL"],
      textStyle: { color: "#8791af" },
    },
    color: ["#ff3d60", "#4aa3ff", "#fcb92c"],
    series: [
    {
      name: "Critical rated observables",
      type: "pie",
      radius: ["50%", "70%"],
      avoidLabelOverlap: false,
      label: {
        normal: {
          show: false,
          position: "center",
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: "20",
            fontWeight: "bold",
          },
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [
      { value: 4, name: "Hash" },
      { value: 2, name: "IP" },
      { value: 3, name: "URL" },
      ],
    },
    ],
  };

  highratedobservables: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Hash", "IP", "URL"],
      textStyle: { color: "#8791af" },
    },
    color: ["#bc2c46", "#5664d2", "#d69100"],
    series: [
    {
      name: "high rated observables",
      type: "pie",
      radius: ["50%", "70%"],
      avoidLabelOverlap: false,
      label: {
        normal: {
          show: false,
          position: "center",
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: "20",
            fontWeight: "bold",
          },
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [
      { value: 3, name: "Hash" },
      { value: 2, name: "IP" },
      { value: 0, name: "URL" },
      ],
    },
    ],
  };

  basicColumChart: ChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#5664d2", "#1cbb8c", "#fcb92c", "#ff3d60"],
    series: [
    {
      name: "Event",
      data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
    },
    {
      name: "News",
      data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
    },
    {
      name: "Vulnerability",
      data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
    },
    {
      name: "Exploit",
      data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
    },
    ],
    xaxis: {
      categories: [
      "00.00",
      "10.00",
      "14.00",
      "18.00",
      "20.00",
      "21.00",
      "22.00",
      "23.00",
      "24.00",
      ],
      title: {
        text: "attribute_timestamp per 12 hours",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  eventDistributionData: ChartType = {
    chart: {
      height: 400,
      type: "donut",
    },
    series: [],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    labels: [],
    colors: ["#1cbb8c", "#5664d2", "#fcb92c", "#4aa3ff"],
    responsive: [
    {
      breakpoint: 600,
      options: {
        chart: {
          height: 10,
        },
        legend: {
          show: false,
        },
      },
    },
    ],
  };

  criticalrb: BarChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#ff3d60", "#4aa3ff", "#fcb92c"],
    series: [
    {
      name: "Hash",
      data: [46],
    },
    {
      name: "IP",
      data: [74],
    },
    {
      name: "URL",
      data: [37],
    },
    ],
    xaxis: {
      categories: ["All"],
      title: {
        text: "All docs",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "Count :  " + val;
        },
      },
    },
  };

  simplePieChart1: ChartType = {
    chart: {
      height: 320,
      type: "pie",
    },
    series: [44, 55, 41],
    labels: ["Hash", "IP", "URL"],
    colors: ["#1cbb8c", "#5664d2", "#fcb92c"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
    {
      breakpoint: 600,
      options: {
        chart: {
          height: 240,
        },
        legend: {
          show: false,
        },
      },
    },
    ],
  };

  ngOnInit(): void {
    if(localStorage.getItem('isDateRange')=="true"){
      this.period = {
        gte : localStorage.getItem('startDate'),
        lte : localStorage.getItem('endDate')
      }
    } else {
      this.period = {
        gte : localStorage.getItem('period'),
        lte : 'now'
      }
    }
    this.breadCrumbItems = [
    { label: "Home" },
    { label: "Management Summary", active: true },
    ];
    // this.getFeedDistribution();
    // this.getEventDistribution();
    // this.getVulnerabilities();
    // this.getVulnerabilitiesExploit();
    // this.getSecurityEvents();
    // this.getHeadlines();
    // this.getSecurityEventData(); main main
    // this.getHeadlinesData();
    // this.getVulnerabilitiesData();
    // this.getVulnerabilitiesExploitData();
  }


// filterMedium(){
//   let filterMedium =  this.securityEventData.filter((sev)=> sev.severity === 'Medium')
//   if(filterMedium){
//     this.securityEventData = filterMedium
//   }
// }

  getFeedDistribution() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
      aggs: {
        "2": {
          date_histogram: {
            field: "attribute_timestamp",
            calendar_interval: "1d",
            time_zone: "Asia/Kolkata",
            min_doc_count: 1,
          },
          aggs: {
            "3": {
              terms: { field: "feed_name", order: { _count: "desc" }, size: 5 },
            },
          },
        },
      },
    };
    this.common.getFeedDistribution(data).subscribe((response: any) => {
      // console.log("First data", response);
      if (response) {
        // console.log(response);
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  getEventDistribution() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
      aggs: {
        "2": {
          terms: {
            field: "event_category",
            order: { _count: "desc" },
            size: 5,
          },
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      // console.log("Second data", response);
      if (response) {
        // console.log(response);
        let buckets = response.aggregations[2].buckets;
        let series = [];
        let labels = [];
        for (let i in buckets) {
          labels.push(buckets[i].key);
          series.push(buckets[i].doc_count);
        }
        this.eventDistributionData.series = series;
        this.eventDistributionData.labels = labels;
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  getVulnerabilities() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          { match_phrase: { event_category: "Vulnerability" } },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      if (response) {
        // console.log(response);
        this.stats.vulnerabilities = response.hits.total.value;
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  getVulnerabilitiesExploit() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          { match_phrase: { event_category: "Exploit" } },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      if (response) {
        // console.log(response);
        this.stats.vulnerabilities_exploit = response.hits.total.value;
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  getSecurityEvents() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          { match_phrase: { event_category: "Event" } },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      if (response) {
        // console.log(response);
        this.stats.security_events = response.hits.total.value;
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  getHeadlines() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          { match_phrase: { event_category: "News" } },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      if (response) {
        // console.log(response);
        this.stats.headlines = response.hits.total.value;
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  isSecurityData = false;
  getSecurityEventData() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
          { match_all: {} },
          {
            match_phrase: {
              feed_name: "Activebytes Innovations - Threat Intelligence Services",
            },
          },
          { match_phrase: { event_category: "Event" } },
          {
            range: {
              attribute_timestamp: {
                gte: this.period.gte,
                lte: this.period.lte,
                format: "strict_date_optional_time",
              },
            },
          },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.isSecurityData = true;
    this.common.getCommunityFeedChart(data).subscribe(async (response: any) => {
      console.log("getSecurityEventData", response);
      if (response) {
        this.isSecurityData = false;
        let hits = response.hits.hits;
        this.securityEventData = [];
        for (let i = 0; i < hits.length; i++) {
          this.securityEventData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            comments: hits[i]._source.comments,
            reference_link: hits[i]._source.reference_link,
            tlp: hits[i]._source.tlp,
            attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
            severity: hits[i]._source.severity,
            location: hits[i]._source.location.split(','),
          });
        }

        if(this.securityEventData && this.securityEventData.length){
          this.securityEventData = this.securityEventData.reverse();
        }

        let dd = await this.sortData(this.securityEventData)
        this.dtTriggerSecurity.next();
      }
    });
    (error) => {
      this.isSecurityData = false;
      console.log("error", error);
    };
  }





  // ///////////////////  low only
getSeverityLow(){
  let data = {
    size: 100,
    query: {
      bool: {
        must: [
          {
            bool: {
              must: [
                {
  
                  bool: {
  
                    should: [
  
                      {
  
                        match_phrase: {
  
                          feed_name: "Activebytes Innovations - Threat Intelligence Services"
  
                        }
  
                      }
  
                    ],
  
                    minimum_should_match: 1
  
                  }
  
                },
  
                {
  
                  bool: {
  
                    should: [
  
                      {
  
                        match_phrase: {
  
                          event_category: "Event"
  
                        }
  
                      }
  
                    ],
  
                    minimum_should_match: 1
  
                  }
  
                },
  
                {
  
                  bool: {
  
                    should: [
  
                      {
  
                        match_phrase: {
  
                          severity: "Low"
  
                        }
  
                      }
  
                    ],
  
                    minimum_should_match: 1
  
                  }
  
                }
  
              ]
  
            }
  
          }
  
        ],
  
        filter: [],
  
        should: [],
  
        must_not: []
  
      }
  
    }
  }

  this.isSecurityData = true;
  this.common.getCommunityFeedChart(data).subscribe(async (response: any) => {
    console.log("getSecurityEventData", response);
    if (response) {
      this.isSecurityData = false;
      let hits = response.hits.hits;
      this.securityEventData = [];
      for (let i = 0; i < hits.length; i++) {
        this.securityEventData.push({
          event_name: hits[i]._source.event_name,
          event_description: hits[i]._source.event_description,
          comments: hits[i]._source.comments,
          reference_link: hits[i]._source.reference_link,
          tlp: hits[i]._source.tlp,
          attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
          severity: hits[i]._source.severity,
          location: hits[i]._source.location.split(','),
        });
      }

      if(this.securityEventData && this.securityEventData.length){
        this.securityEventData = this.securityEventData.reverse();
      }

      let dd = await this.sortData(this.securityEventData)
      this.dtTriggerSecurity.next();
    }
  });
  (error) => {
    this.isSecurityData = false;
    console.log("error", error);
  };

  
}

  // ///////////////////  medium only
  getSeverityMediumOnly(){
    let data = {
      size: 100,
      query: {
        bool: {
          must: [
            {
              bool: {
                must: [
                  {
    
                    bool: {
    
                      should: [
    
                        {
    
                          match_phrase: {
    
                            feed_name: "Activebytes Innovations - Threat Intelligence Services"
    
                          }
    
                        }
    
                      ],
    
                      minimum_should_match: 1
    
                    }
    
                  },
    
                  {
    
                    bool: {
    
                      should: [
    
                        {
    
                          match_phrase: {
    
                            event_category: "Event"
    
                          }
    
                        }
    
                      ],
    
                      minimum_should_match: 1
    
                    }
    
                  },
    
                  {
    
                    bool: {
    
                      should: [
    
                        {
    
                          match_phrase: {
    
                            severity: "Medium"
    
                          }
    
                        }
    
                      ],
    
                      minimum_should_match: 1
    
                    }
    
                  }
    
                ]
    
              }
    
            }
    
          ],
    
          filter: [],
    
          should: [],
    
          must_not: []
    
        }
    
      }
    }

    this.isSecurityData = true;
    this.common.getCommunityFeedChart(data).subscribe(async (response: any) => {
      if (response) {
        this.isSecurityData = false;
        let hits = response.hits.hits;
        this.securityEventData = [];
        for (let i = 0; i < hits.length; i++) {
          this.securityEventData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            comments: hits[i]._source.comments,
            reference_link: hits[i]._source.reference_link,
            tlp: hits[i]._source.tlp,
            attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
            severity: hits[i]._source.severity,
            location: hits[i]._source.location.split(','),
          });
        }

        if(this.securityEventData && this.securityEventData.length){
          this.securityEventData = this.securityEventData.reverse();
        }

        let dd = await this.sortData(this.securityEventData)
        this.dtTriggerSecurity.next();
      }
    });
    (error) => {
      this.isSecurityData = false;
      console.log("error", error);
    };

    
}


// ///////////////////  hign only
getSeverityHignOnly(){
  let data = {
    size: 100,
    query: {
      bool: {
        must: [],
  
        filter: [{
          bool: {
            must: [
              {

                bool: {

                  should: [

                    {

                      match_phrase: {

                        feed_name: "Activebytes Innovations - Threat Intelligence Services"

                      }

                    }

                  ],

                  minimum_should_match: 1

                }

              },

              {

                bool: {

                  should: [

                    {

                      match_phrase: {

                        event_category: "Event"

                      }

                    }

                  ],

                  minimum_should_match: 1

                }

              },

              {

                bool: {

                  should: [

                    {

                      match_phrase: {

                        severity: "High"

                      }

                    },

                  ],

                  minimum_should_match: 1

                }

              }

            ]

          }

        }],
  
        should: [],
  
        must_not: []
  
      }
  
    }
  }

  this.isSecurityData = true;
  this.common.getCommunityFeedChart(data).subscribe(async (response: any) => {
    if (response) {
      this.isSecurityData = false;
      let hits = response.hits.hits;
      this.securityEventData = [];
      for (let i = 0; i < hits.length; i++) {
        this.securityEventData.push({
          event_name: hits[i]._source.event_name,
          event_description: hits[i]._source.event_description,
          comments: hits[i]._source.comments,
          reference_link: hits[i]._source.reference_link,
          tlp: hits[i]._source.tlp,
          attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
          severity: hits[i]._source.severity,
          location: hits[i]._source.location.split(','),
        });
      }

      if(this.securityEventData && this.securityEventData.length){
        this.securityEventData = this.securityEventData.reverse();
      }

      let dd = await this.sortData(this.securityEventData)
      this.dtTriggerSecurity.next();
    }
  });
  (error) => {
    this.isSecurityData = false;
    console.log("error", error);
  };  
}


// ///////////////////  critical only
getSeverityCriticalOnly(){
  let data = {
    size: 100,
    query: {
      bool: {
        must: [
          {
            bool: {
              must: [
                {
  
                  bool: {
  
                    should: [
  
                      {
  
                        match_phrase: {
  
                          feed_name: "Activebytes Innovations - Threat Intelligence Services"
  
                        }
  
                      }
  
                    ],
  
                    minimum_should_match: 1
  
                  }
  
                },
  
                {
  
                  bool: {
  
                    should: [
  
                      {
  
                        match_phrase: {
  
                          event_category: "Event"
  
                        }
  
                      }
  
                    ],
  
                    minimum_should_match: 1
  
                  }
  
                },
  
                {
  
                  bool: {
  
                    should: [
  
                      {
  
                        match_phrase: {
  
                          severity: "Critical"
  
                        }
  
                      }
  
                    ],
  
                    minimum_should_match: 1
  
                  }
  
                }
  
              ]
  
            }
  
          }
  
        ],
  
        filter: [],
  
        should: [],
  
        must_not: []
  
      }
  
    }
  }

  this.isSecurityData = true;
  this.common.getCommunityFeedChart(data).subscribe(async (response: any) => {
    console.log("getSecurityEventData", response);
    if (response) {
      this.isSecurityData = false;
      let hits = response.hits.hits;
      this.securityEventData = [];
      for (let i = 0; i < hits.length; i++) {
        this.securityEventData.push({
          event_name: hits[i]._source.event_name,
          event_description: hits[i]._source.event_description,
          comments: hits[i]._source.comments,
          reference_link: hits[i]._source.reference_link,
          tlp: hits[i]._source.tlp,
          attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
          severity: hits[i]._source.severity,
          location: hits[i]._source.location.split(','),
        });
      }

      if(this.securityEventData && this.securityEventData.length){
        this.securityEventData = this.securityEventData.reverse();
      }

      let dd = await this.sortData(this.securityEventData)
      this.dtTriggerSecurity.next();
    }
  });
  (error) => {
    this.isSecurityData = false;
    console.log("error", error);
  };

  
}





  // getHeadlinesData() {
  //   let data = {
  //     size: 100,
  //     query: {
  //       bool: {
  //         must: [],
  //         filter: [
  //         { match_all: {} },
  //         {
  //           match_phrase: {
  //             feed_name: "Activebytes Innovations - Threat Intelligence Services",
  //           },
  //         },
  //         { match_phrase: { event_category: "News" } },
  //         {
  //           range: {
  //             attribute_timestamp: {
  //               gte: this.period.gte,
  //               lte: this.period.lte,
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //         ],
  //         should: [],
  //         must_not: [],
  //       },
  //     },
  //   };
  //   let data1 = { "size": 100, "query": { "bool": { "must": [], "filter": [{ "match_all": {} }, { "match_phrase": { "feed_name": "Activebytes Innovations - Threat Intelligence Services" } }, { "match_phrase": { "event_category": "News" } }, { "range": { "attribute_timestamp": { "gte": this.period.gte,"lte": this.period.lte,"format": "strict_date_optional_time" } } }], "should": [], "must_not": [] } } }
  //   this.common.getCommunityFeedChart(data1).subscribe((response: any) => {
  //     console.log("getHeadlinesData", response);
  //     if (response) {
  //       let hits = response.hits.hits;
  //       this.headlinesData = [];
  //       console.log("my consoleeee...... headline Datas",this.headlinesData)
  //       for (let i = 0; i < hits.length; i++) {
  //         this.headlinesData.push({
  //           attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
  //           event_category: hits[i]._source.event_category,
  //           event_description: hits[i]._source.event_description,
  //           event_id: hits[i]._source.event_id,
  //           event_name: hits[i]._source.event_name,
  //           feed_name: hits[i]._source.feed_name,
  //           news: hits[i]._source.news,
  //           reference_link: hits[i]._source.reference_link,
  //           severity: hits[i]._source.severity,
  //           tlp: hits[i]._source.tlp,

  //         });
  //       }
  //       this.dtTriggerHeadlines.next();
  //     }
  //   });
  //   (error) => {
  //     console.log("error", error);
  //   };
  // }

  // getVulnerabilitiesData() {
  //   let data = {
  //     size: 100,
  //     query: {
  //       bool: {
  //         must: [],
  //         filter: [
  //         {
  //           match_phrase: {
  //             feed_name: "Activebytes Innovations - Threat Intelligence Services",
  //           },
  //         },
  //         { match_phrase: { event_category: "Vulnerability" } },
  //         {
  //           range: {
  //             attribute_timestamp: {
  //               gte: this.period.gte,
  //               lte: this.period.lte,
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //         ],
  //         should: [],
  //         must_not: [{ match_phrase: { event_category: "Exploit" } }],
  //       },
  //     },
  //   };
  //   this.common.getCommunityFeedChart(data).subscribe((response: any) => {
  //     // console.log("getVulnerabilitiesData", response);
  //     if (response) {
  //       let hits = response.hits.hits;
  //       this.vulnerabilitiesData = [];
  //       for (let i = 0; i < hits.length; i++) {
  //         this.vulnerabilitiesData.push({
  //           event_name: hits[i]._source.event_name,
  //           event_description: hits[i]._source.event_description,
  //           reference_link: hits[i]._source.reference_link,
  //           severity: hits[i]._source.severity,
  //         });
  //       }
  //       this.dtTriggerVulnerabilities.next();
  //     }
  //   });
  //   (error) => {
  //     console.log("error", error);
  //   };
  // }

  // getVulnerabilitiesExploitData() {
  //   let data = {
  //     size: 100,
  //     query: {
  //       bool: {
  //         must: [],
  //         filter: [
  //         { match_all: {} },
  //         {
  //           match_phrase: {
  //             feed_name: "Activebytes Innovations - Threat Intelligence Services",
  //           },
  //         },
  //         { match_phrase: { event_category: "Exploit" } },
  //         {
  //           range: {
  //             attribute_timestamp: {
  //               gte: this.period.gte,
  //               lte: this.period.lte,
  //               format: "strict_date_optional_time",
  //             },
  //           },
  //         },
  //         ],
  //         should: [],
  //         must_not: [],
  //       },
  //     },
  //   };
  //   this.common.getCommunityFeedChart(data).subscribe((response: any) => {
  //     // console.log("getVulnerabilitiesExploitData", response);
  //     if (response) {
  //       let hits = response.hits.hits;
  //       this.vulnerabilitiesExploitData = [];
  //       for (let i = 0; i < hits.length; i++) {
  //         this.vulnerabilitiesExploitData.push({
  //           event_name: hits[i]._source.event_name,
  //           event_description: hits[i]._source.event_description,
  //           cve: hits[i]._source.cve,
  //           cvss: hits[i]._source.cvss,
  //           vendor: hits[i]._source.vendor,
  //           severity: hits[i]._source.severity,
  //         });
  //       }
  //       this.dtTriggerVulnerabilitiesExploit.next();
  //     }
  //   });
  //   (error) => {
  //     console.log("error", error);
  //   };
  // }

  openModal(viewmodal: any, content: any, modalId: number) {
    // console.log(content, modalId);
    if (content.location) {
      this.countries = [['Country']];
      for (let i in content.location) {
        this.countries.push([content.location[i]]);
      }
    }
    this.captureScreenModel = modalId
    this.modalId = modalId;
    this.selectedContent = content;
    this.modalService.open(viewmodal, { size: "lg", centered: true });
  }


  captureScreen() {
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen1() {
    var data = document.getElementById("contentToConvert1");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen2() {
    var data = document.getElementById("contentToConvert2");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen3() {
    var data = document.getElementById("contentToConvert3");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen4() {
    var data = document.getElementById("contentToConvert4");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreenModelPrint() {
    if (this.captureScreenModel == 1) {
      this.captureScreen()
    } else if (this.captureScreenModel == 2) {
      this.captureScreen1()
    }
  }
  sortData(d) {
    console.log(d)
  }

  timeConvert1(t) {
    let time = this.datePipe.transform(t * 1000, 'medium');
    return time

  }
  goToBack() {
    this.router.navigate(["/management-summary"]);
  }
}
