<app-topbar></app-topbar>
<div class="container" style="margin-top: 100px;">
    <div class="row">

        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
            </div>
        </div>

<div class="col-xl-12">
    <div class="headline-container col-md-12">
        <div class="top-section">
            <div class="section-title">
                <h2 class="primary-head sub-heading">Headlines</h2>
            </div>

            <div class="searching-bar-container">
                <input type="text" [(ngModel)]="headlineSearch" placeholder="Search Here">
            </div>
            <!-- <div class="news-filter">
                <ul>

                    <li>
                        <a class="active" id="filterbtn" (click)="filterTimeByHours('Latest')">Latest</a>
                    </li>

                    <li>
                        <a id="filterbtn" (click)="filterTimeByHours('6H')">6H</a>
                    </li>

                    <li>
                        <a id="filterbtn" (click)="filterTimeByHours('12H')">12H</a>
                    </li>

                    <li>
                        <a id="filterbtn" (click)="filterTimeByHours('24H')">24H</a>
                    </li>
                </ul>
            </div> -->
        </div>
        <div class="headline-contents">
            <img class="loader" src="../../../assets/images/loading.gif" *ngIf="loader" alt="">
            <div class="single-head" *ngFor="let news of headlinesData | filter: headlineSearch">
                <div class="timestamp">{{news.attribute_timestamp}}</div>
                <div class="news-heading">
                    <h5>{{news.event_name}}</h5>
                </div>
                <div class="news-link">
                    <a [href]="news.reference_link" target="_blank">Continue Reading</a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
