import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-chart-dashboard',
  templateUrl: './chart-dashboard.component.html',
  styleUrls: ['./chart-dashboard.component.scss']
})
export class ChartDashboardComponent implements OnInit {
  public id;
  public chartDetails = [];
  donutVal
  donutValElmt
  title: string = "";
  constructor(private router: ActivatedRoute, private route: Router, private dashService: DashboardService, private navigateRouter: Router,) { }

  ngOnInit(): void {
    this.donutVal = localStorage.getItem("selectedDonutVal");
    this.donutValElmt = localStorage.getItem("selectedDonutElemet");

    this.router.paramMap.subscribe(params => {
      this.id = this.router.snapshot.paramMap.get("id");
      this.title = decodeURIComponent(this.router.snapshot.paramMap.get("title"));
      this.title = this.title.split('%').join(" ")
      this.title = this.title.split('20').join(" ")
      this.title = this.title.split('25').join(" ")
      this.dashService.getJSON(this.id).subscribe(it => {
        it.forEach(elem => {
          if (elem && elem.analyticalData) {
            elem.analyticalData.api = localStorage.getItem("Datalake_Url") + elem.analyticalData.api;
          }
        })
        this.chartDetails = it;
      });
    });
  }
  goToBack() {
    this.navigateRouter.navigate(["compliance/dashboard"]);
  }
  topdf() {
    const dashboard = document.getElementById('dashboard');
    console.log("Get Our PDF Here Newwwww:", dashboard)
    const dashboardHeight = dashboard.clientHeight;
    const dashboardWidth = dashboard.clientWidth;
    const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

    domtoimage.toPng(dashboard, options).then((imgData) => {
      const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save('Dashboard for hyperpanels.pdf');
    });
  }
  clear() {
    localStorage.setItem("selectedDonutVal", '');
    localStorage.setItem("selectedDonutElemet", '');
    let currentURL = this.route.url.substr(1);
    this.route.navigateByUrl('/dashboard/settings', { skipLocationChange: true }).then(() =>
      this.route.navigate([currentURL]));
  }
}

