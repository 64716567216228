import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-grid',
  templateUrl: './text-grid.component.html',
  styleUrls: ['./text-grid.component.scss']
})
export class TextGridComponent implements OnInit {
  data: any;
  @Input() set analyticalData(val) {
    this.data = val;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
