import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'cybot-dynamic-table',
  templateUrl: './cybot-dynamic-table.component.html',
  styleUrls: ['./cybot-dynamic-table.component.scss']
})
export class CybotDynamicTableComponent implements OnInit {
  hunt_detection
  user_name
  @Input() set DetectionId(val) {
    // console.log("analyticalData", val)
    this.hunt_detection = val
  }
  dataTemp = [];
  datas: any;
  @Input() set value(val) {
    // console.log("analyticalData-val", val)
    this.datas = val;
    this.model = val?.model;
    this.populateData();
  }
  @Input() set UserName(val) {
    // console.log("analyticalData-user_name", val)
    this.user_name = val;

  }
  term: any;
  isLoader = true;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  filteredData: any = [];
  results = []
  expectedValues: any
  type: any
  titleCount: any
  model = "Table";

  constructor(public dashboardService: DashboardService) { }

  ngOnInit(): void {

  }
  populateData() {

    this.titleArray = this.datas?.titleData;
    this.query = this.query;
    let sendApi
    if (this.datas.type == 2) {
      sendApi = this.replaceDataObjectsAgg(this.datas.query)
      // console.log("sendApi", sendApi)
    } else {
      sendApi = this.replaceDataObjects(this.datas.query)
    }
    if (this.datas.api)
      this.dashboardService.dashboardApiCYBOT(sendApi, this.datas.api, this.datas.method).subscribe(
        (result: any) => {
          // console.log("populateData", result)
          if (this.datas.type == 4) {
            // console.log("populateData44", result)
            let data = result.aggregations[2].buckets;
            let k = 0
            data.forEach(data => {
              let dataSub = []
              dataSub.push(k + 1)
              this.datas.expectedValues.forEach(element => {
                let tempData = data
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  tempData = tempData[expectedValuesArray[i]]
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              this.dataTemp.push(dataSub)
              k++
            });
            this.filteredData = this.dataTemp;
            // console.log(this.datas.api + ":", this.dataTemp)
            this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";

          } else {
            if (result) {
              if (result.hits) {
                let data = result.hits.hits;
                let k = 0
                data.forEach(data => {
                  let dataSub = []
                  dataSub.push(k + 1)
                  this.datas.expectedValues.forEach(element => {
                    let tempData = data?._source
                    let expectedValuesArray = element.split('.')
                    for (let i = 0; i < expectedValuesArray.length; i++) {
                      tempData = tempData[expectedValuesArray[i]]
                    }
                    let data1 = tempData
                    dataSub.push(data1)
                  });
                  this.dataTemp.push(dataSub)
                  k++
                });
                this.filteredData = this.dataTemp;
                // console.log(this.datas.api + ":", this.dataTemp)
                this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
              } else {
                this.filteredData = this.dataTemp;
                this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
              }

            }
          }

          this.isLoader = false;
        },
        (inerror) => {
          console.log("error", inerror);
        })

  }

  search(a) {
    this.filteredData = [];
    if (this.dataTemp && this.term) {
      this.dataTemp.forEach(it => {
        let isExist = false;
        it.map(element => {
          if (element.toLowerCase().includes(this.term.toLowerCase())) {
            isExist = true;
          }
        });
        if (isExist) {
          this.filteredData.push(it);
        }
      })
    }
    else {
      this.filteredData = this.dataTemp;
    }
  }

  downloadCSV(data: any, heading: any) {
    data = [heading].concat(data)
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  recurring(index, buckets, values) {
    buckets.forEach(b => {
      let temp = values
      if (b[index + 1]) {
        if (Array.isArray(b[index + 1].buckets) && b[index + 1].buckets.length > 0) {
          if (temp !== '') temp += ` ${b.key}`
          else temp += `${b.key}`
          this.recurring(index + 1, b[index + 1].buckets, temp)
        }
        else {
          let k = temp + ` ${b.key}`
          this.results.push(k.split(' '))
        }
      }
      else {
        let k = temp + ` ${b.key}`
        this.results.push(k.split(' '))
      }
      // console.log(values)
    })
  }


  replaceDataObjects(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.match_phrase && it.match_phrase["hunt_detection_id"]) {
          it.match_phrase["hunt_detection_id"] = this.hunt_detection;
        }
        if (this.user_name)
          if (it && it.match_phrase && it.match_phrase["user_name"]) {
            it.match_phrase["user_name"] = this.user_name;
          }
      });
    }
    return data;
  }
  replaceDataObjectsAgg(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter
      && data.query.bool.filter[0].bool && data.query.bool.filter[0].bool.should) {
      data.query.bool.filter[0].bool.should.forEach(it => {
        if (this.user_name)
          // console.log("it", it)
          if (it && it.bool && it.bool.should && it.bool.should[0].match_phrase && it.bool.should[0].match_phrase["user_name"]) {
            it.bool.should[0].match_phrase["user_name"] = this.user_name;
          }
        if (it && it.bool && it.bool.should && it.bool.should[0].bool && it.bool.should[0].bool.should[0] && it.bool.should[0].bool.should[0].match_phrase && it.bool.should[0].bool.should[0].match_phrase["hunt_detection_id"]) {
          it.bool.should[0].bool.should[0].match_phrase["hunt_detection_id"] = this.hunt_detection;
        }
        // if (this.user_name)
        //   if (it && it.match_phrase && it.match_phrase["user_name"]) {
        //     it.match_phrase["user_name"] = this.user_name;
        //   }
      });
    }
    return data;
  }

}
