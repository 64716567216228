import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-hunter-timeline',
  templateUrl: './hunter-timeline.component.html',
  styleUrls: ['./hunter-timeline.component.scss']
})
export class HunterTimelineComponent implements OnInit {

  @Input() timelineData = {
    data: [],
    listData: [],
    pagination: {
      limit_per_page: 10,
      noOfPages: 0,
      currentPage: 1,
      isLoading: false,
      maxItemsInPagination: 4,
      showLeftInfinte: false,
      showRightInfinte: false,
    }
  }
  @Input('isLoading') recent_detection_bool = true
  @Input('hunt_id') hunt_id = ''

  @Input() hostList
  @Input() userList
  selectedDetections
  constructor(public common: CommonService, private modalService: NgbModal,
    private router: Router) { }

  ngOnInit(): void {
    this.paginateTimelineData()
  }
  paginateTimelineData(): any {
    var index = this.timelineData.pagination.currentPage - 1
    this.timelineData.listData = [];
    var size = this.timelineData.data.length;
    this.timelineData.pagination.noOfPages = Math.ceil(size / this.timelineData.pagination.limit_per_page)
    for (let i = this.timelineData.pagination.limit_per_page * index; i < (this.timelineData.pagination.limit_per_page * index) + this.timelineData.pagination.limit_per_page; i++) {
      if (this.timelineData.data[i])
        this.timelineData.listData.push(this.timelineData.data[i])
    }
    this.timelineData.pagination.isLoading = false
  }
  get getPaginationNumber(): Array<any> {
    var pageNumbers = []
    var flag = true;
    let startFrom = 0

    if ((this.timelineData.pagination.currentPage + (this.timelineData.pagination.maxItemsInPagination / 2)) > this.timelineData.pagination.noOfPages - 1) {
      let diff = this.timelineData.pagination.noOfPages - this.timelineData.pagination.currentPage
      startFrom = this.timelineData.pagination.currentPage - (this.timelineData.pagination.maxItemsInPagination - diff)

    } else
      startFrom = this.timelineData.pagination.currentPage - ((this.timelineData.pagination.maxItemsInPagination) / 2)
    startFrom = Math.floor(startFrom)
    for (let i = startFrom; flag; i++) {
      if (i > 1) {
        if (pageNumbers.length == this.timelineData.pagination.maxItemsInPagination + 1 || i > this.timelineData.pagination.noOfPages - 1) {
          this.timelineData.pagination.showLeftInfinte = !pageNumbers.includes(2)
          this.timelineData.pagination.showRightInfinte = !pageNumbers.includes(this.timelineData.pagination.noOfPages - 1)
          return pageNumbers
        }
        pageNumbers.push(i)
      }
    }
  }
  changePage(index: any) {
    if (index <= this.timelineData.pagination.noOfPages && index > 0) {
      this.timelineData.pagination.isLoading = true
      this.timelineData.pagination.currentPage = index;
      this.paginateTimelineData()
    }
  }

  viewRepetitions(content: any) {
    console.log("content", content);
    console.log("content.detection_status", content.detection_status);
    this.selectedDetections = content;
    if (content.detection_status == "new") {
      console.log("if")
      let str = localStorage.getItem("USER_NAME");
      let date = new Date().toISOString();
      this.selectedDetections.opened_by = str;
      this.selectedDetections.opened_at = date;
      this.selectedDetections.detection_status = "open";
      console.log("this.selectedDetections.id", this.selectedDetections.id);
      if (
        this.selectedDetections.id != "" &&
        this.selectedDetections.id != undefined
      ) {
        console.log("if if")
        let data = {
          doc: {
            opened_by: str,
            opened_at: date,
            detection_status: "open",
          },
        };
        let id = this.selectedDetections.id;
        let index = this.selectedDetections.index;
        console.log("id", id);
        this.common.addOpenCommentAdd(data, id, index).subscribe(
          (response: any) => {
            console.log("if if response", response)
            if (response.result == "updated") {
              this.modalService.dismissAll('Cross click')
              this.router.navigate([
                "/investigate/repetition",
                {
                  host: content.host_name,
                  // hash: content.hash,
                  // ip: content.ip,
                  user: content.user_name,
                  hunt_detection_id: this.selectedDetections.hunt_detection_id,
                  playbook_id: this.selectedDetections.playbook_id,
                  group_id: this.selectedDetections.group_id,
                  type: "hunter",
                  from: "hunter",
                  hunt_id: this.hunt_id
                },
              ]);
            } else {
              console.log("if if response else", response)
              this.modalService.dismissAll('Cross click')
              this.router.navigate([
                "/investigate/repetition",
                {
                  host: this.selectedDetections.host_name,
                  // hash: content.hash,
                  // ip: content.ip,
                  user: this.selectedDetections.user_name,
                  hunt_detection_id: this.selectedDetections.hunt_detection_id,
                  playbook_id: this.selectedDetections.playbook_id,
                  group_id: this.selectedDetections.group_id,
                  type: "hunter",
                  from: "hunter",
                  hunt_id: this.hunt_id
                },
              ]);
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
      } else {
        console.log("if else ",)
        //console.log("id not get");
        this.router.navigate([
          "/investigate/repetition",
          {
            host: this.selectedDetections.host_name,
            // hash: content.hash,
            // ip: content.ip,
            user: this.selectedDetections.user_name,
            hunt_detection_id: this.selectedDetections.hunt_detection_id,
            playbook_id: this.selectedDetections.playbook_id,
            group_id: this.selectedDetections.group_id,
            type: "hunter",
            from: "hunter",
            hunt_id: this.hunt_id
          },
        ]);
      }
    } else {
      console.log("else")
      // this.modalService.dismissAll('Cross click')
      this.router.navigate([
        "/investigate/repetition",
        {
          host: this.selectedDetections.host_name,
          // hash: content.hash,
          // ip: content.ip,
          user: this.selectedDetections.user_name,
          hunt_detection_id: this.selectedDetections.hunt_detection_id,
          playbook_id: this.selectedDetections.playbook_id,
          group_id: this.selectedDetections.group_id,
          type: "hunter",
          from: "hunter",
          hunt_id: this.hunt_id
        },
      ]);
    }
  }

}