import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpParams,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  Observable,
  of,
  Subject,
  BehaviorSubject,
  observable,
  throwError,
} from "rxjs";
import { catchError, map, tap, count } from "rxjs/operators";
import { environment } from "../../../environments/environment";
// const apiUrl = environment.trApiUrl;
const loginUrl = environment.loginUrl;
const apiUrl = localStorage.getItem('cyboturl') + '/cybot_proxy';

const endPoint = environment.ogEndPoint


@Injectable({
  providedIn: "root",
})
export class CommonService {
  HttpOptions: any;
  authorizationData =
    // localStorage.getItem('id_token');
    localStorage.getItem('access_token');
  constructor(private http: HttpClient) { }

  getThreatHuntingSummary(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN'),

      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_enriched_observables*/_search?pretty",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getThreatHuntingStatus(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_status*/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getThreatHuntingData(tid, data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + `/thp_status${tid ? '_' + tid : '*'}/_search`,
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }



  huntSummaryData(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_enriched_observables*/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }



  handleError(error: HttpErrorResponse) {
    console.log("error", error);
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  // getSecurityUserList() {
  //   return this.http
  //     .get<object>(apiUrl + "/_security/user")
  //     .pipe(catchError(this.handleError));
  // }

  getSecurityUserList() {
    return this.http
      .get<object>(apiUrl + "/_plugins/_security/api/internalusers/")
      .pipe(catchError(this.handleError));
  }
  securityUserCreation(data, tenant_id, user_name) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.put<object>(
      apiUrl + "/_plugins/_security/api/internalusers/" + tenant_id + '_' + user_name,
      data,
      this.HttpOptions
    );
  }

  //   securityUserCreation(data, user_name) {
  //   data = JSON.stringify(data);
  //   this.HttpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json","user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')  
  //     }),
  //   };
  //   return this.http.post<object>(
  //     apiUrl + "/_security/user/" + user_name,
  //     data,
  //     this.HttpOptions
  //   );
  // }

  addCloseCommentAdd(data, id, index) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/" + index + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  addOpenCommentAdd(data, id, index) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/" + index + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  getLicenseDetailsList(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_license*/_search",
      data,
      this.HttpOptions
    ).pipe(catchError(this.handleError));;
  }

  deletesecurityUser(user) {
    return this.http
      .delete<object>(apiUrl + "/_plugins/_security/api/internalusers/" + user)
      .pipe(catchError(this.handleError));
  }

  // deletesecurityUser(user) {
  //   return this.http
  //     .delete<object>(apiUrl + "/_security/user/" + user)
  //     .pipe(catchError(this.handleError));
  // }

  getExceptionsListAll(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_exceptions*/_search",
      data,
      this.HttpOptions
    );
  }

  getExceptionsList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_exceptions_" + tenantID + "/_search",
      data,
      this.HttpOptions
    );
  }

  exceptionsCreation(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_exceptions_" + tenantID + "/_doc",
      data,
      this.HttpOptions
    );
  }

  exceptionsUpdate(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_exceptions_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  deletesExceptions(tenantID, id) {
    return this.http
      .delete<object>(apiUrl + "/thp_exceptions_" + tenantID + "/_doc/" + id)
      .pipe(catchError(this.handleError));
  }

  scheduleListAll(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_schedule*/_search",
      data,
      this.HttpOptions
    );
  }
  scheduleList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.get<object>(
      apiUrl + "/thp_schedule_" + tenantID + "/_search",

      this.HttpOptions
    );
  }
  scheduleUpdate(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_schedule_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  scheduleUpdateAll(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_schedule_" + tenantID + "/_update_by_query",
      data,
      this.HttpOptions
    );
  }

  apiListAll(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_api*/_search",
      data,
      this.HttpOptions
    );
  }
  apiList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_api_" + tenantID + "/_search",
      data,
      this.HttpOptions
    );
  }

  getViewDashboardData(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_enriched_observables*/_search?pretty",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  getDetectedObservables(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      })
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_observables*/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }




  soarCreation(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_api_" + tenantID + "/_doc",
      data,
      this.HttpOptions
    );
  }

  soarUpdate(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_api_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  soarDelete(tenantID, id) {
    return this.http
      .delete<object>(apiUrl + "/thp_api_" + tenantID + "/_doc/" + id)
      .pipe(catchError(this.handleError));
  }

  // getSnapshots() {
  //   return this.http
  //     .get<object>(apiUrl + "/_snapshot/thp_repository/_all")
  //     .pipe(catchError(this.handleError));
  // }

  getSnapshots() {
    return this.http
      .get<object>(apiUrl + "/_snapshot/_all")
      .pipe(catchError(this.handleError));
  }

  deleteSnapshot(snapname) {
    return this.http
      .delete<object>(apiUrl + "/_snapshot/thp_repository/" + snapname)
      .pipe(catchError(this.handleError));
  }

  restoreSnapshot(snapname) {
    let body = {};
    return this.http
      .post<object>(apiUrl + "/_snapshot/my_backup/" + snapname + '/_restore', body)
      .pipe(catchError(this.handleError));
  }

  submitCroneName(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.put<object>(apiUrl + "/_slm/policy/nightly-snapshots",
      data, this.HttpOptions);
  }

  getHealthStatus() {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .get<object>(apiUrl + "/_cat/health?format=json", this.HttpOptions)
      .pipe(catchError(this.handleError));
  }



  getConfigurations(tenantID) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .get<object>(apiUrl + "/thp_configurations_" + tenantID + "/_search", this.HttpOptions)
      .pipe(catchError(this.handleError));
  }
  updateConfigurations(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_configurations_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }


  getSiem(tenantID) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .get<object>(apiUrl + "/thp_siem_" + tenantID + "/_search", this.HttpOptions)
      .pipe(catchError(this.handleError));
  }
  updateSiem(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_siem_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  getNotifications(tenantID) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .get<object>(apiUrl + "/thp_notifications_" + tenantID + "/_search", this.HttpOptions)
      .pipe(catchError(this.handleError));
  }

  updateNotifications(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_notifications_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  getViewDashboardDataTID(data, orgData) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    if (orgData != '*') {
      return this.http
        .post<object>(
          apiUrl + "/thp_enriched_observables_" + orgData + "/_search?track_total_hits=true",
          data,
          this.HttpOptions
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post<object>(
          apiUrl + "/thp_enriched_observables*/_search?track_total_hits=true",
          data,
          this.HttpOptions
        )
        .pipe(catchError(this.handleError));
    }

  }




  apiListAllTID(data, orgData) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    if (orgData != '*') {
      return this.http.post<object>(
        apiUrl + "/thp_api*/_search",
        data,
        this.HttpOptions
      );
    } else {
      return this.http.post<object>(
        apiUrl + "/thp_api*/_search",
        data,
        this.HttpOptions
      );
    }

  }

  // respondSubmiteUrl(data, body) {
  //   console.log(data, body)
  //   let data1 = JSON.stringify(body);
  //   console.log("data.header", data.header)
  //   // this.HttpOptions = {
  //   //   headers: data.header,
  //   //   };
  //   this.HttpOptions = {
  //     headers: new HttpHeaders({ "Content-Type": "application/json","user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')   "Authorization": "" }),
  //   };
  //   if (data.method == 'POST') {
  //     return this.http.post<object>(
  //       data.url,
  //       data1,
  //       this.HttpOptions
  //     );
  //   } else {

  //   }
  // }

  respondSubmiteUrl(data, body) {
    let a = JSON.parse(data.header)
    let data1 = JSON.stringify(body.body);
    // console.log("data.header", data.header)
    // this.HttpOptions = {
    //   headers: data.header,
    //   };
    // let a = { "Content-Type": "application/json","user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')   "Authorization": "" }
    // let a = data.header
    // console.log("data.header a", a)
    this.HttpOptions = {
      headers: new HttpHeaders(a),
    };
    if (data.method == 'POST') {
      return this.http.post<object>(
        data.url,
        data1,
        this.HttpOptions
      );
    } else if (data.method == 'GET') {
      return this.http.get<object>(
        data.url,
        this.HttpOptions
      );
    } else if (data.method == 'PUT') {
      return this.http.post<object>(
        data.url,
        data1,
        this.HttpOptions
      );
    } else {

    }
  }


  notificationsTop(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_notify_ab2/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  readNotificationsTop(data, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_notify_ab2/_update/" + id, data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  //MSSP
  totalPlaybooksRun(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_status*/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  completedPlaybooks(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_status*/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  tenentDistributionErrors(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_status*/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  tenentDistributionDetection(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_enriched_observables*/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  mostCommonDetections(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_enriched_observables*/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  leastCommonDetections(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiUrl + "/thp_enriched_observables*/_search", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  // startCustomHunting(url) {
  //   let apiUrl_hunting = apiUrl.replace(/9201/g, "9202")
  //   apiUrl_hunting = apiUrl_hunting.replace(/https/g, "http")
  //   this.HttpOptions = {
  //     headers: new HttpHeaders({
  //       "X-Auth-Token": "y0J27CkunrPr2Wz4xY3uWsy0qKR8ovdh",
  //     }),
  //   };
  //   return this.http
  //     .get<object>(apiUrl_hunting + url, this.HttpOptions)
  //     .pipe(catchError(this.handleError));
  // }


  startCustomHunting(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      loginUrl + "/script_service", data, this.HttpOptions
    ).pipe(catchError(this.handleError));
  }

  backendRolesList(data, user_name) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.put<object>(
      apiUrl + "/_plugins/_security/role/" + user_name,
      data,
      this.HttpOptions
    );
  }


  thpObservables(data) {
    data = JSON.stringify(data);
    return this.http
      .post<object>(
        apiUrl + "/thp_observables*/_search",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }

  thpGroupData(data) {
    data = JSON.stringify(data);
    return this.http
      .post<object>(
        apiUrl + "/thp_group*/_search?track_total_hits=true",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  getFilterData(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_enriched_observables*/_search?track_total_hits=true",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  thp_false_positive(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/thp_false_positive*/_search?track_total_hits=true",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  thp_false_positive_hunter(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .post<object>(
        apiUrl + "/hunt_enriched*/_search?track_total_hits=true",
        data,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }


  getViewDashboardDataTIDGroup(data, orgData) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    if (orgData != '*') {
      return this.http
        .post<object>(
          apiUrl + "/thp_group" + orgData + "/_search?track_total_hits=true",
          data,
          this.HttpOptions
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post<object>(
          apiUrl + "/thp_group*/_search?pretty",
          data,
          this.HttpOptions
        )
        .pipe(catchError(this.handleError));
    }

  }

  getViewDashboardDataTIDGroupHunt(data, orgData) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    if (orgData != '*') {
      return this.http
        .post<object>(
          apiUrl + "/hunt_group" + orgData + "/_search?track_total_hits=true",
          data,
          this.HttpOptions
        )
        .pipe(catchError(this.handleError));
    } else {
      return this.http
        .post<object>(
          apiUrl + "/hunt_group*/_search?pretty",
          data,
          this.HttpOptions
        )
        .pipe(catchError(this.handleError));
    }

  }


}

