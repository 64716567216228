import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { HunterServicesService } from 'src/app/core/services/hunter-services.service';

@Component({
  selector: 'app-hunter-detection',
  templateUrl: './hunter-detection.component.html',
  styleUrls: ['./hunter-detection.component.scss']
})
export class HunterDetectionComponent implements OnInit {
  severityBtn
  showFilter = false
  statusBtn
  hunt_name = ''
  hunt_description = ''
  detailed_description = ''
  huntInfo: any
  steps = []
  timelinedata = []
  timeline_list_setup = {
    data: [],
    listData: [],
    pagination: {
      limit_per_page: 10,
      noOfPages: 0,
      currentPage: 1,
      isLoading: false,
      maxItemsInPagination: 4,
      showLeftInfinte: false,
      showRightInfinte: false,
    }

  }
  recent_detection_bool = true
  filterApplied = false
  routeSub: any
  hunt_id = ''
  constructor(private route: ActivatedRoute, public common: CommonService, private huntService: HunterServicesService) { }
  period = {
    gte: '',
    lte: ''
  };
  dropdownSettings = {};
  GroupUserNameData = []
  f1GroupUserNameData = []

  GroupHostNameData = []
  f1GroupHostNameData = []
  orgData = '*';

  ngOnInit(): void {
    if (localStorage.getItem('isDateRange') == "true") {
      this.period = {
        gte: localStorage.getItem('startDateZ'),
        lte: localStorage.getItem('endDateZ')
      }
    } else {
      this.period = {
        gte: localStorage.getItem('period'),
        lte: 'now'
      }
    }

    this.dropdownSettings = {
      defaultOpen: true,
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      searchPlaceholderText: "search",
      clearSearchFilter: true
    };
    this.GroupUserNameData = ['User', 'inspire']
    this.GroupHostNameData = ['Lenovo', 'VIRTUAL-MACHINE']
    this.routeSub = this.route.params.subscribe(params => {
      this.hunt_id = params['hunt_id']
    });
    this.callAPi();
  }
  callAPi() {
    this.getHunterInfo()
    this.groupedUserName()
    this.groupedHostName()
  }
  getHunterInfo() {
    this.huntService.gethuntinfo(this.hunt_id).subscribe(
      (res: any) => {
        if (res !== null) {
          console.log('hunt info', res);
          this.huntInfo = res
          this.hunt_name = res.hunt_name
          this.hunt_description = res.Description
          this.detailed_description = res.detailed_description
          this.steps = []
          this.steps = res.steps
          for (let val of this.steps) {
            val.timeline_setup = {
              data: [],
              listData: [],
              pagination: {
                limit_per_page: 10,
                noOfPages: 0,
                currentPage: 1,
                isLoading: false,
                maxItemsInPagination: 4,
                showLeftInfinte: false,
                showRightInfinte: false,

              }
            }
          }
          this.getTimeline(this.all_timelinedata)
        }

      },
      error => {
        console.log(error);
      }
    )
  }
  get filterTimeline(): any {
    return { "size": 1000, "query": { "bool": { "filter": [{ "match_phrase": { "hunt_id": this.hunt_id } }, { "range": { "detection_timestamp": { "gte": this.period.gte, "lte": this.period.lte, "format": "strict_date_optional_time" } } }] } } }
  }
  get all_timelinedata(): any {
    return { "size": 1000, "query": { "bool": { "filter": [{ "match_phrase": { "hunt_id": this.hunt_id } }, { "range": { "detection_timestamp": { "gte": this.period.gte, "lte": this.period.lte, "format": "strict_date_optional_time" } } }] } } }
  }
  getTimeline(data) {
    this.recent_detection_bool = true
    this.huntService.hunt_group(data).subscribe(
      (res: any) => {
        console.log('timeline', res);
        if (null !== res) {
          var data: any[] = res.hits.hits
          data.forEach(element => {
            var timeline = element._source
            this.steps.forEach((item, index) => {
              if (item.step == timeline.step) {
                this.steps[index].timeline_setup.data = []
                this.steps[index].timeline_setup.data.push(timeline)
              }
            })
          });
        }
        this.recent_detection_bool = false
      },
      (error) => {
        this.recent_detection_bool = false
        console.log(error);

      }
    )
  }

  getPdf() { }

  statusChange(item) {
    this.statusBtn = item
    this.getTimeline(this.filterTimeline)
  }
  sevirityChange(item) {
    this.sevirityChange = item
    this.getTimeline(this.filterTimeline)
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  groupedUserName() {
    let data = { "size": 0, "query": { "bool": { "filter": [{ "match_all": {} }, { "range": { "current_timestamp": { "gte": "now-100d", "lte": "now", "format": "strict_date_optional_time" } } }] } }, "aggs": { "2": { "terms": { "field": "user_name", "order": { "_count": "desc" } } } } }
    let data1 = { "size": 0, "query": { "bool": { "filter": [{ "match_phrase": { "hunt_id": this.hunt_id } }, { "range": { "detection_timestamp": { "gte": "now-1000d", "lte": "now", "format": "strict_date_optional_time" } } }] } }, "aggs": { "2": { "terms": { "field": "user_name", "order": { "_count": "desc" } } } } }
    this.common.getViewDashboardDataTIDGroupHunt(data1, this.orgData).subscribe(
      (response: any) => {
        // console.log("groupedUserName", response);
        if (response.aggregations) {
          this.GroupUserNameData = []
          let buckets = response.aggregations[2].buckets;
          if (buckets.length > 0) {
            buckets.forEach(ao => {
              this.GroupUserNameData.push(ao != undefined ? ao.key : "");

            });
            // console.log("groupedUserName", this.GroupUserNameData)
          } else {
            console.log("DetectionSeverityData null")
          }
        }

      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  groupedHostName() {
    let data = { "size": 0, "query": { "bool": { "filter": [{ "match_all": {} }, { "range": { "current_timestamp": { "gte": "now-100d", "lte": "now", "format": "strict_date_optional_time" } } }] } }, "aggs": { "2": { "terms": { "field": "host_name", "order": { "_count": "desc" } } } } }
    let data1 = { "size": 0, "query": { "bool": { "filter": [{ "match_phrase": { "hunt_id": this.hunt_id } }, { "range": { "detection_timestamp": { "gte": "now-1000d", "lte": "now", "format": "strict_date_optional_time" } } }] } }, "aggs": { "2": { "terms": { "field": "host_name", "order": { "_count": "desc" } } } } }
    this.common.getViewDashboardDataTIDGroupHunt(data1, this.orgData).subscribe(
      (response: any) => {
        // console.log("groupedHostName", response);
        if (response.aggregations) {
          this.GroupHostNameData = []
          let buckets = response.aggregations[2].buckets;
          if (buckets.length > 0) {
            buckets.forEach(ao => {
              this.GroupHostNameData.push(ao != undefined ? ao.key : "");

            });
            // console.log("groupedHostName", this.GroupHostNameData)
          } else {
            console.log("DetectionSeverityData null")
          }
        }

      },
      (error) => {
        console.log("error", error);
      }
    );
  }
  applyFilter() {
    let data = [];
    if (this.f1GroupUserNameData.length > 0) {
      for (let i in this.f1GroupUserNameData) {
        data.push({
          "match_phrase": {
            "user_name": this.f1GroupUserNameData[i]
          }
        });
      }
    }
    if (this.f1GroupHostNameData.length > 0) {
      for (let i in this.f1GroupHostNameData) {
        data.push({
          "match_phrase": {
            "host_name": this.f1GroupHostNameData[i]
          }
        });
      }
    }
    console.log("data", data)
    let final_data = {
      "size": 1000,
      "query": {
        "bool": {
          "filter": [
            {
              "match_phrase": {
                "hunt_id": this.hunt_id
              }
            },
            {
              'bool': {
                'should': data
              }
            },
            {
              "range": {
                "detection_timestamp": {
                  "gte": this.period.gte,
                  "lte": this.period.lte,
                  "format": "strict_date_optional_time"
                }
              }
            }
          ]
        }
      }
    }

    console.log("data", final_data)
    this.getTimeline(final_data);
  }
  clearAllFilter() {
    this.statusBtn = ''
    this.severityBtn = ''
    this.severityBtn = ''
    this.f1GroupUserNameData = []
    this.f1GroupHostNameData = []
    this.filterApplied = false
    this.getTimeline(this.all_timelinedata)
  }

}
