import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import * as echarts from "echarts";
interface ChartType {
  xAxis?: any;
  yAxis?: any;
  series?: any;
  color?: any;
  tooltip?: any;
  grid?: any;
  legend?: any;
  animationEasing?: any;
  dataZoom?: any;
  toolbox?: any;
}
@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit {
  term: any;
  loding = false;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  dataTemp: any = []
  expectedValues: any
  type: any;
  datas: any;

  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.datas = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  constructor(public dashboardService: DashboardService) { }
  public gaugeChart;
  gauge: ChartType = {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%"
    },
    toolbox: {
      feature: {
        // restore: { title: "Refresh" },
        // saveAsImage: { title: "Download Image" }
      }
    },
    series: [
      {
        name: 'Business indicator',
        type: 'gauge',
        detail: { formatter: '{value}' },
        axisLine: {
          lineStyle: {
            color: [[0.2, '#34c38f'], [0.8, '#556ee6'], [1, '#f46a6a']],
            width: 20
          }
        },
        data: [{ value: 0, name: 'Events' }]
      }
    ]
  }
  ngOnInit(): void {
    this.loding = true;
    this.query = this.query;
    this.dashboardService.dashboardApi(this.query, this.api, this.method).subscribe(
      (result: any) => {
        // console.log("GaugeComponent", result)
        let d = result.hits?.total?.value;
        // console.log("d", d)
        this.gauge.series[0].data[0].value = d;
        if (this.gaugeChart)
        this.gaugeChart.setOption(this.gauge);
        this.loding = false;
      },
      (inerror) => {
        console.log("error", inerror);
      });
  }
  ngAfterViewInit(): void {
    // this.gaugeChart = echarts.init(document.getElementById('gauge'));
  }
}