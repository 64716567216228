import { Injectable } from "@angular/core";

import { getFirebaseBackend } from "../../authUtils";

import { User } from "../models/auth.models";
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpParams,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import {
  Observable,
  of,
  Subject,
  BehaviorSubject,
  observable,
  throwError
} from "rxjs";
import { catchError, map, tap, count } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
const apiUrl = environment.apiUrl;
const loginUrl = environment.loginUrl;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  user: User;
  HttpOptions: any;
  constructor(private http: HttpClient, private router: Router) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    // console.log("error", error)
    // if (error.error instanceof ErrorEvent) {
    //   errorMessage = `Error: ${error.error.message}`;
    // }
    // else {
    //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message ? error.message : 'No error message'}`;
    // }
    // console.log(error);
    return throwError(errorMessage);
  }
  getInvestigationJSON(name: string): Observable<any> {
    return this.http.get("assets/investigation/" + name + ".json")
  }
  getJSON(id: string): Observable<any> {
    return this.http.get("assets/dashbords/" + id + ".json")
  }
  getUnConfirmityDetails(): Observable<any> {
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .get<object>(
        "https://analyticsapi.active-bytes.com/getcompliancesummary?std=iso27001",
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }
  replaceDataObjects(data) {
    const donutVal = localStorage.getItem("selectedDonutVal");
    const donutValElmt = localStorage.getItem("selectedDonutElemet");
    if (donutVal) {
      if (donutVal && data && data.query && data.query.bool) {
        data.query.bool.should.push({
          "match_phrase": {
            [donutValElmt]: donutVal
          }
        });
        data.query.bool.minimum_should_match = 1;
      }
    }
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.range && it.range["@timestamp"]) {
          it.range["@timestamp"] = {
            "format": it.range["@timestamp"].format,
            "gte": localStorage.getItem("startDateZ"),
            "lte": localStorage.getItem("endDateZ"),
          }
        }
      });
    }

    return data;
  }
  dashboardApi(data?: any, api?: any, method?: any) {
    let authorizationData: any =
      localStorage.getItem('ACCESS_TOKEN');
    let url = localStorage.getItem('datalakeurl');
    let getUrl
    if (url) {
      getUrl = url + api
    } else {
      getUrl = "https://datalakedev.active-bytes.com:9202" + api
    }
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
      if (method == 'post') {
        return this.http
          .post<object>(
            api,
            this.replaceDataObjects(data),
            this.HttpOptions
          )
          .pipe(catchError(this.handleError));
      } else {
        return this.http
          .post<object>(
            api,
            this.replaceDataObjects(data),
            // data,
            this.HttpOptions
          )
          .pipe(catchError(this.handleError));
      }
    } else {
      // console.log("no authorizationData", authorizationData)
    }

  }




  dashboardApi1(data?: any, api?: any, method?: any) {
    console.log("data123", data)
    let authorizationData: any =
      localStorage.getItem('CYBOT_ACCESS_TOKEN');
    let url = localStorage.getItem('cyboturl');
    // console.log("api", api)
    // console.log("url", url)
    let getUrl
    if (url) {
      getUrl = url + api
    } else {
      getUrl = "https://52.53.139.65:9201" + api
    }
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "user-token": authorizationData,
          "Content-Type": "application/json"
        })
      };
      if (method == 'post') {
        return this.http
          .post<object>(
            getUrl,
            this.replaceDataObjects(data),
            this.HttpOptions
          )
          .pipe(catchError(this.handleError));
      } else {
        return this.http
          .post<object>(
            getUrl,
            this.replaceDataObjects(data),
            // data,
            this.HttpOptions
          )
          .pipe(catchError(this.handleError));
      }
    } else {
      // console.log("no authorizationData", authorizationData)
    }

  }


  getCybotDashboedDetails(id): Observable<any> {
    let authorizationData: any =
      localStorage.getItem('access_token');
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "Authorization": authorizationData,
          "Content-Type": "application/json"
        })
      };
    }
    return this.http
      .get<object>(
        "https://analyticsapi.active-bytes.com/invgetalertinfo?invid=" + id + "&type=desc",
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }



  dashboardApiCYBOT(data?: any, api?: any, method?: any) {
    // console.log("data123", data)
    let authorizationData: any =
      localStorage.getItem('CYBOT_ACCESS_TOKEN');
    let url = localStorage.getItem('cyboturl');
    // console.log("api", api)
    // console.log("url", url)
    let getUrl
    if (url) {
      getUrl = url + api
    } else {
      getUrl = "https://52.53.139.65:9201" + api
    }
    if (authorizationData) {
      this.HttpOptions = {
        headers: new HttpHeaders({
          "user-token": authorizationData,
          "Content-Type": "application/json"
        })
      };
      if (method == 'post') {
        return this.http
          .post<object>(
            getUrl,
            this.replaceDataObjectsCYBOT(data),
            this.HttpOptions
          )
          .pipe(catchError(this.handleError));
      } else {
        return this.http
          .post<object>(
            getUrl,
            this.replaceDataObjectsCYBOT(data),
            // data,
            this.HttpOptions
          )
          .pipe(catchError(this.handleError));
      }
    } else {
      // console.log("no authorizationData", authorizationData)
    }

  }


  replaceDataObjectsCYBOT(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.range && it.range["@timestamp"]) {
          it.range["@timestamp"] = {
            "format": it.range["@timestamp"].format,
            "gte": localStorage.getItem("startDateZ"),
            "lte": localStorage.getItem("endDateZ"),
          }
        }
      });
    }

    return data;
  }
}
