<app-topbar></app-topbar>
<div class="page-content">
<div class="container" style="margin-top: 50px;">
    <!-- <app-pagetitle title="Management Summary" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->

    <!-- <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Feeds distribution over time</h4>

                    <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart" [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid" [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
                        [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors" [fill]="basicColumChart.fill">
                    </apx-chart>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card card-body ">
                <h4 class="card-title mb-4">Event categories Distribution</h4>
                <apx-chart class="apex-charts" dir="ltr" [series]="eventDistributionData.series" [chart]="eventDistributionData.chart" [labels]="eventDistributionData.labels" [legend]="eventDistributionData.legend" [colors]="eventDistributionData.colors" [responsive]="eventDistributionData.responsive">
                </apx-chart>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3">
            <div class="card">
                <div class="card-header text-center">Vulnerabilities</div>
                <div class="card-body">
                    <h4 class="text-center">{{stats.vulnerabilities}}</h4>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="card-header text-center">Vulnerabilities Exploit</div>
                <div class="card-body">
                    <h4 class="text-center">{{stats.vulnerabilities_exploit}}</h4>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="card-header text-center">Security Events</div>
                <div class="card-body">
                    <h4 class="text-center">{{stats.security_events}}</h4>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card">
                <div class="card-header text-center">Headlines</div>
                <div class="card-body">
                    <h4 class="text-center">{{stats.headlines}}</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Security Incidents Intelligence</h4>
            </div>
        </div>

        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Security Events</h4>
                    <p>This panel shows the information regarding some trending security events, these can include recently found vulnerabilites or security breaches that took place in other organizations or patches to the same and much more.</p>
                    <div class="table-responsive">
                        <table datatable class="row-border hover scroll custWidth">
                            <thead>
                                <tr>
                                    <th>Event Name</th>
                                    <th>Description</th>
                                    <th>Comments</th>
                                    <th>Ref Link</th>
                                    <th>Details</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Item 1</td>
                                    <td>Item 2</td>
                                    <td>Item 3</td>
                                    <td>Item 4</td>
                                    <td>View</td>
                                </tr>
                                <tr>
                                    <td>Item 1</td>
                                    <td>Item 2</td>
                                    <td>Item 3</td>
                                    <td>Item 4</td>
                                    <td>View</td>
                                </tr>
                                <tr>
                                    <td>Item 1</td>
                                    <td>Item 2</td>
                                    <td>Item 3</td>
                                    <td>Item 4</td>
                                    <td>View</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Headlines</h4>
                    <p>This panel shows some trending news items headlines that are related to cyber security, along with reference links for further reading.</p>
                    <div class="table-responsive">
                        <table datatable class="row-border hover scroll custWidth">
                            <thead>
                                <tr>
                                    <th>Event Name</th>
                                    <th>Reference Link</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Vulnerabilities in the Kalay Internet-of-Things protocol puts millions of security devices at risk of complete attacker takeover.
                                    </td>
                                    <td>https://duo.com/decipher/critical-bug-in-kalay-iot-protocol-threatens-millions-of-devices</td>
                                </tr>
                                <tr>
                                    <td>Vulnerabilities in the Kalay Internet-of-Things protocol puts millions of security devices at risk of complete attacker takeover.
                                    </td>
                                    <td>https://duo.com/decipher/critical-bug-in-kalay-iot-protocol-threatens-millions-of-devices</td>
                                </tr>
                                <tr>
                                    <td>Vulnerabilities in the Kalay Internet-of-Things protocol puts millions of security devices at risk of complete attacker takeover.
                                    </td>
                                    <td>https://duo.com/decipher/critical-bug-in-kalay-iot-protocol-threatens-millions-of-devices</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Vulnerabilities Intelligence</h4>
            </div>
        </div>

        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Vulnerabilities</h4>
                    <p>Vulnerabilities are weaknesses in information systems or security infrastructure that could be exploited by a threat source. The following table shows the breakdown of vulnerability information that were obtained from various sources
                        in the given timeframe.</p>
                    <div class="table-responsive">
                        <table datatable class="row-border hover scroll custWidth">
                            <thead>
                                <tr>
                                    <th>Vulnerability name</th>
                                    <th>Vulnerability description</th>
                                    <th>Reference Link</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Botnet starting to scan for routers vulnerable to Realtek exploits</td>
                                    <td>A botnet similar to Mirai is actively scanning for wireless routers affected by a recently disclosed denial-of-service vulnerability affecting SDKs for Realtek chipsets. An attacker could exploit the vulnerability by
                                        sending specially crafted inputs, eventually crashing the HTTP server running the management interface and eventually the router. Security researchers are calling the botnet in question “Dark.IoT.” The botnet reportedly
                                        waits for researchers and organizations to publish proof-of-concepts for newly discovered vulnerabilities, and only takes days to eventually incorporate them. Other Realtek vulnerabilities were disclosed two weeks
                                        ago that affect dozens of internet-of-things devices, including internet-connected cameras and WiFi repeaters</td>
                                    <td>https://www.theregister.com/2021/08/25/mirai_botnet_critical_vuln_realtek_radware/</td>
                                </tr>
                                <tr>
                                    <td>Botnet starting to scan for routers vulnerable to Realtek exploits</td>
                                    <td>A botnet similar to Mirai is actively scanning for wireless routers affected by a recently disclosed denial-of-service vulnerability affecting SDKs for Realtek chipsets. An attacker could exploit the vulnerability by
                                        sending specially crafted inputs, eventually crashing the HTTP server running the management interface and eventually the router. Security researchers are calling the botnet in question “Dark.IoT.” The botnet reportedly
                                        waits for researchers and organizations to publish proof-of-concepts for newly discovered vulnerabilities, and only takes days to eventually incorporate them. Other Realtek vulnerabilities were disclosed two weeks
                                        ago that affect dozens of internet-of-things devices, including internet-connected cameras and WiFi repeaters</td>
                                    <td>https://www.theregister.com/2021/08/25/mirai_botnet_critical_vuln_realtek_radware/</td>
                                </tr>
                                <tr>
                                    <td>Botnet starting to scan for routers vulnerable to Realtek exploits</td>
                                    <td>A botnet similar to Mirai is actively scanning for wireless routers affected by a recently disclosed denial-of-service vulnerability affecting SDKs for Realtek chipsets. An attacker could exploit the vulnerability by
                                        sending specially crafted inputs, eventually crashing the HTTP server running the management interface and eventually the router. Security researchers are calling the botnet in question “Dark.IoT.” The botnet reportedly
                                        waits for researchers and organizations to publish proof-of-concepts for newly discovered vulnerabilities, and only takes days to eventually incorporate them. Other Realtek vulnerabilities were disclosed two weeks
                                        ago that affect dozens of internet-of-things devices, including internet-connected cameras and WiFi repeaters</td>
                                    <td>https://www.theregister.com/2021/08/25/mirai_botnet_critical_vuln_realtek_radware/</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Vulnerabilities with exploit</h4>
                    <p>An exploit is a piece of software, data or sequence of commands that takes advantage of a vulnerability to cause unintended behavior or to gain unauthorized access to sensitive data. The following table shows the breakdown of some
                        vulnerabilities with their exploit information including the CVE, CVSS etc. that were obtained from various sources in the given timeframe.</p>
                    <div class="table-responsive">
                        <table datatable class="row-border hover scroll custWidth">
                            <thead>
                                <tr>
                                    <th>Vulnerability name</th>
                                    <th>Vulnerability description</th>
                                    <th>CVE</th>
                                    <th>CVSS</th>
                                    <th>Vendor</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Weak Authentication Vulnerability in Apache Airflow</td>
                                    <td>The previous default setting for Airflow's Experimental API was to allow all API requests without authentication, but this poses security risks to users who miss this fact. From Airflow 1.10.11 the default has been
                                        changed to deny all requests by default and is documented at https://airflow.apache.org/docs/apache-airflow/1.10.11/security.html#api-authentication. Note this change fixes it for new installs but existing users
                                        need to change their config to default `[api]auth_backend = airflow.api.auth.backend.deny_all` as mentioned in the Updating Guide: https://github.com/apache/airflow/blob/1.10.11/UPDATING.md#experimental-api-will-deny-all-request-by-default
                                    </td>
                                    <td>2020-13927</td>
                                    <td>CVSS v3.1 Base Score: 9.8 (AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:H/A:H)</td>
                                    <td>Apache</td>
                                </tr>
                                <tr>
                                    <td>Weak Authentication Vulnerability in Apache Airflow</td>
                                    <td>The previous default setting for Airflow's Experimental API was to allow all API requests without authentication, but this poses security risks to users who miss this fact. From Airflow 1.10.11 the default has been
                                        changed to deny all requests by default and is documented at https://airflow.apache.org/docs/apache-airflow/1.10.11/security.html#api-authentication. Note this change fixes it for new installs but existing users
                                        need to change their config to default `[api]auth_backend = airflow.api.auth.backend.deny_all` as mentioned in the Updating Guide: https://github.com/apache/airflow/blob/1.10.11/UPDATING.md#experimental-api-will-deny-all-request-by-default
                                    </td>
                                    <td>2020-13927</td>
                                    <td>CVSS v3.1 Base Score: 9.8 (AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:H/A:H)</td>
                                    <td>Apache</td>
                                </tr>
                                <tr>
                                    <td>Weak Authentication Vulnerability in Apache Airflow</td>
                                    <td>The previous default setting for Airflow's Experimental API was to allow all API requests without authentication, but this poses security risks to users who miss this fact. From Airflow 1.10.11 the default has been
                                        changed to deny all requests by default and is documented at https://airflow.apache.org/docs/apache-airflow/1.10.11/security.html#api-authentication. Note this change fixes it for new installs but existing users
                                        need to change their config to default `[api]auth_backend = airflow.api.auth.backend.deny_all` as mentioned in the Updating Guide: https://github.com/apache/airflow/blob/1.10.11/UPDATING.md#experimental-api-will-deny-all-request-by-default
                                    </td>
                                    <td>2020-13927</td>
                                    <td>CVSS v3.1 Base Score: 9.8 (AV:N/AC:L/PR:N/UI:N/S:U/C:H/I:H/A:H)</td>
                                    <td>Apache</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Community Feeds Information</h4>
            </div>
        </div>

        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Community IOCs</h4>
                    <p>Community events are the events provided by open source threat intelligence providers which will contain a large number of IOCs related to any given security event that took place in a recent time frame.</p>
                    <div class="table-responsive">
                        <table datatable class="row-border hover scroll custWidth">
                            <thead>
                                <tr>
                                    <th>Event Name</th>
                                    <th>Feed Name</th>
                                    <th>Event ID</th>
                                    <th>Event drilldown</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Netfilter Rootkit Samples - F. Roth - Google sheet + expansion</td>
                                    <td>CIRCL</td>
                                    <td>9061</td>
                                    <td>View Event details</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row">

        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h3 class="mb-0">Vulnerabilities Intelligence</h3>
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
            </div>
        </div>

        <!-- <div class="col-md-12 pb-2"> 
            <div class="card card-body h-95">
                <h2 class="card-title mt-0 sub-heading-title">Vulnerabilities Intelligence Overview</h2>
                <p class="card-text paragraph-clr">This panel shows the recent vulnerability events, they are split into two categories, one for showing vulnerabilities and another for showing vulnerabilities with exploits. Both are important but exploits have to be identified, prioritized and patched in a more time sensitive manner. For a more detailed view of the events, click view.
                </p>
            </div>
        </div> -->

        <div class="col-xl-12 pb-4">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading-title">Vulnerabilities</h4>
                    <p class="paragraph-clr">Vulnerabilities are weaknesses in information systems or security infrastructure that could be
                        exploited by a threat source. The following table shows the breakdown of vulnerability
                        information that were obtained from various sources
                        in the given timeframe.</p>
                    <div class="table-responsive">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto" width="200"
                                src="assets/images/fileloading.gif" /></div>
                        <table datatable id="security_table" class="row-border hover scroll custWidth"
                            [dtOptions]="dtOptions" [dtTrigger]="dtTriggerVulnerabilities">
                            <thead style="display: none;">
                                <tr>
                                    <th>Vulnerability name</th>
                                    <!-- <th>Vulnerability description</th>
                                    <th>Reference Link</th> -->
                                    <th>Actions</th>
                            </thead>
                            <tbody *ngIf="!isVulnerabilities">
                                <tr *ngFor="let item of vulnerabilitiesData">
                                    <td class="w95 p-4">

                                        <h6>{{item.event_name}}</h6>
                                        <div class="font-14 text-muted">
                                                <p class="mb-0"><span class="hightlight-head primary-clr">Description:</span><span class="paragraph-clr"
                                                    title="{{item.event_description}}">{{item.event_description.length > 183?
                                                        item.event_description.slice(0, 180) + '...' : item.event_description}}</span>
                                                </p>
                                        </div>

                                        <span class="hightlight-head primary-clr">Reference Link:</span>
                                        <ul>
                                            <li><a [href]="item.reference_link" target="blank" style="border-bottom: 1px solid white;"
                                                    [title]="item.reference_link">
                                                    {{item.reference_link.length > 200 ? item.reference_link.slice(0,
                                                    200) + '...' : item.reference_link}}</a></li>
                                        </ul>

                                        <p class="mb-0 py-3"><i class="fa fa-calendar white-text mr-3"></i> <span class="timestamp">{{item.attribute_timestamp}}</span></p>
                                        
                                        <div>
                                            <span class="hightlight-head">Severity :</span>
                                            <span class="custom-alert"
                                            [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium'}">{{item.severity}}</span><span class="hightlight-head">TLP :</span><span class="tlp-styles">{{item.tlp}}</span>
                                        </div>
                                        <!-- <div class="timestamp">{{item.attribute_timestamp}}</div>  -->

                                    </td>
                                    <td class="actionTd viewBtn">
                                        <a href="javascript:void(0);" class="" ngbTooltip="view"
                                            (click)="openModal(viewModal,item,3)"><i
                                                class="fa fa-eye font-size-18"></i> View</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewModal let-modal>
    <div class="modal-header">
        <!-- <h5 class="primary-head">Details</h5> -->
        
        <div class="action-btns">
            <div class="save-btn">
                <button class="btn-default" (click)="captureScreenModelPrint()">Save <span>PDF</span></button>
            </div>
            <div class="close-btn" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                </svg>
            </div>
        </div>
        

        <!-- <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button> -->
    </div>
    
    <div class="modal-body p-3 pb-4">
        <div class="row" *ngIf="modalId==3">

            <div class="main-header col-md-12">
                <div class="event-title">
                    <h2 class="main-heading">Event Details</h2>
                </div>
                <div>
                    <p class="event-description paragraph-clr-dark">{{selectedContent.event_name}}</p>
                </div>
            </div>

            <div id="contentToConvert2">

                <!-- <div class="col-md-12">
                    <h3 class="eventNameHead">{{selectedContent.event_name}}</h3>
                </div> -->

                <div class="tags col-md-12">
                    <div class="colm-1 taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low',
                        status_border_medium: selectedContent.severity == 'Medium',
                        status_border_high: selectedContent.severity == 'High',
                        status_border_critical: selectedContent.severity == 'Critical'
                    }">
                        <!-- <span class="span-head" >Severity</span>  -->
                        <label>Severity</label>
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low',
                            status_highLabel: selectedContent.severity == 'High',
                            status_mediumLabel: selectedContent.severity == 'Medium',
                            status_criticalLabel: selectedContent.severity == 'Critical'
                        }">{{ selectedContent.severity }}</span>
                    </div>
                    <div class="colm2 label-style">
                        <label class="span-head">TLP</label> <span class="tlp-styles">{{selectedContent.tlp}}</span>
                    </div>

                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                    </div>
                </div>

                <!-- //////////////////////////// -->
            
                    <!-- <span class="timestamp">{{selectedContent.attribute_timestamp}}</span><br>
                    <div class="Severity py-2">
                        <span class="hightlight-head"> Severity :</span>
                    <span class="custom-alert" [ngClass]="{
                                  status_lowLabel: selectedContent.severity == 'Low',
                                  status_highLabel: selectedContent.severity == 'High',
                                  status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                              }">{{ selectedContent.severity }}</span>
                    </div>
               
                <div class="col-md-6">
                <div>
                    <span class="hightlight-head">TLP :</span>
                    <span>{{selectedContent.tlp}}</span>
                </div>
                </div> -->
                <!-- <button (click)="captureScreen2()" style="left: 93%;">print</button> -->
                <!-- <div class="col-md-12">
                    <app-geochart [countries]="countries"></app-geochart>
                </div> -->
                <!-- <div class="col-md-12">
                    <p class="card-title-desc"><span class="viewHead">Vulnerability name:</span>
                        <br><b>{{selectedContent.event_name}}</b></p>
                </div> -->


                <div class="overview col-md-12">
                    <h2 class="sub-heading">Vulnerability description:</h2>
                
                    <div class="overview-description">
                        <p class="paragraph-clr-dark">
                            {{selectedContent.event_description}}
                        </p>
                    </div>

                
                    <h2 class="sub-heading">Reference Link</h2>
                        <div class="links">
                            <ul>
                                <li>
                                    <a href="{{selectedContent.reference_link}}" target="_blank">{{selectedContent.reference_link}}</a>
                                </li>
                            </ul>
                        </div>
                </div>

                <!-- ////////////////////////123123123123123123123123123123 -->
                <!-- <div class="col-md-12">
                    <p class="card-title-desc"><span class="viewHead">
                        <span class="hightlight-head">Vulnerability description:</span>
                        </span><br><b>{{selectedContent.event_description}}</b></p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc"><span class="viewHead primary-sub-head">Reference Link:</span>
                        <br><b><a class="url-link" href="{{selectedContent.reference_link}}">{{selectedContent.reference_link}}</a></b>
                    </p>
                </div> -->
            </div>
        </div>

        <div class="row"*ngIf="modalId==4">
            
            <div class="col-md-12">
                <div>
                    <p class="event-description paragraph-clr-dark">{{selectedContent.event_name}}</p>
                </div>
            </div>

            <div id="contentToConvert3">

                <div class="tags col-md-12">
                    <div class="colm-1  taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low',
                        status_border_high: selectedContent.severity == 'High',
                        status_border_medium: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                    }">
                        <!-- <span class="span-head" >Severity</span>  -->
                        <label>Severity</label>
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low',
                            status_highLabel: selectedContent.severity == 'High',
                             status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                        }">{{ selectedContent.severity }}</span>
                    </div>
                    <div class="colm2 label-style">
                        <label>TLP</label>
                        <!-- <span class="span-head">TLP</span>  -->
                        <span class="tlp-styles">{{selectedContent.tlp}}</span>
                    </div>

                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                    </div>
                </div>

                <!-- <div class="label-style col-md-6">
                    <span>{{selectedContent.attribute_timestamp}}</span>
                </div> -->

                <!-- ////////////////////////////asdasdasdasdasdasd -->
                <!-- <div class="col-md-6">
                    {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                                  status_lowLabel: selectedContent.severity == 'Low',
                                  status_highLabel: selectedContent.severity == 'High',
                                   status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                              }">{{ selectedContent.severity }}</span>
                </div>
                <div class="col-md-6">
<div>TLP : <span>{{selectedContent.tlp}}</span></div>
                </div> -->
                <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
                <!-- <div class="col-md-12">
                    <app-geochart [countries]="countries"></app-geochart>
                </div> -->

                <div class="col-md-12">
                    <h2 class="sub-heading">Vulnerability description</h2>
                    <div class="overview-description">
                        <p class="paragraph-clr-dark">
                            {{selectedContent.event_description}}
                        </p>
                    </div>
                    <!-- <p class="card-title-desc"><span class="viewHead">Vulnerability description:</span>
                        <br><b>{{selectedContent.event_description}}</b>
                    </p> -->
                </div>
                <div class="col-md-12">
                    <h2 class="sub-heading">CVE</h2>
                    <br><p class="paragraph-clr-dark">{{selectedContent.cve}}</p>
                </div>
                <div class="col-md-12">
                    <h2 class="sub-heading">CVSS</h2>
                        <p class="paragraph-clr-dark">{{selectedContent.cvss}}</p>
                </div>
                <div class="col-md-12">
                    <h2 class="sub-heading">Vendor</h2>
                    <p class="paragraph-clr-dark">{{selectedContent.vendor}}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
</div>