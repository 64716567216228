import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ChartType } from '../dashboard/dashboard.model';
import { revenueChart, salesAnalytics } from '../dashboard/data';
@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss']
})
export class ChartDonutComponent implements OnInit {
  data: ChartType;
  revenueChart: ChartType;
  currentURL
  term: any;
  titleArray: any;
  api: any;
  query: any = '';
  method: any;
  dataTemp: any = []
  expectedValues: any
  datas: any;
  series: any = [];
  labels: any = [];
  type: any = []
  colors: any = []
  loding: boolean = true;
  currentPage
  selectedDonutElemet
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData;
    val.chart = {
      type: 'donut',
      events: {
        click: (event, chartContext, config) => {
          let currentURL = this.router.url.substr(1);
          console.log("event.srcElement", event)
          if (event.srcElement.innerText) {
            if (event.srcElement.innerText != "undefined") {
              localStorage.setItem("selectedDonutVal", event.srcElement.innerText);
              this.router.navigateByUrl('/dashboard/settings', { skipLocationChange: true }).then(() =>
                this.router.navigate([currentURL]));
            }
          }
        }

      }
    }
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  constructor(public dashboardService: DashboardService, private router: Router) {
   
  }

  ngOnInit(): void {
    this.currentURL = this.router.url.substr(1);
    this.query = this.query;
    if (this.query.aggs[0]) {
      this.selectedDonutElemet = this.query.aggs[0].terms.field
      localStorage.setItem("selectedDonutElemet", this.selectedDonutElemet);
    } else if (this.query.aggs[1]) {
      this.selectedDonutElemet = this.query.aggs[1].terms.field
      localStorage.setItem("selectedDonutElemet", this.selectedDonutElemet);
    } else if (this.query.aggs[2]) {
      this.selectedDonutElemet = this.query.aggs[2].terms.field
      localStorage.setItem("selectedDonutElemet", this.selectedDonutElemet);
    } else if (this.query.aggs[3]) {
      this.selectedDonutElemet = this.query.aggs[3].terms.field
      localStorage.setItem("selectedDonutElemet", this.selectedDonutElemet);
    }

    this.dashboardService.dashboardApi(this.query, this.api, this.method).subscribe(
      (result: any) => {
        // console.log("dashboardService", result)
        this.loding = true;
        console.log(this.data.series)
        if (result) {
          if (this.type == 3) {
            // let data = result.aggregations[2].buckets

            let data = result && result.aggregations ? result.aggregations[3] ? result.aggregations[3].buckets : [] : [];

            // console.log("data 33", data)
            let k = 0
            data.forEach(data => {
              this.data.series.push(data[3].buckets[0].doc_count)
              this.data.labels.push(data[3].buckets[0].key)
              this.data.colors.push(this.getRandomColor())
            });
          } else if (this.type == 2) {
            // let data = result.aggregations[2].buckets
            let data = result && result.aggregations ? result.aggregations[2] ? result.aggregations[2].buckets : [] : [];

            // console.log("data 22", data)
            console.log()
            let k = 0
            data.forEach(data => {
              this.data.series.push(data.doc_count)
              this.data.labels.push(data.key)
              this.data.colors.push(this.getRandomColor())
            });
            // console.log("datadatadata", data)
          } else if (this.type == 1) {
            // console.log("dashboardService", result)
            let data = result && result.aggregations ? result.aggregations[1] ? result.aggregations[1].buckets : [] : [];
            let k = 0
            data.forEach(data => {
              this.data.series.push(data.doc_count)
              this.data.labels.push(data.key)
              this.data.colors.push(this.getRandomColor())
            });
          } else if (this.type == 0) {
            // let data = result.aggregations[0].buckets
            let data = result && result.aggregations ? result.aggregations[0] ? result.aggregations[0].buckets : [] : [];

            let k = 0
            data.forEach(data => {
              this.data.series.push(data.doc_count)
              this.data.labels.push(data.key)
              this.data.colors.push(this.getRandomColor())
            });
          } else if (this.type == 4) {
            let data = result.aggregations[1].buckets
            let k = 0
            data.forEach(data => {
              this.data.series.push(data.doc_count)
              this.data.labels.push(data.key)
              this.data.colors.push(this.getRandomColor())
            });
          }
          else {
            console.log("ChartDonutComponent", result)
          }
          this.loding = false;
        }
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }


}
