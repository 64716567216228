import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() icon: string;

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  checkUrl(){
    console.log("checking Whats inside the title ???", this.title)
    if(this.title === 'Security Events'){
      this.router.navigate(['security-incidents'])
    }else if(this.title === 'Headlines'){
      this.router.navigate(['headlines'])
    }else if(this.title === 'Vulnerabilities'){
      this.router.navigate(['vulnerabilities-summary'])
    }else{
      this.router.navigate(['vulnerabilities-exploit'])
    }
  }
  

}
