<div class="card-body">
  <div class="media">
    <div class="media-body overflow-hidden">
      <h4 class="card-title mb-4">{{data.title}}</h4>
      <p>{{data.titleBody}}</p>
      <h4 class="mb-0" style="font-size: 4rem;">{{count}}</h4>
    </div>
    <div class="text-primary">
      <i class="{{data.icon}} font-size-24"></i>
    </div>
  </div>
</div>