// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiSsoDataUrl: "https://analyticsapi.active-bytes.com",
  apiSsoUrl: "https://sso.active-bytes.com/oauth2",
  loginUrl: "https://datalakedev.active-bytes.com:9202",
  apiUrl: "https://datalakedev.active-bytes.com:9202/logs-*",
  ServerUrl: window.location.origin + "/account/login",
  apiAlertDataUrl: "https://52.53.139.65:9201",
  apiAlertDataUrl1: "https://datalakedev.active-bytes.com:9202",
  trApiUrl: "https://analyticsapi.active-bytes.com/tiplatform",
  entPointTest: '/misp_event_test_*/search',
  ogEndPoint: '/misp_event_data/_search',
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
