import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SsoService } from 'src/app/core/services/sso.service';

@Component({
  selector: 'app-data-static-table',
  templateUrl: './data-static-table.component.html',
  styleUrls: ['./data-static-table.component.scss']
})
export class DataStaticTableComponent implements OnInit {


  term: any;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  dataTemp: any = []
  expectedValues: any
  type: any
  tableVal: any;
  selTab = "";
  unconformity = 0;
  maxConfirmity = 0;
  percentagevalue: any;
  confirmityVal: any;
  unconformityForm: FormGroup;
  colValArrSelectData
  subArraySelectData

  @Input() set analyticalData(val) {
    // console.log("analyticalData", val)
    this.titleArray = val.titleData
    this.datas = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  datas: any;
  constructor(private formBuilder: FormBuilder, private SsoService: SsoService) { }
  ngOnInit(): void {
    this.getTabItems(this.datas.tabs[0]);
    this.unconformityForm = this.formBuilder.group({
      details: ['', Validators.required]
    });

  }
  getUnconfirmity() {
    return this.unconformity.toString();
  }
  getMaxConfirmity() {
    return "100";// this.maxConfirmity.toString();
  }

  getTabItems(val) {
    this.tableVal = this.datas.data.filter(it => it.tab == val)[0];
    this.selTab = val;
    if (this.tableVal) {
      this.tableVal.data.forEach(it => {
        it.details.forEach(element => {
          if (element.col.includes("unconformity")) {
            this.unconformity++;
          }
          this.maxConfirmity++;
        });
      })
      this.percentagevalue = ((this.unconformity * 100) / this.maxConfirmity).toFixed(2);
      this.confirmityVal = (((this.maxConfirmity - this.unconformity) * 100) / this.maxConfirmity).toFixed(2);
    }
  }
  showDetails(val) {
    console.log("this.colValArrSelectData", this.colValArrSelectData)
    if (this.colValArrSelectData)
      this.colValArrSelectData.showDetails = false
    if (this.subArraySelectData)
      this.subArraySelectData.showDetails = false
    this.colValArrSelectData = val
    val.showDetails = !val.showDetails;
  }
  showDetails1(val) {
    console.log("this.subArraySelectData", this.subArraySelectData)
    console.log("showDetails1", val)
    if (this.subArraySelectData)
      this.subArraySelectData.showDetails = false
    this.subArraySelectData = val
    val.showDetails = !val.showDetails;
  }
  getStyle(val = "") {
    const wdth = val == 'confirmity' ? this.confirmityVal : this.percentagevalue;
    return wdth + "%";
  }

  saveDataDb(data) {
    console.log("data", data)
  }
  getSystemvArs() {
    this.SsoService.getComplianceArs().subscribe((data) => {
      console.log("getSystemvArs", data)
      let userData: any = data    
    })
  }

  onSubmit() {
    console.log("this.loginForm.invalid", this.unconformityForm)
    if (this.unconformityForm.invalid) {
      return;
    } else {
      let users = [];
      let variables = [];
      this.unconformityForm.value.variables.forEach(it =>
        variables.push({ "name": it.name, "value": it.value })
      );
      let sendData = {
        "datalakeurl": this.unconformityForm.value.datalakeurl,
        "datalakeuname": this.unconformityForm.value.datalakeuname,
        "datalakepass": this.unconformityForm.value.datalakepass,
        "test": "123",
        "privusers": users,
        "variables": variables
      }
      this.SsoService.setComplianceArs(sendData).subscribe((data: any) => {
        console.log("getDashboards", data)
        if (data == "updated") {
          // this.router.navigate(["/dashboard"], {});
        }
      })
    }
  }

}
