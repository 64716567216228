<header id="page-topbar" >
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </span>
        </a>

        <a href="/" class="logo logo-light text-center">
          <span class="logo-sm">
            <img src="assets/images/logo-sm-light.png" alt="" height="40">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="ri-search-line"></span>
        </div>
      </form> -->

    </div>

    <div *ngIf="showFilter">
      <div class="switch-holder">
        <div class="switch-label">
            <label for="utc" class="float-right m-auto">Local</label>
        </div>
        <div class="switch-toggle">
            <input type="checkbox" id="utc"
            [(ngModel)]="isUTC" (change)="changeUTCType()" [checked]="isUTC">
            <label for="utc"></label>
        </div>
        <div class="switch-label">
          <label for="utc" class="m1ema">UTC</label>
      </div>
    </div>
    </div>

    <div class="d-flex mr-4">

      <div class="dropdown d-inline-block d-lg-none ml-2" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-search-line"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ...">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group delay-id" *ngIf="showFilter">
        <div class="d-flex justify-center align-items-center" *ngIf="showFilter">
          <div *ngFor="let item of periodList">
            <input [(ngModel)]="period" (change)="changePeriod(period);isCalClick=false;" class="form-control"
              type="radio" [id]="item.value" name="period" [value]="item.value">
            <label [for]="item.value">{{item.name}}</label>
          </div>
          <div class="ml-2">
            <input class="customInput" type="text" (click)="period='';isCalClick=true;" ngxDaterangepickerMd
              [(ngModel)]="selected" [alwaysShowCalendars]="true" [ranges]="ranges" [showClearButton]="true"
              [locale]="locale" [maxDate]='maxDate' [ngClass]="{ 'bg-sel' : isCalClick }"
              (change)="rangeClicked($event)" placeholder="Select date range..." />
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item noti-icon waves-effect" style="display: none;"
          id="page-header-notifications-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="far fa-bell"></i>
          <span class="noti-dot"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <perfect-scrollbar style="position: relative; height: 230px;" [config]="configData">
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-2.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-2.jpg" class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </perfect-scrollbar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle mr-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>



        </div>
      </div>
      <!-- <div class="form-group delay-id">
        <div class="d-flex justify-center align-items-center" *ngIf="showFilter">
          <div *ngFor="let item of periodList">
            <input [(ngModel)]="period" (change)="changePeriod(period);isCalClick=false;" class="form-control"
              type="radio" [id]="item.value" name="period" [value]="item.value">
            <label [for]="item.value">{{item.name}}</label>
          </div>
          <div class="ml-2">
            <input class="customInput" type="text" (click)="period='';isCalClick=true;" ngxDaterangepickerMd
              [(ngModel)]="selected" [alwaysShowCalendars]="true" [ranges]="ranges" [showClearButton]="true"
              [locale]="locale" [maxDate]='maxDate' [ngClass]="{ 'bg-sel' : isCalClick }"
              (change)="rangeClicked($event)" placeholder="Select date range..." />
          </div>
        </div>
      </div> -->
      <div class="dropdown d-inline-block user-dropdown" ngbDropdown>
        <button type="button" ngbDropdownToggle class="btn header-item waves-effect" id="page-header-user-dropdown"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-2.jpg" alt="Header Avatar"> -->
          <b class="header-icon">{{userNameLetter}}</b>
          <span class="d-none d-xl-inline-block ml-1">{{user_name}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);">
            <i class="fa fa-user"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}
          </a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i
            class="ri-wallet-2-line align-middle mr-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a> -->
          <a class="dropdown-item d-block" href="/user-settings">
            <i class="fa fa-cog"></i>
            {{ 'HEADER.LOGIN.SETTINGS' | translate}}
          </a>
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i class="ri-lock-unlock-line align-middle mr-1"></i>
          {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a> -->
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}
          </a>
        </div>
      </div>

    </div>
  </div>
</header>