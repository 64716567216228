<app-topbar></app-topbar>
<div class="container" style="margin-top: 100px;">
<div class="row">

    <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h3 class="mb-0">Vulnerabilities Intelligence</h3>
            <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
        </div>
    </div>

    <div class="col-md-12 pb-2"> 
        <div class="card card-body h-95">
            <h2 class="card-title mt-0 sub-heading-title">Vulnerabilities Intelligence Overview</h2>
            <p class="card-text paragraph-clr">This panel shows the recent vulnerability events, they are split into two categories, one for showing vulnerabilities and another for showing vulnerabilities with exploits. Both are important but exploits have to be identified, prioritized and patched in a more time sensitive manner. For a more detailed view of the events, click view.
            </p>
        </div>
    </div>

<div class="col-xl-12">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title sub-heading-title">Vulnerabilities with exploit</h4>
            <p class="paragraph-clr">An exploit is a piece of software, data or sequence of commands that takes advantage of a
                vulnerability to cause unintended behavior or to gain unauthorized access to sensitive data. The
                following table shows the breakdown of some
                vulnerabilities with their exploit information including the CVE, CVSS etc. that were obtained
                from various sources in the given timeframe.</p>
            <div class="table-responsive">
                <div class="text-center" *ngIf="isVulnerabilitiesExploit"><img class="m-auto" width="200"
                        src="assets/images/fileloading.gif" /></div>
                <table datatable id="security_table" class="row-border hover scroll custWidth"
                    [dtOptions]="dtOptions" [dtTrigger]="dtTriggerVulnerabilitiesExploit">
                    <thead style="display: none;">
                        <tr>
                            <th>Vulnerability name</th>
                            <!-- <th>Vulnerability description</th>
                            <th>CVE</th>
                            <th>CVSS</th>
                            <th>Vendor</th> -->
                            <th>Actions</th>
                    </thead>
                    <tbody *ngIf="!isVulnerabilitiesExploit">
                        <tr *ngFor="let item of vulnerabilitiesExploitData">
                            <td class="w95">
                                
                                <h6>{{item.event_name}}</h6>

                                <div class="font-14 text-muted">
                                    <p class="mb-0"><span class="hightlight-head primary-clr">Description:</span><span class="paragraph-clr"
                                        title="{{item.event_description}}">{{item.event_description.length > 183 ?
                                            item.event_description.slice(0, 180) + '...' :
                                            item.event_description}}</span>
                                    </p>
                                    <p class="mb-0 primary-clr">Vendor : <span class="paragraph-clr">{{item.vendor}}</span></p>
                                    <p class="mb-0 py-3"><i class="fa fa-calendar mr-3 white-text "></i> <span class="timestamp">{{item.attribute_timestamp * 1000| date: 'medium'}}</span></p>
                                </div>

                                <div>
                                    <span class="hightlight-head">Severity : </span>
                                    <span class="custom-alert"
                                        [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium', 'criticalLabel' : item.severity=='Critical'}">{{item.severity}}
                                    </span> 

                                    <span class="hightlight-head"> TLP :</span><span class="tlp-styles">{{item.tlp}}</span>
                                </div>
                            </td>
                            <td class="actionTd viewBtn">
                                <a href="javascript:void(0);" class="" ngbTooltip="view"
                                    (click)="openModal(viewModal,item,4)"><i
                                        class="fa fa-eye font-size-18"></i> View</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</div>
</div>


<ng-template #viewModal let-modal>

    <div class="modal-header">
        <!-- <h5 class="primary-head">Details</h5> -->
        
        <div class="action-btns">
            <div class="save-btn">
                <button class="btn-default" (click)="captureScreenModelPrint()">Save <span>PDF</span></button>
            </div>
            <div class="close-btn" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                </svg>
            </div>
        </div>
        

        <!-- <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button> -->
    </div>


<div class="row vulnerabilites-container" *ngIf="modalId==4">
        
    <div class="col-md-12">
        <div>
            <p class="event-description paragraph-clr-dark">{{selectedContent.event_name}}</p>
        </div>
    </div>

    <div id="contentToConvert3">

        <div class="tags col-md-12">
            <div class="colm-1  taag label-style" [ngClass]="{
                status_border_low: selectedContent.severity == 'Low',
                status_border_medium: selectedContent.severity == 'Medium',
                status_border_high: selectedContent.severity == 'High',
                status_border_critical: selectedContent.severity == 'Critical'
            }">
                <!-- <span class="span-head" >Severity</span>  -->
                <label>Severity</label>
                <span class="custom-alert" [ngClass]="{
                    status_lowLabel: selectedContent.severity == 'Low',
                    status_highLabel: selectedContent.severity == 'High',
                    status_mediumLabel: selectedContent.severity == 'Medium',
                     status_criticalLabel: selectedContent.severity == 'Critical'
                }">{{ selectedContent.severity }}</span>
            </div>
            <div class="colm2 label-style">
                <label>TLP</label>
                <!-- <span class="span-head">TLP</span>  -->
                <span class="tlp-styles">{{selectedContent.tlp}}</span>
            </div>

            <div class="colm3 label-style">
                <label>Timestamp</label>
                <span class="timestamp">{{selectedContent.attribute_timestamp * 1000| date: 'medium'}}</span>
            </div>
        </div>

        <!-- <div class="label-style col-md-6">
            <span>{{selectedContent.attribute_timestamp}}</span>
        </div> -->

        <!-- ////////////////////////////asdasdasdasdasdasd -->
        <!-- <div class="col-md-6">
            {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                          status_lowLabel: selectedContent.severity == 'Low',
                          status_highLabel: selectedContent.severity == 'High',
                           status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                      }">{{ selectedContent.severity }}</span>
        </div>
        <div class="col-md-6">
<div>TLP : <span>{{selectedContent.tlp}}</span></div>
        </div> -->
        <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
        <!-- <div class="col-md-12">
            <app-geochart [countries]="countries"></app-geochart>
        </div> -->

        <div class="col-md-12">
            <h2 class="sub-heading">Vulnerability description</h2>
            <div class="overview-description">
                <p class="paragraph-clr-dark">
                    {{selectedContent.event_description}}
                </p>
            </div>
            <!-- <p class="card-title-desc"><span class="viewHead">Vulnerability description:</span>
                <br><b>{{selectedContent.event_description}}</b>
            </p> -->
        </div>
        <div class="col-md-12">
            <h2 class="sub-heading">CVE</h2>
            <br><p class="paragraph-clr-dark">{{selectedContent.cve}}</p>
        </div>
        <div class="col-md-12">
            <h2 class="sub-heading">CVSS</h2>
                <p class="paragraph-clr-dark">{{selectedContent.cvss}}</p>
        </div>
        <div class="col-md-12">
            <h2 class="sub-heading">Vendor</h2>
            <p class="paragraph-clr-dark">{{selectedContent.vendor}}</p>
        </div>
    </div>
</div>
</ng-template>