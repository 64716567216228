import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { CommonService } from "src/app/core/services/common.service";
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { Router } from "@angular/router";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";

@Component({
  selector: "app-headlines",
  templateUrl: "./headlines.component.html",
  styleUrls: ["./headlines.component.scss"],
})
export class HeadlinesComponent implements OnInit {
  constructor(
    private common: ThreatIntelligenceService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private router: Router
  ) {}


  headlineSearch:string = ''

  setTime = ["Latest", "6H", "12H", "24H"];
  filteredTimeStamp = []
  loader:boolean = true

  isFilteredTimeStamp = false

  period = {
    gte: "",
    lte: "",
  };
  countries = [];
  headlinesData = [];
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 5,
    lengthMenu: [5, 10, 25],
    processing: true,
    order: [],
  };
  dtTriggerSecurity: Subject<any> = new Subject<any>();
  dtTriggerHeadlines: Subject<any> = new Subject<any>();

  captureScreenModel;
  modalId: number;
  selectedContent: any;

  // time filter section

  selected: any = {};

  isSecurityData = false;
  getHeadlinesData() {
    // let data = {
    //   size: 100,
    //   query: {
    //     bool: {
    //       must: [],
    //       filter: [
    //         { match_all: {} },
    //         {
    //           match_phrase: {
    //             feed_name:
    //               "Activebytes Innovations - Threat Intelligence Services",
    //           },
    //         },
    //         { match_phrase: { event_category: "News" } },
    //         {
    //           range: {
    //             attribute_timestamp: {
    //               gte: this.period.gte,
    //               lte: this.period.lte,
    //               format: "strict_date_optional_time",
    //             },
    //           },
    //         },
    //       ],
    //       should: [],
    //       must_not: [],
    //     },
    //   },
    // };
    let data1 = {
      size: 10000,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name:
                  "CTI",
              },
            },
            { match_phrase: { event_category: "News" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
      aggs: {
        "1": {
          top_hits: {
            docvalue_fields: [
              {
                field: "attribute_timestamp",
                format: "date_time",
              },
            ],
            _source: "attribute_timestamp",
            size: 1,
            sort: [{ attribute_timestamp: { order: "desc" } }],
          },
        },
      },
    };


    this.common.getCommunityFeedChart(data1).subscribe((response: any) => {
      if (response) {
        let hits = response.hits.hits;
        this.headlinesData = [];
        for (let i = 0; i < hits.length; i++) {
          this.headlinesData.push({
            attribute_timestamp: this.timeConvert1(
              hits[i]._source.attribute_timestamp
            ),
            event_category: hits[i]._source.event_category,
            event_description: hits[i]._source.event_description,
            event_id: hits[i]._source.event_id,
            event_name: hits[i]._source.event_name,
            news: hits[i]._source.news,
            reference_link: hits[i]._source.reference_link,
          });
          // this.timeStampAre = hits[i].attribute_timestamp
        }
        this.loader = false

        if(this.headlinesData && this.headlinesData.length){
          this.headlinesData = this.headlinesData.reverse();
        }

       console.log("Headlines data are :", this.headlinesData)

        this.dtTriggerHeadlines.next();
      }
    });
    (error) => {
      console.log("error", error);
    };
  }

  ngOnInit(): void {
    if (localStorage.getItem("isDateRange") == "true") {
      this.period = {
        gte: localStorage.getItem("startDate"),
        lte: localStorage.getItem("endDate"),
      };
    } else {
      this.period = {
        gte: localStorage.getItem("period"),
        lte: "now",
      };
    }

    this.getHeadlinesData();

    let getButtonActive = document.querySelectorAll('#filterbtn')
    getButtonActive.forEach((button)=>{
      button.addEventListener('click', this.setACtiveStyle)
    })
  }

  
  setACtiveStyle(e){
    const buttons = document.querySelectorAll('#filterbtn')
    buttons.forEach(button => {
      button.classList.remove('active');
    });
    e.target.classList.add('active');

    }
  
  // filtering time

  // reset all
  ShowLatestNews(filterTime:string){
    if(filterTime === 'Latest'){
      this.getHeadlinesData()
    }

  }

  filterTimeByHours(filterTime:string){


    this.isFilteredTimeStamp = true
    const currentTime:any = new Date();
    let timePeriodInMilliSeconds

    if(filterTime === '6H'){
      timePeriodInMilliSeconds = 6 * 60 * 60 * 1000;
    }else if(filterTime === '12H'){
      timePeriodInMilliSeconds = 12 * 60 * 60 * 1000;
    }else if(filterTime === '24H'){
      timePeriodInMilliSeconds = 24 * 60 * 60 * 1000;
    }else{
      this.headlinesData
    }

    let newFetchedTimeStamp = this.headlinesData.map(items => items)
    const filterTimestamp = newFetchedTimeStamp.filter(timestamps => {
      const timeStampDate:any = new Date(timestamps.attribute_timestamp);
      return currentTime - timeStampDate <= timePeriodInMilliSeconds;
    });

    this.headlinesData =  filterTimestamp;
    console.log("all set", filterTimestamp)
  }

  openModal(viewmodal: any, content: any, modalId: number) {
    // console.log(content, modalId);
    if (content.location) {
      this.countries = [["Country"]];
      for (let i in content.location) {
        this.countries.push([content.location[i]]);
      }
    }
    this.captureScreenModel = modalId;
    this.modalId = modalId;
    this.selectedContent = content;
    this.modalService.open(viewmodal, { size: "lg", centered: true });
  }

  captureScreen1() {
    var data = document.getElementById("contentToConvert1");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image();
      img.src = "assets/images/logo-dark.png";
      pdf.addImage(img, "png", 150, 5, 50, 18);
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name + ".pdf"); // Generated PDF
    });
  }

  captureScreenModelPrint() {
    if (this.captureScreenModel == 2) this.captureScreen1();
  }

  timeConvert1(t) {
    let time = this.datePipe.transform(t * 1000, "medium");
    return time;
  }

  goToBack() {
    this.router.navigate(["/dashboard", { ti: 1 }]);
  }

  // filter news by time

  filterTime(selectedTime: string) {
    // let newDate:any = new Date()
    // if(selectedTime === '6H'){
    //   for(let i=0;i<this.headlinesData.length;i++){
    //     let filtedDate = this.headlinesData[i].attribute_timestamp
    //     let final = filtedDate - newDate
    //   }
    // }
  }
}
