<app-topbar>
</app-topbar>
<div class="" style="margin-top: 100px;">
    <div class="container" style="margin:auto">
        <!-- <div class="row">
            <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left"
                    aria-hidden="true"></i>
                Back
            </button>
        </div> -->
        <div class="row">
            <div class="col-md-9">
                <h2>{{getData?.inv_name}}</h2>
            </div>
            <div class="col-md-3">
                <button (click)="goToBack()" style="    float: right;" class="btn btn-themeBack"><i
                        class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    Back
                </button>



                <!-- <div class="text-right d-flex justify-content-between align-items-end">
                    <p class="mb-0">
                        <span *ngIf="donutVal" class="bg-dark text-light pl-2 py-1 m-1"> {{donutValElmt }} : {{donutVal
                            }}<span class="bg-danger text-dark px-2 py-1" (click)="clear()"> X</span></span>

                    </p>
                    <button (click)="topdf()" class="btn btn-info"><i class="fa fa-download" aria-hidden="true"></i>
                        Download PDF</button>
                </div> -->
            </div>
        </div>

    </div>
</div>

<div class="">
    <div class="container" style="margin:auto">
        <div class="row">
            <div class="col-md-12 d-flex flex-row-reverse">
                <button class="btn btn-primary" (click)="gotoCorrelation()">
                    Correlation</button>
                <button type="button" class="btn btn-success mr-2" (click)="addExceptions()"
                    [disabled]="!detectionTableData">Add
                    Exceptions</button>
                <button type="button" *ngIf="detectionTableData?.detection_status != 'closed'"
                    class="btn btn-danger mr-2" (click)="openModal(viewModal, detectionTableData)"
                    [disabled]="!detectionTableData">Close Detection</button>
                <button type="button" *ngIf="detectionTableData?.detection_status == 'closed'"
                    class="btn btn-secondary mr-2" (click)="openModal(viewModal, detectionTableData)"
                    [disabled]="!detectionTableData">Detection closed</button>
                <button type="button" class="btn btn-secondary mr-2"
                    (click)="openModal(respondModal, detectionTableData,true)"
                    [disabled]="!detectionTableData">Respond</button>
            </div>
        </div>
        <div class="row" id="dashboard">

            <div class="col-md-12">
                <div class="card h-101">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 title-main">About the investigation</h4>
                            <br>
                            <div class="col-md-12 color-w">
                                <div [innerHTML]="getData?.Description"></div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card-body p-0 m-0 color-w">
                                            <div [innerHTML]="getData?.detaild_description"></div>
                                            <div [innerHTML]="getData?.detailed_description"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card h-101">
                    <div class="card-body">
                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                            [component]="'detected_observables'"></cybot-investigation-view>
                    </div>
                </div>
                <div class="card h-101" *ngIf="Investigating_the_Hash_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">Investigating the Hash</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT about the suspicious Hash
                                observed</div>

                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card-body p-0 m-0">

                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'process_information_obtained_from_datalake'">
                                        </cybot-investigation-view>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="row">
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <div class="bg-card h-100">
                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'hash_reputation_check_on_virustotal'">
                                            </cybot-investigation-view>
                                        </div>

                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'hash_reputation_check_on_alienVault_OTX'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="bg-card h-100">
                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'Hash_Threat_Score'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 row">
                                <h5 class="font-size-14">Suggested Action</h5>
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="card-text1">
                                            We suggest to block the Hash in EDR if the Threat level is High(Red) based
                                            on Threat
                                            score.Please ensure that blocking this Hash does not make any business
                                            impact.The respond Button will help you to block
                                            the Hash in EDR.</p>
                                    </div>
                                    <div class="col-md-4">
                                        <button type="button" class="btn btn-outline-dark"
                                            (click)="openModal(respondModal, detectionTableData)">Respond</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card h-101" *ngIf="Investigating_the_URL_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">Investigating the URL</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT about the suspicious URL
                                observed</div>

                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 m-0">
                                    <div class="bg-card h-100">
                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'URL_reputation_check_on_virusTotal'">
                                            </cybot-investigation-view>
                                        </div>

                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'URL_reputation_check_on_alienVault_OTX'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 m-0">
                                    <div class="bg-card h-100">
                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'URL_Threat_Score'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 m-0">
                                    <h5 class="font-size-14"> Suggested Action</h5>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <p class="card-text1">We suggest to block the URL in firewall only if the
                                                Threat level is High (Red) based on Threat
                                                score Please ensure that the URL is not an organisation owned URL or
                                                doesn’t make any business impact.The Respond Button
                                                will help you to block the URL in firewall.</p>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" class="btn btn-outline-dark"
                                                (click)="openModal(respondModal, detectionTableData)">Respond</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card h-101" *ngIf="Investigating_the_IP_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">Investigating the IP</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT about the suspicious IP
                                observed</div>
                        </div>
                        <div class="col-md-12">
                            <div class="card h-100">
                                <div class="card-body p-0">
                                    <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                        [component]="'traffic_from_other_hosts_to_detected_IP'">
                                    </cybot-investigation-view>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 m-0">
                                    <div class="bg-card h-100">
                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'IP_reputation_check_on_virustotal'">
                                            </cybot-investigation-view>
                                        </div>

                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'IP_reputation_check_on_alienVault_OTX'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 m-0">
                                    <div class="bg-card h-100">
                                        <div class="card-body p-0 m-0">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'IP_Threat_Score'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-12">
                            <h5 class="font-size-14">Suggested Action</h5>
                            <div class="row">
                                <div class="col-md-8">
                                    <p class="card-text1">We suggest to block the IP in firewall only if the Threat
                                        level is High (Red) based on Threat score Please ensure that
                                        the IP is not an organisation owned IP or doesn’t make any business impact.The
                                        Respond Button will help you to block the
                                        IP in firewall.
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <button type="button" class="btn btn-outline-dark"
                                        (click)="openModal(respondModal, detectionTableData)">Respond</button>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div class="card h-101" *ngIf="Investigation_on_Host_and_User_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">Investigation on Host and User</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT about the Host & User which
                                executed the suspected activity</div>

                        </div>
                        <div class="row h-101">
                            <div class="col-md-6">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'hosts_logged_into_by_user'">
                                        </cybot-investigation-view>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'recent_authentications_in_host'">
                                        </cybot-investigation-view>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row h-101">
                            <div class="row h-101">
                                <div class="col-md-12">
                                    <div class="card h-100">
                                        <div class="card-body">
                                            <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                                [component]="'recent_file_creation'">
                                            </cybot-investigation-view>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card h-101">
                                    <div class="card-body">
                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'recent_file_deletion'">
                                        </cybot-investigation-view>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="card h-101">
                                    <div class="card-body">
                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'uncommon_processes_run_by_detected_user'">
                                        </cybot-investigation-view>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row h-101">
                            <div class="col-md-12">

                                <div class="card h-100">
                                    <div class="card-body">
                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'processes_run_by_detected_host'">
                                        </cybot-investigation-view>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                            [component]="'processes_run_by_detected_user'">
                                        </cybot-investigation-view>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="card h-101">
                            <div class="card-body">
                                <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                    [component]="'recent_commandlines_run_by_user_on_the_host'">
                                </cybot-investigation-view>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="card h-101" *ngIf="Investigating_the_IP_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">INVESTIGATION ON ANTIVIRUS</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT on Antivirus to find
                                detections on User and Host.</div>
                        </div>
                        <div class="col-md-12">
                            <div class="card h-100">
                                <div class="card-body p-0">
                                    <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                        [component]="'antivirus_detection_on_an_user'">
                                    </cybot-investigation-view>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card h-100">
                                <div class="card-body p-0">
                                    <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                        [component]="'antivirus_detection_on_host'">
                                    </cybot-investigation-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->


                <div class="card h-101" *ngIf="Investigating_the_IP_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">INVESTGATION ON FIREWALL</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT about the suspicious IP
                                observed.</div>
                        </div>
                        <div class="col-md-12">
                            <div class="card h-100">
                                <div class="card-body p-0">
                                    <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                        [component]="'traffic_from_an_ip_detected_on_firewall'">
                                    </cybot-investigation-view>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card h-100">
                                <div class="card-body p-0">
                                    <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                        [component]="'traffic_to_an_ip_detected_on_firewall'">
                                    </cybot-investigation-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="card h-101" *ngIf="Investigating_the_IP_check()">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">INVESTGATION ON WAF</h4><br>
                            <div [innerHTML]="">Detailed automated investigation by CYBOT about the suspicious IP
                                observed.</div>
                        </div>
                        <div class="col-md-12">
                            <div class="card h-100">
                                <div class="card-body p-0">
                                    <cybot-investigation-view [UserName]="user" [huntDetection]="hunt_detection_id"
                                        [component]="'traffic_from_an_ip_detected_on_WAF'">
                                    </cybot-investigation-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="card h-101">
                    <div class="card-body">
                        <div class="row m-0">
                            <h4 class="card-title mb-2 w-100 title-main">Conclusion</h4><br>
                            <div [innerHTML]="addName(getData?.conclution)"></div>
                            <div [innerHTML]="addName(getData?.conclusion)"></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #viewModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ selectedDetections?.detection_name }}</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pt-3 pb-4">
        <div class="row">
            <div class="col-md-12" [ngClass]="{
				'active-close': closeCommentFalg,
				'deactive-close': !closeCommentFalg
			}">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-center">Detection Closing</h3>
                    </div>
                    <div class="col-md-12">
                        <p class="card-title-desc">
                            Detection Name: <br /><b>{{
                                selectedDetections?.detection_name
                                }}</b>
                        </p>
                    </div>
                    <div class="col-md-12">
                        <p class="card-title-desc">
                            Technique Name: <br /><b>{{
                                selectedDetections?.technique_name
                                }}</b>
                        </p>
                    </div>
                    <div class="col-md-12" *ngIf="selectedDetections?.detection_status == 'closed'">
                        <p class="card-title-desc">
                            Closing comment: <br /><b>{{
                                selectedDetections?.closing_comment
                                }}</b>
                        </p>
                    </div>
                </div>
                <form [formGroup]="closeCommentform" role="form"
                    *ngIf="selectedDetections?.detection_status != 'closed'">
                    <div class="form-group">
                        <!-- Editor -->
                        <label for="category-name" class="control-label">Comments*</label>
                        <textarea formControlName="comment" class="form-control" placeholder="Comment">
					</textarea>
                        <div class="error-div">
                            <small class="text-danger" *ngIf="
						closeCommentform.controls.comment.errors?.required &&
						closeCommentform.controls.comment.touched
						">Comment is required
                            </small>
                        </div>
                    </div>
                </form>
                <div class="text-right" *ngIf="selectedDetections?.detection_status != 'closed'">
                    <button type="button" class="btn btn-danger mr-2" (click)="closeCommentClickClose()">
                        Cancel
                    </button>
                    <button type="button" [disabled]="!closeCommentform.valid" class="btn btn-success"
                        (click)="closeCommentAdd()">
                        Approved <i class="fab fa-telegram-plane ml-1"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #respondModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Respond</h5>
        <button type="button" class="close" aria-hidden="true" (click)="responceClose()">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pt-3 pb-4" style="min-height: 10rem;">
        <div class="row">
            <div class="col-md-12">
                <p>Please choose the required action's integration and corresponding observables from the dropdowns
                    below</p>
            </div>
            <div class="col-md-12 text-center mb-2">
                <!-- <div class="btn-group" role="group" ngbDropdown style="width: 50%;">
                    <button id="btnGroupVerticalDrop1" type="button" class="btn btn-outline-secondary dropdown-toggle" ngbDropdownToggle>
						{{btnGroupVerticalDrop1}} <i class="mdi mdi-chevron-down"></i>
					</button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" ngbDropdownMenu>
                        <a *ngFor="let ip of soarListData" class="dropdown-item" (click)="btnGroupVerticalClick(ip.count)">{{ip.api_name}}</a>
                    </div>
                </div> -->
                <select style="width: 50%;" class="btn btn-outline-secondary dropdown-toggle"
                    (change)="btnGroupVerticalClick($event)">
                    <option selected value="" hidden> {{btnGroupVerticalDrop1}} </option>
                    <option *ngFor="let ip of soarListData" [value]="ip.id"> {{ip.api_name}}</option>
                </select>
            </div>
            <div class="col-md-12 text-center mb-2">
                <form [formGroup]="productForm" *ngIf="!sub_url_flge">
                    <table class="table table-bordered" formArrayName="quantities">
                        <tr *ngFor="let quantity of quantities().controls; let i = index" [formGroupName]="i">
                            <td>
                                <div class="form-group d-inline-grid">
                                    <label class="text-left mb-02">#param{{i+1}}</label>
                                    <input formControlName="select" list="quantity_lists" name="quantity_list"
                                        id="quantity_list" autocomplete="off"
                                        class="btn btn-outline-secondary dropdown-toggle">
                                    <datalist id="quantity_lists">
                                        <option [value]="doData.process_commandline">{{ doData.process_commandline }}
                                        </option>
                                        <option [value]="doData.ProcessName">{{ doData.ProcessName }}</option>
                                        <option [value]="doData.ProcessID">{{ doData.ProcessID }}</option>
                                        <option [value]="doData.process_parent_name">{{ doData.process_parent_name }}
                                        </option>
                                        <option [value]="doData.UserName">{{ doData.UserName }}</option>
                                        <option [value]="doData.user_domain">{{ doData.user_domain }}</option>
                                        <option [value]="doData.HostName">{{ doData.HostName }}</option>
                                        <option [value]="doData.dest_ip"
                                            *ngIf="doData.dest_ip != 'No IP' && doData.dest_ip">{{ doData.dest_ip }}
                                        </option>
                                        <option [value]="doData.dest_ip1"
                                            *ngIf="doData.dest_ip1 != 'No IP' && doData.dest_ip1">{{ doData.dest_ip1 }}
                                        </option>
                                        <option [value]="doData.dest_ip2"
                                            *ngIf="doData.dest_ip2 != 'No IP' && doData.dest_ip2">{{ doData.dest_ip2 }}
                                        </option>
                                        <option [value]="doData.dest_ip3"
                                            *ngIf="doData.dest_ip3 != 'No IP' && doData.dest_ip3">{{ doData.dest_ip3 }}
                                        </option>
                                        <option [value]="doData.dest_ip4"
                                            *ngIf="doData.dest_ip4 != 'No IP' && doData.dest_ip4">{{ doData.dest_ip4 }}
                                        </option>
                                        <option [value]="doData.url" *ngIf="doData.url != 'No URL' && doData.url">{{
                                            doData.url }}</option>
                                        <option [value]="doData.url1" *ngIf="doData.url1 != 'No URL' && doData.url1">{{
                                            doData.url1 }}</option>
                                        <option [value]="doData.url2" *ngIf="doData.url2 != 'No URL' && doData.url2">{{
                                            doData.url2 }}</option>
                                        <option [value]="doData.url3" *ngIf="doData.url3 != 'No URL' && doData.url3">{{
                                            doData.url3 }}</option>
                                        <option [value]="doData.url4" *ngIf="doData.url4 != 'No URL' && doData.url4">{{
                                            doData.url4 }}</option>
                                        <option [value]="doData.SourceIP" *ngIf="doData.SourceIP">{{ doData.SourceIP }}
                                        </option>
                                    </datalist>
                                </div>
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
            <div class="col-md-12 text-center">
                <button *ngIf="!sub_url_flge" type="button" (click)="respondSubmite()" class="btn btn-success">
                    Submit
                </button>
                <div *ngIf=sub_url_flge>
                    {{responce_submit}}
                </div>
            </div>
        </div>
    </div>
</ng-template>