import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { RuleStudioService } from 'src/app/core/services/rule-studio.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-rule-studio-hunter',
  templateUrl: './rule-studio-hunter.component.html',
  styleUrls: ['./rule-studio-hunter.component.scss']
})
export class RuleStudioHunterComponent implements OnInit {
  load=false;
  hunterCustom = this.fb.group({
    hunt_id: ['', Validators.required],
    hunt_name: ['', Validators.required],
    description: [''],
    detailed_description: [''],
    notify:['', Validators.required],
    hunt_step: this.fb.array([])
  })
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  constructor(private fb: FormBuilder, private ruleStudioService: RuleStudioService, private router: Router) { }
  get hunt_step(): FormArray {
    return this.hunterCustom.get('hunt_step') as FormArray
  }
  get f() { return this.hunterCustom.controls; }
  tenantCatalogue
  playbookIdData
  logcategoryList: { selected: false, items: [{ item_id: string, item_text: string }], itemsSelected: any[] };
  ckeditorData
  logcategorySelectedItems = [];
  public Editor = ClassicEditor;
  listofItems = [];
  selectedlist = [];
  dropdownSettingsmodule: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 5,
    allowSearchFilter: true,
  };
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  addStep() {
    this.hunt_step.push(this.fb.group({
      name: ['', Validators.required],
      description: [''],
      list: ['']
    }))
    console.log(this.hunt_step);

  }
  removeStep(i: number) {
    this.hunt_step.removeAt(i)
  }
  ngOnInit(): void {
    this.getData()
    this.addStep()
    this.ckeditorData = ''
  }


  onSubmit() {
    if (this.hunterCustom.valid) {
      let data = {
        hunt_id: this.f.hunt_id.value,
        hunt_name: this.f.hunt_name.value,
        Description: this.f.description.value,
        detailed_description: this.f.detailed_description.value,
        notify: this.f.notify.value,
        steps: []
      }
      console.log("data", data)

      let info = {};
      let k = 1
      let step = []
      this.f.hunt_step.value.forEach(v => {
        let obj
        obj = this.playbookIdData.find((o, i) => {
          if (o.playbook_id === v.list) {
            return o; // stop searching
          }
        });
        console.log("obj", v.list)
        let val = {
          step: k,
          name: v.name,
          description: v.description,
          inv_playbook: v.list
        }
        data.steps.push(val)
        // let n = 'step_' + k
        // info[n] = val
        k++
      });
      console.log("info", info)

      // let sendData = {
      //   ...data,
      //   ...info
      // };

      console.log("sendData", data)
      this.ruleStudioService.createCustomRuleStudioHunt(data, this.f.hunt_id.value)
        .subscribe((data) => {
          console.log("data", data)
          this.router.navigate(['rule-studio/hunter']);
        });
    }
    else {
      return
    }
  }
  onItemList(event,isAdd,dataSet){
     console.log(dataSet)
     console.log(event)
  }
  cancel() {
    this.router.navigate(['rule-studio/hunter']);
  }
  // getData() {
  //   this.tenantCatalogue = [];
  //   this.ruleStudioService.getCatalogueTenant().subscribe(
  //     (res: any) => {
  //       console.log("res", res)
  //       // this.playbookIdData = res;
  //       let log_category: [{ item_id: string, item_text: string }] = [{ item_id: null, item_text: null }]
  //       log_category.pop();
  //       console.log("log_category", log_category)
  //       res.forEach(data => {
  //         this.tenantCatalogue.push(data);
  //         log_category.push({ item_id: data[0], item_text: data[1] });
  //       });
  //       console.log("log_category", log_category)
  //       this.logcategoryList = { selected: false, items: log_category, itemsSelected: [] };
  //       console.log("logcategoryList", this.logcategoryList)
  //     },
  //     (error) => {
  //       console.log("error", error);
  //     }
  //   );

  // }
  getData() {
    this.tenantCatalogue = [];
    this.ruleStudioService.getCatalogueTenant().subscribe(
      (res: any) => {
        console.log("res", res)
        this.playbookIdData = res;
        res.forEach(data => {
          this.tenantCatalogue.push(data);
          this.listofItems.push(`${data[1]} - ${data[0]}`);
        });
        this.load = true;
      },
      (error) => {
        console.log("error", error);
        this.load = true;
      }
    );

  }
}


