import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cybot-analytics',
  templateUrl: './cybot-analytics.component.html',
  styleUrls: ['./cybot-analytics.component.scss']
})
export class CybotAnalyticsComponent implements OnInit {

  constructor(private router: Router) { }
  routerUrl = "";
  ngOnInit(): void {
    this.routerUrl = this.router.url;
  }

}
