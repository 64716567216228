<div class="history-tl-container">
    <div class="d-flex ht-250 w-100" *ngIf="recent_detection_bool || timelineData.pagination.isLoading"><img
            class="m-auto" width="200" src="assets/images/fileloading.gif" /></div>
    <ul class="tl" *ngIf="!recent_detection_bool && !timelineData.pagination.isLoading">
        <li *ngFor="let item of timelineData.listData;" class="tl-item" ng-repeat="item in retailer_history">
            <a>
                <div class="timestamp">
                    {{item.detection_timestamp | date:'medium' : 'UTC'}}
                </div>
                <div class="item-title" (click)="viewRepetitions(item)"><span [innerHTML]="item.description"></span>
                </div>
            </a>
        </li>
    </ul>
    <div *ngIf="!recent_detection_bool && timelineData.data.length==0" class="mx-3 mb-3 mt-0 text-center text-danger">No
        Data
        Found!</div>
    <div class="pagination" *ngIf="!timelineData.pagination.isLoading && timelineData.pagination.noOfPages>1">

        <nav aria-label="Page navigation mx-auto mt-3">
            <ul class="pagination justify-content-center">
                <li class="page-item" [class]="timelineData.pagination.currentPage==1?'disabled':''"
                    (click)="changePage(timelineData.pagination.currentPage-1)">
                    <a class="page-link" tabindex="-1">Previous</a>
                </li>
                <li class="page-item" [class]="timelineData.pagination.currentPage==1?'active':''"><a class="page-link"
                        (click)="changePage(1)">1</a>
                </li>
                <li *ngIf="timelineData.pagination.showLeftInfinte && timelineData.pagination.noOfPages>timelineData.pagination.maxItemsInPagination+1"
                    class="page-item disabled"><a class="page-link">...</a></li>
                <li *ngFor="let in of getPaginationNumber ;let i = index" class="page-item"
                    [class]="timelineData.pagination.currentPage==in?'active':''" (click)="changePage(in)">
                    <a class="page-link">{{in}}</a>
                </li>
                <li *ngIf="timelineData.pagination.showRightInfinte && timelineData.pagination.noOfPages>timelineData.pagination.maxItemsInPagination+1"
                    class="page-item disabled"><a class="page-link">...</a></li>
                <li class="page-item"
                    [class]="timelineData.pagination.currentPage==timelineData.pagination.noOfPages?'active':''">
                    <a class="page-link"
                        (click)="changePage(timelineData.pagination.noOfPages)">{{timelineData.pagination.noOfPages}}</a>
                </li>

                <li class="page-item"
                    [class]="timelineData.pagination.currentPage==timelineData.pagination.noOfPages?'disabled':''">
                    <a class="page-link" (click)="changePage(timelineData.pagination.currentPage+1)">Next</a>
                </li>
            </ul>
        </nav>
    </div>
</div>