import { Component, Input, OnInit } from '@angular/core';
import { EchartType } from "../../pages/community/view-dashboard.model";
import { UebaService } from 'src/app/core/services/ueba.service';
import * as echarts from "echarts";
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-investigation-gauge-chart',
  templateUrl: './investigation-gauge-chart.component.html',
  styleUrls: ['./investigation-gauge-chart.component.scss']
})
export class InvestigationGaugeChartComponent implements OnInit {
  hunt_detection
  user_name
  @Input() set DetectionId(val) {
    // console.log("analyticalData", val)
    this.hunt_detection = val
  }
  @Input() set UserName(val) {
    this.user_name = val;
  }
  query: any = '';
  name: string = '';
  data: any;
  titleArray
  api
  method
  type
  expectedValues
  component
  @Input() set analyticalData(val) {
    this.data = val;
    this.query = val.query;
    this.name = val.title;
    this.titleArray = val.titleData
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
    this.component = val.component;
  }
  hashVirtualScore = 0;
  hashThreatGauge: EchartType = {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%"
    },
    toolbox: {
      feature: {
      }
    },
    series: [
      {
        name: '',
        type: 'gauge',
        detail: { formatter: '{value}' },
        axisLine: {
          lineStyle: {
            color: [[0.2, '#34c38f'], [0.8, '#556ee6'], [1, '#f46a6a']],
            width: 20
          }
        },
        data: [{ value: 0, name: this.name }]
      }
    ]
  }

  private hashGaugeChart;



  constructor(public uebaService: UebaService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getHashThreatScore();
  }

  getHashThreatScore() {
    this.query = this.query;
    let d = this.expectedValues[0]
    this.dashboardService.dashboardApiCYBOT(this.replaceDataObjects(this.query), this.api, this.method).subscribe(
      (response: any) => {
        if (response.hits?.hits[0]) {
          this.hashVirtualScore = response.hits?.hits[0]._source[d];
          // console.log(this.data.title, response, this.hashVirtualScore, d);
          this.hashThreatGauge.series[0].data[0].value = this.hashVirtualScore ? Number(this.hashVirtualScore) : 0;
          this.hashGaugeChart.setOption(this.hashThreatGauge);
        } else {

        }

      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  ngAfterViewInit(): void {
    this.hashGaugeChart = echarts.init(document.getElementById('hashGaugeChart-' + this.component));
  }
  replaceDataObjects(data) {
    if (data && data.query && data.query.bool && data.query.bool.filter) {
      data.query.bool.filter.forEach(it => {
        if (it && it.match_phrase && it.match_phrase["hunt_detection_id"]) {
          it.match_phrase["hunt_detection_id"] = this.hunt_detection;
        }
      });
    }
    return data;
  }

}
