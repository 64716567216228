<div class="row align-items-center">
    <!-- <div class="col-md-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="Card-title mb-0">Dashboard</h4>
        </div>
    </div> -->
    <div class="col-md-12 m-0">
        <h4 class="card-title">Filter</h4>
    </div>
    <div class="form-group auth-form-group-custom inner-form-group col-md-12">
        <i class="ri-user-2-line auti-custom-input-icon"></i>
        <label for="username">Search</label>
        <input class="form-control" id="search" placeholder="search">

    </div>

    <!-- <div class="form-group auth-form-group-custom inner-form-group col-md-12">
        <i class="ri-lock-2-line auti-custom-input-icon"></i>
        <label for="password">Host name</label>
        <input  class="form-control" id="hostname" placeholder="host name" />

    </div> -->
    <div class="form-group auth-form-group-custom  col-md-12">
        <div class="row">
            <div class="col-md-6">
                <button class="btn btn-primary w-md waves-effect">
                    Apply Filter
                </button>
            </div>
            <div class="col-md-6">
                <button class="btn btn-light w-md waves-effect">
                    Cancel
                </button>
            </div>
        </div>
    </div>

</div>