<app-topbar>
</app-topbar>
<div class="container  pt-2" style="margin-top:70px">
   
    <!-- <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
        <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back
        </button>
        <button class="btn btn-themePrint" (click)="openModal(viewModal,1)" [disabled]="isLoading"><i class="fa fa-print"
                [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-file-pdf': !isLoading}"></i> {{button}}</button>
    </p> -->

    <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h3 class="mb-0">Event Details</h3>
            <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left"
                        aria-hidden="true"></i> Back </button>
                <button class="btn btn-themePrint" (click)="getPdf()" [disabled]="isLoading"><i
                        class="fa fa-print" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-file-pdf': !isLoading}"></i>
                    {{button}}</button>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">

            <div class="card card-body">
                <!-- <h4 class="card-title">Event details</h4> -->
                <!-- <h3 class="card-title mt-0">Event Name :{{eventData?.event_name}}</h3>
                <p class="card-text">Event Id : {{eventData?.event_id}}</p>
                <p class="card-text">Feed Name :{{eventData?.feed_name}} </p>
                <p class="card-text">Severity : </p> -->
                <h5 *ngIf="table2Loader" class="mb-0 text-center"><i
                        class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i></h5>
                <!--          <table datatable class="row-border hover scroll custWidth bg-white" [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger1" width="100%">
                    <thead style="visibility: hidden;">
                        <tr>
                            <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of eventData">
                            <td>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b>Event Name :{{item._source.event_name}}</b><br>
                                            <b>Event Id : {{item._source.event_id}}</b><br>
                                            <b>Feed Name :{{item._source.feed_name}}</b><br>
                                        </div>
                                        <div class="col-md-12">
                                            <b class="card-text"><b>Event Description: </b>{{item._source.event_description}}</b>
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
                <div class="row eventDetails" *ngIf="!table2Loader">
                    <div class="col-md-8">
                        <h6><span class="secondary-head-clr">Event Name </span> {{eventData[0]._source.event_name}}</h6>
                        <h6><span class="secondary-head-clr" >Event ID </span> {{eventData[0]._source.event_id}}</h6>
                    </div>
                    <div class="col-md-4">
                        <h6><span class="secondary-head-clr">Severity </span> {{eventData[0]._source.severity | titlecase}}</h6>
                        <h6><span class="secondary-head-clr">Feed Name </span> {{eventData[0]._source.feed_name}}</h6>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-lg-12 eventDetails-rev" *ngIf="!table2Loader">
            <div class="card card-body">
                <h6><span class="secondary-head-clr">Event Description :</span> {{eventData[0]._source.event_description}}</h6>

                <div class="reference-link-section" *ngIf="eventData[0]._source.reference_link">
                    <span class="secondary-head-clr">Reference Link</span>
                <a target="_blank" href="{{eventData[0]._source.reference_link}}">Click Here</a>
                </div>
            </div>
        </div>


        <!-- <div class="col-lg-12">
            <div class="card card-body h-95">
                
                <table datatable class="row-border hover scroll custWidth" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2">
                    <thead *ngIf="!tableLoader">
                        <tr>
                            <th>Indicator</th>
    
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of eventDescription">
                            <td>
                                <h3 class="card-title mt-0">Event Description:</h3>
                                <p class="card-text">{{item._source.event_description}}</p>
                            </td>
    
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->

        <div class="col-md-12">
            <div class="card card-min-height">
                <div class="card-body">
                    <h4 class="card-title sub-heading">IOC type</h4>
                    <p class="card-text  my-0 paragraph-clr">This panel depicts the various types of IOC’s encountered in various community feeds</p>
                    <!-- <div echarts [options]="attributeTypeChart" class="demo-chart" id="attributeTypePie"></div> -->
                    <apx-chart *ngIf="!isCommunityFeedLoading" id="mostAnomalousPieHost" dir="ltr"
                                class="apex-charts" [series]="iocTypeBar.series"
                                [chart]="iocTypeBar.chart" [legend]="iocTypeBar.legend"
                                [colors]="iocTypeBar.colors" [labels]="iocTypeBar.labels"
                                [dataLabels]="iocTypeBar.dataLabels"
                                [plotOptions]="iocTypeBar.plotOptions" [xaxis]="iocTypeBar.xaxis" [yaxis]="iocTypeBar.yaxis">
                    </apx-chart>
                </div>
            </div>
        </div>

        <!-- <div class="col-md-6">
            <div class="card card-min-height">
                <div class="card-body">
                    <h4 class="card-title sub-heading">IOC Types Timeline</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">This panel depicts the various types of IOC’s encountered in various community feeds over time</p>
                    <apx-chart class="apex-charts" dir="ltr" [series]="attributeTypes.series"
                        [chart]="attributeTypes.chart" [plotOptions]="attributeTypes.plotOptions"
                        [yaxis]="attributeTypes.yaxis" [grid]="attributeTypes.grid" [tooltip]="attributeTypes.tooltip"
                        [stroke]="attributeTypes.stroke" [dataLabels]="attributeTypes.dataLabels"
                        [xaxis]="attributeTypes.xaxis" [colors]="attributeTypes.colors" [fill]="attributeTypes.fill">
                    </apx-chart>
                </div>
            </div>
        </div> -->

        <!-- <div echarts [options]="attributeCategoryChart" class="demo-chart" id="attributePie"></div> -->
        <!-- <div class="col-md-6" id="contentToConvert2">
            <div class="card card-min-height">
                <div class="card-body">
                    <h4 class="card-title sub-heading">IOC category</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">This pannel depicts the different categories of IOC’s recived from  community</p>
                    <apx-chart id="feedDisribution" class="apex-charts" dir="ltr" *ngIf="!isEventDisribution1" [series]="feedDisribution.series"
                        [chart]="feedDisribution.chart" [plotOptions]="feedDisribution.plotOptions"
                        [yaxis]="feedDisribution.yaxis" [grid]="feedDisribution.grid"
                        [tooltip]="feedDisribution.tooltip" [stroke]="feedDisribution.stroke"
                        [dataLabels]="feedDisribution.dataLabels" [xaxis]="feedDisribution.xaxis"
                        [colors]="feedDisribution.colors" [fill]="feedDisribution.fill"></apx-chart>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-md-6">
            <div class="card card-min-height">
                <div class="card-body">
                    <h4 class="card-title sub-heading">IOC category Timeline</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">This pannel depicts the different categories of IOC’s recived from  community over time</p>
                    <apx-chart class="apex-charts" dir="ltr" [series]="attributeCategories.series"
                        [chart]="attributeCategories.chart" [plotOptions]="attributeCategories.plotOptions"
                        [yaxis]="attributeCategories.yaxis" [grid]="attributeCategories.grid"
                        [tooltip]="attributeCategories.tooltip" [stroke]="attributeCategories.stroke"
                        [dataLabels]="attributeCategories.dataLabels" [xaxis]="attributeCategories.xaxis"
                        [colors]="attributeCategories.colors" [fill]="attributeCategories.fill"></apx-chart>
                </div>
            </div>
        </div> -->

        <div class="col-md-12" id="contentToConvert2">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading">Indicators Table</h4>
                    <p class="card-text  my-0 pb-4 paragraph-clr">The below panel shows the various attributes of IOC's which have occurred according to various community feeds</p>
                    <h5 *ngIf="tableLoader" class="mb-0 text-center"><i
                            class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i></h5>
                    <table datatable class="row-border hover scroll custWidth" [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>IOC Value</th>
                                <th>IOC Type</th>
                                <!-- <th>IOC Category</th>
                                <th>IOC Comment</th> -->
                                <th>IOC id </th>
                                <!-- <th>Timestamp</th> -->


                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableResponseData">
                                <td>{{item._source.attribute_value}}</td>
                                <td>{{item._source.attribute_type | titlecase}}</td>
                                <!-- <td>{{item._source.attribute_category}}</td>
                                <td>{{item._source.attribute_comment}}</td> -->
                                <td>{{item._source.attribute_id}}</td>
                                <!-- <td>{{item._source.attribute_timestamp | date: 'medium'}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- <div class="col-lg-12" id="contentToConvert2" >
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">IOC type distribution</h4>
            <div echarts [options]="attributeTypeChart" class="demo-chart" id="attributeTypePie"></div>
        </div>
    </div>

    <div class="col-xl-6" *ngIf="!printClick">
        <h5 class="mb-0 text-center">
            <img src="assets/images/loadingbar.gif" width="100%">
        </h5>
    </div>
</div> -->


