<app-topbar></app-topbar>

<div class="container">
    <div class="row mt-top">
        <div class="col-6">
            <div class="page-title-box d-flex align-items-center justify-content-between py-2">
                <h4 class="mb-0 w-100">Correlation Studio</h4>
            </div>
        </div>
        <div class="col-md-6">
            <p class="text-end printBtn mb-0 d-flex justify-content-end align-items-end">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left"
                        aria-hidden="true"></i>
                    Back
                </button>
            </p>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-md-8 d-flex align-items-center">
            <!-- <app-pagetitle title="User name : {{userName}}"></app-pagetitle> -->
            <h5 *ngIf="isGeneral" class="mb-0">User name : {{userName}}</h5>
            <div *ngIf="!isGeneral">
                <div *ngIf="isUser" class="d-flex align-items-center">
                    <label>User&nbsp;:&nbsp;</label>
                    <select class="form-control" [(ngModel)]="selectedUser" (change)="callFunctions(selectedUser)">
                        <option>Select User</option>
                        <option *ngFor="let user of userList" [value]="user">{{user}}</option>
                    </select>
                </div>
                <div *ngIf="!isUser" class="d-flex align-items-center">
                    <label>Host&nbsp;:&nbsp;</label>
                    <select class="form-control" [(ngModel)]="selectedHost" (change)="callFunctions(selectedHost)">
                        <option>Select Host</option>
                        <option *ngFor="let host of hostList" [value]="host">{{host}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-right">
            <div class="btn-group" role="group" aria-label="Basic example" *ngIf="!correlationShow">
                <button type="button" class="btn" [ngClass]="{'btn-cor-active': isUser,'btn-cor-non-active': !isUser}"
                    (click)="isUser=true;callFunctions(selectedUser)">User</button>
                <button type="button" class="btn" [ngClass]="{'btn-cor-active': !isUser, 'btn-cor-non-active': isUser}"
                    (click)="isUser=false;callFunctions(selectedHost)">Host</button>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-12 pt-4">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">User name : {{userName}}</h4>
            </div>
        </div> -->
        <div class="col-md-6">
            <div class="card card-body h-95">
                <h4 class="card-title mb-4 mt-4">Most common detections</h4>
                <div class="d-flex ht-250" *ngIf="ismostCommonDetections"><img class="m-auto" width="200"
                        src="assets/images/fileloading.gif" /></div>
                <table class="table table-light table-bordered" *ngIf="!ismostCommonDetections">
                    <thead>
                        <tr>
                            <th class="col-1">No</th>
                            <th class="col-5">Detection Name</th>
                            <th class="col-1">Count</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of mostCommon; let i=index;">
                            <th scope="row">{{i+1}}</th>
                            <td>{{item.key}}</td>
                            <td>{{item.doc_count}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card card-body h-95">
                <h4 class="card-title mb-4 mt-4">Least common detections</h4>
                <div class="d-flex ht-250" *ngIf="isleastCommonDetections"><img class="m-auto" width="200"
                        src="assets/images/fileloading.gif" /></div>
                <table class="table table-light table-bordered" *ngIf="!isleastCommonDetections">
                    <thead>
                        <tr>
                            <th class="col-1">No</th>
                            <th class="col-5">Detection Name</th>
                            <th class="col-1">Count</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of leastCommon; let i=index;">
                            <th scope="row">{{i+1}}</th>
                            <td>{{item.key}}</td>
                            <td>{{item.doc_count}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col">
            <div class="card card-body bg-card">
                <h4 class="card-title m-4">Detection Count</h4>
                <div class="d-flex ht-250" *ngIf="istacticsDetected"><img class="m-auto" width="200"
                        src="assets/images/fileloading.gif" /></div>
                <apx-chart dir="ltr" class="apex-charts" [series]="tactics_chart.series" [chart]="tactics_chart.chart"
                    [legend]="tactics_chart.legend" [colors]="tactics_chart.colors" [labels]="tactics_chart.labels"
                    [stroke]="tactics_chart.stroke" [plotOptions]="tactics_chart.plotOptions">
                </apx-chart>
            </div>
        </div>
    </div>


    <div class="row mt-2">
        <div class="col">
            <div class="card card-body bg-card">
                <h4 class="card-title m-4">Investigation Type & Investigation Names</h4>
                <div class="row">
                    <div class="col-md-3">
                        <ul class="mitre_tab list-inline  d-flex justify-content-center align-items-center">
                            <li style="width: 100%;" *ngFor="let item of tabsList; let i=index;">
                                <div (click)="showTab(i);getTechniqNames(item, isUser ? this.selectedUser : this.selectedHost)"
                                    [ngClass]="{'text-success': item.show}">
                                    <a style="width: 100%;" href="javascript:void(0)">{{item.name}}({{item.count}})</a>
                                </div>
                            </li>
                        </ul>

                    </div>
                    <div class="col mt-md-2" *ngIf="tabsList.length>0">
                        <div class="col">
                            <h4 class="card-title mb-3">{{selectedTitle}}</h4>
                            <div *ngIf="isLoadingNames" class="text-center">
                                <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
                            </div>
                            <!-- <div class="indtechn" *ngFor="let item of techniqNames">
                                <a href="javascript:void(0)"
                                    (click)="getTechniqDetails(item, techniqDetails);">{{item.name}}
                                    ({{item.count}})</a>
                            </div> -->
                            <div *ngFor="let tab of tabsList; let i=index;">
                                <div *ngIf="tab.show">
                                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
                                        <ngb-panel *ngFor="let item of techniqNames; let i=index;" id="static-{{i}}">
                                            <ng-template ngbPanelTitle class="text-black">
                                                <a href="javascript:void(0)" class="acco-tab-txt"
                                                    (click)="getTechniqDetails(item, techniqDetails, isUser ? this.selectedUser : this.selectedHost);techDetails=[];">{{item.name}}
                                                    ({{item.count}})</a>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="col-md-12 mt-2">
                                                    <div>
                                                        <h5 class="text-center mt-2">{{selectedName}}</h5>
                                                    </div>
                                                    <div>
                                                        <div *ngIf="isLoadingDetails" class="text-center">
                                                            <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
                                                        </div>
                                                        <div *ngIf="!isLoadingDetails && techDetails.length==0 && selectedName!=''"
                                                            class="text-center text-danger">
                                                            No Details Found
                                                        </div>
                                                        <div class="table-responsive table-show"
                                                            *ngIf="techDetails.length>0 && !isLoadingDetails">
                                                            <table id="basic-datatable" class="table table-centered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Detection Time</th>
                                                                        <th>Detection Name</th>
                                                                        <th>Hunt Detection ID</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let tech of techDetails; let i=index">
                                                                        <td>{{tech.detection_time}}</td>
                                                                        <td>{{tech.detection_name}}</td>
                                                                        <td>{{tech.hunt_detection_id}}</td>
                                                                        <td>
                                                                            <a href="javascript:void(0);"
                                                                                class="mr-2 text-primary"
                                                                                ngbTooltip="Dashboard"
                                                                                (click)="viewDashbord(tech,tech.hunt_detection_id,tech.playbook_id)"><i
                                                                                    class="mdi mdi-monitor-eye font-size-18"></i>View Dashbord </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title m-4">Tactics & Techniques Detected</h4>
              <ngb-accordion #acc="ngbAccordion" activeIds="static-0" [destroyOnHide]='true' [closeOthers]="true">
                <ngb-panel *ngFor="let tab of tabsList; let i=index;" id="static-{{i}}">
                  <ng-template ngbPanelTitle class="text-black">
                    <a href="javascript:void(0)"  (click)="showTab(i);getTechniqNames(tab, isUser ? this.selectedUser : this.selectedHost);techDetails=[];selectedName='';"><b>{{tab.name}}({{tab.count}})</b></a>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row" *ngIf="tabsList.length>0">
                        <div class="col-md-12">
                            <div *ngIf="isLoadingNames" class="text-center">
                                <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
                            </div>
                            <div class="indtechn" *ngFor="let item of techniqNames">
                                <a href="javascript:void(0)"
                                    (click)="getTechniqDetails(item, techniqDetails, isUser ? this.selectedUser : this.selectedHost);techDetails=[];">{{item.name}}
                                    ({{item.count}})</a>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div>
                                <h5 class="text-center mt-2">{{selectedName}}</h5>
                            </div>
                            <div>
                                <div *ngIf="isLoadingDetails" class="text-center">
                                    <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
                                </div>
                                <div *ngIf="!isLoadingDetails && techDetails.length==0 && selectedName!=''" class="text-center text-danger">
                                    No Details Found
                                </div>
                                <div class="table-responsive table-show" *ngIf="techDetails.length>0 && !isLoadingDetails">
                                    <table id="basic-datatable" class="table table-centered">
                                        <thead>
                                            <tr>
                                                <th>Detection Time</th>
                                                <th>Detection Name</th>
                                                <th>Hunt Detection ID</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let tech of techDetails; let i=index">
                                                <td>{{tech.detection_time}}</td>
                                                <td>{{tech.detection_name}}</td>
                                                <td>{{tech.hunt_detection_id}}</td>
                                                <td>
                                                    <a href="javascript:void(0);" class="mr-2 text-primary" ngbTooltip="Dashboard"
                                                        (click)="viewDashbord(tech,tech.hunt_detection_id,tech.playbook_id)"><i
                                                            class="mdi mdi-monitor-eye font-size-18"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
 
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
    </div>
    </div> -->
    <!-- <div class="row">
        <div class="col">

            <div class="card card-body">
                <h4 class="card-title m-4">Tactics & Techniques Detected</h4>
                <div class="row">
                    <div id="chart-containertree"></div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row" style="display: none;">
        <div class="col">

            <div class="card card-body">
                <h4 class="card-title m-4">Tree Map</h4>
                <div class="row">
                    <div id="chart" class="w-75">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                            [title]="chartOptions.title" [colors]="chartOptions.colors"></apx-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #techniqDetails let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-center mt-0">{{selectedName}}</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
    </div>
    <div class="modal-body">
        <div *ngIf="isLoadingDetails" class="text-center">
            <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
        </div>
        <div *ngIf="!isLoadingDetails && techDetails.length==0" class="text-center text-danger">
            No Details Found
        </div>
        <div class="table-responsive table-show" *ngIf="techDetails.length>0 && !isLoadingDetails">
            <table id="basic-datatable" class="table table-centered">
                <thead>
                    <tr>
                        <th>Detection Time</th>
                        <th>Detection Name</th>
                        <th>Hunt Detection ID</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let tech of techDetails; let i=index">
                        <td>{{tech.detection_time}}</td>
                        <td>{{tech.detection_name}}</td>
                        <td>{{tech.hunt_detection_id}}</td>
                        <td>
                            <a href="javascript:void(0);" class="mr-2 text-primary" ngbTooltip="Dashboard"
                                (click)="viewDashbord(tech,tech.hunt_detection_id,tech.playbook_id)"><i
                                    class="mdi mdi-monitor-eye font-size-18"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('Close click');">Close</button>
    </div>
</ng-template>