import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { first } from 'rxjs/operators';
import { Subscriber } from 'rxjs/Subscriber';
import { SsoService } from './sso.service';

@Injectable({
  providedIn: 'root'
})
export class UrlHelperService {

  constructor(private http: HttpClient, private SsoService: SsoService) {
    this.setAuthorizationToken();
  }
  private _authorizationToken = null

  private setAuthorizationToken() {
    if (!this._authorizationToken) {
      this.SsoService.getSystemvArs().pipe(first())
        .subscribe((data: any) => {
          this._authorizationToken = data.variables?.find(v => v.name == "analytics-ui-token")?.value;
        })
    }
  }
  
  // "Cookie": "security_authentication = Fe26.2** 656e607a8126455d4cdce35876c065cc2455bcdde0a312223e5acbf1796c50ef*xJKvaiEBTAjs_ulGNgHf7w*wjEWUI0diw_43xntZLhn3pcZe7TbzXqOC5s1l0PuS5btu4Ci0eBWzYLi- PE0oRZQiUs3ya470wvQu59fvyPomkYA0soMJp67Rnz2nfEi - Xjd4gYR4hsIVYZ8jh75aBogA - 83kzD - v6Germ0BlN2cmy45vsWdy65NcZwBAZjaU_IDIekomTOXJA_K68CJ0gdBMZb7chOkyb_3ra4YCA3VlFWeQv_6i - RnBFdN7VtJmqtfkTtjv6PPbiC - IEpUY_Z0lPTOpESAnyTnEI7Xqpd6vg ** 05139bb0b652d8cb79fdcc875e9651741e80c797e0770d2d6513622d1b446848 * W0nHkaAz_m20_K0b0YH - PxQtQTUz2x4gSmfwVJmMlR4"
  get(url: any): Observable<any> {
    console.log("test")
    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      this.http
        .get(url, {
          headers: new HttpHeaders({
            "Authorization": this._authorizationToken,
            "Access-Control-Allow-Origin": "*",
            "ResponseType": 'text/plain'
          })
        })
        .subscribe((m: any) => {
         // objectUrl = URL.createObjectURL(m.blob());
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }
}
