import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const apiUrl = localStorage.getItem('cyboturl') + '/cybot_proxy';

const apiUrl1 = environment.apiSsoDataUrl;
@Injectable({
  providedIn: 'root'
})
export class HunterServicesService {
  HttpOptions: any;
  constructor(private http: HttpClient) { }
  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    }
    else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message ? error.message : 'No error message'}`;
    }
    console.log(error);
    return throwError(errorMessage);
  }
  huntObservablesSearch(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/hunt_observables*/_search?track_total_hits=true", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }
  hunt_group(data) {
    data = JSON.stringify(data);
    return this.http.post<object>(
      apiUrl + "/hunt_group*/_search?track_total_hits=true", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    })
      .pipe(catchError(this.handleError));
  }
  gethuntinfo(hunt_id) {
   
    let authorizationData: any =
      localStorage.getItem('access_token');
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + authorizationData
      })
    };
    return this.http
      .get<object>(
        apiUrl1 + "/hunt/gethuntinfo?huntid=" + hunt_id,
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));

  }
}
