<div class="card-body m-0 p-0">
    <div class="media">
        <div class="media-body overflow-hidden bg-card">
            <h4 class="count-card-title mb-2">{{data.title}}</h4>
            <div class="row">
                <!-- <div class="col-md-9">
                    <p>{{data.description}}</p>

                </div> -->
                <div class="col-md-12">
                    <div class="count-card h-100">
                        <!-- <h5 class="mb-0 py-3" style="font-size: 1.2rem;">{{count}}</h5> -->
                        <h5 class="mb-0 py-3" style="font-size: 1.2rem;">{{displayData(dataTemp)}}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-primary">
            <i class="{{data.icon}} font-size-24"></i>
        </div>
    </div>
</div>