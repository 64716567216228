<!-- <h4 class="pb-4 ">Automation Exceptions</h4> -->
<div class="tabbarbtnbox">
    <button type="button " *ngIf="editFlag" class="btn btn-success " (click)="addExceptions() ">
        Create Exceptions
    </button>
</div>
<!-- <div class="pb-2 text-right ">
    <div class="col-md-6 f-l pb-2 p-l-0 "></div>
    <div class="col-md-8 f-r pb-2 createBtn ">
        
    </div>
</div> -->
<div class="gif-css col-md-12 " [ngClass]="{ dis_on: loader, dis_off: !loader } ">
    <img src="assets/images/fileloading.gif " />
</div>
<div [ngClass]="{ dis_on: !loader, dis_off: loader } " class="table-responsive ">
    <table id="datatable1" datatable class="row-border hover scroll " [dtOptions]="dtOptions "
        [dtTrigger]="dtTrigger3 ">
        <thead>
            <tr>
                <th *ngIf="type=='Investigate'">Playbook Id</th>
                <th *ngIf="type=='Investigate'">Playbook Name</th>
                <th *ngIf="type=='UEBA'">UEBA Id</th>
                <th *ngIf="type=='UEBA'">Detection Name</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Comment</th>
                <th class="w-10p ">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of tableData3; index as i ">
                <td *ngIf="type=='Investigate'">{{ item.playbook_id }}</td>
                <td *ngIf="type=='Investigate'">{{ item.playbook_name }}</td>
                <td *ngIf="type=='UEBA'">{{ item.ueba_id }}</td>
                <td *ngIf="type=='UEBA'">{{ item.detection_name }}</td>
                <td>{{ item.created_by }}</td>
                <td>{{ item.created_at | date: "dd/MM/yyyy h:mm a " }}</td>
                <td [ngStyle]="{'color': !item.exception_comment ? 'red' : 'white'}">{{ item.exception_comment ? item.exception_comment : '--' }}</td>
                <td>
                    <a href="javascript:void(0); " *ngIf="editFlag" style="padding: 0 10px;" class="fa fa-edit font-size-18 text-warning "
                        ngbTooltip="Edit " (click)="editExceptions(item, i) "><i
                            class="mdi mdi-pencil-box-outline font-size-18 "></i></a>
                    <a href="javascript:void(0); "  *ngIf="editFlag" style="padding: 0 10px;" class="fa fa-trash font-size-18 text-danger "
                        ngbTooltip="Delete " (click)="deleteExceptions(item, i) "><i
                            class="mdi mdi-delete font-size-18 "></i></a>
                    <a href="javascript:void(0); " style="padding: 0 10px;" class="fa fa-info font-size-18 "
                        ngbTooltip="view " (click)="openModal(viewModal, item, 4) "><i
                            class="mdi mdi-file-eye font-size-18 "></i></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>


<ng-template #viewModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{Details}}</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click ')">
            ×
        </button>
    </div>
    <div class="modal-body p-3 pb-4">
        <div class="row" *ngIf="modalId == 4">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Comment : <br /><b>{{ selectedContent.comment }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created At : <br /><b>{{ selectedContent.created_at }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Created By: <br /><b>{{ selectedContent.created_by }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Host Name: <br /><b>{{ selectedContent.host_name }}</b>
                    </p>
                </div>
                <!-- <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Id: <br /><b>{{ selectedContent.playbook_id }}</b>
                    </p>
                </div> -->
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Playbook Name: <br /><b>{{ selectedContent.playbook_name }}</b>
                    </p>
                </div>
                <!-- <div class="col-md-12">
                    <p class="card-title-desc">
                        Source Ip: <br /><b>{{ selectedContent.source_ip }}</b>
                    </p>
                </div> -->
                <div class="col-md-12">
                    <p class="card-title-desc">
                        User Name: <br /><b>{{ selectedContent.user_name }}</b>
                    </p>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="modalId == 5">
            <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->
            <div id="contentToConvert3">
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Api Name : <b>{{ selectedContent.api_name }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Method : <b>{{ selectedContent.method }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        header: <b>{{ selectedContent.header }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        body: <b>{{ selectedContent.body }}</b>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        Url: <b>{{ selectedContent.url }}</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>