<app-topbar>
</app-topbar>
<div class="page-content huntingModule">

<div class="container">
    <!-- <app-pagetitle title="ActivBytes Threat Feeds" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
    <!-- <a href="javascript:void(0);" class="" ngbTooltip="view" (click)="openModal(viewModal,1)"><i
        class="fa fa-eye font-size-18"></i> Print</a> -->
    <!-- <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
        <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
        <button class="btn btn-secondary" (click)="openModal(viewModal,1)"><i class="ri-printer-line"></i>Print</button>
        <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>

        <button class="btn btn-warning" (click)="openModal(viewModal,1)" [disabled]="isLoading"><i class="fa fa-print"
                [ngClass]="{'fa-spin fa-spinner': isLoading, 'ri-printer-line': !isLoading}"></i> {{button}}</button>

    </p> -->

    <div class="col-md-12 p-0">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h3 class="mb-0">ActiveBytes Intelligence</h3>
            <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
                <button class="btn btn-themePrint" (click)="openModal(viewModal,1)" [disabled]="isLoading"><i class="fa fa-print"
                    [ngClass]="{'fa-spin fa-spinner': isLoading, 'ri-printer-line': !isLoading}"></i> {{button}}</button>
                    
            </p>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-lg-12 pb-2">
            <div class="card card-body">
                <h3 class="card-title mt-0 sub-heading">ActiveBytes Intelligence overview</h3>
                <p class="card-text paragraph-clr">ActiveBytes threat feeds contain rich information about threat events,
                    vulnerabilities, and exploits along with recent news headlines, from curated sources. This can help
                    in keeping up to date with the latest developments in the cyber-space.
                </p>
            </div>
        </div>

        <div class="col-md-12 pb-2">
            <div class="card card-body ">
                <h4 class="card-title mb-4 sub-heading">Distribution of event categories</h4>
                <p class="card-text  my-0 pb-4 paragraph-clr">This pannel shows the event categories which is being fetched by Activebytes Innovations-Threat Intelligence Services</p>
                <!--   <apx-chart id="activePieChart" class="apex-charts" dir="ltr" [series]="activePieChartData.series" [chart]="activePieChartData.chart" [labels]="activePieChartData.labels" [legend]="activePieChartData.legend" [colors]="activePieChartData.colors" [responsive]="activePieChartData.responsive">
                </apx-chart> -->
                <div class="d-flex pabs100 mt-9em"><img class="m-auto" width="200" *ngIf="isActivePie"
                        src="assets/images/fileloading.gif" /></div>
                <div echarts [options]="activePieChartData" class="demo-chart" id="activePieChart"></div>
            </div>
        </div>

        <div class="col-md-12 pb-2">
            <div class="card card-body h-95">
                <h4 class="card-title mb-4 sub-heading">Distribution of event categories over time</h4>
                <p class="card-text  my-0 pb-4 paragraph-clr">This panel shows the categories of events fetched by Activebytes Innovations-Threat Intelligence Service over time</p>

                <!-- <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart" [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid" [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
                    [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors" [fill]="basicColumChart.fill">
                </apx-chart> -->
                <div class="d-flex pabs100 mt-9em"><img class="m-auto" width="200" *ngIf="isEventDisribution"
                        src="assets/images/fileloading.gif" /></div>
                <apx-chart id="eventDisribution" class="apex-charts" dir="ltr" *ngIf="!isEventDisribution" [series]="eventDisribution.series"
                    [chart]="eventDisribution.chart" [plotOptions]="eventDisribution.plotOptions"
                    [yaxis]="eventDisribution.yaxis" [grid]="eventDisribution.grid" [tooltip]="eventDisribution.tooltip"
                    [stroke]="eventDisribution.stroke" [dataLabels]="eventDisribution.dataLabels"
                    [xaxis]="eventDisribution.xaxis" [colors]="eventDisribution.colors" [fill]="eventDisribution.fill">
                </apx-chart>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-lg-6">
            <!-- <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Security Incidents Intelligence</h4>
            </div> -->
            <div class="card card-body h-95">
                <h4 class="card-title mb-4 sub-heading">Security Incidents Intelligence</h4>
                <p class="card-text paragraph-clr">This panel shows the recent news related to cybersecurity, they are split into two
                    categories, one for showing general news which maybe important from a business perspective and the
                    other which shows security events, including recent vulnerabilities,
                    patches etc. which may be important from a technical perspective. For a more detailed view of the
                    events, click the link beside this panel.
                </p>

                

                <!-- bottom section  -->

                <div class="row countRow countRowBG">
                    <div class="text-center" *ngIf="isCountData"><img class="m-auto" width="200"
                            src="assets/images/fileloading.gif" /></div>
                    <div *ngIf="!isCountData" class="col-md-12 row">
                        <div *ngFor="let stat of countsData" class="col-md-6  cout-btn-styles">
                            <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
                        </div>
                    </div>
                </div>


                <!-- <a href="javascript: void(0);" [routerLink]="['/security-incidents']"
                    class="btn btn-primary mt-auto ml-auto cst-button primary-btn-style">View Dashboard <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> -->
            </div>
        </div>

        <div class="col-lg-6">
            <!-- <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Vulnerabilities Intelligence</h4>
            </div> -->
            <div class="card card-body h-95">
                
                <div class="top-section">
                    <h4 class="card-title mb-4 sub-heading">Vulnerabilities Intelligence</h4>
                <p class="card-text paragraph-clr">This panel shows the recent vulnerability events, they are split into two
                    categories, one for showing vulnerabilities and another for showing vulnerabilities with exploits.
                    Both are important but exploits have to be identified, prioritized
                    and patched in a more time sensitive manner. For a more detailed view of the events, click the link
                    beside this panel.
                </p>
                </div>


                <!-- bottom section -->

                <!-- <div class="row countRow countRowBG">
                    <div class="text-center" *ngIf="isCountData"><img class="m-auto" width="200"
                            src="assets/images/fileloading.gif" /></div>
                    <div *ngIf="!isCountData" class="col-md-12 row">
                        <div *ngFor="let stat of countsData" class="col-md-6  cout-btn-styles">
                            <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
                        </div>
                    </div>
                </div> -->

                <div class="bottom-section">
                    <div class="row countRow countRowBG">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto" width="200"
                                src="assets/images/fileloading.gif" /></div>
                        <div *ngIf="!isVulnerabilities" class="col-md-12 row strict-mb">
                            <div *ngFor="let stat of vulCountData" class="col-md-6">
                                <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- <a href="javascript: void(0);" [routerLink]="['/vulnerabilities-summary']"
                    class="btn mt-auto ml-auto cst-button primary-btn-style">View Dashboard <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a> -->
            </div>
        </div>
    </div>
</div>

<ng-template #viewModal let-modal>
    <!-- <div class="modal-header">
        <h5 class="modal-title">Print Preview</h5>
        <p class="text-right pb-2 printBtn p-m-b-0">
            <button class="btn btn-secondary" (click)="captureScreenModelPrint()"><i class="ri-printer-line"></i>Print</button>
        </p>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>



    </div> -->

    <div class="modal-header ngCustModal">

        <!-- (click)="captureScreenModelPrint()" -->
        <div class="col-xl-12">
            
            <div class="col-xl-12 printMsg">
                <div class="col-xl-6" *ngIf="printClick">
                    <h5 class="modal-title">Generate PDF</h5>

                    <button class="btn btn-danger" (click)="modal.dismiss('Cross click')">
                        Cancel
                    </button>

                    <button class="btn btn-primary" (click)="captureScreenModelPrint()">
                        <i class="ri-printer-line"></i> Generate
                    </button>
                </div>
                <div class="col-xl-6" *ngIf="!printClick">
                    <h5 class="mb-0 text-center">
                        <img src="assets/images/loadingbar.gif" width="100%">
                    </h5>
                </div>
            </div> 
            <div class="col-xl-12" style="margin-top: 14px;">
                <!-- <p>
                        <ngb-progressbar type="success" textType="white" [value]="25" [showValue]="true"></ngb-progressbar>
                    </p> -->
                <!-- <ngb-progressbar showvalue="true" *ngIf="progressbar">
                        <div class="progress">
                            <div role="progressbar" aria-valuemin="0" class="progress-bar" aria-valuenow="{{progressbarData}}" [style.width]="progressbarData+'%'" aria-valuemax="100"><span>{{progressbarData}}%</span>
                            </div>
                        </div>
                    </ngb-progressbar> -->
            </div>
        </div>


        <!-- <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button> -->
    </div>
    <div class="modal-body p-3 pb-4 op-cass">
        <div id="contentToConvert">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card card-body p-m-b-0">
                        <h2 class="card-title mt-0">Community Threat Feeds overview</h2>
                        <p class="card-text paragraph-clr-dark">ActivBytes threat feeds contain rich information about threat events,
                            vulnerabilities, and exploits along with recent news headlines, from curated sources. This
                            can help in keeping up to date with the latest developments in the
                            cyber-space.
                        </p>
                    </div>
                </div>


                <div class="col-lg-12">
                    <div class="card card-body h-95 p-m-b-0">
                        <h4 class="card-title mb-4">Distribution of event categories over time</h4>

                        <!-- <apx-chart class="apex-charts" dir="ltr" [series]="basicColumChart.series" [chart]="basicColumChart.chart" [plotOptions]="basicColumChart.plotOptions" [yaxis]="basicColumChart.yaxis" [grid]="basicColumChart.grid" [tooltip]="basicColumChart.tooltip" [stroke]="basicColumChart.stroke"
                    [dataLabels]="basicColumChart.dataLabels" [xaxis]="basicColumChart.xaxis" [colors]="basicColumChart.colors" [fill]="basicColumChart.fill">
                </apx-chart> -->
                        <div class="d-flex pabs100"><img class="m-auto" width="200" *ngIf="isEventDisribution"
                                src="assets/images/fileloading.gif" /></div>
                        <apx-chart id="eventDisribution" class="apex-charts" dir="ltr"
                            [series]="eventDisribution.series" [chart]="eventDisribution.chart"
                            [plotOptions]="eventDisribution.plotOptions" [yaxis]="eventDisribution.yaxis"
                            [grid]="eventDisribution.grid" [tooltip]="eventDisribution.tooltip"
                            [stroke]="eventDisribution.stroke" [dataLabels]="eventDisribution.dataLabels"
                            [xaxis]="eventDisribution.xaxis" [colors]="eventDisribution.colors"
                            [fill]="eventDisribution.fill"></apx-chart>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="card card-body ">
                        <h4 class="card-title mb-4">Distribution of event categories</h4>
                        <!--   <apx-chart id="activePieChart" class="apex-charts" dir="ltr" [series]="activePieChartData.series" [chart]="activePieChartData.chart" [labels]="activePieChartData.labels" [legend]="activePieChartData.legend" [colors]="activePieChartData.colors" [responsive]="activePieChartData.responsive">
                        </apx-chart> -->
                        <div class="d-flex pabs100"><img class="m-auto" width="200" *ngIf="isActivePie"
                                src="assets/images/fileloading.gif" /></div>
                        <div echarts [options]="activePieChartData" class="demo-chart" id="activePieChart"></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="contentToConvert1">

            <div class="row">

                <div class="col-lg-12">
                    <!-- <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Security Incidents Intelligence</h4>
            </div> -->
                    <div class="card card-body h-95">
                        <h4 class="card-title mb-4">Security Incidents Intelligence</h4>
                        <p class="card-text paragraph-clr-dark">This panel shows the recent news related to cybersecurity, they are split
                            into two categories, one for showing general news which maybe important from a business
                            perspective and the other which shows security events, including
                            recent vulnerabilities, patches etc. which may be important from a technical perspective.
                            For a more detailed view of the events, click the link beside this panel.
                        </p>
                        <div class="row countRow countRowBG">
                            <div *ngFor="let stat of countsData" class="col-md-6">
                                <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
                            </div>
                        </div>
                        <!-- <a href="javascript: void(0);" [routerLink]="['/security-incidents']" class="btn btn-primary mt-auto ml-auto cst-button">View Dashboard <i class="ri-arrow-right-line align-middle ml-2"></i></a> -->
                    </div>
                </div>

                <div class="col-lg-12">
                    <!-- <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Vulnerabilities Intelligence</h4>
            </div> -->
                    <div class="card card-body h-95">
                        <h4 class="card-title mb-4">Vulnerabilities Intelligence</h4>
                        <p class="card-text paragraph-clr-dark">This panel shows the recent vulnerability events, they are split into two
                            categories, one for showing vulnerabilities and another for showing vulnerabilities with
                            exploits. Both are important but exploits have to be identified,
                            prioritized and patched in a more time sensitive manner. For a more detailed view of the
                            events, click the link beside this panel.
                        </p>
                        <div class="row countRow countRowBG">
                            <div *ngFor="let stat of vulCountData" class="col-md-6">
                                <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
                            </div>
                        </div>
                        <!-- <a href="javascript: void(0);" [routerLink]="['/vulnerabilities-summary']" class="btn btn-primary mt-auto ml-auto cst-button">View Dashboard <i class="ri-arrow-right-line align-middle ml-2"></i></a> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-template>

</div>