<section class="shop-reg-wrapper">
    <div class="row" style="margin-right: 0px;">
        <div class="col-md-12" style="    overflow-y: auto;">


            <div class="container-fluid p-0 view-tiles" style="min-height: 75vh; display: flex; justify-content: center;">
                <div class="col-md-8 d-flex justify-content-center align-items-center mgg">
                    <div class="row w-100">
                        <div class="col-md-4 text-center d-flex  mg justify-content-center align-items-center">
                            <!-- <div class="col-md-10" > -->

                            <a href="javascript: void(0);" class="card items" (click)="dashboards()">
                                <div class="overlay"></div>
                                <div class="circle">

                                    <img width="71px" height="76px" viewBox="29 14 71 76" version="1.1"
                                        src="assets/images/seo.png">

                                </div>
                                <p>My Dashboards</p>
                            </a>





                            <!-- <a href="javascript: void(0);" style="width: 300px;" (click)="dashboards()">
                                <div class="leftCard">
                                    <img src="assets/images/egg-hunt.png">
                                    <h5 class="mt-4 mb-2 text-white"><i class="mdi mdi-check-all mr-3"></i>
                                        My Dashboards</h5>

                                </div>
                            </a> -->
                            <!-- </div> -->
                        </div>
                        <div class="col-md-4 text-center d-flex  mg justify-content-center align-items-center">
                            <!-- <div class="col-md-10" > -->

                            <a href="javascript: void(0);" class="card items" (click)="dashboardsList()">
                                <div class=" overlay">
                        </div>
                        <div class="circle">

                            <img width="71px" height="76px" viewBox="29 14 71 76" version="1.1"
                                src="assets/images/dashboard.png">

                        </div>
                        <p>Dashboards List</p>
                        </a>


                        <!-- <a href="javascript: void(0);" style="width: 300px;" ">
                                <div class="leftCard">
                                    <img src="assets/images/download.png">
                                    <h5 class="mt-4 mb-2 text-white"><i class="mdi mdi-check-all mr-3"></i>
                                        Dashboards List</h5>

                                </div>
                            </a> -->
                        <!-- </div> -->
                    </div>
                    <div class="col-md-4 text-center d-flex justify-content-center align-items-center">
                        <!-- <div class="col-md-10"> -->


                        <a href="javascript: void(0);" class="card items"  (click)="settings()">
                            <div class=" overlay">
                            </div>
                            <div class="circle">

                                <img width="71px" height="76px" viewBox="29 14 71 76" version="1.1"
                                    src="assets/images/settings.png">

                            </div>
                            <p>Settings</p>
                        </a>
                        <!-- <a href="javascript: void(0);" style="width: 300px;" (click)="settings()" class="">
                            <div class="leftCard">
                                <img src="assets/images/artificial-intelligence.png">
                                <h5 class="mt-4 mb-2 text-white"><i class="mdi mdi-bullseye-arrow mr-3"></i>
                                    Settings</h5>

                            </div>
                        </a> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>


    </div>
    </div>
</section>