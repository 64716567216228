<app-topbar></app-topbar>
<div class="col-md-12 mt-top">
    <p class="text-end printBtn mb-0 d-flex justify-content-end align-items-end">
        <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            Back
        </button>
    </p>
</div>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-10 mb-4">
            <div class="page-title-box d-flex align-items-center justify-content-between text-center">
                <h2 class="mb-0 w-100 ">{{group_id!='undefined'?'Grouped Detections':'Repetitions'}}&nbsp;</h2>
            </div>
            <p class="text-center" *ngIf="group_id=='undefined'">This page shows the repetitions of detections which contain identical observables. The identical
                observables
                are
                shown
                below along with a timeline of the detections (Only the first detection within 24h will be investigated
                to avoid
                redundancy)</p>
        </div>
       
        <div class="col-lg-12" *ngIf="huntSummaryData.length>0">
            <div class="timeline customeTimeLine" dir="ltr">
                <div class="timeline-item timeline-left">
                    <div class="timeline-block">
                        <div class="time-show-btn mt-0">
                            <div>
                                <ul class="list-unstyled repetationList" *ngIf="group_id=='undefined'">

                                    <li><span>User </span>: {{this.huntSummaryData[0].user}}</li>
                                    <li><span>Host </span>: {{this.huntSummaryData[0].host}}</li>
                                    <li><span>IP </span>: {{this.huntSummaryData[0].ip}}</li>
                                    <li><span>File </span>: {{this.huntSummaryData[0].file}}</li>
                                    <li><span>Hash </span>: {{this.huntSummaryData[0].hash}}</li>
                                    <li><span>Url </span>: {{this.huntSummaryData[0].url}}</li>
                                </ul>
                                <ul class="list-unstyled repetationList" *ngIf="group_id!='undefined'">
                                    <li *ngFor="let field of groupedField  | keyvalue"><span>{{field.key | titlecase}} </span>: {{field.value}}</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="timeline-item" *ngFor="let data of huntSummaryData"
                    [ngClass]="{'timeline-left': data.align === 'left'}">
                    <div class="timeline-block">
                        <div class="timeline-box card">
                            <div class="card-body">
                                <span class="timeline-icon"></span>
                                <div class="timeline-date">
                                    <i class="mdi mdi-circle-medium circle-dot"></i>
                                    {{data.time_stamp| date: 'dd:MMM:yyyy'}}&nbsp;|&nbsp;{{data.time_stamp| date: 'hh:mm:ss'}}
                                </div>
                                <a  [ngClass]="{'lt': data.align === 'left'}" class="mr-2 text-secondary" (click)="viewCybotDashbord(data)" ngbTooltip="Go to Dashboard">
                                    <img src="assets/images/dashboard-analysis.png" alt="logo" class="repetation_dashboard">
                                </a>

                                <div class="text-muted">
                                    <p class="mb-0">Host : {{data.host_name}}</p>
                                    <p class="mb-0">User : {{data.user_name}}</p>
                                    <p class="mb-0">Detection Name : {{data.detection_name}}</p>
                                    <p class="mb-0">Detection Id : {{data.playbook_id}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>