import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/core/services/common.service";
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { SettingsService } from "src/app/core/services/settings.service";
const url = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
@Component({
  selector: 'app-add-integrations',
  templateUrl: './add-integrations.component.html',
  styleUrls: ['./add-integrations.component.scss']
})
export class AddIntegrationsComponent implements OnInit {

  @Input() objArr: any = [];
  organizationList = [];
  addSoar: FormGroup;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  err = false;
  passwordShow = false;
  btnName = "Create";
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder
  ) { }
  submit = false
  soarCreation;
  soarUpdate;
  Soar;
  ngOnInit(): void {
    this.addSoar = this.formBuilder.group({
      count: ["", Validators.required],
      api_name: ["", Validators.required],
      body: ["", [Validators.required, this.isJson]],
      header: ["", [Validators.required, this.isJson]],
      method: ["", Validators.required],
      url: ["", [Validators.required, Validators.pattern('((https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-])|((https?://)?([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})(?:/)?(?:[^\\s]*))$')]],
      tenant_id: [""],
    });
    this.addSoar.valueChanges.subscribe(() => {
      this.err = false;
    });
    this.getLicenseDetailsList();
    this.btnName = this.objArr[2];
    if (this.objArr[0] == "Soar Edit") {
      this.addSoar.controls["count"].setValue(this.objArr[1].count);
      this.addSoar.controls["api_name"].setValue(this.objArr[1].api_name);
      this.addSoar.controls["body"].setValue(this.objArr[1].body);
      this.addSoar.controls["header"].setValue(this.objArr[1].header);
      this.addSoar.controls["method"].setValue(this.objArr[1].method);
      this.addSoar.controls["url"].setValue(this.objArr[1].url);
      this.addSoar.controls["tenant_id"].setValue(this.objArr[1].tenant_id);
    } else {
      this.addSoar.controls["tenant_id"].setValue(this.objArr[5]);
    }
  }

  isJson(control: AbstractControl) {
    try {
      JSON.parse(control.value);
    } catch (e) {
      return { invalidJson: true };
    }
    return null;
  }

  exceptionsCreation() {
    this.submit = true
    if (!this.addSoar.valid) {
      return
    }
    let a = ["thp_exceptions_" + this.addSoar.value.tenant_id];
    let data;
    let tenantID = this.addSoar.value.tenant_id;
    if (this.objArr[0] == "Soar Edit") {
      let id = this.objArr[1].id;
      data = {
        count: this.addSoar.value.count,
        api_name: this.addSoar.value.api_name,
        body: this.addSoar.value.body,
        header: this.addSoar.value.header,
        method: this.addSoar.value.method,
        url: this.addSoar.value.url,
        tenant_id: this.addSoar.value.tenant_id,
      };
      let d1 = {
        doc: data,
      };
      this.settingsService.soarUpdate(d1, tenantID, id).subscribe(
        (response: any) => {
          console.log("response", response);
          if (response.result == "updated") {
            let data = {
              count: this.addSoar.value.count,
              api_name: this.addSoar.value.api_name,
              id: response._id,
              body: this.addSoar.value.body,
              header: this.addSoar.value.header,
              index: response._index,
              type: response._type,
              method: this.addSoar.value.method,
              url: this.addSoar.value.url,
            };

            console.log("data", data);
            this.modalService.dismissAll({
              status: "update",
              data: data,
              index: this.objArr[3],
            });
          } else {
            this.err = true;
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      data = {
        count: this.addSoar.value.count,
        api_name: this.addSoar.value.api_name,
        body: this.addSoar.value.body,
        header: this.addSoar.value.header,
        method: this.addSoar.value.method,
        url: this.addSoar.value.url,
        tenant_id: this.addSoar.value.tenant_id,
      };
      this.settingsService.soarCreation(data, tenantID).subscribe(
        (response: any) => {
          console.log("response", response);
          if (response.result == "created") {
            let data = {
              id: response._id,
              index: response._index,
              type: response._type,
              count: this.addSoar.value.count,
              api_name: this.addSoar.value.api_name,
              body: this.addSoar.value.body,
              header: this.addSoar.value.header,
              method: this.addSoar.value.method,
              url: this.addSoar.value.url,
              tenant_id: this.addSoar.value.tenant_id,
            };
            this.modalService.dismissAll({
              status: "created",
              data: data,
            });
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }
  clearForm() {
    this.modalService.dismissAll({
      status: "not-created",
    });
  }

  getLicenseDetailsList() {
    let data = {
      query: {
        match_all: {},
      },
    };
    this.settingsService.getLicenseDetailsList(data).subscribe(
      (response: any) => {
        let data = response.hits.hits;
        this.organizationList = [];
        data.forEach((item) => {
          let t = {
            start_date: item._source.start_date,
            end_date: item._source.end_date,
            id: item._id,
            tenant_id: item._source.tenant_id,
            organization_name: item._source.organization_name,
            index: item._index,
          };
          this.organizationList.push(t);
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }
}
