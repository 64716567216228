import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ChartType } from '../dashboard/dashboard.model';
import { revenueChart, salesAnalytics } from '../dashboard/data';

@Component({
  selector: 'app-stat-count',
  templateUrl: './stat-count.component.html',
  styleUrls: ['./stat-count.component.scss']
})
export class StatCountComponent implements OnInit {
  data: any;
  revenueChart: ChartType;
  term: any;
  titleArray: any;
  api: any;
  query: any = '';
  method: any;
  dataTemp: any = []
  expectedValues: any
  datas: any;
  series: any = [];
  labels: any = [];
  type: any = []
  colors: any = []
  loding: boolean = true;
  count
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
  }
  constructor(public dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.query = this.query;
    this.dashboardService.dashboardApi(this.query, this.api, this.method).subscribe(
      (result: any) => {
        // console.log("app-stat-count", result)
        this.loding = true;
        if (result) {
          let data = result.hits.total
          // console.log("app-stat-count", data)
          this.count = data.value
          // console.log("app-stat-count", this.count)
          this.loding = false;
        }
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
