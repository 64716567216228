<h4 class="card-title mb-2">{{datas.name}}</h4>
<div>{{datas.titleBody}}</div>
<div *ngIf="!loding">
  <!-- <apx-chart class="apex-charts" dir="ltr" [series]="datas.series"
    [chart]="datas.chart" [plotOptions]="datas.plotOptions"
    [labels]="datas.labels" [colors]="datas.colors">
  </apx-chart> -->

  <div echarts [options]="gauge" class="demo-chart" id="gauge"></div>

</div>
<div class="loader" *ngIf="loding"></div>