<div class="card-body p-0 m-0">
    <div class="media">
        <div class="media-body overflow-hidden">
            <h4 class="count-card-title mb-2">{{data.title}}</h4>
            <!-- <p>{{data.description}}</p> -->
            <div class="card-outer p-0 m-0">
                <div echarts [options]="hashThreatGauge" style="max-height: 260px;" class="demo-chart" id="hashGaugeChart-{{component}}"></div>
            </div>
        </div>
        <div class="text-primary">
            <i class="{{data.icon}} font-size-24"></i>
        </div>
    </div>
</div>