import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import { Observable, Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/core/services/common.service";
import jspdf from "jspdf";
import html2canvas from "html2canvas";

import {
  BarChartType,
  ChartType,
  Stat,
  EchartType,
} from "../../pages/community/view-dashboard.model";
import { DatePipe } from "@angular/common";
import * as moment from 'moment';
import * as localization from 'moment/locale/fr';
import { ActivatedRoute, Router } from "@angular/router";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";
moment.updateLocale('fr', localization);

@Component({
  selector: "app-management-summary",
  templateUrl: "./management-summary.component.html",
  styleUrls: ["./management-summary.component.scss"],
})
export class ManagementSummaryComponent implements OnInit {
  constructor(public common: ThreatIntelligenceService, private router: Router, private modalService: NgbModal,
    private datePipe: DatePipe, private route:ActivatedRoute) { }
    
    
    query
    public filteredSeverity = [];
    headlineSeach = ''

    dataTableSearch = ''
    p: number = 1;
    p2: number = 1;
    p3:number = 1;

    severityCounts:any = [
      {
        low: 0,
        medium: 0,
        high: 0,
        critical: 0
      },
      {
        low: 0,
        medium: 0,
        high: 0,
        critical: 0
      },{
        low: 0,
        medium: 0,
        high: 0,
        critical: 0
      }
    ]

  feedDistributionData = [];
  securityEventData = [];
  headlinesData = [];
  vulnerabilitiesData:any = [];
  vulnerabilitiesExploitData = [];
  IOCCommunityfeedsData = [];
  communityIocsData = [];
  selectedContent: any;
  modalId: number;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 5,
    lengthMenu: [5, 10, 25],
    processing: true,
    order: [],
    searching: false
  };
  dtTriggerSecurity: Subject<any> = new Subject<any>();
  dtTriggerHeadlines: Subject<any> = new Subject<any>();
  dtTriggerVulnerabilities: Subject<any> = new Subject<any>();
  dtTriggerVulnerabilitiesExploit: Subject<any> = new Subject<any>();
  dtTriggerCommunityIOCs: Subject<any> = new Subject<any>();

  countries = [];
  printClick: boolean = true;
  button = 'Save Pdf';
  isLoading = true;

  stats = {
    vulnerabilities: 0,
    vulnerabilities_exploit: 0,
    security_events: 0,
    headlines: 0,
  };
  period = {
    gte: '',
    lte: ''
  };

  total$: Observable<number>;
  service = {
    page: 1,
    pageSize: 4,
  };

  breadCrumbItems: Array<{}>;


  // ///////////////////////////// Security Events and Vulnerabilities

  countsData: Stat[] = [
    {
        icon: 'ri-stack-line',
        title: 'Security Events',
        value: '0'
    }, {
        icon: 'ri-store-2-line',
        title: 'Headlines',
        value: '0'
    }];

    vulCountData: Stat[] = [
    {
        icon: 'ri-stack-line',
        title: 'Vulnerabilities',
        value: '0'
    }, {
        icon: 'ri-store-2-line',
        title: 'Vulnerabilities with exploit',
        value: '0'
    }];

    // ///////////////////////////

  vset: Stat[] = [
    {
      icon: "ri-stack-line",
      title: "Vulnerabilities",
      value: "5",
    },
    {
      icon: "ri-stack-line",
      title: "Vulnerabilities with exploit",
      value: "10",
    },
  ];

  cSet: Stat[] = [
    {
      icon: "ri-stack-line",
      title: "Community IOCs",
      value: "10",
    },
  ];

  donughnutChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Defense Evasion", "Privilege Escalation"],
      textStyle: { color: "#8791af" },
    },
    color: ["#556ee6", "#f1b44c"],
    series: [
      {
        name: "Tactics detected",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 20, name: "Defense Evasion" },
          { value: 70, name: "Privilege Escalation" },
        ],
      },
    ],
  };
  criticalobservables: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Hash", "IP", "URL"],
      textStyle: { color: "#8791af" },
    },
    color: ["#ff3d60", "#4aa3ff", "#fcb92c"],
    series: [
      {
        name: "Critical rated observables",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 4, name: "Hash" },
          { value: 2, name: "IP" },
          { value: 3, name: "URL" },
        ],
      },
    ],
  };

  highratedobservables: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Hash", "IP", "URL"],
      textStyle: { color: "#8791af" },
    },
    color: ["#bc2c46", "#5664d2", "#d69100"],
    series: [
      {
        name: "high rated observables",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 3, name: "Hash" },
          { value: 2, name: "IP" },
          { value: 0, name: "URL" },
        ],
      },
    ],
  };

  basicColumChart: ChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#5664d2", "#1cbb8c", "#fcb92c", "#ff3d60"],
    series: [
      {
        name: "Event",
        data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
      },
      {
        name: "News",
        data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
      },
      {
        name: "Vulnerability",
        data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
      },
      {
        name: "Exploit",
        data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
      },
    ],
    xaxis: {
      categories: [
        "00.00",
        "10.00",
        "14.00",
        "18.00",
        "20.00",
        "21.00",
        "22.00",
        "23.00",
        "24.00",
      ],
      title: {
        text: "attribute_timestamp per 12 hours",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  eventDistributionData: ChartType = {
    chart: {
      height: 400,
      type: "donut",
    },
    series: [],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    labels: [],
    colors: ["#1cbb8c", "#5664d2", "#fcb92c", "#4aa3ff"],
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 10,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  criticalrb: BarChartType = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: ["#ff3d60", "#4aa3ff", "#fcb92c"],
    series: [
      {
        name: "Hash",
        data: [46],
      },
      {
        name: "IP",
        data: [74],
      },
      {
        name: "URL",
        data: [37],
      },
    ],
    xaxis: {
      categories: ["All"],
      title: {
        text: "All docs",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return "Count :  " + val;
        },
      },
    },
  };

  feedDisribution: ChartType = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '45%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: ['#5664d2', '#1cbb8c', '#fcb92c', '#8de762'],
    series: [{
      name: "CIRCL",
      data: []
    }, {
      name: "Activebytes Innovations - Threat Intelligence Services",
      data: []
    }],
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: '#f1f1f1'
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return 'Count' + val + '..';
        }
      }
    }
  };

  simplePieChart1: ChartType = {
    chart: {
      height: 320,
      type: "pie",
    },
    series: [44, 55, 41],
    labels: ["Hash", "IP", "URL"],
    colors: ["#1cbb8c", "#5664d2", "#fcb92c"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  ngOnInit(): void {
    if (localStorage.getItem('isDateRange') == "true") {
      this.period = {
        gte: localStorage.getItem('startDate'),
        lte: localStorage.getItem('endDate')
      }
    } else {
      this.period = {
        gte: localStorage.getItem('period'),
        lte: 'now'
      }
    }
    this.breadCrumbItems = [
      { label: "Home" },
      { label: "Management Summary", active: true },
    ];
    this.getFeedDistribution();
    this.getEventDistribution();
    this.getVulnerabilities();
    this.getVulnerabilitiesExploit();
    this.getSecurityEvents();
    this.getHeadlines();
    this.getSecurityEventData();
    this.getHeadlinesData();
    // this.getVulnerabilitiesData();
    this.getVulnerabilitiesExploitData();
    this.getIOCCommunityfeedsData();
    this.getFeedDistributionBar();  
    this.getVulnerabilitiesDataResponse();  
  }

  vulFilter = []
  vulnerExploit = []
  secuEvents = []
  checkingSeverityFlag = ''

  filterDatas(filterVal:string){

    let getAllFilterBtns = document.querySelectorAll('.filter-sev-btn')
    
    getAllFilterBtns.forEach((btn)=>{
      btn.addEventListener('click', function(e){
        getAllFilterBtns.forEach((button)=>{
          button.classList.remove('active-filter')
        })
      })
    })

  
    this.vulFilter = [...this.vulnerData]
    this.vulnerExploit = [...this.vulnerabilitiesExploitData]
    this.secuEvents = [...this.securityEventData]

    if(filterVal === 'Low'){
      this.vulFilter = this.vulFilter.filter(item=> item.severity === "Low" ||  item.severity === "LOW")
      this.vulnerExploit = this.vulnerExploit.filter(item=> item.severity === "Low")
      this.secuEvents = this.secuEvents.filter(item=> item.severity === "Low")
      this.checkingSeverityFlag = 'Low'
    }else if(filterVal === 'Medium'){
      this.vulFilter = this.vulFilter.filter(item=> item.severity === "Medium" || item.severity === "MEDIUM")
      this.vulnerExploit = this.vulnerExploit.filter(item=> item.severity === "Medium")
      this.secuEvents = this.secuEvents.filter(item=> item.severity === "Medium")
      this.checkingSeverityFlag = 'Medium'
    }else if(filterVal === 'High'){
      this.vulFilter = this.vulFilter.filter(item=> item.severity === "High" || item.severity === "HIGH")
      this.vulnerExploit = this.vulnerExploit.filter(item=> item.severity === "High")
      this.secuEvents = this.secuEvents.filter(item=> item.severity === "High")
      this.checkingSeverityFlag = 'High'
    }else if(filterVal === 'Critical'){
      this.vulFilter = this.vulFilter.filter(item=> item.severity === "Critical" || item.severity === "CRITICAL")
      this.vulnerExploit = this.vulnerExploit.filter(item=> item.severity === "Critical")
      this.secuEvents = this.secuEvents.filter(item=> item.severity === "Critical")
      this.checkingSeverityFlag = 'Critical'
    }else{
      this.vulFilter
      this.vulnerExploit
      this.secuEvents
    }
  }



  getFeedDistribution() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
      aggs: {
        "2": {
          date_histogram: {
            field: "attribute_timestamp",
            calendar_interval: "1d",
            time_zone: "Asia/Kolkata",
            min_doc_count: 1,
          },
          aggs: {
            "3": {
              terms: { field: "feed_name", order: { _count: "desc" }, size: 5 },
            },
          },
        },
      },
    };
    this.common.getFeedDistribution(data).subscribe((response: any) => {
      if (response) {
      }
    });
    (error) => {
    };
  }

  isEventDistribution = false;

  getEventDistribution() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [
            {
              bool: {
                should: [
                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            feed_name: "CTI"
                          }
                        }
                      ],
                      minimum_should_match: 1
                    }
                  },
                  {
                    bool: {
                      must: [
                        {
                          bool: {
                            should: [
                              {
                                match_phrase: {
                                  feed_name: "Activebytes Innovations - Threat Intelligence Services"
                                }
                              }
                            ],
                            minimum_should_match: 1
                          }
                        },
                        {
                          bool: {
                            should: [
                              {
                                exists: {
                                  field: "event_category"
                                }
                              }
                            ],
                            minimum_should_match: 1
                          }
                        }
                      ]
                    }
                  }
                ],
                minimum_should_match: 1
              }
            }
          ],
          filter: [],
          should: [],
          must_not: []
        }
      },
      aggs: {
        "2": {
          terms: {
            field: "event_category",
            order: { _count: "desc" },
            size: 5,
          },
        },
      },
    };
    this.isEventDistribution = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      if (response) {
        this.isEventDistribution = false;
        let buckets = response.aggregations[2].buckets;
        if (buckets.length > 0) {
          let series = [];
          let labels = [];
          for (let i in buckets) {
            labels.push(buckets[i].key);
            series.push(buckets[i].doc_count);
          }
          this.eventDistributionData.series = series;
          this.eventDistributionData.labels = labels;
        } else {
          document.getElementById('eventDistributionData').innerHTML = "<div class='mhbar d-flex text-danger'><div class='m-auto'>Not Enough Data</div></div>";

        }
      }
    });
    (error) => {
      this.isEventDistribution = false;
    };
  }

  isVulnerabilities = false;
  getVulnerabilities() {
    let data = {
      size: 100,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name: "Activebytes Innovations - Threat Intelligence Services",
              },
            },
            { match_phrase: { event_category: "Vulnerability" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.isVulnerabilities = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      this.isVulnerabilities = false;
      if (response) {
        // this.stats.vulnerabilities = response.hits.total.value
        // this.vulCountData[0].value = response.hits.total.value;
      }
    });
    (error) => {
      this.isVulnerabilities = false;
    };
  }

  isVulnerabilitiesExploit = false;
  getVulnerabilitiesExploit() {
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [
            {
              bool: {
                must: [
                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            feed_name:
                              "Activebytes Innovations - Threat Intelligence Services",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [
                        {
                          match_phrase: {
                            event_category: "Exploit",
                          },
                        },
                      ],

                      minimum_should_match: 1,
                    },
                  },

                  {
                    bool: {
                      should: [],

                      minimum_should_match: 1,
                    },
                  },
                ],
              },
            },
          ],

          filter: [],

          should: [],

          must_not: [],
        },
      },
    };
    this.isVulnerabilitiesExploit = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      this.isVulnerabilitiesExploit = false;
      if (response) {
        // this.stats.vulnerabilities_exploit = response.hits.total.value;
        this.vulCountData[1].value = response.hits.total.value;
      }
    });
    (error) => {
      this.isVulnerabilitiesExploit = false;
    };
  }

  isSecurity = false;
  getSecurityEvents() {
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name: "Activebytes Innovations - Threat Intelligence Services",
              },
            },
            { match_phrase: { event_category: "Event" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.isSecurity = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      this.isSecurity = false;
      if (response) {
        // this.stats.security_events = response.hits.total.value;
        // this.countsData[0].value = response.hits.total.value
      }
    });
    (error) => {
      this.isSecurity = false;
    };
  }

  isHeadlines = false;
  getHeadlines() {
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name: "CTI",
              },
            },
            { match_phrase: { event_category: "News" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.isHeadlines = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      this.isHeadlines = false;
      if (response) {
        // this.stats.headlines = response.hits.total.value;
        // this.countsData[1].value = response.hits.total.value;
      }
    });
    (error) => {
      this.isHeadlines = false;
    };
  }

  isSecurityEventData = false;
  getSecurityEventData() {
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name: "Activebytes Innovations - Threat Intelligence Services",
              },
            },
            { match_phrase: { event_category: "Event" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.isSecurityEventData = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      this.isSecurityEventData = false;
      if (response) {
        let hits = response.hits.hits;
        this.securityEventData = [];
        for (let i = 0; i < hits.length; i++) {
          this.securityEventData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            comments: hits[i]._source.comments,
            reference_link: hits[i]._source.reference_link,
            severity: hits[i]._source.severity,
            tlp: hits[i]._source.tlp,
            attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
            location: hits[i]._source.location.split(","),
          });
        }

        if(this.securityEventData && this.securityEventData.length){
          this.securityEventData = this.securityEventData.reverse();
        }

        this.secuEvents = [...this.securityEventData]
        this.stats.security_events = response.hits.total.value;

        // filtering severity

        let filterLow =  this.securityEventData.filter((sev)=> sev.severity === 'Low')
        this.severityCounts[2].low = filterLow.length

        let filterMedium =  this.securityEventData.filter((sev)=> sev.severity === 'Medium')
        this.severityCounts[2].medium = filterMedium.length

        let filterHigh = this.securityEventData.filter((sev)=> sev.severity === "High")
        this.severityCounts[2].high = filterHigh.length

        let filterCritical = this.securityEventData.filter((sev)=> sev.severity === "Critical")
        this.severityCounts[2].critical = filterCritical.length

        this.isLoading = false
        this.dtTriggerSecurity.next();
      }
    });
    (error) => {
      this.isSecurityEventData = false;
    };
  }

  isHeadlinesData = false;
  getHeadlinesData() {
    let data1 = {
      size: 10000,
      query: {
        bool: {
          must: [],
          filter: [
            { match_all: {} },
            {
              match_phrase: {
                feed_name:
                  "CTI",
              },
            },
            { match_phrase: { event_category: "News" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
      aggs: {
        "1": {
          top_hits: {
            docvalue_fields: [
              {
                field: "attribute_timestamp",
                format: "date_time",
              },
            ],
            _source: "attribute_timestamp",
            size: 1,
            sort: [{ attribute_timestamp: { order: "desc" } }],
          },
        },
      },
    };
    this.isHeadlinesData = true;
    this.common.getCommunityFeedChart(data1).subscribe((response: any) => {
      this.isHeadlinesData = false;
      if (response) {
        this.stats.headlines = response.hits.total.value;
        this.countsData[1].value = response.hits.total.value;
        let hits = response.hits.hits;
        this.headlinesData = [];
        for (let i = 0; i < hits.length; i++) {
          this.headlinesData.push({
            attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
            event_category: hits[i]._source.event_category,
            event_description: hits[i]._source.event_description,
            event_id: hits[i]._source.event_id,
            event_name: hits[i]._source.event_name,
            feed_name: hits[i]._source.feed_name,
            news: hits[i]._source.news,
            reference_link: hits[i]._source.reference_link,
            severity: hits[i]._source.severity,
            tlp: hits[i]._source.tlp,
          });
        }

        if(this.headlinesData && this.headlinesData.length){
          this.headlinesData = this.headlinesData.reverse();
        }

        this.dtTriggerHeadlines.next();
      }
    });
    (error) => {
      this.isHeadlinesData = false;
    };
  }

// new vulnerabilities data

 // checking new vulner url response
//  newVulnerData = []
//  allSeverity
//  vulnerTimestamp: [] = []

//  isVulnerabilitiesData = false; 
//  getVulnerabilitiesDataResponse(){
//    this.isVulnerabilitiesData = true;
//    this.common.getVulnerabilitiesData().subscribe((response:any)=>{
//      this.isVulnerabilitiesData = false;
//      if(response){
//        let vulner = response.vulnerabilities
  
//        for(let i=0; i<=vulner.length; i++){
//         this.newVulnerData.push({
//           cve : vulner[i].cve.id,
//           event_description : vulner[i].cve.descriptions[0].value,
//           severity : vulner[i].cve.metrics.cvssMetricV2[0].baseSeverity,
//           reference_link : vulner[i].cve.references,
//           version : vulner[i].cve.metrics.cvssMetricV2[0].cvssData.version,
//           base_score : vulner[i].cve.metrics.cvssMetricV2[0].cvssData.baseScore,
//           timestamp : vulner[i].cve.lastModified      
//         })

//         this.stats.vulnerabilities = this.newVulnerData.length
        

//         this.newVulnerData.sort((a,b)=>{
//           let date1 = new Date(a.timestamp);
//           let date2 = new Date(b.timestamp);
//           return date1.getFullYear() - date2.getFullYear();
//         }).reverse()

//         let filterLow = this.vulFilter.filter((item)=> item.severity === 'LOW')
//         this.severityCounts[0].low = filterLow.length

//         let filterMedium = this.vulFilter.filter((item)=> item.severity === 'MEDIUM')
//         this.severityCounts[0].medium = filterMedium.length

//         let filterHigh = this.vulFilter.filter((item)=> item.severity === 'HIGH')
//         this.severityCounts[0].high = filterHigh.length

//         let filterCritical = this.vulFilter.filter((item)=> item.severity === 'CRITICAL')
//         this.severityCounts[0].critical = filterCritical.length

//        }
      
//      }
     
//    })
 
//    this.vulFilter = this.newVulnerData
//  }


// vulnerabilities

vulnerData = []

isVulnerabilitiesData = false; 
getVulnerabilitiesDataResponse(){
  this.isVulnerabilitiesData = true
  this.common.getVulnerabilitiesTotalData().subscribe((response:any)=>{
    this.isVulnerabilitiesData = false
    if(response){
      this.stats.vulnerabilities = response.totalResults
      let vulnerabilitiesAllData = response.vulnerabilities 
      this.vulnerData = []
      vulnerabilitiesAllData.forEach(element => {
        this.vulnerData.push({
        cve_id : element.cve.id,
        event_description : element.cve.descriptions[0].value,
        published_date : this.dateConverter(element.cve.published),
        reference_link : element.cve.references[0]?.url,
        severity : element.cve.metrics.cvssMetricV31?.[0].cvssData.baseSeverity,
        vectorString : element.cve.metrics.cvssMetricV2?.[0].cvssData.vectorString,
        attack_vector : element.cve.metrics.cvssMetricV2?.[0].cvssData.attackVector,
        attack_complexity : element.cve.metrics.cvssMetricV2?.[0].cvssData.attackComplexity,
        privileges_Required : element.cve.metrics.cvssMetricV2?.[0].cvssData.privilegesRequired,
        confidentiality_Impact : element.cve.metrics.cvssMetricV2?.[0].cvssData.confidentialityImpact,
        integrity_Impact : element.cve.metrics.cvssMetricV2?.[0].cvssData.integrityImpact,
        availability_Impact : element.cve.metrics.cvssMetricV2?.[0].cvssData.availabilityImpact,
        base_Score : element.cve.metrics.cvssMetricV2?.[0].cvssData.baseScore,
        exploitability_Score : element.cve.metrics.cvssMetricV2?.[0].exploitabilityScore,
        impact_Score : element.cve.metrics.cvssMetricV2?.[0].impactScore
        })
      });
      this.vulFilter = [...this.vulnerData].reverse()
      // for(let i = 0; i<=vulnerabilitiesAllData.length; i++ ){
      //  this.vulnerData.push({
      //   cve_id : vulnerabilitiesAllData[i].cve.id,
      //   event_description : vulnerabilitiesAllData[i].cve.descriptions[0].value,
      //   published_date : vulnerabilitiesAllData[i].cve.published,
      //   reference_link : vulnerabilitiesAllData[i].cve.references[0],
      //   severity : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV31?.[0].cvssData.baseSeverity,
      //   vectorString : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.vectorString,
      //   attack_vector : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.attackVector,
      //   attack_complexity : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.attackComplexity,
      //   privileges_Required : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.privilegesRequired,
      //   confidentiality_Impact : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.confidentialityImpact,
      //   integrity_Impact : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.integrityImpact,
      //   availability_Impact : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.availabilityImpact,
      //   base_Score : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].cvssData.baseScore,
      //   exploitability_Score : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].exploitabilityScore,
      //   impact_Score : vulnerabilitiesAllData[i].cve.metrics.cvssMetricV2?.[0].impactScore
      //  })

      //  this.vulFilter = [...this.vulnerData]
      // }
      
    }
  });
  (error)=>{
    this.isVulnerabilitiesData = false
  }

  // LOW severity
  this.common.getSingleSeverityCounts('LOW').subscribe((response:any)=>{
        if(response){
          this.severityCounts[0].low = response.totalResults
        }
  })

  // Medium severity
  this.common.getSingleSeverityCounts('MEDIUM').subscribe((response:any)=>{
    if(response){
      this.severityCounts[0].medium = response.totalResults
    }
  })

  // High severity
  this.common.getSingleSeverityCounts('HIGH').subscribe((response:any)=>{
    if(response){
      this.severityCounts[0].high = response.totalResults
    }
  })

  // Critical severity
  this.common.getSingleSeverityCounts('CRITICAL').subscribe((response:any)=>{
    if(response){
      this.severityCounts[0].critical = response.totalResults
    }
  })
}



  isVulnerabilitiesExploitData = false;
  getVulnerabilitiesExploitData() {
    let data = {
      size: 10000,
      query: {
        bool: {
          must: [],

          filter: [
            {
              match_phrase: {
                feed_name: "Activebytes Innovations - Threat Intelligence Services",
              },
            },
            { match_phrase: { event_category: "Exploit" } },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
    
          should: [],
    
          must_not: []
    
        }
    
      }
    };
    this.isVulnerabilitiesExploitData = true;
    this.common.getCommunityFeedChart(data).subscribe((response: any) => {
      this.isVulnerabilitiesExploitData = false;
      if (response) {
        this.stats.vulnerabilities_exploit = response.hits.total.value;
        this.vulCountData[1].value = response.hits.total.value;
        let hits = response.hits.hits;
        this.vulnerabilitiesExploitData = [];
        for (let i = 0; i < hits.length; i++) {
          this.vulnerabilitiesExploitData.push({
            event_name: hits[i]._source.event_name,
            event_description: hits[i]._source.event_description,
            cve: hits[i]._source.cve,
            cvss: hits[i]._source.cvss,
            tlp: hits[i]._source.tlp,
            vendor: hits[i]._source.vendor,
            severity: hits[i]._source.severity,
            attribute_timestamp: hits[i]._source.attribute_timestamp,
            // location: hits[i]._source.location.split(","),
          });
        }

        let sortedTimeStamp  = this.vulnerabilitiesExploitData.sort((a,b):any=>{

          let dateA = new Date(a.attribute_timestamp * 1000);
          let dateB = new Date(b.attribute_timestamp * 1000);

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        }).reverse()

        this.vulnerExploit = [...this.vulnerabilitiesExploitData]

        // if(this.vulnerabilitiesExploitData && this.vulnerabilitiesExploitData.length){
        //   this.vulnerabilitiesExploitData = this.vulnerabilitiesExploitData.reverse();
        // }

        // if (this.vulnerabilitiesExploitData && this.vulnerabilitiesExploitData.length) {
        //   this.vulnerabilitiesExploitData = this.vulnerabilitiesExploitData.sort((a, b) =>
        //     a.attribute_timestamp > b.attribute_timestamp ? -1 : 1
        //   );
        // }

        // filtering severity
        this.stats.vulnerabilities_exploit = response.hits.hits.length;

        let filterLow =  this.vulnerabilitiesExploitData.filter((sev)=> sev.severity === 'Low')
        this.severityCounts[1].low = filterLow.length

        let filterMedium =  this.vulnerabilitiesExploitData.filter((sev)=> sev.severity === 'Medium')
        this.severityCounts[1].medium = filterMedium.length

        let filterHigh = this.vulnerabilitiesExploitData.filter((sev)=> sev.severity === "High")
        this.severityCounts[1].high = filterHigh.length

        let filterCritical = this.vulnerabilitiesExploitData.filter((sev)=> sev.severity === "Critical")
        this.severityCounts[1].critical = filterCritical.length

        this.dtTriggerVulnerabilitiesExploit.next();
      }
    });
    (error) => {
      this.isVulnerabilitiesExploitData = false;
    };
  }
 
  openModal(viewmodal: any, content: any, modalId: number) {
    if (content.location) {
      this.countries = [['Country']];
    
      for (let i= 0 ; i<=content.location.length; i++) {
        this.countries.push([content.location[i]]);
      }
    
      
    }else{
      this.countries = []
    }
    this.modalId = modalId;
    this.selectedContent = content;
    this.modalService.open(viewmodal, { size: "lg", centered: true });
  }

  captureScreen() {
    var data = document.getElementById("contentToConvert");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen1() {
    var data = document.getElementById("contentToConvert1");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen2() {
    var data = document.getElementById("contentToConvert2");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }
  captureScreen3() {
    var data = document.getElementById("contentToConvert3");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }

  captureScreen4() {
    var data = document.getElementById("contentToConvert4");
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      var imgWidth = 200;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
      var img = new Image()
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 50, 18)
      pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
      pdf.save(this.selectedContent.event_name+".pdf"); // Generated PDF
    });
  }

  isIOCCommunityfeedsData = false;
  getIOCCommunityfeedsData() {
    let data = {
      size: 500,
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_all: {},
            },
            {
              range: {
                attribute_timestamp: {
                  gte: this.period.gte,
                  lte: this.period.lte,
                  format: "strict_date_optional_time",
                },
              },
            },
          ],
          should: [],
          must_not: [
            {
              match_phrase: {
                feed_name: "Activebytes Innovations - Threat Intelligence Services",
              },
            },
          ],
        },
      },
    };
    this.isIOCCommunityfeedsData = true;
    this.common.getIOCCommunityfeeds(data).subscribe((response: any) => {
      this.isIOCCommunityfeedsData = false;
      if (response) {
        let hits = response.hits.hits;
        this.IOCCommunityfeedsData = [];
        for (let i = 0; i < hits.length; i++) {
          this.IOCCommunityfeedsData.push({
            event_name: hits[i]._source.event_name,
            feed_name: hits[i]._source.feed_name,
            event_id: hits[i]._source.event_id,
            event_description: hits[i]._source.event_description,
            id: hits[i]._id,
            index: hits[i]._index,
            type: hits[i]._type,
            attribute_category: hits[i]._source.attribute_category,
            attribute_comment: hits[i]._source.attribute_comment,
            attribute_id: hits[i]._source.attribute_id,
            attribute_timestamp: this.timeConvert1(hits[i]._source.attribute_timestamp),
            attribute_type: hits[i]._source.attribute_type,
            attribute_value: hits[i]._source.attribute_value,
            event_category: hits[i]._source.event_category,
            severity: hits[i]._source.severity,
            tlp: hits[i]._source.tlp,
          });
        }
        this.dtTriggerCommunityIOCs.next();
      }
    });
    (error) => {
      this.isIOCCommunityfeedsData = false;
    };
  }

  isFeedDistribution = false;

  getFeedDistributionBar() {
    let data = {
      "size": 0,
      "aggs": {
        "2": {
          "date_histogram": {
            "field": "attribute_timestamp",
            "calendar_interval": "1d",
            "time_zone": "Asia/Dubai",
            "min_doc_count": 1
          },
          "aggs": {
            "3": {
              "terms": {
                "field": "feed_name",
                "order": {
                  "_count": "desc"
                },
                "size": 100
              }
            }
          }
        }
      },
      "query": {
        "bool": {
          "must": [],
          "filter": [
            {
              "match_all": {}
            },
            {
              "range": {
                "attribute_timestamp": {
                  "gte": this.period.gte,
                  "lte": this.period.lte,
                  "format": "strict_date_optional_time"
                }
              }
            }
          ],
          "should": [],
          "must_not": []
        }
      }
    };
    this.isFeedDistribution = true;
    this.common.getCommunityFeedChart(data).subscribe(
      (response: any) => {
        if (response) {
          this.isFeedDistribution = false;
          let dataSeries = [...this.feedDisribution.series];

          for (let k in this.feedDisribution.series) {
            dataSeries[k].data = new Array(response.aggregations[2].buckets.length).fill(0);
          }

          for (let i = 0; i < response.aggregations[2].buckets.length; i++) {
            this.feedDisribution.xaxis.categories.push(moment.unix(response.aggregations[2].buckets[i].key / 1000).format('DD/MM/YYYY'));
            for (let j in response.aggregations[2].buckets[i][3].buckets) {
              for (let k in this.feedDisribution.series) {
                if (dataSeries[k].name == response.aggregations[2].buckets[i][3].buckets[j].key) {
                  dataSeries[k].data[i] = response.aggregations[2].buckets[i][3].buckets[j].doc_count;
                }
                dataSeries[k].data = Array.from(dataSeries[k].data, item => item || 0);
              }
            }
          }
          this.feedDisribution.series = dataSeries;
          if (response.aggregations[2].buckets.length == 0) {
            document.getElementById('feedDisribution').innerHTML = "<div class='mhbar d-flex text-danger'><div class='m-auto'>Not Enough Data</div></div>";
          }
        }
      },
      error => {
        this.isFeedDistribution = false;
      }
    );
  }
  captureScreenModelPrint() {

    if (this.modalId == 1) {
      this.captureScreen()
    } else if (this.modalId == 2) {
      this.captureScreen1()
    } else if (this.modalId == 3) {
      this.captureScreen2()
    } else if (this.modalId == 4) {
      this.captureScreen3()
    } else if (this.modalId == 5) {
      this.captureScreen4()
    }
  }

  ngOnDestroy():void {
    this.dtTriggerVulnerabilities.unsubscribe()
  }

  openModalPrint(viewmodal:any, modalId: number) {

    // this.printPdf()
    this.modalId = modalId;
    this.modalService.open(viewmodal, { size: "lg printCustModal", centered: true, });
  }


  async printPdf() {
    this.isLoading = true
    this.printClick = false;
    let r = 50
    let l = 35
    let ml = 20
    let st = 20
    let ll = 165
    let sl = 27
    let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
    var img = new Image()
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 75, 130, 50, 18)
    let period = localStorage.getItem('period');
    let startDate = localStorage.getItem('startDate');
    let endDate = localStorage.getItem('endDate');
    pdf.setFontSize(11);
    pdf.setFont(undefined, 'bold');
    pdf.text(startDate + "  -  " + endDate, 77, 170);

    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(12.5);
    pdf.setTextColor(0, 0, 0);
    pdf.text('INDEX', 90, 30);
    pdf.setFontSize(12.5);
    pdf.text('1. Summary', 20, 40);

    pdf.text('2. Security Events', ml, r);
    for (let k = 0; k < this.securityEventData.length; k++) {
      pdf.setFontSize(10);
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.text("2." + (k + 1), l - 9, r);
      // pdf.circle(32, r - 1, .75, 'S')
      pdf.text(this.securityEventData[k].event_name, l, r);
    }
    pdf.setFontSize(12.5);
    r = r + 10;

    pdf.text('3. Headlines', ml, r);
    for (let k = 0; k < this.securityEventData.length; k++) {
      pdf.setFontSize(10);
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      // pdf.circle(32, r - 1, .75, 'S')
      pdf.text("3." + (k + 1), l - 9, r);
      pdf.text(this.securityEventData[k].event_name, l, r);
    }
    pdf.setFontSize(12.5);
    r = r + 10;

    pdf.text('4. Vulnerabilites', ml, r);
    for (let k = 0; k < this.vulnerabilitiesData.length; k++) {
      pdf.setFontSize(10);
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      // pdf.circle(32, r - 1, .75, 'S')
      pdf.text("4." + (k + 1), l - 9, r);
      pdf.text(this.vulnerabilitiesData[k].event_name, l, r);
    }
    pdf.setFontSize(12.5);
    r = r + 10;

    pdf.text('5. Vulnerabilities with exploit', ml, r);
    for (let k = 0; k < this.vulnerabilitiesExploitData.length; k++) {
      pdf.setFontSize(10);
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      // pdf.circle(32, r - 1, .75, 'S')
      pdf.text("5." + (k + 1), l - 9, r);
      pdf.text(this.vulnerabilitiesExploitData[k].event_name, l, r);
    }
    pdf.setFontSize(12.5);
    r = r + 10;

    pdf.text('6. Community IOCs', ml, r);

    for (let k = 0; k < this.IOCCommunityfeedsData.length; k++) {
      pdf.setFontSize(10);
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      // pdf.circle(32, r - 1, .75, 'S')
      pdf.text("6." + (k + 1), l - 9, r);
      pdf.text(this.IOCCommunityfeedsData[k].event_name, l, r);
    }


    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    pdf.setTextColor(0, 0, 0)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(14);
    r = 30
    l = 20
    pdf.setFont(undefined, 'bold');
    pdf.text('1.Summary', ml, r);
    pdf.setFont(undefined, 'normal');

    r = r + 8;
    pdf.setFontSize(10);
    var strArr = pdf.splitTextToSize("This report consists of Threat intelligence information exclusively collected by Activbytes threat feeds.These include a curated list of threat intelligence information like recent security events,headlines that are hot in the cybersecurity / information security space.Also recent vulnerability information along with their severity and cvss information.", ll)
    pdf.text(strArr, ml, r);
    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    pdf.setTextColor(0, 0, 0)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(14);
    r = 30
    l = 20
    pdf.setFont(undefined, 'bold');
    pdf.text('2.Security Events', ml, r);
    pdf.setFont(undefined, 'normal');
    r = r + 8;
    pdf.setFontSize(10);
    var strArr = pdf.splitTextToSize("Security events include information regarding some trending security events, these can be recently found vulnerabilities or security breaches that took place in other organizations or patches to the same and much more.", ll)
    pdf.text(strArr, ml, r);
    r = r + 18
    for (let k = 0; k < this.securityEventData.length; k++) {
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.securityEventData[k].event_name, ll)
      let rtemp = r + 4.75 * (strArr.length) + 7;
      if (rtemp > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFont(undefined, 'bold');
      pdf.text('2.' + (k + 1), l, r);
      pdf.setFontSize(11);
      pdf.text(strArr, st + 8, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7 * (strArr.length);
      // let data = document.getElementById("securityChart" + k);
      // let canvas: any
      // try {
      //   canvas = await html2canvas(data)
      // }
      // catch (e) {
      //   canvas = null
      // }
      // // Few necessary setting options
      // const imgWidth = 200;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // const contentDataURL = canvas.toDataURL("image/png");
      // pdf.addImage(contentDataURL, "PNG", 5, r, imgWidth, imgHeight);
      // r = r + imgHeight;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.securityEventData[k].event_description, ll)
      rtemp = r + 4.75 * (strArr.length) + 7;
      if (rtemp > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFont(undefined, 'bold');
      pdf.text('Description:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      pdf.setFontSize(10);
      pdf.text(strArr, sl, r);
      r = r + 4.75 * (strArr.length);
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      if (this.securityEventData[k].comments.length > 0) {
        pdf.setFontSize(10);
        pdf.setFont(undefined, 'bold');
        let tr = r + 7;
        if (tr > 273) {
          pdf.addPage();
          pdf.setTextColor(68, 68, 68);
          pdf.setFontSize(9);
          pdf.text("Threat Intelligence Report", 5, 10)
          pdf.setTextColor(0, 0, 0)
          img.src = 'assets/images/logo-dark.png'
          pdf.addImage(img, 'png', 150, 5, 45, 15)
          r = 30
        }
        pdf.text('Comments:', sl, r);
        pdf.setFont(undefined, 'normal');
        r = r + 7;
        pdf.setFontSize(10);
        for (let p = 0; p < this.securityEventData[k].comments.length; p++) {
          pdf.setFontSize(10);
          if (r > 273) {
            pdf.addPage();
            pdf.setTextColor(68, 68, 68);
            pdf.setFontSize(9);
            pdf.text("Threat Intelligence Report", 5, 10)
            pdf.setTextColor(0, 0, 0)
            img.src = 'assets/images/logo-dark.png'
            pdf.addImage(img, 'png', 150, 5, 45, 15)
            r = 30
          }
          var strArr = pdf.splitTextToSize(this.securityEventData[k].comments[p], ll)
          pdf.setTextColor(0, 0, 0);
          pdf.text(strArr, sl, r);
          // pdf.setFillColor(101, 101, 101,0);
          // pdf.rect(st + 2, r-4, 170,  7 * (strArr.length)-5, "F");
          r = r + 4.5 * (strArr.length);
        }

        r = r + 4;
      } else {
        r = r + 4;
      }
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont(undefined, 'bold');
      pdf.text('Reference Link:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      for (let j = 0; j < this.securityEventData[k].reference_link.length; j++) {
        pdf.setFontSize(10);
        if (r > 273) {
          pdf.addPage();
          pdf.setTextColor(68, 68, 68);
          pdf.setFontSize(9);
          pdf.text("Threat Intelligence Report", 5, 10)
          pdf.setTextColor(0, 0, 0)
          img.src = 'assets/images/logo-dark.png'
          pdf.addImage(img, 'png', 150, 5, 45, 15)
          r = 30
        }
        pdf.circle(29, r - 1, .75, 'S')
        var strArr = pdf.splitTextToSize(this.securityEventData[k].reference_link[j], ll)
        pdf.setTextColor(0, 0, 255);
        pdf.text(strArr, sl + 6, r);
        r = r + 4 * (strArr.length);
        pdf.setTextColor(0, 0, 0);
      }
      pdf.setTextColor(0, 0, 0);
      pdf.line(20, r, 195, r, 'S');
      r = r + 7
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
    }
    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    pdf.setTextColor(0, 0, 0)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(14);
    r = 30
    pdf.setFont(undefined, 'bold');
    pdf.text('3.Headlines', ml, r);
    pdf.setFont(undefined, 'normal');
    r = r + 8;
    pdf.setFontSize(10);
    var strArr = pdf.splitTextToSize("Trending news items headlines that are related to cyber security, along with reference links for further reading.", ll)
    pdf.text(strArr, 20, r);
    r = r + 15;
    for (let k = 0; k < this.securityEventData.length; k++) {
      // pdf.addPage();
      // pdf.setTextColor(68, 68, 68);
      // pdf.setFontSize(9);
      // pdf.text("Threat Intelligence Report", 5, 10)
      // pdf.setTextColor(0, 0, 0)
      // img.src = 'assets/images/logo-dark.png'
      // pdf.addImage(img, 'png', 150, 5, 45, 15)
      // r = 30
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('3.' + (k + 1), l, r);
      pdf.setFontSize(11);
      var strArr = pdf.splitTextToSize(this.securityEventData[k].event_name, ll)
      pdf.text(strArr, st + 8, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7 * (strArr.length);
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont(undefined, 'bold');
      pdf.text('Reference Link:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      for (let j = 0; j < this.securityEventData[k].reference_link.length; j++) {
        pdf.setFontSize(10);
        if (r > 273) {
          pdf.addPage();
          pdf.setTextColor(68, 68, 68);
          pdf.setFontSize(9);
          pdf.text("Threat Intelligence Report", 5, 10)
          img.src = 'assets/images/logo-dark.png'
          pdf.addImage(img, 'png', 150, 5, 45, 15)
          r = 30
        }
        pdf.circle(29, r - 1, .75, 'S')
        var strArr = pdf.splitTextToSize(this.securityEventData[k].reference_link[j], ll)
        pdf.setTextColor(0, 0, 255);
        pdf.text(strArr, sl + 6, r);
        r = r + 4 * (strArr.length);
        pdf.setTextColor(0, 0, 0);
      }
      pdf.line(20, r, 195, r, 'S');
      r = r + 7
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
    }

    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    pdf.setTextColor(0, 0, 0)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(14);
    r = 30
    pdf.setFont(undefined, 'bold');
    pdf.text('4.Vulnerabilities', ml, r);
    pdf.setFont(undefined, 'normal');
    r = r + 8;
    pdf.setFontSize(10);
    var strArr = pdf.splitTextToSize("Vulnerabilities are weaknesses in information systems or security infrastructure that could be exploited by a threat source. The following table shows the breakdown of vulnerability information that were obtained from various sources in the given timeframe.", ll)
    pdf.text(strArr, ml, r);
    r = r + 7 * (strArr.length);
    for (let k = 0; k < this.vulnerabilitiesData.length; k++) {
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('4.' + (k + 1), l, r);
      pdf.setFontSize(11);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesData[k].event_name, ll)
      pdf.text(strArr, st + 8, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7 * (strArr.length);
      // let data1 = document.getElementById("vulnerabilitiesChart" + k);
      // let canvas1: any
      // try {
      //   canvas1 = await html2canvas(data1)
      // }
      // catch (e) {
      //   canvas1 = null
      // }
      //    const imgWidth = 200;
      // const imgHeight = (canvas1.height * imgWidth) / canvas1.width;
      // const contentDataURL = canvas1.toDataURL("image/png");
      // pdf.addImage(contentDataURL, "PNG", 5, r, imgWidth, imgHeight);
      // r = r + imgHeight;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesData[k].event_description, ll)
      let rtemp = r + 4.75 * (strArr.length) + 7;
      if (rtemp > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFont(undefined, 'bold');
      pdf.text('Description:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      pdf.setFontSize(10);
      pdf.text(strArr, sl, r);
      r = r + 4.5 * (strArr.length);
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Reference Link:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;

      pdf.setFontSize(10);
      pdf.circle(29, r - 1, .75, 'S')
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesData[k].reference_link, ll)
      pdf.setTextColor(0, 0, 255);
      pdf.text(strArr, sl + 6, r);
      r = r + 7 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setTextColor(0, 0, 0);
      pdf.line(20, r, 195, r, 'S');
      r = r + 7
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

    }
    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    pdf.setTextColor(0, 0, 0)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(14);
    r = 30
    pdf.setFont(undefined, 'bold');
    pdf.text('5.Vulnerabilities with exploit', ml, r);
    pdf.setFont(undefined, 'normal');
    r = r + 8;
    pdf.setFontSize(10);
    var strArr = pdf.splitTextToSize("An exploit is a piece of software, data or sequence of commands that takes advantage of a vulnerability to cause unintended behavior or to gain unauthorized access to sensitive data. The following table shows the breakdown of some vulnerabilities with their exploit information including the CVE, CVSS etc. that were obtained from various sources in the given timeframe.", ll)
    pdf.text(strArr, st, r);
    r = r + 4.5 * (strArr.length);
    for (let k = 0; k < this.vulnerabilitiesExploitData.length; k++) {
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('5.' + (k + 1), l, r);
      pdf.setFontSize(11);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesExploitData[k].event_name, ll)
      pdf.text(strArr, st + 8, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Description:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesExploitData[k].event_description, ll)
      pdf.text(strArr, sl, r);
      r = r + 4.75 * (strArr.length);
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('CVE:', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesExploitData[k].cve, ll)
      pdf.text(strArr, sl + 10, r);
      r = r + 6 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('CVSS:', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesExploitData[k].cvss, ll)
      pdf.text(strArr, sl + 12, r);
      r = r + 6 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Vendor:', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.vulnerabilitiesExploitData[k].vendor, ll)
      pdf.text(strArr, sl + 15, r);
      r = r + 6 * (strArr.length);
      pdf.setTextColor(0, 0, 0);
      pdf.line(20, r, 195, r, 'S');
      r = r + 7
    }
    pdf.addPage();
    pdf.setTextColor(68, 68, 68);
    pdf.setFontSize(9);
    pdf.text("Threat Intelligence Report", 5, 10)
    pdf.setTextColor(0, 0, 0)
    img.src = 'assets/images/logo-dark.png'
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    pdf.setFontSize(14);
    r = 30
    pdf.setFont(undefined, 'bold');
    pdf.text('6.Community Feeds Information', ml, r);
    pdf.setFont(undefined, 'normal');
    r = r + 8;
    pdf.setFontSize(10);
    pdf.text('Community IOCs', ml, r);
    r = r + 8;
    pdf.setFontSize(10);
    var strArr = pdf.splitTextToSize("Community events are the events provided by open source threat intelligence providers which will contain a large number of IOCs related to any given security event that took place in a recent time frame.", ll)
    pdf.text(strArr, ml, r);
    r = r + 6 * (strArr.length);
    for (let k = 0; k < this.IOCCommunityfeedsData.length; k++) {
      pdf.addPage();
      pdf.setTextColor(68, 68, 68);
      pdf.setFontSize(9);
      pdf.text("Threat Intelligence Report", 5, 10)
      pdf.setTextColor(0, 0, 0)
      img.src = 'assets/images/logo-dark.png'
      pdf.addImage(img, 'png', 150, 5, 45, 15)
      r = 30
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('6.' + (k + 1), l, r);
      pdf.setFontSize(11);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].event_name, ll)
      pdf.text(strArr, l + 7, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Feed Name', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].feed_name, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 6 * (strArr.length);
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Event Id', sl, r);
      pdf.setFont(undefined, 'normal');

      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].event_id, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 6 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].event_description, ll)
      let p = r + 4.5 * (strArr.length) + 7;
      if (p > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Event Description:', sl, r);
      pdf.setFont(undefined, 'normal');
      r = r + 7;
      pdf.setFontSize(10);
      pdf.text(strArr, sl, r);
      r = r + 4.25 * (strArr.length);
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }
      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Event Category', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].event_category, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 7 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Attribute Category', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].attribute_category, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 7 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      // pdf.setFontSize(10);
      // pdf.text('Attribute Comment :', l, r);
      // r = r + 7;
      // pdf.setFontSize(10);
      // var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].attribute_comment, ll)
      // pdf.text(strArr, st, r);
      // r = r + 6 * (strArr.length);;
      // if (r > 273) {
      //   pdf.addPage();
      pdf.setTextColor(68, 68, 68);
      pdf.setFontSize(9);
      pdf.text("Threat Intelligence Report", 5, 10)
      pdf.setTextColor(0, 0, 0)
      //   img.src = 'assets/images/logo-dark.png'
      //   pdf.addImage(img, 'png', 150, 5, 45, 15)
      //   r = 30
      // }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Attribute Id', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].attribute_id, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 7 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Attribute Timestamp', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].attribute_timestamp, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 7 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Attribute Type', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].attribute_type, ll)
      pdf.text(": " + strArr, sl + 40, r);
      r = r + 7 * (strArr.length);;
      if (r > 273) {
        pdf.addPage();
        pdf.setTextColor(68, 68, 68);
        pdf.setFontSize(9);
        pdf.text("Threat Intelligence Report", 5, 10)
        pdf.setTextColor(0, 0, 0)
        img.src = 'assets/images/logo-dark.png'
        pdf.addImage(img, 'png', 150, 5, 45, 15)
        r = 30
      }

      pdf.setFontSize(10);
      pdf.setFont(undefined, 'bold');
      pdf.text('Attribute Value', sl, r);
      pdf.setFont(undefined, 'normal');
      pdf.setFontSize(10);
      var strArr = pdf.splitTextToSize(this.IOCCommunityfeedsData[k].attribute_value, 120)
      pdf.text(": " + strArr, sl + 40, r);
      pdf.setTextColor(0, 0, 0);

    }


    pdf.save("Threat Intelligence Report.pdf"); // Generated PDF
    this.modalService.dismissAll('Ok click')
    this.printClick = true;
    this.isLoading = false
  }

  timeConvert1(t) {
    let time = this.datePipe.transform(t * 1000, 'medium');
    return time
  }

  outputDate:any;
  inputDate:any

  dateConverter(inputDates){
    this.inputDate = new Date(inputDates);
    const options = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZoneName: 'short'
    };
    this.outputDate = this.inputDate.toLocaleDateString('en-US', options);

    return this.outputDate
  }

  goToBack() {
    this.router.navigate(["/dashboard", { ti: 1 }]);
  }
}
