import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DashboardService } from 'src/app/core/services/dashboard.service';

@Component({
  selector: 'app-show-item',
  templateUrl: './show-item.component.html',
  styleUrls: ['./show-item.component.scss']
})
export class ShowItemComponent implements OnInit {
  @Input() item;
  currentPage


  //Title and description allowed for selected widget type
  allowedWidget = ['donut', 'bar', 'stat', 'filter', 'gauge']

  constructor(public dashboardService: DashboardService, private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentPage = this.router.url;
        // console.log("this.currentPage select", this.currentPage)
        let s = this.currentPage.split("/");
        // console.log("s", s)
      }
    });
  }


  ngOnInit(): void {
    // console.log("item", this.item);
  }

}
