import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/core/services/settings.service';
import { AddExceptionsComponent } from '../add-exceptions/add-exceptions.component';

@Component({
  selector: 'app-automation-exceptions',
  templateUrl: './automation-exceptions.component.html',
  styleUrls: ['./automation-exceptions.component.scss']
})
export class AutomationExceptionsComponent implements OnInit {

  constructor(private settingsService: SettingsService, private modalService: NgbModal) { }
  @Input() type: any
  dtTrigger3: Subject<any> = new Subject<any>();
  tableData3 = [];
  loader = false
  tenantID
  isDtInitialized: boolean[] = [false];
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 10,
    processing: true,
    order: []
  };
  modalId: number;
  selectedContent: any;
  Details = "Details"
  user_prv
  editFlag = false
  ngOnInit(): void {
    this.tenantID = localStorage.getItem("tenantID");
    if (this.type == 'Investigate') {
      this.loadTable3()
    } else if (this.type == 'UEBA') {
      this.loadTable4()
    } else {
      this.tenantID = "*"
    }
    this.user_prv = localStorage.getItem("user_prv_data") ? localStorage.getItem("user_prv_data") : "r";
    if (this.user_prv == 'rw') {
      this.editFlag = true
    } else {
      this.editFlag = false
    }

  }
  loadTable3() {
    this.loader = true;
    let data = {
      query: {
        match_all: {},
      },
    };
    let tenantID = this.tenantID;
    var table = $('#myTable3').DataTable();
    if (table)
      table.destroy();
    if (tenantID == undefined || tenantID == "") {
      this.settingsService.getExceptionsListAll(data).subscribe(
        (response: any) => {
          //// console.log("response", response);
          let data = response.hits.hits;
          this.tableData3 = [];
          data.forEach((item) => {
            let t = {
              id: item._id,
              index: item._index,
              type: item._type,
              playbook_id: item._source.playbook_id,
              playbook_name: item._source.playbook_name,
              created_by: item._source.created_by,
              created_at: item._source.created_at,
              comment: item._source.comment,
              exception_user_name: item._source.exception_user_name,
              exception_host_name: item._source.exception_host_name,
              exception_process_name: item._source.exception_process_name,
              exception_process_executable: item._source.exception_process_executable,
              exception_process_hash: item._source.exception_process_hash,
              exception_parent_process_name: item._source.exception_parent_process_name,
              exception_parent_process_executable: item._source.exception_parent_process_executable,
              exception_url: item._source.exception_url,
              exception_source_ip: item._source.exception_source_ip,
              exception_dest_ip: item._source.exception_dest_ip,
              exception_comment: item._source.exception_comment
            };
            this.tableData3.push(t);
          });
          this.tableData3 = this.tableData3;
          this.dtTrigger3.next();
          this.isDtInitialized[3] = true;
          setTimeout(() => {
            this.loader = false;
          }, 600);
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      this.settingsService.getExceptionsList(data, tenantID).subscribe(
        (response: any) => {
          let data = response.hits.hits;
          this.tableData3 = [];
          data.forEach((item) => {
            let t = {
              id: item._id,
              index: item._index,
              type: item._type,
              playbook_id: item._source.playbook_id,
              playbook_name: item._source.playbook_name,
              created_by: item._source.created_by,
              created_at: item._source.created_at,
              comment: item._source.comment,
              exception_user_name: item._source.exception_user_name,
              exception_host_name: item._source.exception_host_name,
              exception_process_name: item._source.exception_process_name,
              exception_process_executable: item._source.exception_process_executable,
              exception_process_hash: item._source.exception_process_hash,
              exception_parent_process_name: item._source.exception_parent_process_name,
              exception_parent_process_executable: item._source.exception_parent_process_executable,
              exception_url: item._source.exception_url,
              exception_source_ip: item._source.exception_source_ip,
              exception_dest_ip: item._source.exception_dest_ip,
              exception_comment: item._source.exception_comment
            };
            this.tableData3.push(t);
          });
          this.tableData3 = this.tableData3;
          // console.log("this.tableData3", this.tableData3)
          this.dtTrigger3.next();
          this.isDtInitialized[3] = true;
          setTimeout(() => {
            this.loader = false;
          }, 600);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }

  addExceptions() {

    if (this.tenantID == undefined || this.tenantID == '') {
      //   this.alertService.alert(
      //     `Tenant Id was not selected`,
      //     status => {
      //       return
      //     }
      //   );
      return
    } else {
      let objArr = [];
      objArr[0] = "Exceptions Creation";
      objArr[2] = "Create";
      objArr[3] = this.tenantID;
      objArr[4] = this.type;
      let ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-dialog-all common-modal",
      };
      const modalRef = this.modalService.open(
        AddExceptionsComponent,
        ngbModalOptions
      ); 
      modalRef.componentInstance.objArr = objArr;
      modalRef.result.then(
        (result) => { },
        (reason) => {
          // console.log("reason", reason);
          if (reason.status == "created") {
            let item = reason.data;
            this.tableData3.push(item);
          }
        }
      );
    }

  }
  editExceptions(data, i) {
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      data.tenant_id = this.tenantID;
      let objArr = [];
      objArr[0] = "Exceptions Edit";
      objArr[1] = {
        ueba_id : data.ueba_id,
        user_name : data.user_name,
        host_name : data.host_name
      }
      objArr[2] = "Update";
      objArr[3] = i;
      objArr[4] = this.type;
      let ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-dialog-all common-modal",
      };
      const modalRef = this.modalService.open(
        AddExceptionsComponent,
        ngbModalOptions
      );
      modalRef.componentInstance.objArr = objArr;
      modalRef.result.then(
        (result) => { },
        (reason) => {
          if (reason.status == "update") {
            let item = reason.data;
            this.tableData3[reason.index] = item;
          }
        }
      );
    }

  }

  deleteExceptions(data, i) {
    // if (this.tenantID == undefined || this.tenantID == '') {
    //   this.alertService.alert(
    //     `Tenant Id was not selected`,
    //     status => {
    //       return
    //     }
    //   );
    // } else {
    // this.alertService.confirm(
    //   `Are you sure to delete Exceptions ?`,
    //   status => {
    //     if (status == true) {
    let id = data.id;
    let tenantID = this.tenantID;

    if (this.type == 'Investigate') {
      this.settingsService.deletesExceptions(tenantID, id).subscribe(
        (response: any) => {
          // console.log("response", response);
          if (response.result == "deleted") {
            // console.log("response", response.result);
            this.tableData3.splice(i, 1);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else if (this.type == 'UEBA') {
      this.settingsService.deletesExceptionsUEBA(tenantID, id).subscribe(
        (response: any) => {
          // console.log("response", response);
          if (response.result == "deleted") {
            // console.log("response", response.result);
            this.tableData3.splice(i, 1);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      this.settingsService.deletesExceptions(tenantID, id).subscribe(
        (response: any) => {
          // console.log("response", response);
          if (response.result == "deleted") {
            // console.log("response", response.result);
            this.tableData3.splice(i, 1);
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    }


    //     }
    //   }
    // );
    // }

  }

  openModal(viewmodal: any, content: any, modalId: number) {
    // console.log(content, modalId);
    this.modalId = modalId;
    this.selectedContent = content;
    this.modalService.open(viewmodal, { size: "lg", centered: true });
  }


  loadTable4() {
    this.loader = true;
    let data = {
      query: {
        match_all: {},
      },
    };
    let tenantID = this.tenantID;
    var table = $('#myTable3').DataTable();
    if (table)
      table.destroy();
    // if (tenantID == undefined || tenantID == "") {
    this.settingsService.getExceptionsListAllUeba(data).subscribe(
      (response: any) => {
        // console.log("response table23", response);
        let data = response.hits.hits;
        this.tableData3 = [];
        data.forEach((item) => {
          let t = {
            ueba_id: item._source.ueba_id,
            detection_name: item._source.detection_name,
            id: item._id,
            index: item._index,
            type: item._type,
            playbook_id: item._source.playbook_id,
            playbook_name: item._source.playbook_name,
            created_by: item._source.created_by,
            created_at: item._source.created_at,
            comment: item._source.comment,
            exception_user_name: item._source.exception_user_name,
            exception_host_name: item._source.exception_host_name,
            exception_process_name: item._source.exception_process_name,
            exception_process_executable: item._source.exception_process_executable,
            exception_process_hash: item._source.exception_process_hash,
            exception_parent_process_name: item._source.exception_parent_process_name,
            exception_parent_process_executable: item._source.exception_parent_process_executable,
            exception_url: item._source.exception_url,
            exception_source_ip: item._source.exception_source_ip,
            exception_dest_ip: item._source.exception_dest_ip,
            exception_comment: item._source.comment
          };
          this.tableData3.push(t);
        });
        // this.tableData3 = this.tableData3;
        // console.log("this.tableData3", this.tableData3)
        this.dtTrigger3.next();
        this.isDtInitialized[3] = true;
        setTimeout(() => {
          this.loader = false;
        }, 600);
      },
      (error) => {
        console.log("error", error);
      }
    );
    // } else {
    //   this.settingsService.getExceptionsList(data, tenantID).subscribe(
    //     (response: any) => {
    //       let data = response.hits.hits;
    //       this.tableData3 = [];
    //       data.forEach((item) => {
    //         let t = {
    //           id: item._id,
    //           index: item._index,
    //           type: item._type,
    //           playbook_id: item._source.playbook_id,
    //           playbook_name: item._source.playbook_name,
    //           created_by: item._source.created_by,
    //           created_at: item._source.created_at,
    //           comment: item._source.comment,
    //           exception_user_name: item._source.exception_user_name,
    //           exception_host_name: item._source.exception_host_name,
    //           exception_process_name: item._source.exception_process_name,
    //           exception_process_executable: item._source.exception_process_executable,
    //           exception_process_hash: item._source.exception_process_hash,
    //           exception_parent_process_name: item._source.exception_parent_process_name,
    //           exception_parent_process_executable: item._source.exception_parent_process_executable,
    //           exception_url: item._source.exception_url,
    //           exception_source_ip: item._source.exception_source_ip,
    //           exception_dest_ip: item._source.exception_dest_ip,
    //           exception_comment: item._source.exception_comment
    //         };
    //         this.tableData3.push(t);
    //       });
    //       this.tableData3 = this.tableData3;
    //       // console.log("this.tableData3", this.tableData3)
    //       this.dtTrigger3.next();
    //       this.isDtInitialized[3] = true;
    //       setTimeout(() => {
    //         this.loader = false;
    //       }, 600);
    //     },
    //     (error) => {
    //       console.log("error", error);
    //     }
    //   );
    // }
  }
}
