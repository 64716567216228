import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { SsoService } from 'src/app/core/services/sso.service';
import { ALERT, COMPILANCE } from 'src/app/layouts/shared/sidebar/menu';

@Component({
  selector: 'app-dashbord-list',
  templateUrl: './dashbord-list.component.html',
  styleUrls: ['./dashbord-list.component.scss']
})
export class DashbordListComponent implements OnInit {
  selectedMenus = [];
  selectedOption = "iso";
  routerUrlVal;
  searchVal = "";
  menuListTemp: any = [];
  searchForm: FormGroup;
  dashbordSearch = '';
  filteredMenuList = [];
  selectedOpction = 'iso'
  @Input() set routerUrl(val) {
    this.routerUrlVal = val.replace("/", "");
    console.log("routerUrlVal", this.routerUrlVal)
    if (val && val.includes("compliance")) {
      if (val.includes("alert")) {
        this.selectedMenus.push("Alert");
        this.menuList.push({
          name: "Alert",
          list: ALERT
        })
      }
      else if (val.includes("compliance/controlview")) {
        this.selectedMenus.push("Control View");
        this.menuList.push({
          name: "Control View",
          list: COMPILANCE
        })
      }
      else if (val.includes("dashboard")) {
        this.loadDashboard();
      }

    } else if (val && val.includes("investigation")) {
      this.loadDashboardAnalytics();
    }
    else {
      this.selectedMenus.push("Dashboard");
      this.loadDashboardAnalytics();
    }
  };
  mylist: any = [];
  menuList: any = [];
  isManage = false;
  constructor(private dashService: DashboardService, private formBuilder: FormBuilder, private router: Router, private SsoService: SsoService,) {
    router.events.subscribe(val => {
      // console.log("val", val)
      // localStorage.setItem("selectedDonutVal", '');
      // localStorage.setItem("selectedDonutElemet", '');
    });
  }

  loadDashboard() {
    let id = "dashboard-list"
    this.dashService.getJSON(id).subscribe(list => {

      let myData = []
      list.forEach(element => {
        if (!element.analytics_view) {
          myData.push(element)
        }
      });
      this.menuList.push({
        name: "Dashboard",
        list: myData
      });
      this.menuListTemp.push({
        name: "Dashboard",
        list: myData
      });
    });
    // console.log("menuList", this.menuList)
    this.selectedMenus.push("Dashboard");
    this.SsoService.getDashboards().subscribe((data: any) => {
      this.mylist = data.dash;
      this.selectionChange(this.routerUrlVal === 'analytics' ? 'sa' : 'iso')
    })
  }

  loadDashboardAnalytics() {
    let id = "dashboard-list";
    this.dashService.getJSON(id).subscribe(list => {
      // console.log("list123", list)
      let myData = []
      list.forEach(element => {
        if (element.analytics_view) {
          myData.push(element)
        }
      });
      this.menuList.push({
        name: "Dashboard",
        list: myData
      });
      this.menuListTemp.push({
        name: "Dashboard",
        list: myData
      });
    });
    // console.log("menuList", this.menuList)
    // this.selectedMenus.push("Dashboard");
    this.SsoService.getDashboards().subscribe((data: any) => {
      this.mylist = data.dash
      this.selectionChange(this.routerUrlVal === 'analytics' ? 'sa' : 'iso')
    })
  }
  search(val) {
    if (this.searchVal) {
      this.menuList[0].list = this.menuListTemp[0].list.filter(it => it.title.toLowerCase().includes(this.searchVal.toLowerCase()));
    }
    else {
      this.menuList = this.structuredClone(this.menuListTemp);
    }
    if (this.selectedOption != "all") {
      this.menuList[0].list = this.menuList[0].list.filter(it => it.type === this.selectedOption);
    }
  }
  selectionChange(val) {
    console.log("val", val)
    this.selectedOpction = val
    if (val === "all") {
      this.menuList = this.structuredClone(this.menuListTemp);
    }
    else {
      this.menuList[0].list = this.menuListTemp[0].list.filter(it => it.type === this.selectedOpction);
    }
    if (this.searchVal) {
      this.menuList[0].list = this.menuList[0].list.filter(it => it.title.toLowerCase().includes(this.searchVal));
    }
  }
  ngOnInit(): void {
    this.isManage = this.routerUrlVal === 'compliance';
    this.searchForm = this.formBuilder.group({
      searchName: [""]
    });
    this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.dashbordSearch = this.searchForm.value.searchName;
      if (this.dashbordSearch != undefined && this.dashbordSearch != '') {
        console.log("dashbordSearch", this.dashbordSearch)
        console.log(" this.menuList", this.menuList)
        // let found = this.menuList[0].list.find(e => e.name === this.dashbordSearch);
        let found = this.menuListTemp[0].list.filter((el) => el.name.toLowerCase().includes(this.dashbordSearch.toLowerCase()));
        console.log("found", found)
        if (found)
          this.menuList[0].list = found
      } else if (this.dashbordSearch == '') {
        console.log(this.dashbordSearch == '')
        // this.clearSearch()
        // this.menuList = this.menuListTemp;
      }
    });
    this.SsoService.getSystemvArs().subscribe((data) => {
      console.log("getSystemvArs", data)
      let userData: any = data
      if (userData.datalakeuname && userData.datalakepass) {
        localStorage.setItem("ACCESS_TOKEN",
          "Basic " + btoa(userData.datalakeuname + ":" + userData.datalakepass));
      }
      if (userData.datalakeurl) {
        localStorage.setItem("Datalake_Url", userData.datalakeurl);
      }
    })
  }
  getRouter(list, dash) {
    return ((list.name.replace(" ", "") + "/" + (dash.name || dash.label)).replace("", "").replace("-", "")).toLowerCase() + "/" + dash?.title
  }
  manageItems() {
    this.isManage = true;
  }
  backHome() {
    // this.router.navigate(["/dashboard"], {});
    this.isManage = false;
  }
  checkSelect(id) {
    let d = this.mylist.includes(id);
    return this.routerUrlVal === "analytics" ? d : true;
  }
  menuChange(val) {
    if (this.selectedMenus?.includes(val)) {
      this.selectedMenus.splice(this.selectedMenus.indexOf(val), 1)
    }
    else {
      this.selectedMenus.push(val);
    }
  }
  selectChange(id) {
    let checked = !this.checkSelect(id)
    // console.log("id", id)
    // console.log("checked", checked)
    if (checked) {
      this.mylist.push(Number(id));
      console.log("this.mylist", this.mylist)
    } else {
      const index = this.mylist.indexOf(Number(id));
      if (index > -1) { // only splice list when item is found
        this.mylist.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log("this.mylist", this.mylist)
    }
  }
  saveDashbord() {
    let a1 = { "dash": this.mylist }
    this.SsoService.setDashboards(a1).subscribe((data: any) => {
      // console.log("getDashboards", data)
      if (data == "updated") {
        this.router.navigate(["/dashboard"], {});
      }
    })
  }
  clearSearch() {
    this.dashbordSearch = "";
    this.menuList = this.menuListTemp;
  }

  structuredClone(objectToClone: any) {
    const stringified = JSON.stringify(objectToClone);
    const parsed = JSON.parse(stringified);
    return parsed;
  }
  ngOnDestroy() {
    console.log("ngOnDestroy")
    localStorage.setItem("selectedDonutVal", '');
    localStorage.setItem("selectedDonutElemet", '');
  }
  goToBack() {
    this.router.navigate(["/dashboard", { cm: 1 }]);
  }
  goToBack1() {
    this.router.navigate(["/dashboard"]);
  }
}
