import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'ng-apexcharts';

@Component({
  selector: 'app-host-name-filter',
  templateUrl: './host-name-filter.component.html',
  styleUrls: ['./host-name-filter.component.scss']
})
export class HostNameFilterComponent implements OnInit {
  data: ChartType;
  term: any;
  titleArray: any;
  api: any;
  query: any = '';
  method: any;
  dataTemp: any = []
  expectedValues: any
  datas: any;
  series: any = [];
  labels: any = [];
  type: any = []
  colors: any = []
  loding: boolean = true;
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.series = val.series
    this.expectedValues = val.expectedValues;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
