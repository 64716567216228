import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DateFormatArray, TimePeriod } from 'src/app/core/models/auth.models';
import { CommonService } from 'src/app/core/services/common.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { SsoService } from 'src/app/core/services/sso.service';
const ipPattern = "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  settingsForm: FormGroup;
  settingsForm2: FormGroup;
  cybotForm: FormGroup;
  submitted: boolean = false;
  submitted1: boolean = false;
  isSuccess = false;
  isSaving = false;
  uebaFlage = "false";
  userData;
  CYBOTHealthData
  DatalikeHealthData
  adminstatus = false
  // tab setup start now
  settings_tab: any
  tableData5 = [];
  threat_intelligence_weekly_value = 'no'
  threat_intelligence_monthly_value = 'no'
  threat_intelligence_individual_value = 'no'
  threat_hunting_report_value = 'no'
  isLoading = false
  tenantID = ""
  notificationsForm: FormGroup;
  notifyF2: FormGroup;
  siemForm: FormGroup;
  dtOptions: DataTables.Settings = {};
  dtTrigger0: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();

  dtTrigger3: Subject<any> = new Subject<any>();
  dtTrigger4: Subject<any> = new Subject<any>();
  dtTrigger5: Subject<any> = new Subject<any>();
  dtTrigger9: Subject<any> = new Subject<any>();
  Details = "Details"
  dtOptions1: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 50,
    processing: true,
    order: [],
    search: true
  };

  dtOptions2: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 50,
    processing: true,
    order: [],
    columnDefs: [
      { orderable: false, targets: 3 }
    ]
  };
  user_prv;
  loader: Boolean = false;
  scheduleTime = "02:00";
  allCheckstatus = "On";
  scheduleTimeId = "";
  isDisabledSiem = true;
  backupStatus = true;
  isSubmittingCrone = false;
  isCroneCreated = false;
  frequencyData = 'hour';
  snapshotList = [];
  isFetchingSnaps = false;
  isSnapDeleting = false;
  deleteSnap: any;
  delmodalRef;
  descmodalRef;
  retentionData
  numbertostoreData
  numbertostoreDataError: boolean = false;
  numbertostoreNumError = false
  crone = {
    month: '',
    day: '',
    week: '',
    hour: '',
    minute: ''
  }
  retentionDataError: boolean = false;
  retentionNumError = false
  scheduleList = [];
  scheduleDetails = {
    playbook_id: '',
    playbook_name: '',
    description: ''
  };
  selectedContent: any;
  modalId: number;
  dateFormate: string = "0";
  timePeriod: string = "0";
  timeZoneSettings: string = "normal";
  dateFormatArray = DateFormatArray;
  timePeriods = TimePeriod;
  editFlag = false
  onDateFormatChange() {
    let formate = `${this.dateFormate}:${this.timePeriod}`;
    localStorage.setItem("dateFormat", formate);
    localStorage.setItem("timeZone", this.timeZoneSettings);
    this.router.navigate(["/home"], {});
  }

  // tab setup end

  constructor(
    private modalService: NgbModal,
    private SsoService: SsoService, private settingsService: SettingsService,
    private formBuilder: FormBuilder, private router: Router, private common: CommonService) { }

  ngOnInit(): void {
    // tab setup start now
    // this.getCYBOTHealth()
    this.getcybotvars()
    this.getlicenseinfo()
    // this.getDatalikeHealth()
    this.loadTable5()
    this.dateFormate = localStorage.getItem("dateFormat") ? localStorage.getItem("dateFormat").split(":")[0] : "0";
    this.timePeriod = localStorage.getItem("dateFormat") ? localStorage.getItem("dateFormat").split(":")[1] : "0";
    this.timeZoneSettings = localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : "normal";
    this.notificationsForm = this.formBuilder.group({
      threat_hunting_report: [""],
      threat_intelligence_individual: [""],
      threat_intelligence_weekly: [""],
      threat_intelligence_monthly: [""],
      threat_intelligence_weekly_email: ["", [Validators.required, emailsValidator]],
      threat_intelligence_monthly_email: ["", [Validators.required, emailsValidator]],
      threat_intelligence_daily_email: ["", [Validators.required, emailsValidator]],
      threat_hunting_report_email: ["", [Validators.required, emailsValidator]],
      id: [""]
    });
    this.notifyF2 = this.formBuilder.group({
      cbox: [false],
      ip1: [""],
      ip2: [""],
      select1: [""],
    });
    this.notifyF2.controls['ip1'].disable();
    this.notifyF2.controls['ip2'].disable();
    this.notifyF2.controls['select1'].disable();

    this.siemForm = this.formBuilder.group({
      syslog_ip: ["", [Validators.required, Validators.pattern(ipPattern)]],
      protocol: [""],
      syslog_port: ["", [Validators.min(0), Validators.max(65535)]],
      id: [""]
    });

    // this.settings_tab = {
    //   selectedIndex: 0,
    //   tabs: [
    //     {
    //       selectedIndex: 0,
    //       name: "Authentication",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: true,
    //       sub_tab: [
    //         {
    //           name: "Datalake",
    //           icon: "",
    //           tab: 'TabContent1'
    //         },
    //         {
    //           name: "Cybot",
    //           icon: "",
    //           tab: "TabContent2"
    //         }
    //       ]

    //     },
    //     {
    //       selectedIndex: 0,
    //       name: "Exception",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: true,
    //       sub_tab: [
    //         {
    //           name: "Investigate",
    //           icon: "",
    //           tab: "TabContent4"
    //         },
    //         {
    //           name: "UEBA",
    //           icon: "",
    //           tab: "TabContent5"
    //         }
    //       ]

    //     },
    //     {
    //       selectedIndex: 0,
    //       name: "License",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: false,
    //       sub_tab: [
    //         {
    //           name: "Investigate",
    //           icon: "",
    //           tab: ""
    //         },
    //         {
    //           name: "UEBA",
    //           icon: "",
    //           tab: ""
    //         }
    //       ]

    //     },

    //     {
    //       selectedIndex: 0,
    //       name: "Health Status",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: false,
    //       sub_tab: [
    //         {
    //           name: "Healthy",
    //           icon: "",
    //           tab: "HealthStatus"
    //         }
    //       ]

    //     }
    //     // , {
    //     //   selectedIndex: 0,
    //     //   name: "Backup and restore",
    //     //   icon: "fa fa-key",
    //     //   showSingleTab: true,
    //     //   show: true,
    //     //   sub_tab: [
    //     //     {
    //     //       name: "Investigate",
    //     //       icon: "",
    //     //       tab: "backupinvestigate"
    //     //     },
    //     //     {
    //     //       name: "UEBA",
    //     //       icon: "",
    //     //       tab: ""
    //     //     }
    //     //   ]

    //     // }
    //     ,
    //     {
    //       selectedIndex: 0,
    //       name: "Integrations",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: true,
    //       sub_tab: [
    //         {
    //           name: "Investigate",
    //           icon: "",
    //           tab: "integrationinvestigate"
    //         },
    //         // {
    //         //   name: "UEBA",
    //         //   icon: "",
    //         //   tab: "integrationueba"
    //         // }
    //       ]

    //     },

    //     {
    //       selectedIndex: 0,
    //       name: "Notifications",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: true,
    //       sub_tab: [
    //         {
    //           name: "Investigate",
    //           icon: "",
    //           tab: "notificationinvestigate"
    //         },
    //         // {
    //         //   name: "UEBA",
    //         //   icon: "",
    //         //   tab: ""
    //         // }
    //       ]

    //     },

    //     {
    //       selectedIndex: 0,
    //       name: "SIEM Integration",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: false,
    //       sub_tab: [
    //         {
    //           name: "Investigate",
    //           icon: "",
    //           tab: "siemintegrationinvestigate"
    //         },
    //         {
    //           name: "UEBA",
    //           icon: "",
    //           tab: ""
    //         }
    //       ]

    //     },
    //     {
    //       selectedIndex: 0,
    //       name: "Profile settings",
    //       icon: "fa fa-key",
    //       showSingleTab: false,
    //       show: false,
    //       sub_tab: [
    //         {
    //           name: "Password",
    //           icon: "",
    //           tab: ""
    //         }
    //       ]

    //     },
    //     {
    //       selectedIndex: 0,
    //       name: "Date Format",
    //       icon: "fa fa-key",
    //       showSingleTab: true,
    //       show: true,
    //       sub_tab: [
    //         {
    //           name: "Date",
    //           icon: "",
    //           tab: "timeZoneSettings"
    //         }
    //       ]
    //     }

    //   ]
    // }

    // tab setup end


    this.settingsForm = this.formBuilder.group({
      datalakeuname: ['', [Validators.required]],
      datalakepass: ['', [Validators.required]],
      datalakeurl: ['', [Validators.required]],
      privusers: this.formBuilder.array([]),
      variables: this.formBuilder.array([])
    });
    this.cybotForm = this.formBuilder.group({
      cybotuname: ['', [Validators.required]],
      cybotpass: ['', [Validators.required]],
      cyboturl: ['', [Validators.required]]
    });

    this.settingsForm2 = this.formBuilder.group({
      datalakeuname_dev: ['', [Validators.required]],
      datalakepass_dev: ['', [Validators.required]],
      datalakeurl_dev: ['', [Validators.required]],
      privusers_dev: this.formBuilder.array([]),
      variables_dev: this.formBuilder.array([])
    });

    this.SsoService.getSystemvArs().subscribe((data) => {
      console.log("getSystemvArs", data)
      let userData: any = data
      this.userData = data
      if (userData.datalakeuname && userData.datalakepass) {
        this.settingsForm
          .get("datalakeuname")
          .setValue(userData.datalakeuname);
        this.settingsForm
          .get("datalakepass")
          .setValue(userData.datalakepass);
      }
      if (userData.datalakeurl) {
        this.settingsForm
          .get("datalakeurl")
          .setValue(userData.datalakeurl);
      }
      if (userData.variables) {
        userData.variables.forEach(it => {
          this.variables.push(this.formBuilder.group({
            name: it.name,
            value: it.value,
            new: false
          }));
        })

      }
      if (userData.privusers) {
        userData.privusers.forEach(it => {
          this.privusers.push(this.formBuilder.group({
            user: it
          }));
        })

      }
      if (userData.cybotuname && userData.cybotpass) {
        this.cybotForm
          .get("cybotuname")
          .setValue(userData.cybotuname);
        this.cybotForm
          .get("cybotpass")
          .setValue(userData.cybotpass);
      }
      if (userData.cyboturl) {
        this.cybotForm
          .get("cyboturl")
          .setValue(userData.cyboturl);
      }


      if (userData.datalakeuname_dev && userData.datalakepass_dev) {
        this.settingsForm2
          .get("datalakeuname_dev")
          .setValue(userData.datalakeuname_dev);
        this.settingsForm2
          .get("datalakepass_dev")
          .setValue(userData.datalakepass_dev);
      }
      if (userData.datalakeurl_dev) {
        this.settingsForm2
          .get("datalakeurl_dev")
          .setValue(userData.datalakeurl_dev);
      }

    })
    // this.getHealthStatus()
    this.tenantID = localStorage.getItem("tenantID");
    console.log("tenantID", this.tenantID)
    this.getNotifications()
    this.user_prv = localStorage.getItem("user_prv_data") ? localStorage.getItem("user_prv_data") : "r";
    if (this.user_prv == 'rw') {
      this.editFlag = true
    } else {
      this.editFlag = false
    }
  }
  get f() { return this.settingsForm.controls; }
  get f1() { return this.cybotForm.controls; }
  get f2() { return this.settingsForm2.controls; }
  onSubmit() {
    this.submitted = true;
    console.log("this.loginForm.invalid", this.settingsForm)
    if (this.settingsForm.invalid) {
      return;
    } else {
      let users = [];
      let variables = [];
      this.settingsForm.value.variables.forEach(it =>
        variables.push({ "name": it.name, "value": it.value })
      );
      let sendData = {
        "datalakeurl": this.settingsForm.value.datalakeurl,
        "datalakeuname": this.settingsForm.value.datalakeuname,
        "datalakepass": this.settingsForm.value.datalakepass,
        "test": "123",
        "privusers": users,
        "variables": variables,
        "cyboturl": this.userData.cyboturl,
        "cybotuname": this.userData.cybotuname,
        "cybotpass": this.userData.cybotpass,
        "datalakeurl_dev": this.userData.datalakeurl_dev,
        "datalakeuname_dev": this.userData.datalakeuname_dev,
        "datalakepass_dev": this.userData.datalakepass_dev,
      }
      this.isSaving = true;
      this.SsoService.setSystemvArs(sendData).subscribe((data: any) => {
        console.log("getDashboards", data)
        if (data == "updated") {
          localStorage.setItem("datalakeurl", sendData.datalakeurl);
          localStorage.setItem("datalakepass", sendData.datalakepass);
          localStorage.setItem("datalakeuname", sendData.datalakeuname);
          localStorage.setItem("ACCESS_TOKEN",
            "Basic " + btoa(sendData.datalakeuname + ":" + sendData.datalakepass));
          localStorage.setItem("datalakeurl_dev", sendData.datalakeurl_dev);
          localStorage.setItem("datalakepass_dev", sendData.datalakepass_dev);
          localStorage.setItem("datalakeuname_dev", sendData.datalakeuname_dev);
          localStorage.setItem("cybotpass", sendData.cybotpass);
          localStorage.setItem("cybotuname", sendData.cybotuname);
          localStorage.setItem("cyboturl", sendData.cyboturl);
          this.isSuccess = true;
          this.isSaving = false;
          setTimeout(() => this.router.navigate(["/home"], {}), 2000)
        }
      })
    }
  }
  onSubmit1() {
    this.submitted1 = true;
    console.log("this.cybotForm.invalid", this.cybotForm)
    if (this.cybotForm.invalid) {
      return;
    } else {
      let users = [];
      let variables = [];

      let sendData = {
        "cyboturl": this.cybotForm.value.cyboturl,
        "cybotuname": this.cybotForm.value.cybotuname,
        "cybotpass": this.cybotForm.value.cybotpass,
        "datalakeurl": this.userData.datalakeurl,
        "datalakeuname": this.userData.datalakeuname,
        "datalakepass": this.userData.datalakepass,
        "test": "123",
        "privusers": this.userData.users,
        "variables": this.userData.variables,
        "datalakeurl_dev": this.userData.datalakeurl_dev,
        "datalakeuname_dev": this.userData.datalakeuname_dev,
        "datalakepass_dev": this.userData.datalakepass_dev,
      }
      this.isSaving = true;
      this.SsoService.setSystemvArs(sendData).subscribe((data: any) => {
        console.log("getDashboards", data)
        if (data == "updated") {
          localStorage.setItem("datalakeurl", sendData.datalakeurl);
          localStorage.setItem("datalakepass", sendData.datalakepass);
          localStorage.setItem("datalakeuname", sendData.datalakeuname);
          localStorage.setItem("ACCESS_TOKEN",
            "Basic " + btoa(sendData.datalakeuname + ":" + sendData.datalakepass));
          localStorage.setItem("datalakeurl_dev", sendData.datalakeurl_dev);
          localStorage.setItem("datalakepass_dev", sendData.datalakepass_dev);
          localStorage.setItem("datalakeuname_dev", sendData.datalakeuname_dev);
          localStorage.setItem("cybotpass", sendData.cybotpass);
          localStorage.setItem("cybotuname", sendData.cybotuname);
          localStorage.setItem("cyboturl", sendData.cyboturl);
          this.isSuccess = true;
          this.isSaving = false;
          setTimeout(() => this.router.navigate(["/home"], {}), 2000)
        }
      })
    }
  }
  backHome() {
    this.router.navigate(["/home"], {});
  }
  get privusers(): FormArray {
    return this.settingsForm.get("privusers") as FormArray
  }
  get variables(): FormArray {
    return this.settingsForm.get("variables") as FormArray
  }

  newUser(): FormGroup {
    return this.formBuilder.group({
      user: ''
    })
  }
  newVariables(): FormGroup {
    return this.formBuilder.group({
      name: '',
      value: '',
      new: true
    })
  }

  addUser() {
    this.privusers.push(this.newUser());
  }

  removeUser(i: number) {
    this.privusers.removeAt(i);
  }
  addVariables() {
    this.variables.push(this.newVariables());
  }
  removeVariables(i: number) {
    this.variables.removeAt(i);
  }


  onSubmit2() {
    this.submitted = true;
    console.log("this.loginForm.invalid", this.settingsForm2)
    if (this.settingsForm2.invalid) {
      return;
    } else {
      let users = [];
      let variables = [];
      this.settingsForm.value.variables.forEach(it =>
        variables.push({ "name": it.name, "value": it.value })
      );
      let sendData = {
        "datalakeurl_dev": this.settingsForm2.value.datalakeurl_dev,
        "datalakeuname_dev": this.settingsForm2.value.datalakeuname_dev,
        "datalakepass_dev": this.settingsForm2.value.datalakepass_dev,
        "test": "123",
        "privusers": users,
        "variables": variables,
        "cyboturl": this.userData.cyboturl,
        "cybotuname": this.userData.cybotuname,
        "cybotpass": this.userData.cybotpass,
        "datalakeurl": this.userData.datalakeurl,
        "datalakeuname": this.userData.datalakeuname,
        "datalakepass": this.userData.datalakepass,
      }
      this.isSaving = true;
      this.SsoService.setSystemvArs(sendData).subscribe((data: any) => {
        console.log("getDashboards", data)
        if (data == "updated") {
          localStorage.setItem("datalakeurl", sendData.datalakeurl);
          localStorage.setItem("datalakepass", sendData.datalakepass);
          localStorage.setItem("datalakeuname", sendData.datalakeuname);
          localStorage.setItem("ACCESS_TOKEN",
            "Basic " + btoa(sendData.datalakeuname + ":" + sendData.datalakepass));
          localStorage.setItem("datalakeurl_dev", sendData.datalakeurl_dev);
          localStorage.setItem("datalakepass_dev", sendData.datalakepass_dev);
          localStorage.setItem("datalakeuname_dev", sendData.datalakeuname_dev);
          localStorage.setItem("cybotpass", sendData.cybotpass);
          localStorage.setItem("cybotuname", sendData.cybotuname);
          localStorage.setItem("cyboturl", sendData.cyboturl);
          this.isSuccess = true;
          this.isSaving = false;
          setTimeout(() => this.router.navigate(["/home"], {}), 2000)
        }
      })
    }
  }
  getHealthStatus() {
    this.common.getHealthStatus().subscribe(
      (res) => {
        console.log('Health Service', res);

      }, (error) => {
        console.log(error);

      }
    )
  }
  getCYBOTHealth() {
    this.settingsService.getCYBOTHealth().subscribe(
      (res) => {
        console.log('getCYBOTHealth', res);
        this.CYBOTHealthData = res[0]

      }, (error) => {
        console.log(error);

      }
    )
  }
  getDatalikeHealth() {
    this.settingsService.getDatalikeHealth().subscribe(
      (res) => {
        console.log('getDatalikeHealth', res[0]);
        this.DatalikeHealthData = res[0]

      }, (error) => {
        console.log(error);

      }
    )
  }

  dataCheck(data, list) {
    if (list == 'data') {
      return data['node.data']
    } else if (list == 'total') {
      return data['node.total']
    } else {
      return ' '
    }
  }


  loadTable5() {
    // this.loader = true;
    let data = {
      query: {
        match_all: {},
      },
    };
    var table = $('#datatable5').DataTable();
    if (table)
      table.destroy();
    let tenantID = this.tenantID;
    if (tenantID == undefined || tenantID == "") {
      this.common.apiListAll(data).subscribe(
        (response: any) => {
          let data = response.hits.hits;
          this.tableData5 = [];

          // console.log("datadatadata", data);
          data.forEach((item) => {
            if (item._source.api_name != undefined) {
              let t = {
                id: item._id,
                index: item._index,
                doc: item._doc,
                api_name: item._source.api_name,
                method: item._source.method,
                url: item._source.url,
                count: item._source.count,
                header: item._source.header,
                body: item._source.body,
              };
              this.tableData5.push(t);
            }
          });
          this.tableData5 = this.tableData5;
          // this.dtTrigger5.next();
          // this.isDtInitialized[5] = true;
          setTimeout(() => {
            // this.loader = false;
          }, 600);
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      this.common.apiList(data, tenantID).subscribe(
        (response: any) => {
          //// console.log("getapiList", response);
          let data = response.hits.hits;
          this.tableData5 = [];
          data.forEach((item) => {
            let t = {
              id: item._id,
              index: item._index,
              doc: item._doc,
              api_name: item._source.api_name,
              method: item._source.method,
              url: item._source.url,
              api_key: item._source.api_key,
              header: item._source.header,
              body: item._source.body,
            };
            this.tableData5.push(t);
          });
          this.tableData5 = this.tableData5;
          // this.dtTrigger5.next();
          // this.isDtInitialized[5] = true;
          setTimeout(() => {
            // this.loader = false;
          }, 600);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }


  getNotifications() {
    let tenantID = this.tenantID;
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      this.common.getNotifications(tenantID).subscribe(
        (response: any) => {
          console.log("getNotifications", response)
          let data = response.hits.hits;
          if (data[0]) {
            // if (data[0]._source.threat_hunting_report != undefined) this.threat_hunting_report_value = data[0]._source.threat_hunting_report;
            // if (data[0]._source.threat_intelligence_individual != undefined) this.threat_intelligence_individual_value = data[0]._source.threat_intelligence_individual;
            // if (data[0]._source.threat_intelligence_weekly != undefined) this.threat_intelligence_weekly_value = data[0]._source.threat_intelligence_weekly;
            // if (data[0]._source.threat_intelligence_monthly != undefined) this.threat_intelligence_monthly_value = data[0]._source.threat_intelligence_monthly;

            if (data[0]._source.threat_hunting_report != undefined) { this.notificationsForm.controls["threat_hunting_report"].setValue(data[0]._source.threat_hunting_report == 'yes' ? true : false); this.threat_hunting_report_value = data[0]._source.threat_hunting_report; };
            if (data[0]._source.threat_intelligence_individual != undefined) { this.notificationsForm.controls["threat_intelligence_individual"].setValue(data[0]._source.threat_intelligence_individual == 'yes' ? true : false); this.threat_intelligence_individual_value = data[0]._source.threat_intelligence_individual; };
            if (data[0]._source.threat_intelligence_weekly != undefined) { this.notificationsForm.controls["threat_intelligence_weekly"].setValue(data[0]._source.threat_intelligence_weekly == 'yes' ? true : false); this.threat_intelligence_weekly_value = data[0]._source.threat_intelligence_weekly };
            if (data[0]._source.threat_intelligence_monthly != undefined) { this.notificationsForm.controls["threat_intelligence_monthly"].setValue(data[0]._source.threat_intelligence_monthly == 'yes' ? true : false); this.threat_intelligence_monthly_value = data[0]._source.threat_intelligence_monthly };


            if (data[0]._source.threat_intelligence_weekly_email != undefined) this.notificationsForm.controls["threat_intelligence_weekly_email"].setValue(data[0]._source.threat_intelligence_weekly_email);
            if (data[0]._source.threat_intelligence_monthly_email != undefined) this.notificationsForm.controls["threat_intelligence_monthly_email"].setValue(data[0]._source.threat_intelligence_monthly_email);
            if (data[0]._source.threat_intelligence_daily_email != undefined) this.notificationsForm.controls["threat_intelligence_daily_email"].setValue(data[0]._source.threat_intelligence_daily_email);
            if (data[0]._source.threat_hunting_report_email != undefined) this.notificationsForm.controls["threat_hunting_report_email"].setValue(data[0]._source.threat_hunting_report_email);
            if (data[0]._id != undefined) this.notificationsForm.controls["id"].setValue(data[0]._id);
          }
        },
        (error) => {
          this.isSnapDeleting = false;
          console.log("error", error);
        }
      );
    }
  }
  updateNotifications() {
    let tenantID = this.tenantID;
    console.log("tenantID", tenantID)
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      this.isLoading = true
      let id = this.notificationsForm.value.id
      let data = {
        threat_hunting_report: this.threat_hunting_report_value,
        threat_intelligence_individual: this.threat_intelligence_individual_value,
        threat_intelligence_weekly: this.threat_intelligence_weekly_value,
        threat_intelligence_monthly: this.threat_intelligence_monthly_value,
        threat_intelligence_monthly_email: this.notificationsForm.value.threat_intelligence_monthly_email,
        threat_intelligence_daily_email: this.notificationsForm.value.threat_intelligence_daily_email,
        threat_intelligence_weekly_email: this.notificationsForm.value.threat_intelligence_weekly_email,
        threat_hunting_report_email: this.notificationsForm.value.threat_hunting_report_email,
        emails: this.notificationsForm.value.emails
      };
      let d1 = {
        doc: data,
      };
      this.common.updateNotifications(d1, tenantID, id).subscribe(
        (response: any) => {
          this.isLoading = false
        },
        (error) => {
          this.isLoading = false
          console.log("error", error);
        }
      );
    }
  }
  roleChange(d) {
    let k = d.split('_')
    if (k[0] == 'ab2') {
      let p = 'thp_' + d
      return p
    } else {
      return d
    }
  }
  handleCheckChange(ev, type) {
    let e = ev.target.checked
    if (type == 'threat_intelligence_weekly') {
      if (e == true) {
        this.threat_intelligence_weekly_value = 'yes'
      } else {
        this.threat_intelligence_weekly_value = 'no'
      }
    }
    else if (type == 'threat_intelligence_monthly') {
      if (e == true) {
        this.threat_intelligence_monthly_value = 'yes'
      } else {
        this.threat_intelligence_monthly_value = 'no'
      }
    }
    else if (type == 'threat_intelligence_individual') {
      if (e == true) {
        this.threat_intelligence_individual_value = 'yes'
      } else {
        this.threat_intelligence_individual_value = 'no'
      }
    }
    else if (type == 'threat_hunting_report') {
      if (e == true) {
        this.threat_hunting_report_value = 'yes'
      } else {
        this.threat_hunting_report_value = 'no'
      }
    }

  }

  backupClick() {
    this.backupStatus = true;
  }
  getAllSnapshots() {
    this.snapshotList = [];
    this.isFetchingSnaps = true;
    this.backupStatus = false;
    this.common.getSnapshots().subscribe(
      (response: any) => {
        this.isFetchingSnaps = false;
        let snaps = response.snapshots;
        for (let i in snaps) {
          this.snapshotList.push({
            name: snaps[i].snapshot,
            start_time: snaps[i].start_time_in_millis,
            end_time: snaps[i].end_time_in_millis,
          });
        }
      },
      (error) => {
        this.isFetchingSnaps = false;
        console.log("error", error);
      }
    );
  }

  confirmSnapDelete(name) {
    this.isSnapDeleting = true;
    this.backupStatus = false;
    this.common.deleteSnapshot(name).subscribe(
      (response: any) => {
        this.isSnapDeleting = false;
        this.deleteSnap = {};
        this.getAllSnapshots();
        this.delmodalRef.close();
      },
      (error) => {
        this.isSnapDeleting = false;
        console.log("error", error);
      }
    );
  }

  confirmSnapRestore(name) {
    this.isSnapDeleting = true;
    this.backupStatus = false;
    this.common.restoreSnapshot(name).subscribe(
      (response: any) => {
        this.isSnapDeleting = false;
        this.deleteSnap = {};
        this.delmodalRef.close();
      },
      (error) => {
        this.isSnapDeleting = false;
        console.log("error", error);
      }
    );
  }
  frequencyChange(event) {
    this.frequencyData = event.target.value;
    // console.log("this.frequencyData", this.frequencyData)
  }
  retentionChange(event) {
    this.retentionData = event.target.value;
    if (this.retentionData < 0) {
      this.retentionNumError = true
    } else {
      this.retentionNumError = false
    }
  }

  numbertostoreChange(event) {
    this.numbertostoreData = event.target.value;
    if (this.numbertostoreData < 0) {
      this.numbertostoreNumError = true
    } else {
      this.numbertostoreNumError = false
    }
  }
  enableSyslogClick(event) {
    let e = event.target.checked;
    if (!e) {
      this.notifyF2.controls['ip1'].disable();
      this.notifyF2.controls['ip2'].disable();
      this.notifyF2.controls['select1'].disable();
      this.isDisabledSiem = true
    } else {
      this.notifyF2.controls['ip1'].enable();
      this.notifyF2.controls['ip2'].enable();
      this.notifyF2.controls['select1'].enable();
      this.isDisabledSiem = false
    }
  }
  getSiem() {
    let tenantID = this.tenantID;
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      this.common.getSiem(tenantID).subscribe(
        (response: any) => {
          let data = response.hits.hits;
          if (data[0]) {
            this.siemForm.controls["syslog_ip"].setValue(data[0]._source.syslog_ip);
            this.siemForm.controls["protocol"].setValue(data[0]._source.protocol);
            this.siemForm.controls["syslog_port"].setValue(data[0]._source.syslog_port);
            this.siemForm.controls["id"].setValue(data[0]._id);
          }
        },
        (error) => {
          this.isSnapDeleting = false;
          console.log("error", error);
        }
      );
    }
  }
  addSoar() {
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      let objArr = [];
      objArr[0] = "Create Integration";
      objArr[2] = "Create";
      let ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-dialog-all common-modal",
      };
      // const modalRef = this.modalService.open(AddSoarComponent, ngbModalOptions);
      // modalRef.componentInstance.objArr = objArr;
      // modalRef.result.then(
      //   (result) => { },
      //   (reason) => {
      //     // console.log("reason", reason);
      //     if (reason.status == "created") {
      //       let item = reason.data;
      //       this.tableData5.push(item);
      //     }
      //   }
      // );
    }
  }
  updateSiem() {
    let tenantID = this.tenantID;
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      let id = this.siemForm.value.id
      let data = {
        syslog_ip: this.siemForm.value.syslog_ip,
        protocol: this.siemForm.value.protocol,
        syslog_port: this.siemForm.value.syslog_port,
      };
      let d1 = {
        doc: data,
      };
      this.common.updateSiem(d1, tenantID, id).subscribe(
        (response: any) => {
          console.log("response", response);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  }
  editSoar(data, i) {

    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      data.tenant_id = this.tenantID;
      let objArr = [];
      objArr[0] = "Soar Edit";
      objArr[1] = data;
      objArr[2] = "Update";
      objArr[3] = i;
      let ngbModalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false,
        windowClass: "modal-dialog-all common-modal",
      };
      // const modalRef = this.modalService.open(AddSoarComponent, ngbModalOptions);
      // modalRef.componentInstance.objArr = objArr;
      // modalRef.result.then(
      //   (result) => { },
      //   (reason) => {
      //     if (reason.status == "update") {
      //       let item = reason.data;
      //       this.tableData5[reason.index] = item;
      //     }
      //   }
      // );
    }

  }

  deleteSoar(data, i) {
    // console.log("this.tenantID", this.tenantID)
    if (this.tenantID == undefined || this.tenantID == '') {
      // this.alertService.alert(
      //   `Tenant Id was not selected`,
      //   status => {
      //     return
      //   }
      // );
    } else {
      // this.alertService.confirm(
      //   `Are you sure to delete Soar ?`,
      //   status => {
      //     if (status == true) {
      //       let id = data.id;
      //       let tenantID = this.tenantID;
      //       this.common.soarDelete(tenantID, id).subscribe(
      //         (response: any) => {
      //           // console.log("response", response);
      //           if (response.result == "deleted") {
      //             // console.log("response", response.result);
      //             this.tableData5.splice(i, 1);
      //           }
      //         },
      //         (error) => {
      //           console.log("error", error);
      //         }
      //       );
      //     }
      //   }
      // );
    }



  }


  viewSoar(data, i, viewmodal: any) {

    // console.log(content, modalId);
    this.modalId = 5;
    this.selectedContent = data;
    this.Details = "Integrations Details"
    this.modalService.open(viewmodal, { size: "lg", centered: true });

  }
  openSnapModal(viewmodal, data) {
    this.deleteSnap = data;
    console.log(data);
    this.delmodalRef = this.modalService.open(viewmodal, { size: "sm", centered: true });
  }
  submitCrone(freq, crone) {
    this.isSubmittingCrone = true;
    if (this.retentionData == undefined || this.retentionData == 0) {
      this.isSubmittingCrone = false;
      if (this.retentionData < 0) {
        this.retentionNumError = true
        return
      } else {
        this.retentionDataError = true;
        return
      }
    } else {
      this.retentionDataError = false;
    }
    if (this.numbertostoreData == undefined || this.numbertostoreData == 0) {
      this.isSubmittingCrone = false;
      if (this.numbertostoreData < 0) {
        this.numbertostoreNumError = true
        return
      } else {
        this.numbertostoreDataError = true;
        return
      }
    } else {
      this.numbertostoreDataError = false;
    }




    let schedule = '';
    if (freq == 'year') {
      schedule = crone.minute + ' ' + crone.hour + ' ' + crone.day + ' ' + crone.month + ' * ?';
    } else if (freq == 'month') {
      schedule = crone.minute + ' ' + crone.hour + ' ' + crone.day + ' *' + ' *' + ' ?';
    } else if (freq == 'week') {
      schedule = crone.minute + ' ' + crone.hour + ' *' + ' *' + crone.week + ' ?';
    } else if (freq == 'day') {
      schedule = crone.minute + ' ' + crone.hour + ' *' + ' *' + ' * ?';
    } else if (freq == 'hour') {
      schedule = crone.minute + ' *' + ' *' + ' *' + ' * ?';
    }
    let data = {
      "schedule": schedule,
      "name": "nightly-snapshots",
      "repository": "thp_repository",
      "config": {
        "indices": ["*"]
      },
      "retention": {
        "expire_after": this.retentionData + 'd',
        "min_count": 2,
        "max_count": this.numbertostoreData
      }
    }
    this.common.submitCroneName(data).subscribe(
      data => {
        this.isSubmittingCrone = false;
        this.isCroneCreated = true;
        let $this = this;
        setTimeout(function () { $this.isCroneCreated = false; }, 3000);
      },
      error => {
        console.log(error);
        alert("Error Creating Crone.. Ty Again!")
        this.isSubmittingCrone = false;
      });
  }
  getcybotvars() {
    this.SsoService.getCybotvars().subscribe((data: any) => {
      console.log("getCybotvars", data)
      let cybotuname = data.cybotuname
      let check = cybotuname.slice(-2);
      if (check == 'rw') {
        this.adminstatus = true
      } else {
        this.adminstatus = false
      }
    })
  }


  getlicenseinfo() {
    this.SsoService.getlicenseinfo().subscribe((data: any) => {
      this.uebaFlage = data.ueba;
      console.log("this.uebaFlage", this.uebaFlage)
      if (data.ueba == 'true') {
        this.settings_tab = {
          selectedIndex: 0,
          tabs: [
            {
              selectedIndex: 0,
              name: "Authentication",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Datalake",
                  icon: "",
                  tab: 'TabContent1'
                },
                {
                  name: "Cybot",
                  icon: "",
                  tab: "TabContent2"
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "Exception",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "TabContent4"
                },
                {
                  name: "UEBA",
                  icon: "",
                  tab: "TabContent5"
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "License",
              icon: "fa fa-key",
              showSingleTab: true,
              show: false,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: ""
                },
                {
                  name: "UEBA",
                  icon: "",
                  tab: ""
                }
              ]

            },

            {
              selectedIndex: 0,
              name: "Health Status",
              icon: "fa fa-key",
              showSingleTab: true,
              show: false,
              sub_tab: [
                {
                  name: "Healthy",
                  icon: "",
                  tab: "HealthStatus"
                }
              ]

            }
            // , {
            //   selectedIndex: 0,
            //   name: "Backup and restore",
            //   icon: "fa fa-key",
            //   showSingleTab: true,
            //   show: true,
            //   sub_tab: [
            //     {
            //       name: "Investigate",
            //       icon: "",
            //       tab: "backupinvestigate"
            //     },
            //     {
            //       name: "UEBA",
            //       icon: "",
            //       tab: ""
            //     }
            //   ]

            // }
            ,
            {
              selectedIndex: 0,
              name: "Integrations",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "integrationinvestigate"
                },
                // {
                //   name: "UEBA",
                //   icon: "",
                //   tab: "integrationueba"
                // }
              ]

            },

            {
              selectedIndex: 0,
              name: "Notifications",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "notificationinvestigate"
                },
                // {
                //   name: "UEBA",
                //   icon: "",
                //   tab: ""
                // }
              ]

            },

            {
              selectedIndex: 0,
              name: "SIEM Integration",
              icon: "fa fa-key",
              showSingleTab: true,
              show: false,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "siemintegrationinvestigate"
                },
                {
                  name: "UEBA",
                  icon: "",
                  tab: ""
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "Profile settings",
              icon: "fa fa-key",
              showSingleTab: false,
              show: false,
              sub_tab: [
                {
                  name: "Password",
                  icon: "",
                  tab: ""
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "Date Format",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Date",
                  icon: "",
                  tab: "timeZoneSettings"
                }
              ]
            }

          ]
        }
      } else {
        this.settings_tab = {
          selectedIndex: 0,
          tabs: [
            {
              selectedIndex: 0,
              name: "Authentication",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Datalake",
                  icon: "",
                  tab: 'TabContent1'
                },
                {
                  name: "Cybot",
                  icon: "",
                  tab: "TabContent2"
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "Exception",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "TabContent4"
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "License",
              icon: "fa fa-key",
              showSingleTab: true,
              show: false,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: ""
                },
                {
                  name: "UEBA",
                  icon: "",
                  tab: ""
                }
              ]

            },

            {
              selectedIndex: 0,
              name: "Health Status",
              icon: "fa fa-key",
              showSingleTab: true,
              show: false,
              sub_tab: [
                {
                  name: "Healthy",
                  icon: "",
                  tab: "HealthStatus"
                }
              ]

            }
            // , {
            //   selectedIndex: 0,
            //   name: "Backup and restore",
            //   icon: "fa fa-key",
            //   showSingleTab: true,
            //   show: true,
            //   sub_tab: [
            //     {
            //       name: "Investigate",
            //       icon: "",
            //       tab: "backupinvestigate"
            //     },
            //     {
            //       name: "UEBA",
            //       icon: "",
            //       tab: ""
            //     }
            //   ]

            // }
            ,
            {
              selectedIndex: 0,
              name: "Integrations",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "integrationinvestigate"
                },
                // {
                //   name: "UEBA",
                //   icon: "",
                //   tab: "integrationueba"
                // }
              ]

            },

            {
              selectedIndex: 0,
              name: "Notifications",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "notificationinvestigate"
                },
                // {
                //   name: "UEBA",
                //   icon: "",
                //   tab: ""
                // }
              ]

            },

            {
              selectedIndex: 0,
              name: "SIEM Integration",
              icon: "fa fa-key",
              showSingleTab: true,
              show: false,
              sub_tab: [
                {
                  name: "Investigate",
                  icon: "",
                  tab: "siemintegrationinvestigate"
                },
                {
                  name: "UEBA",
                  icon: "",
                  tab: ""
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "Profile settings",
              icon: "fa fa-key",
              showSingleTab: false,
              show: false,
              sub_tab: [
                {
                  name: "Password",
                  icon: "",
                  tab: ""
                }
              ]

            },
            {
              selectedIndex: 0,
              name: "Date Format",
              icon: "fa fa-key",
              showSingleTab: true,
              show: true,
              sub_tab: [
                {
                  name: "Date",
                  icon: "",
                  tab: "timeZoneSettings"
                }
              ]
            }

          ]
        }
      }
    });
  }
}
function validateEmails(emails: string) {
  return (emails.split(',')
    .map(email => Validators.email(<AbstractControl>{ value: email }))
    .find(_ => _ !== null) === undefined);
}

function emailsValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value === '' || !control.value || validateEmails(control.value)) {
    return null
  }
  return { emails: true };
}