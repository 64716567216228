<div class="card-body m-0 p-0">
    <div class="media">
        <div class="media-body overflow-hidden bg-card">
            <h4 class="count-card-title mb-0">{{data.title}}</h4>
            <!-- <div class="row">
                <div class="col-md-12">
                    <p>{{data.description}}</p>
                </div>


            </div> -->
            <!-- <div class="row">
                <div class="col-md-3 p-0 m-0" *ngFor="let val of itemVal">
                    <div class="count-card" *ngIf="col12items.includes(val['name'])">
                        <h5 class="mb-0 pb-2">{{val["name"]}}</h5>
                        <h3>{{val["val"]}}</h3>
                    </div>
                </div>
            </div> -->
            
            <!-- <div class="col-md-12">
                <div class="count-card h-100">
                    <h5 class="mb-0" style="font-size: 1.2rem;">{{count}}</h5>
                </div>
            </div> -->
            <div class="row px-1 py-0 m-0">
                <ng-container *ngFor="let val of itemVal">
                    <div class="col-md-12" *ngIf="col12items.includes(val['name'])" >
                        <div class="count-card h-100">
                            <h5 class="mb-0" style="font-size: 1.2rem;">{{val['val']}}</h5>
                        </div>
                    </div>
                    <div *ngIf="!col12items.includes(val['name'])" 
                        class="col-md-4 p-1 my-1 mx-0">
                        <div class="count-card" [style.background]="val['color']">
                            <h5 class="">{{val["name"]}}</h5>
                            <h3 class="">{{val["val"]}}</h3>
                        </div>
                        
                    </div>
                </ng-container>
            </div>



        </div>
        <div class="text-primary">
            <i class="{{data.icon}} font-size-24"></i>
        </div>
    </div>
</div>