import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import {
    HttpClient,
    HttpHeaders,
    HttpEventType,
    HttpParams,
    HttpEvent,
    HttpErrorResponse
} from "@angular/common/http";
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const apiUrl = environment.apiUrl;
const loginUrl = environment.loginUrl;
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    HttpOptions: any;

    constructor(private http: HttpClient, private router: Router) { }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    // login tocken check 
    public isLoggedIn() {
        return this.getAccessToken() !== null;

    }
    getAccessToken() {
        return localStorage.getItem('id_token');
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        sessionStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem("id_token");
        localStorage.removeItem("access_token");
        localStorage.removeItem("onBoard");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("ACCESS_TOKEN");
        this.router.navigate(['account/login']);
    }
    handleError(error: HttpErrorResponse) {
        let errorMessage = "Unknown error!";
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        }
        else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message ? error.message : 'No error message'}`;
        }
        console.log(error);
        return throwError(errorMessage);
    }

    loginCybot1(Token: any) {
        this.HttpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization": Token
            })
        };
        return this.http
            .get<object>(loginUrl + "/_security/_authenticate", this.HttpOptions)
            .pipe(catchError(this.handleError));
    }
}

