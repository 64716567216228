import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [];

export const ALERT: MenuItem[] = [
    {
        id: "ISO-27001",
        label: 'ISO-27001',
        icon: 'fa-angle-double-right',
        link: '/alert/ISO-27001'
    },
    {
        id: "NIST",
        label: 'NIST',
        icon: 'fa-angle-double-right',
        link: '/alert/02'
    },
    {
        id: "PCI-DSS",
        label: 'PCI-DSS',
        icon: 'fa-angle-double-right',
        link: '/alert/03'
    }  // {
    //     id: 3,
    //     label: 'Dashboard-2',
    //     icon: 'fa-angle-double-right',
    //     link: '2'
    // },
    // {
    //     id: 4,
    //     label: 'Dashboard-3',
    //     icon: 'fa-angle-double-right',
    //     link: '3'
    // }

];

export const COMPILANCE: MenuItem[] = [
    {
        id: "ISO27001Compliance",
        label: 'complaints-iso27001.json',
        icon: 'fa-angle-double-right',
        link: '/controlview/complaints-iso27001.json',
        description : 'List of controls associated with ISO 27001 compliance standard, along with the current status. This can help the user / auditor to track the overall compliance status of the organization for the each control.'
    },
    {
        id: "NIST",
        label: 'NIST',
        icon: 'fa-angle-double-right',
        link: '/controlview/NIST',
        description : 'List of controls associated with NIST compliance standard, along with the current status. This can help the user / auditor to track the overall compliance status of the organization for the each control.'
    },
    {
        id: "PCI-DSS",
        label: 'PCI-DSS',
        icon: 'fa-angle-double-right',
        link: '/controlview/PCI-DSS',
        description : 'List of controls associated with PCI-DSS compliance standard, along with the current status. This can help the user / auditor to track the overall compliance status of the organization for the each control.'
        
    },
    // {
    //     id: "sa5",
    //     label: 'SA5',
    //     icon: 'fa-angle-double-right',
    //     link: '/controlview/sa5'
    // },
    // {
    //     id: "sa6",
    //     label: 'SA6',
    //     icon: 'fa-angle-double-right',
    //     link: '/controlview/sa6'
    // },
    // {
    //     id: "sa11",
    //     label: 'SA11',
    //     icon: 'fa-angle-double-right',
    //     link: '/controlview/sa11'
    // }
];
