import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { ActivbytesThreadComponent } from './activbytes-thread/activbytes-thread.component';
import { ChartDashboardComponent } from './chart-dashboard/chart-dashboard.component';
import { ViewEventDetailsComponent } from './community/view-event-details/view-event-details.component';
import { CorrelationStudioComponent } from './correlation-studio/correlation-studio.component';
import { CybotDashboardViewComponent } from './cybot-dashboard-view/cybot-dashboard-view.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashbordListComponent } from './dashbord-list/dashbord-list.component';
import { DashbordTestComponent } from './dashbord-test/dashbord-test.component';
import { HeadlinesComponent } from './headlines/headlines.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HunterDetectionComponent } from './hunter/hunter-detection/hunter-detection.component';
import { HunterHomeComponent } from './hunter/hunter-home/hunter-home.component';
import { InvestigationRepetitionComponent } from './investigation/investigation-repetition/investigation-repetition.component';
import { ManagementSummaryComponent } from './management-summary/management-summary.component';
import { HuntListComponent } from './rule-studio/hunt-list/hunt-list.component';
import { InvestigationComponent } from './rule-studio/investigation/investigation.component';
import { RuleStudioCustomEditComponent } from './rule-studio/rule-studio-custom-edit/rule-studio-custom-edit.component';
import { RuleStudioCustomComponent } from './rule-studio/rule-studio-custom/rule-studio-custom.component';
import { RuleStudioHunterEditComponent } from './rule-studio/rule-studio-hunter-edit/rule-studio-hunter-edit.component';
import { RuleStudioHunterComponent } from './rule-studio/rule-studio-hunter/rule-studio-hunter.component';
import { SecurityIncidentsIntelligenceComponent } from './security-incidents-intelligence/security-incidents-intelligence.component';
import { SettingsComponent } from './settings/settings.component';
import { VulnerabilitiesExploitComponent } from './vulnerabilities-exploit/vulnerabilities-exploit.component';
import { VulnerabilitiesSummaryComponent } from './vulnerabilities-summary/vulnerabilities-summary.component';

const routes: Routes = [
    { path: 'list', component: DashbordTestComponent, canActivate: [AuthGuard] },
    { path: '', component: HomePageComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashbordListComponent, canActivate: [AuthGuard] },
    { path: 'view-event-details', component: ViewEventDetailsComponent, canActivate: [AuthGuard] },
    { path: 'test', component: DashbordTestComponent, canActivate: [AuthGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'management-summary', component: ManagementSummaryComponent, canActivate: [AuthGuard] },
    { path: 'activebytes-threat', component: ActivbytesThreadComponent, canActivate: [AuthGuard] },
    { path: 'security-incidents', component: SecurityIncidentsIntelligenceComponent, canActivate: [AuthGuard] },
    { path: 'headlines', component: HeadlinesComponent, canActivate: [AuthGuard] },
    { path: 'vulnerabilities-summary', component: VulnerabilitiesSummaryComponent, canActivate: [AuthGuard] },
    { path: 'cybot-dashboard/:id', component: CybotDashboardViewComponent, canActivate: [AuthGuard] },
    { path: 'vulnerabilities-exploit', component: VulnerabilitiesExploitComponent, canActivate: [AuthGuard] },
    { path: 'rule-studio/investigation', component: InvestigationComponent, canActivate: [AuthGuard] },
    { path: 'rule-studio/hunter', component: HuntListComponent, canActivate: [AuthGuard] },
    { path: 'rule-studio/custom', component: RuleStudioCustomComponent, canActivate: [AuthGuard] },
    { path: 'rule-studio/custom-hunter', component: RuleStudioHunterComponent, canActivate: [AuthGuard] },
    { path: 'rule-studio/custom-edit/:id', component: RuleStudioCustomEditComponent, canActivate: [AuthGuard] },
    { path: 'rule-studio/custom-hunter-edit/:id', component: RuleStudioHunterEditComponent, canActivate: [AuthGuard] },
    { path: 'hunter', loadChildren: () => import('./hunter/hunter.module').then(m => m.HunterModule), canActivate: [AuthGuard] },
    { path: 'investigate/repetition', component: InvestigationRepetitionComponent, canActivate: [AuthGuard] },
    { path: 'correlation-studio', component: CorrelationStudioComponent, canActivate: [AuthGuard] },
    { path: 'charts', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule) },
    { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
    { path: 'alert', loadChildren: () => import('./alert/alert.module').then(m => m.AlertModule) },
    { path: 'controlview', loadChildren: () => import('./controlview/controlview.module').then(m => m.ControlviewModule), canActivate: [AuthGuard] },
    { path: 'investigation', loadChildren: () => import('./investigation/investigation.module').then(m => m.InvestigationModule), canActivate: [AuthGuard] },
    { path: 'custom-hunting', loadChildren: () => import('./custom-hunting/custom-hunting.module').then(m => m.CustomHuntingModule), canActivate: [AuthGuard] },
    { path: 'ueba', loadChildren: () => import('./ueba/ueba.module').then(m => m.UebaModule), canActivate: [AuthGuard] },
    { path: 'rule-studio', loadChildren: () => import('./rule-studio/rule-studio.module').then(m => m.RuleStudioModule), canActivate: [AuthGuard] },
    { path: 'investigate', loadChildren: () => import('./investigation/investigation.module').then(m => m.InvestigationModule), canActivate: [AuthGuard] },
    { path: ':id', component: ChartDashboardComponent, canActivate: [AuthGuard] },
    { path: 'hunter/detection/:hunt_id', component: HunterDetectionComponent, canActivate: [AuthGuard] },
    { path: ':id/:title', component: ChartDashboardComponent, canActivate: [AuthGuard] }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
