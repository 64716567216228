import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { BarChartType, ChartType, EchartType, Stat } from "../view-dashboard.model";
import { CommonService } from "src/app/core/services/common.service";
import * as echarts from "echarts";
import { Observable, Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import * as localization from 'moment/locale/fr';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
moment.updateLocale('fr', localization);
declare var $: any;
declare var jQuery: any;
moment.updateLocale('fr', localization);
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { async } from "@angular/core/testing";
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DatePipe } from "@angular/common";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";
@Component({
  selector: "app-view-event-details",
  templateUrl: "./view-event-details.component.html",
  styleUrls: ["./view-event-details.component.scss"],
})
export class ViewEventDetailsComponent implements OnInit {

  fromHtml1:HTMLElement;

  tableDataSeries = []
  public iocTypeBar: ChartType;
  public feedDisribution: ChartType;

  attributeTypePieChart;
  attributePieChart;
  modalId
  dtTrigger: Subject<any> = new Subject<any>();
  detectionTableData = [];
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 5,
    processing: true,
    lengthMenu: [5, 10, 15, 20],
    order: []
  };

  dtTrigger1: Subject<any> = new Subject<any>();
  detectionTableDatatemp = [];
  dtTrigger2: Subject<any> = new Subject<any>();
  loader: Boolean = false;
  loader1: Boolean = false;
  tableLoader: boolean = false;
  table2Loader: boolean = false;
  eventId: number;
  tenantID = "ab2";
  eventData = [];
  eventDescription = [];
  attributeTypeTimeline = [];
  attributeCategoryTimeline = [];
  tableResponseData = []
  printClick: boolean = true;
  button = 'Save PDF';
  isLoading = true;

  attributeTypeChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      x: "center",
      y: "bottom",
      data: [],
      textStyle: { color: "rgb(238, 238, 238)" },
    },
    color: ["#1cbb8c", "#5664d2", "#fcb92c", '#4aa3ff', "#ff3d60", "#9c27b0", "#ff5722", "#009688"],
    series: [
      {
        name: "IOC TYPE",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 0, name: "CIRCL" },
          { value: 0, name: "Completed" },
          { value: 0, name: "InProgress" },
        ],
      },
    ],
  };

  attributeCategoryChart: EchartType = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: [],
      textStyle: { color: "rgb(238, 238, 238)" },
    },
    color: ["#1cbb8c", "#5664d2", "#fcb92c"],
    series: [
      {
        name: "IOC CATEGORY",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 0, name: "CIRCL" },
          { value: 0, name: "Completed" },
          { value: 0, name: "InProgress" },
        ],
      },
    ],
  };

  attributeTypes: ChartType = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'flat',
        columnWidth: '20%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: ["#1cbb8c", "#5664d2", "#fcb92c", '#4aa3ff', "#ff3d60", "#9c27b0", "#ff5722", "#009688"],
    series: [{
      name: "sha1",
      data: []
    }, {
      name: "sha256",
      data: []
    }, {
      name: "link",
      data: []
    }, {
      name: "malware-sample",
      data: []
    }, {
      name: "url",
      data: []
    }, {
      name: "ip-dst",
      data: []
    }, {
      name: "domain",
      data: []
    }, {
      name: "md5",
      data: []
    },
    {
      name: "filename",
      data: []
    }],
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: '#f1f1f1'
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return 'Count' + val + '..';
        }
      }
    }
  };

  attributeCategories: ChartType = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'flat',
        columnWidth: '5%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: ["#1cbb8c", "#5664d2", "#fcb92c"],
    series: [{
      name: "Payload delivery",
      data: []
    }, {
      name: "Network activity",
      data: []
    }, {
      name: "External analysis",
      data: []
    }],
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    grid: {
      borderColor: '#f1f1f1'
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return 'Count' + val + '..';
        }
      }
    }
  };

  constructor(
    public common: ThreatIntelligenceService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) { 
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.eventId = params.id;
        this.getEventDetails(params.id);
        this.getEventDescription(params.id);
        this.attributeTypeChartData(params.id);
        this.attributeCategoryChartData(params.id);
        this.getIndicatorTable(params.id);
        this.getAttributeTypeTimeline(params.id);
        this.getAttributeCategoryTimeline(params.id);
        this.getAttributeTypesBar(params.id);
        this.getAttributeCategoriesBar(params.id);


        this.iocTypeBar = {
          series: [],
          chart: {
            type: "bar",
            height: 200,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '35%'
            }
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: -10
          }
        }

        this.feedDisribution = {
          series: [],
          chart: {
            type: "bar",
            height: 200,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '35%'
            }
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: -10
          }
        };


      } else {
        this.router.navigateByUrl("/community-threat");
      }
    });
  }

  ngAfterViewInit(): void {
    this.attributeTypePieChart = echarts.init(
      document.getElementById("attributeTypePie")
    );
    this.attributePieChart = echarts.init(
      document.getElementById("attributePie")
    );
  }

  isCommunityFeedLoading = false;
  attributeTypeChartData(id) {
    this.isCommunityFeedLoading = true;
    let data = {
      "size": 100, "query": {
        "bool": {
          "must": [], "filter": [{ "match_all": {} }, { "match_phrase": { "event_id": id } }
          ], "should": [], "must_not": []
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "attribute_type",
            "order": {
              "_count": "desc"
            },
            "size": 100
          }
        }
      }
    };
    

    this.common.getAttributecategoryChart(data).subscribe(
      (response: any) => {
        this.isCommunityFeedLoading = false;
        console.log('Response got :', response)
        this.tableResponseData = response.hits.hits

        let k = 0
          this.tableResponseData.forEach(data => {
            this.detectionTableDatatemp.push([k + 1, data._source.attribute_value,
            data._source.attribute_type, data._source.attribute_category, data._source.attribute_comment, data._source.attribute_id,
            this.timeConvert(data._source.attribute_timestamp)
            ])
            k++
          });

        let buckets = response.aggregations[2].buckets;
        let datas = [];
        let series = []
        this.attributeTypeChart.legend.data = [];
        for (let i in response.aggregations[2].buckets) {
          datas.push({
            value: buckets[i] != undefined ? buckets[i].doc_count : 0,
            name: buckets[i] != undefined ? buckets[i].key : "",
          });

          series.push({
            name: [buckets[i].key.toUpperCase()],
            data: [buckets[i].doc_count]
          })

          this.attributeTypeChart.legend.data.push(
            buckets[i] != undefined ? buckets[i].key : ""
          );
        }
        this.dtTrigger.next();
        this.iocTypeBar.series = series
        // this.attributeTypeChart.series[0].data = datas;
        this.attributeTypePieChart.setOption(this.attributeTypeChart);
      },
      (error) => {
        this.isCommunityFeedLoading = false;
      }
    );
  }

  isEventDisribution1 = false
  attributeCategoryChartData(id) {
    this.isEventDisribution1 = true
    let data = {
      "size": 0, "query": {
        "bool": {
          "must": [], "filter": [{ "match_all": {} }, { "match_phrase": { "event_id": id } }
          ], "should": [], "must_not": []
        }
      },
      "aggs": {
        "2": {
          "terms": {
            "field": "attribute_category",
            "order": {
              "_count": "desc"
            },
            "size": 100
          }
        }
      }
    };
    this.common.getAttributecategoryChart(data).subscribe(
      (response: any) => {
        this.isEventDisribution1 = false
        let buckets = response.aggregations[2].buckets;
        let datas = [];
        let series = []
        this.attributeCategoryChart.legend.data = [];
        for (let i in response.aggregations[2].buckets) {
          datas.push({
            value: buckets[i] != undefined ? buckets[i].doc_count : 0,
            name: buckets[i] != undefined ? buckets[i].key : "",
          });

          series.push({
            name: [buckets[i].key],
            data: [buckets[i].doc_count]
          })
          this.attributeCategoryChart.legend.data.push(
            buckets[i] != undefined ? buckets[i].key : ""
          );
        }

        this.feedDisribution.series = series
        this.attributeCategoryChart.series[0].data = datas;
        this.attributePieChart.setOption(this.attributeCategoryChart);
      },
      (error) => {
        this.isEventDisribution1 = false
      }
    );
  }

  getIndicatorTable(id) {
    let data = {
      query: {
        bool: {
          must: [],
          filter: [{ match_all: {} }, { match_phrase: { event_id: id } }],
          should: [],
          must_not: [],
        },
      },
    };
    this.tableLoader = true;
    this.common.getAttributecategoryChart(data).subscribe(
      (response: any) => {
        if (response) {
          // let aggregations = response.aggregations;
          // this.detectionTableData = [];
          // if (aggregations != undefined) {
          //   this.detectionTableData.push({
          //     event: aggregations[2].buckets[0].key,
          //     feed: aggregations[2].buckets[0][3].buckets[0].key,
          //     eventid:
          //       aggregations[2].buckets[0][3].buckets[0][4].buckets[0].key,
          //     drilldown: "View Event Details",
          //   });
          //   this.dtTrigger.next();
          // }
          this.tableLoader = false;
          this.detectionTableData = response.hits.hits;
          console.log("checking Console.... ?", this.detectionTableData)
          
          this.isLoading = false
          
        }
        this.tableLoader = false;
      },
      (error) => {
        this.tableLoader = false;
      }
    );
  }

  getEventDetails(id) {
    let data = {
      query: {
        bool: {
          must: [],
          filter: [{ match_all: {} }, { match_phrase: { event_id: id } }],
          should: [],
          must_not: [],
        },
      },
    };
    this.table2Loader = true;
    this.common.getEventDetails(data, this.tenantID).subscribe(
      (response: any) => {
        if (response) {
          let data = response.hits.hits[0];
          this.eventData = response.hits.hits;
          this.dtTrigger1.next();
        }
        this.table2Loader = false;
      },
      (error) => {
        this.table2Loader = false;
      }
    );
  }

  getEventDescription(id) {
    let data = {
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_all: {},
            },
            {
              match_phrase: {
                event_id: id,
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
    this.tableLoader = true;
    this.common.getEventDescription(data, this.tenantID).subscribe(
      (response: any) => {
        if (response) {
          let data = response.hits.hits[0];
          this.eventDescription = response.hits.hits;
          this.dtTrigger2.next();
        }
        this.tableLoader = false;
      },
      (error) => {
        this.tableLoader = false;
      }
    );
  }

  getAttributeTypeTimeline(id) {
    let data = {
      size: 0,
      aggs: {
        "2": {
          date_histogram: {
            field: "attribute_timestamp",
            calendar_interval: "1w",
            time_zone: "Asia/Kolkata",
            min_doc_count: 1,
          },
          aggs: {
            "3": {
              terms: {
                field: "attribute_type",
                order: {
                  _count: "desc",
                },
                size: 100,
              },
            },
          },
        },
      },
      query: {
        bool: {
          must: [],
          filter: [{ match_all: {} }, { match_phrase: { event_id: id } }],
          should: [],
          must_not: [],
        },
      },
    };

    this.tableLoader = true;
    this.common.getAttributeTypeTimeline(data, this.tenantID).subscribe(
      (response: any) => {
        if (response) {
          let buckets = response.aggregations[2].buckets;
          this.attributeTypeTimeline = buckets[0][3].buckets;
        }
        this.tableLoader = false;
      },
      (error) => {
        this.tableLoader = false;
      }
    );
  }

  getAttributeCategoryTimeline(id) {
    let data = {
      size: 0,
      aggs: {
        "2": {
          date_histogram: {
            field: "attribute_timestamp",
            calendar_interval: "1w",
            time_zone: "Asia/Kolkata",
            min_doc_count: 1,
          },
          aggs: {
            "3": {
              terms: {
                field: "attribute_category",
                order: {
                  _count: "desc",
                },
                size: 100,
              },
            },
          },
        },
      },
      query: {
        bool: {
          must: [],
          filter: [{ match_all: {} }, { match_phrase: { event_id: id } }],
          should: [],
          must_not: [],
        },
      },
    };

    this.tableLoader = true;
    this.common.getAttributeCategoryTimeline(data, this.tenantID).subscribe(
      (response: any) => {
        if (response) {
          let buckets = response.aggregations[2].buckets;
          this.attributeCategoryTimeline = buckets[0][3].buckets;
        }
        this.tableLoader = false;
      },
      (error) => {
        this.tableLoader = false;
      }
    );
  }

  getAttributeTypesBar(id) {
    let data = {
      "size": 0,
      "aggs": {
        "2": {
          "date_histogram": {
            "field": "attribute_timestamp",
            "calendar_interval": "1w",
            "time_zone": "Asia/Kolkata",
            "min_doc_count": 1
          },
          "aggs": {
            "3": {
              "terms": {
                "field": "attribute_type",
                "order": {
                  "_count": "desc"
                },
                "size": 100
              }
            }
          }
        }
      },
      "query": {
        "bool": {
          "must": [], "filter": [{ "match_all": {} }, { "match_phrase": { "event_id": id } }
          ], "should": [], "must_not": []
        }
      }
    };
    this.common.getCommunityFeedChart(data).subscribe(
      (response: any) => {
        if (response) {
          let dataSeries = [...this.attributeTypes.series];

          for (let k in this.attributeTypes.series) {
            dataSeries[k].data = new Array(response.aggregations[2].buckets.length).fill(0);
          }

          for (let i = 0; i < response.aggregations[2].buckets.length; i++) {
            this.attributeTypes.xaxis.categories.push(moment.unix(response.aggregations[2].buckets[i].key / 1000).format('DD/MM/YYYY'));
            for (let j in response.aggregations[2].buckets[i][3].buckets) {
              for (let k in this.attributeTypes.series) {
                if (dataSeries[k].name == response.aggregations[2].buckets[i][3].buckets[j].key) {
                  dataSeries[k].data[i] = response.aggregations[2].buckets[i][3].buckets[j].doc_count;
                }
                dataSeries[k].data = Array.from(dataSeries[k].data, item => item || 0);
              }
            }
          }
          this.attributeTypes.series = dataSeries;

        }
      },
      error => {
      }
    );
  }

  getAttributeCategoriesBar(id) {
    let data = {
      "size": 0,
      "aggs": {
        "2": {
          "date_histogram": {
            "field": "attribute_timestamp",
            "calendar_interval": "1w",
            "time_zone": "Asia/Kolkata",
            "min_doc_count": 1
          },
          "aggs": {
            "3": {
              "terms": {
                "field": "attribute_category",
                "order": {
                  "_count": "desc"
                },
                "size": 100
              }
            }
          }
        }
      },
      "query": {
        "bool": {
          "must": [], "filter": [{ "match_all": {} }, { "match_phrase": { "event_id": id } }
          ], "should": [], "must_not": []
        }
      }
    };
    this.common.getCommunityFeedChart(data).subscribe(
      (response: any) => {
        if (response) {
          let dataSeries = [...this.attributeCategories.series];
          

          for (let k in this.attributeCategories.series) {
            dataSeries[k].data = new Array(response.aggregations[2].buckets.length).fill(0);
          }

          for (let i = 0; i < response.aggregations[2].buckets.length; i++) {
            this.attributeCategories.xaxis.categories.push(moment.unix(response.aggregations[2].buckets[i].key / 1000).format('DD/MM/YYYY'));
            for (let j in response.aggregations[2].buckets[i][3].buckets) {
              for (let k in this.attributeCategories.series) {
                if (dataSeries[k].name == response.aggregations[2].buckets[i][3].buckets[j].key) {
                  dataSeries[k].data[i] = response.aggregations[2].buckets[i][3].buckets[j].doc_count;
                }
                dataSeries[k].data = Array.from(dataSeries[k].data, item => item || 0);
              }
            }
          }
          // this.attributeCategories.series = dataSeries;
          this.attributeCategories.series = dataSeries;
    
        }
      },
      error => {
      }
    );
  }


  // new pdf generating

  // generatePdf(){
  //   this.captureScreenModelPrint()
  // }

  // openModal(viewmodal: any, modalId: number) {

  //   this.modalId = modalId;
  //   this.modalService.open(viewmodal, { size: "lg printCustModal", centered: true, });
  // }

  getPdf(){
    this.isLoading = true
    this.printClick = false;
    var data2 = document.getElementById("contentToConvert2");
    let head = [['Sl No', 'IOC Value', 'IOC Type', 'IOC Category', 'IOC Comment', 'IOC id', 'Timestamp']]
    let r = 50
    let ll = 220
    let sl = 12
    let pdf = new jspdf("p", "mm", "a4");
    var img = new Image()
    // let period = localStorage.getItem('period');
    let startDate = localStorage.getItem('startDate');
    let endDate = localStorage.getItem('endDate');
    img.src = 'assets/images/logo-dark.png'
    // pdf.addImage(img, 'png', 75, 130, 50, 18)  -------------i commented
    // pdf.setFontSize(11);
    // pdf.setFont(undefined, 'bold');
    //  pdf.text(startDate + "  -  " + endDate, 77, 170);
    // pdf.addPage();  i commented -------------i commented
    pdf.addImage(img, 'png', 150, 5, 45, 15)
    var strArr = pdf.splitTextToSize(this.eventData[0]._source.event_description, ll)
    pdf.setFontSize(11);
    pdf.setFont(undefined, 'bold');
    pdf.text('Event Name', sl, r);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(10);
    pdf.text(': ' + this.eventData[0]._source.event_name, sl + 35, r);
    r = r + 7;
    pdf.setFontSize(11);
    pdf.setFont(undefined, 'bold');
    pdf.text('Event ID', sl, r);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(10);
    pdf.text(': ' + this.eventData[0]._source.event_id, sl + 35, r);
    r = r + 7;
    pdf.setFontSize(11);
    pdf.setFont(undefined, 'bold');
    pdf.text('Feed Name', sl, r);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(10);
    pdf.text(': ' + this.eventData[0]._source.feed_name, sl + 35, r);
    r = r + 7;
    pdf.setFontSize(11);
    pdf.setFont(undefined, 'bold');
    pdf.text('Severity', sl, r);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(10);
    pdf.text(': ' + this.eventData[0]._source.severity, sl + 35, r);
    r = r + 7;
    pdf.setFontSize(11);
    pdf.setFont(undefined, 'bold');
    pdf.text('Event Description', sl, r);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(10);
    r = r + 5;
    pdf.text(strArr, sl, r);
    html2canvas(data2).then((canvas1) => {
      pdf.addPage();
      pdf.addImage(img, 'png', 150, 5, 45, 15)

            if (this.detectionTableDatatemp.length > 0) {
              var header = function (data) {
                pdf.addImage(img, 'png', 150, 5, 45, 15);
              };
              (pdf as any).autoTable({
                columnStyles: {
                  0: { halign: 'center', cellWidth: 15 },
                  1: { halign: 'left', cellWidth: 40 },
                  2: { halign: 'center', cellWidth: 22 },
                  3: { halign: 'center', cellWidth: 30 },
                  4: { halign: 'center', cellWidth: 35 },
                  5: { halign: 'center', cellWidth: 20 },
                  6: { halign: 'center', cellWidth: 30 },
                },
                margin: { top: 25, left: 8 },
                head: head,
                body: this.detectionTableDatatemp,
                didDrawPage: header,
                theme: 'grid'
              })
              pdf.save("community-threat-event.pdf");
              this.printClick = true;
              this.isLoading = false
              this.modalService.dismissAll('Ok click')
            } else {
              pdf.save("community-threat.pdf");
              this.printClick = true;
              this.isLoading = false
              this.modalService.dismissAll('Ok click')
            }
    });
    
  }


  timeConvert(t) {
    let time = this.datePipe.transform(t * 1000, 'medium');
    return time
  }

  goToBack() {
    this.router.navigate(["/community-threat"]);
  }
}
