<app-topbar></app-topbar>
<div class="container mt-top" *ngIf="load">
    <!-- <ng-container *ngIf="isLoaderShow">
        <div class="d-flex pabs100 mt-9"><img class="m-auto" width="400" src="assets/images/loading.gif" /></div>
    </ng-container> -->
    <div class="row">
        <div class="col-md-12">
            <div class="card p-4">
                <div class="card-body ">
                    <h4 class="mb-2">CUSTOM HUNTER</h4>
                    <!-- <p class="card-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita obcaecati
                        dolores dolorem minus a unde odit fugiat exercitationem odio in corrupti repellat perspiciatis
                        cupiditate impedit temporibus itaque tenetur, sit quam.</p> -->
                    <form (ngSubmit)="onSubmit()" [formGroup]="hunterCustom">

                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="hunt_id">HUNT ID *</label>
                                <input type="text" class="form-control rounded-0" id="hunt_id" name="hunt_id"
                                    formControlName="hunt_id" placeholder="HUNT ID">
                                <div class="form-error" *ngIf="hunterCustom.controls.hunt_id.status=='INVALID' && hunterCustom.controls.hunt_id.touched">
                                    Please Enter INVESTIGATE ID
                                </div>
                            </div>
                            <div class="form-group col-md-8">
                                <label for="hunt_name">HUNT NAME *</label>
                                <input type="text" class="form-control rounded-0" id="hunt_name" name="hunt_name"
                                    formControlName="hunt_name" placeholder="HUNT NAME">
                                <div class="form-error"  *ngIf="hunterCustom.controls.hunt_name.status=='INVALID' && hunterCustom.controls.hunt_name.touched">
                                    Please Enter HUNT NAME
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="desc">DESCRIPTION</label>
                                <textarea type="text" class="form-control rounded-0" id="desc" name="desc"
                                    formControlName="description" placeholder="DESCRIPTION HERE"></textarea>
                                <div class="form-error"   *ngIf="hunterCustom.controls.description.status=='INVALID' && hunterCustom.controls.description.touched">
                                    Please Enter DESCRIPTION
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="detaileddescription">DETAILED DESCRIPTION *</label>
                                <ckeditor name="DETAILED Description" formControlName="detailed_description" [editor]="Editor"
                                    [data]="ckeditorData"></ckeditor>
                                <div class="form-error"
                                    *ngIf="hunterCustom.controls.detailed_description.status=='INVALID' && hunterCustom.controls.detailed_description.touched">
                                    Please Enter DESCRIPTION
                                </div>
                            </div>
                        
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-3 mx-0 mt-2">
                                <label for="step_name">Notify</label>
                                <input type="text" class="form-control rounded-0" id="notify"
                                   formControlName="notify" name="notify" placeholder="Notify">
                                <div class="form-error" *ngIf="hunterCustom.controls.notify.status=='INVALID' && hunterCustom.controls.notify.touched" >
                                    Please Enter Notify
                                </div>
                            </div>
                        </div>
                        <div class="step-area mt-2" formArrayName="hunt_step">
                            <div *ngFor="let step of hunt_step['controls']; let i=index" [formGroupName]="i">

                                <h3>{{'Step '+(i+1)}}</h3>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="step_name">NAME</label>
                                        <input type="text" class="form-control rounded-0" id="step_name"
                                           formControlName="name" name="step_name" placeholder="NAME">
                                        <div class="form-error"   *ngIf="step.controls.name.status=='INVALID' && step.controls.name.touched">
                                            Please Enter NAME
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="desc">DESCRIPTION</label>
                                        <textarea type="text" class="form-control rounded-0" id="desc" name="desc"
                                           formControlName="description" placeholder="DESCRIPTION HERE"></textarea>
                                        <div class="form-error"  *ngIf="step.controls.description.status=='INVALID' && step.controls.description.touched">
                                            Please Enter DESCRIPTION
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="desc">LIST</label>
                                        <ng-multiselect-dropdown [placeholder]="''" [settings]="dropdownSettings"
                                        formControlName="list" name="list"
                                        (onSelect)="onItemList($event,true,step)"
                                        (onDeSelect)="onItemList($event,false,step)"
                                         [data]="listofItems" [(ngModel)]="step.list">
                                        </ng-multiselect-dropdown>
                                        <!-- <select  formControlName="list" class="form-control" id="desc" name="desc" placeholder="Playbook ID">
                                            <option hidden value="" selected>Playbook Name</option>
                                            <option *ngFor="let organization of playbookIdData" [value]="organization[0]">
                                                {{organization[1]}}-{{organization[0]}}
                                            </option>
                                        </select> -->
                                        <!-- <ng-multiselect-dropdown [placeholder]="''" [settings]="dropdownSettings" formControlName="list" name="list"
                                            [data]="logcategoryList?.items" [(ngModel)]="logcategorySelectedItems">
                                        </ng-multiselect-dropdown> -->
                                        <div class="form-error"
                                            *ngIf="step.controls.list.status=='INVALID' && step.controls.list.touched">
                                            Please Select List 
                                        </div>

                                        <!-- <textarea type="text" class="form-control rounded-0" id="desc" name="desc"
                                          formControlName="list"  placeholder="LIST(Playbook name, description & log type)"></textarea>
                                        <div class="form-error"  *ngIf="step.controls.list.status=='INVALID' && step.controls.list.touched">
                                            Please Enter LIST(Playbook name, description & log type)
                                        </div> -->
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <button *ngIf="hunt_step.length-1==i"
                                                    class="btn btn-warning mr-2 rounded-0 pos-bottom pull-right"
                                                    (click)="addStep()"><i class="fa fa-plus pr-2"></i>ADD
                                                    MORE</button>
                                                <button *ngIf="hunt_step.length>1"
                                                    class="btn btn-warning mr-2 rounded-0 pos-bottom pull-right"
                                                    (click)="removeStep(i)"><i
                                                        class="fa fa-minus pr-2"></i>REMOVE</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-3">
                            <div class="col md-12">
                                <button class="btn btn-sm px-4  btn-green pull-right"  [disabled]="hunterCustom.invalid?'disabled':''">SAVE</button>
                                <button class="btn btn-sm px-4 mr-2 btn-red pull-right" (click)="cancel()">CANCEL</button>

                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</div>