<app-topbar></app-topbar>
<div class="container mt-top">
    <ng-container *ngIf="isLoaderShow">
        <div class="d-flex pabs100 mt-9"><img class="m-auto" width="400" src="assets/images/loading.gif" /></div>
    </ng-container>
    <div class="row" *ngIf="!isLoaderShow">
        <div class="col-md-12">
            <div class="card p-4">
                <div class="card-body ">
                    <h4 class="mb-2">CUSTOM INVESTIGATE</h4>
                    <!-- <p class="card-text">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita obcaecati
                        dolores dolorem minus a unde odit fugiat exercitationem odio in corrupti repellat perspiciatis
                        cupiditate impedit temporibus itaque tenetur, sit quam.</p> -->
                    <form (ngSubmit)="onSubmit()" [formGroup]="ruleStudioCustom">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="investigateid">INVESTIGATE ID *</label>
                                <input type="text" formControlName="inv_id" class="form-control rounded-0" id="inv_id"
                                    name="inv_id" placeholder="INVESTIGATE ID" readonly>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.inv_id.status=='INVALID' && ruleStudioCustom.controls.inv_id.touched">
                                    Please Enter INVESTIGATE ID
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="investigatename">INVESTIGATE NAME *</label>
                                <input type="text" formControlName="inv_name" class="form-control rounded-0"
                                    id="inv_name" name="inv_name" placeholder="INVESTIGATE NAME" readonly>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.inv_name.status=='INVALID' && ruleStudioCustom.controls.inv_name.touched">
                                    Please Enter INVESTIGATE NAME
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="logcategory">LOG CATEGORY *</label>
                                <ng-multiselect-dropdown [placeholder]="''" [settings]="dropdownSettings"
                                    formControlName="log" name="log" [data]="logcategoryList.items"
                                    [(ngModel)]="logcategorySelectedItems">
                                </ng-multiselect-dropdown>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.log.status=='INVALID' && ruleStudioCustom.controls.log.touched">
                                    Please Select LOG CATEGORY
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="schedule">SCHEDULE *</label>
                                <select formControlName="schedule" name="schedule" id="schedule"
                                    class="form-control rounded-0 drop-down">
                                    <ng-container *ngFor="let schedule of scheduleList">
                                        <option value="{{schedule.value}}">{{schedule.text}}</option>
                                    </ng-container>
                                </select>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="suppress">SUPPRESS *</label>
                                <input type="text" formControlName="suppress" name="suppress"
                                    class="form-control rounded-0" id="suppress" placeholder="SUPPRESS">
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.suppress.status=='INVALID' && ruleStudioCustom.controls.suppress.touched">
                                    Please Enter SUPPRESS
                                </div>
                            </div>
                        </div>

                        <div class="form-group auth-form-group-custom mb-4">
                            <div class="row mg-15">
                                <label for="investigateid mb-4">OTHER INFORMATIONS *</label>
                            </div>
                            <div formArrayName="infos">
                                <div *ngFor="let user of ruleStudioCustom.get('infos')['controls']; let i=index">
                                    <div class="form-row" [formGroupName]="i">
                                        <div class="form-group col-md-4">
                                            <!-- <input class="form-control  rounded-0 mt-1" readonly
                                                                        *ngIf="!user.controls.new.value" type="text" formControlName="infoText"> -->
                                            <input class="form-control user rounded-0 mt-1"
                                                *ngIf="user.controls.new.value" type="text" formControlName="infoText"
                                                placeholder="OTHER INFORMATIONS">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <input class="form-control user rounded-0 mt-1" type="text"
                                                formControlName="infoVal" placeholder="VALUE">
                                        </div>
                                        <div class="form-group col-md-4 ">

                                            <button *ngIf="i!=0" class="btn btn-warning ms-2 rounded-0 pos-bottom"
                                                (click)="removeInfo(i)"><i class="fa fa-minus pr-2"></i>REMOVE</button>
                                            <button *ngIf="i==0" class="btn btn-warning ms-2 rounded-0 pos-bottom"
                                                (click)="addInfo()"><i class="fa fa-plus pr-2"></i>ADD
                                                MORE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="detaileddescription"> DESCRIPTION *</label>
                                <ckeditor name="Description" formControlName="description" [editor]="Editor" [data]="ckeditorData"></ckeditor>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.description.status=='INVALID' && ruleStudioCustom.controls.description.touched">
                                    Please Enter DESCRIPTION
                                </div>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="detaileddescription">DETAILED DESCRIPTION *</label>
                                <ckeditor name="DETAILED Description" formControlName="detailed_description" [editor]="Editor" [data]="ckeditorData"></ckeditor>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.detailed_description.status=='INVALID' && ruleStudioCustom.controls.detailed_description.touched">
                                    Please Enter DESCRIPTION
                                </div>
                            </div>
                        
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="detaileddescription">Conclution *</label>
                                <ckeditor name="Conclution" formControlName="conclution" [editor]="Editor" [data]="ckeditorData"></ckeditor>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.conclution.status=='INVALID' && ruleStudioCustom.controls.conclution.touched">
                                    Please Enter Conclution
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="query">QUERY *</label>
                                <textarea  formControlName="query" class="form-control rounded-0  textarea-lg" id="query"
                                    placeholder="QUERY HERE" (blur)="queryValidate()"></textarea>
                                <div class="form-error"
                                    *ngIf="ruleStudioCustom.controls.query.status=='INVALID' && ruleStudioCustom.controls.query.touched">
                                    Please Enter Valid QUERY
                                </div>
                            </div>

                        </div>
                        <div class="page-title-box justify-content-between mt-3">
                            <!-- <h4 class="mb-0">EXPECTED VALUES</h4> -->
                        </div>
                        <div class="card bg-card ">
                            <div class="card-body">
                                <div class="card-title mb-0">EXPECTED OUTPUT *</div>
                                <div class="col-md-12">
                                    <ng-multiselect-dropdown [placeholder]="''" [settings]="dropdownSettings"
                                    formControlName="expected_out" name="expected_out"
                                    (onSelect)="onItemLogmodule1($event,true)"
                                    (onDeSelect)="onItemLogmodule1($event,false)"
                                     [data]="edr" [(ngModel)]="selectededr">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>


                        <ng-container *ngFor="let module of modules;let j=index">
                            <div class="page-title-box justify-content-between mt-5 pb-2">
                                <h4 class="mb-0" *ngIf="j==0">MODULES</h4>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="card bg-card">
                                        <div class="card-body pl-5 pr-2 module">
                                            <div class="card-title mb-0">{{module.moduleType}}</div>
                                            <p class="small-text">{{module.Description}}</p>
                                            <ul>
                                                <li [class]="item.selected?'active':''"
                                                    *ngFor="let item of module.modules; let i = index" (click)="changeModuleTab(j,i,item)">
                                                    <div class="form-check form-check-inline moduletext">
                                                        <input class="form-check-input" type="checkbox"
                                                            (change)="item.selected=!item.selected"
                                                            [checked]="item.selected">
                                                        <label class="form-check-label">{{item.text}}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="card bg-card">
                                        <img class="m-auto" width="200" *ngIf="loadTab && selectedModuleIndex == j "
                                            src="assets/images/fileloading.gif" />
                                        <div class="card-body " *ngIf="!loadTab || selectedModuleIndex != j ">
                                            <div class="card-title mb-0">{{module.modules[moduletabindex[j]].text}}</div>
                                            <p class="small-text">{{module.modules[moduletabindex[j]].description}}</p>
                                            <div class="col-md-12">
                                                    <ng-multiselect-dropdown [placeholder]="''" [formControl]="multiSelect"
                                                        [settings]="dropdownSettingsmodule"
                                                        (onSelect)="onItemSelectmodule(j,$event,true)"
                                                        (onDeSelect)="onItemSelectmodule(j,$event,false)"
                                                        [data]="module.modules[moduletabindex[j]].items"
                                                        [(ngModel)]="module.modules[moduletabindex[j]].itemsSelected">
                                                    </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                        <div class="row mt-3">
                            <div class="col md-12">
                                <button class="btn btn-sm px-4  btn-green pull-right "
                                    *ngIf="!ruleStudioCustom.invalid">SAVE</button>

                                <button class="btn btn-sm px-4  btn-green pull-right " disabled="disabled"
                                    *ngIf="ruleStudioCustom.invalid">SAVE</button>
                                <button class="btn btn-sm px-4 mr-2 btn-red pull-right" (click)="cancel()">CANCEL</button>

                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</div>