import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ChartType } from '../dashboard/dashboard.model';
import { revenueChart } from '../dashboard/data';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit {
  data: ChartType;
  term: any;
  titleArray: any;
  api: any;
  query: any = '';
  method: any;
  dataTemp: any = []
  expectedValues: any
  datas: any;
  series: any = [];
  labels: any = [];
  type: any = []
  colors: any = []
  loding: boolean = true;
  @Input() set analyticalData(val) {
    this.titleArray = val.titleData
    this.data = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.series = val.series
    this.expectedValues = val.expectedValues;
  }
  constructor(public dashboardService: DashboardService) { }
  ngOnInit(): void {
    this.query = this.query;
    // console.log("this.series", this.data.series[0].data)
    this.loding = true;
    this.dashboardService.dashboardApi(this.query, this.api, this.method).subscribe(
      (result: any) => {
        if (result) {
          if (this.type == 0) {
            let data = result.aggregations[0].buckets
            // console.log("data", data)
            let k = 0
            data.forEach(data => {
              this.data.labels.push(data.key.slice(0, 10))
              this.data.series[0].data.push(data.doc_count)
            });
            console.log(this.data.labels)
            console.log(this.data.series[0].data)
            this.loding = false;
          }
          else if (this.type == 1) {
            if (result.aggregations) {
              let data = result.aggregations[1].buckets
              // console.log("data", data)
              let k = 0
              data.forEach(data => {
                this.data.labels.push(data.key_as_string.slice(0, 10))
                this.data.series[0].data.push(data[3].buckets[0].doc_count)
              });
              // console.log(this.data.labels)
              // console.log(this.data.series[0].data)
              this.loding = false;
            }
          } else {
            console.log("DataGridComponent", result)
          }

        }
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }

}
