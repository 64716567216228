<div class="row">
    <div class="col-md-12 col-md-12">
        <div class="d-flex tableHeader align-items-center">
            <h4 class="card-title mb-2">{{datas?.title}}</h4>
            <h2 class="statCount" *ngIf="datas?.statCount&&model!=='View'">{{datas?.statCount}}</h2>
        </div>
        <div>
            <p>{{datas?.description}}</p>
        </div>
    </div>
    <div class="col-sm-5 col-md-5">
    </div>
    <div class="col-md-10 mt-0">
        <div>{{datas?.titleBody}}</div>
    </div>
    <div class="col-md-12 mt-0 mb-0">
        <div class="row mb-0">
            <div class="col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-left"><label
                        class="d-inline-flex align-items-center">Search:
                        <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                            aria-controls="tickets-table" [(ngModel)]="term" (ngModelChange)="search($event)" /></label>
                </div>
            </div>
            <div class="col-md-6">
                <div (click)="downloadCSV(filteredData,titleArray)" *ngIf="filteredData.length"
                    class="download  pull-right">Export
                    Excel
                </div>
            </div>
        </div>
    </div>
</div>
<div class="table-responsive table-rev" *ngIf="model==='Table'">
    <table class="table table-centered">
        <thead class="thead-light">
            <tr>
                <th scope="col" [style.width]="col=='Sl No'?'60px':''" *ngFor="let col of titleArray">{{col}}</th>
            </tr>
        </thead>
        <tbody *ngIf="filteredData">
            <tr *ngFor="let row of filteredData">
                <td [style.width]="i==0?'60px':''" *ngFor="let col of titleArray; let i = index">{{row[i]}}</td>
            </tr>
            <tr *ngIf="filteredData.length==0">
                <td  class="mx-3 mb-3 mt-0 text-center text-danger">
                    No Data Found!
                </td>
            </tr>

        </tbody>
    </table>
</div>
<div class="table-responsive table-rev" *ngIf="model==='View'&&filteredData.length!==0">
    <table class="table table-centered">
        <tbody>
            <tr *ngFor="let row of titleArray;let i = index">
                <td>{{row}}:</td>
                <td>{{filteredData[0][i]}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="loader" *ngIf="isLoader"></div>