import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { TitlebarwithdescComponent } from './titlebarwithdesc/titlebarwithdesc.component';

@NgModule({
  declarations: [PagetitleComponent, TitlebarwithdescComponent],
  imports: [
    CommonModule
  ],
  exports: [PagetitleComponent,TitlebarwithdescComponent]
})
export class UiModule { }
