import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { latLng, tileLayer } from "leaflet";
import { Observable } from "rxjs";
import { CommonService } from "src/app/core/services/common.service";
import * as echarts from "echarts";
import {
    BarChartType,
    ChartType,
    Stat,
    EchartType,
} from "../../pages/community/view-dashboard.model";import * as moment from 'moment';
import * as localization from 'moment/locale/fr';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { Router } from "@angular/router";
import { ThreatIntelligenceService } from "src/app/core/services/threat-intelligence.service";
moment.updateLocale('fr', localization);

@Component({
    selector: 'app-activbytes-thread',
    templateUrl: './activbytes-thread.component.html',
    styleUrls: ['./activbytes-thread.component.scss']
})
export class ActivbytesThreadComponent implements OnInit {

    public activePieChart;
    period = {
        gte : '',
        lte : ''
    };
    modalId
    button = 'Save PDF';
    isLoading = true;
    constructor(public common: ThreatIntelligenceService, private router: Router, private modalService: NgbModal) { }
    printClick: boolean = true;
    total$: Observable<number>;
    service = {
        page: 1,
        pageSize: 4
    }

    breadCrumbItems: Array<{}>;

    countsData: Stat[] = [
    {
        icon: 'ri-stack-line',
        title: 'Security Events',
        value: '0'
    }, {
        icon: 'ri-store-2-line',
        title: 'Headlines',
        value: '0'
    }];

    vulCountData: Stat[] = [
    {
        icon: 'ri-stack-line',
        title: 'Vulnerabilities',
        value: '0'
    }, {
        icon: 'ri-store-2-line',
        title: 'Vulnerabilities with exploit',
        value: '0'
    }];

    donughnutChart: EchartType = {
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            x: 'left',
            data: ['Defense Evasion', 'Privilege Escalation'],
            textStyle: { color: '#8791af' }
        },
        color: ['#556ee6', '#f1b44c'],
        series: [
        {
            name: 'Tactics detected',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    show: true,
                    textStyle: {
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: [
            { value: 20, name: 'Defense Evasion' },
            { value: 70, name: 'Privilege Escalation' }
            ]
        }
        ]
    };
    criticalobservables: EchartType = {
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            x: 'left',
            data: ['Hash', 'IP', 'URL'],
            textStyle: { color: '#8791af' }
        },
        color: ['#ff3d60', '#4aa3ff', '#fcb92c'],
        series: [
        {
            name: 'Critical rated observables',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    show: true,
                    textStyle: {
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: [
            { value: 4, name: 'Hash' },
            { value: 2, name: 'IP' },
            { value: 3, name: 'URL' }
            ]
        }
        ]
    };

    eventDisribution: ChartType = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '45%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: ['#5664d2', '#1cbb8c', '#fcb92c', '#ff3d60'],
        series: [{
            name: "Event",
            data: []
        }, {
            name: "News",
            data: []
        }, {
            name: "Vulnerability",
            data: []
        }, {
            name: "Exploit",
            data: []
        }],
        xaxis: {
            categories: [],
        },
        yaxis: {
            title: {
                text: 'Count'
            }
        },
        fill: {
            opacity: 1
        },
        grid: {
            borderColor: '#f1f1f1'
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return 'Count' + val + '..';
                }
            }
        }
    };


    activePieChartData: EchartType = {
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            x: 'left',
            data: [],
            textStyle: { color: '#8791af' }
        },
        color: ['#ff3d60', '#1cbb8c', '#5664d2', '#fcb92c'],
        series: [
        {
            name: 'high rated observables',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    show: true,
                    textStyle: {
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: []
        }
        ]
    };

    basicColumChart: ChartType = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '45%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: ['#5664d2', '#1cbb8c', '#fcb92c', '#ff3d60'],
        series: [{
            name: 'Event',
            data: [46, 57, 59, 54, 62, 58, 64, 60, 66]
        }, {
            name: 'News',
            data: [74, 83, 102, 97, 86, 106, 93, 114, 94]
        }, {
            name: 'Vulnerability',
            data: [37, 42, 38, 26, 47, 50, 54, 55, 43]
        }, {
            name: 'Exploit',
            data: [37, 42, 38, 26, 47, 50, 54, 55, 43]
        }],
        xaxis: {
            categories: ['00.00', '10.00', '14.00', '18.00', '20.00', '21.00', '22.00', '23.00', '24.00'],
            title: {
                text: 'attribute_timestamp per 12 hours'
            }
        },
        yaxis: {
            title: {
                text: 'Count'
            }
        },
        fill: {
            opacity: 1
        },
        grid: {
            borderColor: '#f1f1f1'
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return val;
                }
            }
        }
    };

    donutChart: ChartType = {
        chart: {
            height: 400,
            type: 'donut',
        },
        series: [44, 55, 41, 17],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10
        },
        labels: ['Event', 'News', 'Vulnerability', 'Exploit'],
        colors: ['#1cbb8c', '#5664d2', '#fcb92c', '#4aa3ff'],
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 10
                },
                legend: {
                    show: false
                },
            }
        }],
    };


    criticalrb: BarChartType = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#fff'],
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: ['#ff3d60', '#4aa3ff', '#fcb92c'],
        series: [{
            name: 'Hash',
            data: [46]
        }, {
            name: 'IP',
            data: [74]
        }, {
            name: 'URL',
            data: [37]
        }],
        xaxis: {
            categories: ['All'],
            title: {
                text: 'All docs'
            }
        },
        yaxis: {
            title: {
                text: 'Count'
            }
        },
        fill: {
            opacity: 1
        },
        grid: {
            borderColor: '#f1f1f1'
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    return 'Count :  ' + val;
                }
            }
        }
    };

    simplePieChart1: ChartType = {
        chart: {
            height: 320,
            type: 'pie',
        },
        series: [44, 55, 41],
        labels: ['Hash', 'IP', 'URL'],
        colors: ['#1cbb8c', '#5664d2', '#fcb92c'],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }]
    };


    ngOnInit(): void {
        if(localStorage.getItem('isDateRange')=="true"){
            this.period = {
                gte : localStorage.getItem('startDate'),
                lte : localStorage.getItem('endDate')
            }
        } else {
            this.period = {
                gte : localStorage.getItem('period'),
                lte : 'now'
            }
        }
        this.breadCrumbItems = [{ label: 'Home' }, { label: 'ActivBytes Threat Feeds', active: true }];
        this.getActivePieChart();
        this.getEventDistributionBar();
        this.getSecurityEvents();

        this.getHeadlines();
        this.getVulnerabilities();
        this.getVulnerabilitiesExploit();

    }

    isActivePie = false;

    getActivePieChart() {
        let data = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [],
                    "filter": [{
                        "match_all": {}
                    }, {
                        "match_phrase": {
                            "feed_name": "Activebytes Innovations - Threat Intelligence Services"
                        }
                    }, {
                        "range": {
                            "attribute_timestamp": {
                                "gte": this.period.gte,
                                "lte": this.period.lte,
                                "format": "strict_date_optional_time"
                            }
                        }
                    }],
                    "should": [],
                    "must_not": []
                }
            },
            "aggs": {
                "2": {
                    "terms": {
                        "field": "event_category",
                        "order": {
                            "_count": "desc"
                        },
                        "size": 5
                    }
                }
            }
        };

        this.isActivePie = true;
        this.common.getCommunityFeedChart(data).subscribe(
            (response: any) => {
                this.isActivePie = false;
                let buckets = response.aggregations[2].buckets;
                let datas = [];
                this.activePieChartData.legend.data = [];
                if (buckets.length > 0) {
                    for (let i in response.aggregations[2].buckets) {
                        datas.push({
                            value: buckets[i] != undefined ? buckets[i].doc_count : 0, name: buckets[i] != undefined ? buckets[i].key : ''
                        })
                        this.activePieChartData.legend.data.push(buckets[i] != undefined ? buckets[i].key : '');
                    }
                    this.activePieChartData.series[0].data = datas;
                    this.activePieChart.setOption(this.activePieChartData)
                } else {
                    this.activePieChart.dispose();
                    document.getElementById('activePieChart').innerHTML = "<div class='m-auto text-danger'>Not Enough Data</div>";
                }

            },
            error => {
                this.isActivePie = false;
                console.log("error", error);
            });
    }

    isCountData = false;
    getSecurityEvents() {
        let data = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [],
                    "filter": [{
                        "match_all": {}
                    }, {
                        "match_phrase": {
                            "feed_name": "Activebytes Innovations - Threat Intelligence Services"
                        }
                    }, {
                        "match_phrase": {
                            "event_category": "Event"
                        }
                    }, {
                        "range": {
                            "attribute_timestamp": {
                                "gte": this.period.gte,
                                "lte": this.period.lte,
                                "format": "strict_date_optional_time"
                            }
                        },
                    }],
                    "should": [],
                    "must_not": []
                }
            }
        };
        this.isCountData = true;
        this.common.getCommunityFeedChart(data).subscribe(
            (response: any) => {
                this.isCountData = false;
                this.countsData[0].value = response.hits.total.value;
            },
            error => {
                this.isCountData = false;
                console.log("error", error);
            }
            );
    }


    getHeadlines() {
        let data = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [],
                    "filter": [{
                        "match_all": {}
                    }, {
                        "match_phrase": {
                            "feed_name": "Activebytes Innovations - Threat Intelligence Services"
                        }
                    }, {
                        "match_phrase": {
                            "event_category": "News"
                        }
                    }, {
                        "range": {
                            "attribute_timestamp": {
                                "gte": this.period.gte,
                                "lte": this.period.lte,
                                "format": "strict_date_optional_time"
                            }
                        }
                    }],
                    "should": [],
                    "must_not": []
                }
            }
        };
        this.common.getCommunityFeedChart(data).subscribe(
            (response: any) => {
                this.countsData[1].value = response.hits.total.value;
            },
            error => {
                console.log("error", error);
            }
            );
    }

    isVulnerabilities = false;
    getVulnerabilities() {
        let data = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [],
                    "filter": [{
                        "match_all": {}
                    }, {
                        "match_phrase": {
                            "feed_name": "Activebytes Innovations - Threat Intelligence Services"
                        }
                    }, {
                        "match_phrase": {
                            "event_category": "Vulnerability"
                        }
                    }, {
                        "range": {
                            "attribute_timestamp": {
                                "gte": this.period.gte,
                                "lte": this.period.lte,
                                "format": "strict_date_optional_time"
                            }
                        }
                    }],
                    "should": [],
                    "must_not": []
                }
            }
        };
        this.isVulnerabilities = true;
        this.common.getCommunityFeedChart(data).subscribe(
            (response: any) => {
                this.isVulnerabilities = false;
                this.vulCountData[0].value = response.hits.total.value;
            },
            error => {
                this.isVulnerabilities = false;
                console.log("error", error);
            }
            );
    }

    getVulnerabilitiesExploit() {
        let data = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [],
                    "filter": [{
                        "match_all": {}
                    }, {
                        "match_phrase": {
                            "feed_name": "Activebytes Innovations - Threat Intelligence Services"
                        }
                    }, {
                        "match_phrase": {
                            "event_category": "Exploit"
                        }
                    }, {
                        "range": {
                            "attribute_timestamp": {
                                "gte": this.period.gte,
                                "lte": this.period.lte,
                                "format": "strict_date_optional_time"
                            }
                        }
                    }],
                    "should": [],
                    "must_not": []
                }
            }
        }
        this.common.getCommunityFeedChart(data).subscribe(
            (response: any) => {
                this.vulCountData[1].value = response.hits.total.value;
            },
            error => {
                console.log("error", error);
            }
            );
    }

    isEventDisribution = false;
    getEventDistributionBar() {
        this.isLoading = true
        let data = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [],
                    "filter": [{
                        "match_all": {}
                    }, {
                        "match_phrase": {
                            "feed_name": "Activebytes Innovations - Threat Intelligence Services"
                        }
                    }, {
                        "range": {
                            "attribute_timestamp": {
                                "gte": this.period.gte,
                                "lte": this.period.lte,
                                "format": "strict_date_optional_time"
                            }
                        }
                    }],
                    "should": [],
                    "must_not": []
                }
            },
            "aggs": {
                "2": {
                    "date_histogram": {
                        "field": "attribute_timestamp",
                        "calendar_interval": "1d",
                        "time_zone": "Asia/Kolkata",
                        "min_doc_count": 1
                    },
                    "aggs": {
                        "3": {
                            "terms": {
                                "field": "event_category",
                                "order": {
                                    "_count": "desc"
                                },
                                "size": 5
                            }
                        }
                    }
                }
            }
        };
        this.isEventDisribution = true;
        this.common.getCommunityFeedChart(data).subscribe(
            (response: any) => {
                if (response) {
                    this.isEventDisribution = false;
                    let dataSeries = [...this.eventDisribution.series];

                    for (let k in this.eventDisribution.series) {
                        dataSeries[k].data = new Array(response.aggregations[2].buckets.length).fill(0);
                    }

                    for (let i = 0; i < response.aggregations[2].buckets.length; i++) {
                        this.eventDisribution.xaxis.categories.push(moment.unix(response.aggregations[2].buckets[i].key / 1000).format('DD/MM/YYYY'));
                        for (let j in response.aggregations[2].buckets[i][3].buckets) {
                            for (let k in this.eventDisribution.series) {
                                if (dataSeries[k].name == response.aggregations[2].buckets[i][3].buckets[j].key) {
                                    dataSeries[k].data[i] = response.aggregations[2].buckets[i][3].buckets[j].doc_count;
                                }
                                dataSeries[k].data = Array.from(dataSeries[k].data, item => item || 0);
                            }
                        }
                    }
                    this.eventDisribution.series = dataSeries;
                    if (response.aggregations[2].buckets.length == 0) {
                        document.getElementById('eventDisribution').innerHTML = "<div class='mhbar d-flex text-danger'><div class='m-auto'>Not Enough Data</div></div>";
                    }
                    this.isLoading = false
                }
            },
            error => {
                this.isLoading = false
                this.isEventDisribution = false;
                console.log("error", error);
            });
    }

    ngAfterViewInit(): void {
        this.activePieChart = echarts.init(document.getElementById('activePieChart'));
    }

    openModal(viewmodal: any, modalId: number) {
        this.modalId = modalId;
        this.modalService.open(viewmodal, { size: "lg printCustModal", centered: true });
    }
    captureScreenModelPrint() {
        this.printClick = false;
        var data1 = document.getElementById("contentToConvert");
        var data2 = document.getElementById("contentToConvert1");
        html2canvas(data1).then((canvas) => {
            var imgWidth = 200;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            console.log(imgHeight)
            const contentDataURL = canvas.toDataURL("image/png");
            let pdf = new jspdf("p", "mm", "a4");
            var img = new Image()
            img.src = 'assets/images/logo-dark.png'
            pdf.addImage(img, 'png', 150, 5, 50, 18)
            pdf.addImage(contentDataURL, "PNG", 5, 35, imgWidth, imgHeight);
            pdf.addPage();
            html2canvas(data2).then((canvas1) => {
                var imgWidth = 200;
                var imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
                const contentDataURL1 = canvas1.toDataURL("image/png");
                img.src = 'assets/images/logo-dark.png'
                pdf.addImage(img, 'png', 150, 5, 50, 18)
                pdf.addImage(contentDataURL1, "PNG", 5, 35, imgWidth, imgHeight1);
                pdf.save("activebytes-threat.pdf");
                this.printClick = true;
                this.modalService.dismissAll('Ok click')
            });
        });
    }

    ngOnDestroy() {
        this.activePieChart.dispose();
    }
    goToBack() {
        this.router.navigate(["/dashboard", { ti: 1 }]);
    }

}

