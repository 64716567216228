<app-topbar></app-topbar>
<div class="container mt-top">
    <!-- <app-pagetitle title="Threat Hunting Summary" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 card">
                <div class="card-body row">
                    <div class=" col-md-12 p-4">
                        <div class="heading">
                            <h3 class="color-white"><span>Name:</span> {{hunt_name}}</h3>
                            <p><span>Description:</span></p>
                            <div class="color-white" [innerHTML]="detailed_description"></div>
                            <br>
                        </div>
                        <div class="menu-area mt-4">
                            <div class="float-right">
                                <!-- <button class="btn btn-primary btn-sm mr-1" [disabled]="detectionTableData.length==0"
                                (click)="getPdf()">Export</button> -->
                                <div class="btn-group-vertical" role="group" aria-label="Vertical button group">

                                    <div class="btn-group dropdown" role="group">
                                        <button id="btnGroupVerticalDrop1PDF" type="button"
                                            class="btn btn-black btn-sm mr-1 dropdown-toggle " data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            Export <i class="mdi mdi-chevron-down"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1PDF">
                                            <a class="dropdown-item" (click)="getPdf()">Export Pdf</a>
                                            <!-- <a class="dropdown-item" href="#">Export Excel</a> -->
                                        </div>
                                    </div>
                                </div>

                                <button class="btn btn-black btn-sm mr-1"
                                    (click)="showFilter=!showFilter">Filter</button>
                                <button class="btn btn-black btn-sm mr-3" (click)="clearAllFilter()">Clear
                                    Filter</button>


                            </div>

                            <h4 class="card-title mb-1">Detections</h4>
                            <div class="side-filter"
                                [ngClass]="{ 'show-filter': showFilter, 'hide-filter': !showFilter }">
                                <div class="row">
                                    <div class="w-100 text-right">
                                        <a class="mr-2" href="javascript:void(0)" (click)="showFilter=false;"><i
                                                class="ri-close-fill"></i></a>
                                    </div>
                                    <div class="col-md-12">
                                        <span>User Name</span>
                                        <ng-multiselect-dropdown [placeholder]="'Select User Name'"
                                            [settings]="dropdownSettings" [data]="GroupUserNameData"
                                            [(ngModel)]="f1GroupUserNameData">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="col-md-12">
                                        <span>Host Name</span>
                                        <ng-multiselect-dropdown [placeholder]="'Select Host Name'"
                                            [settings]="dropdownSettings" [data]="GroupHostNameData"
                                            [(ngModel)]="f1GroupHostNameData">
                                        </ng-multiselect-dropdown>
                                    </div>

                                    <div class="col-md-12 mt-3">
                                        <button (click)="applyFilter();showFilter=false;" type="button"
                                            class="btn btn-primary mr-1">
                                            Apply Filter
                                        </button>
                                        <button type="button" class="btn btn-danger"
                                            (click)="clearAllFilter();showFilter=false;">Clear
                                            Filter</button>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex ht-250 w-100" *ngIf="recent_detection_bool"><img class="m-auto"
                                    width="200" src="assets/images/fileloading.gif" /></div>
                        </div>
                        <ng-container *ngIf="!recent_detection_bool">
                            <div class="card bg-card-auto col-md-12 mt-4 step-items" *ngFor="let item of steps">
                                <div class="row p-3">
                                    <div class="col-md-12">
                                        <h3>{{'Step '+item.step}}</h3>
                                        <h5><span>{{'Name: '+item.name}} </span></h5>
                                        <p class="color-white"><span>Description:</span>{{item.description}}</p>
                                    </div>
                                </div>
                                <app-hunter-timeline [timelineData]="item.timeline_setup"
                                    [isLoading]="recent_detection_bool" [hunt_id]="hunt_id"></app-hunter-timeline>
                            </div>
                        </ng-container>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>