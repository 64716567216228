import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashbord-test',
  templateUrl: './dashbord-test.component.html',
  styleUrls: ['./dashbord-test.component.scss']
})
export class DashbordTestComponent implements OnInit {
  public id;
  public chartDetails = [];
  constructor(private router: ActivatedRoute, private route: Router) { }

  ngOnInit(): void {
    this.router.paramMap.subscribe(params => {
      this.id = this.router.snapshot.paramMap.get("id");
      console.log("DashbordTestComponent123333")
      this.chartDetails = [
        {
          type: 'filter',
          class: "col-md-6",
          analyticalData: {
            series: [],
            chart: {
              height: 230,
              type: 'donut',
            },
            labels: [],
            plotOptions: {
              pie: {
                donut: {
                  size: '15%'
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
            },
            colors: [],
            name: "Top Users",
            type: 1,
            title: "Filter",
            titleBody: "To filter by selected username/hostname",
            statCount: "",
            method: "post",
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(`  {
        "aggs": {
          "2": {
            "terms": {
              "field": "user.name",
              "order": {
                "_count": "desc"
              },
              "size": 25
            }
          }
        },
        "size": 0,
        "query": {
          "bool": {
            "must": [],
            "filter": [
              {
                "bool": {
                  "should": [
                    {
                      "bool": {
                        "should": [
                          {
                            "match": {
                              "system.auth.ssh.event": "Accepted"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    },
                    {
                      "bool": {
                        "should": [
                          {
                            "match": {
                              "event.outcome": "Success"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    }
                  ],
                  "minimum_should_match": 1
                }
              },
              {
                "match_phrase": {
                  "event.action": "ssh_login"
                }
              },
              {
                "range": {
                  "@timestamp": {
                    "format": "strict_date_optional_time",
                    "gte": "2021-07-01T18:30:00.000Z",
                    "lte": "2022-07-02T03:16:43.056Z"
                  }
                }
              }
            ],
            "should": [],
            "must_not": []
          }
        }
      }

`)
          }
        },
        {
          type: 'donut',
          class: "col-md-6",
          analyticalData: {
            series: [],
            chart: {
              height: 230,
              type: 'donut',
            },
            labels: [],
            plotOptions: {
              pie: {
                donut: {
                  size: '15%'
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
            },
            colors: [],
            name: "Successfully Authenticated Users",
            type: 1,
            title: "Successfully Authenticated Users",
            titleBody: "Distribution of users (Top 25) with successful SSH logons",
            statCount: "",
            method: "post",
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(`  {
        "aggs": {
          "2": {
            "terms": {
              "field": "user.name",
              "order": {
                "_count": "desc"
              },
              "size": 25
            }
          }
        },
        "size": 0,
        "query": {
          "bool": {
            "must": [],
            "filter": [
              {
                "bool": {
                  "should": [
                    {
                      "bool": {
                        "should": [
                          {
                            "match": {
                              "system.auth.ssh.event": "Accepted"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    },
                    {
                      "bool": {
                        "should": [
                          {
                            "match": {
                              "event.outcome": "Success"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    }
                  ],
                  "minimum_should_match": 1
                }
              },
              {
                "match_phrase": {
                  "event.action": "ssh_login"
                }
              },
              {
                "range": {
                  "@timestamp": {
                    "format": "strict_date_optional_time",
                    "gte": "2021-07-01T18:30:00.000Z",
                    "lte": "2022-07-02T03:16:43.056Z"
                  }
                }
              }
            ],
            "should": [],
            "must_not": []
          }
        }
      }

`)
          }
        },
        {
          type: 'bar',
          class: "col-md-12",
          analyticalData: {
            series: [{
              name: 'Event',
              type: 'column',
              data: []
            }],
            chart: {
              height: 280,
              type: 'line',
              toolbar: {
                show: false,
              }
            },
            stroke: {
              width: [0, 3],
              curve: 'smooth'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '20%',
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            colors: ['#CC3354', '#ddff8c'],
            labels: [],
            name: "SSH Login Status vs Time",
            type: 1,
            title: "SSH Login Status vs Time",
            titleBody: "Distribution of authentication status over time",
            statCount: "",
            method: "post",
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(` {
        "aggs": {
          "2": {
            "date_histogram": {
              "field": "@timestamp",
              "calendar_interval": "1w",
              "time_zone": "Asia/Calcutta",
              "min_doc_count": 1
            },
            "aggs": {
              "3": {
                "terms": {
                  "field": "system.auth.ssh.event",
                  "order": {
                    "_count": "desc"
                  },
                  "size": 100
                }
              }
            }
          }
        },
        "size": 0,
        "query": {
          "bool": {
            "must": [],
            "filter": [
              {
                "match_phrase": {
                  "event.action": "ssh_login"
                }
              },
              {
                "range": {
                  "@timestamp": {
                    "format": "strict_date_optional_time",
                    "gte": "2021-07-01T18:30:00.000Z",
                    "lte": "2022-07-02T03:16:43.056Z"
                  }
                }
              }
            ],
            "should": [],
            "must_not": []
          }
        }
      }
`)
          }
        },
        {
          type: 'donut',
          class: "col-md-6",
          analyticalData: {
            series: [],
            chart: {
              height: 230,
              type: 'donut',
            },
            labels: [],
            plotOptions: {
              pie: {
                donut: {
                  size: '15%'
                }
              }
            },
            dataLabels: {
              enabled: true
            },
            legend: {
              show: true,
            },
            type: 2,
            colors: [],
            name: "Authentication Failed Users",
            title: "Authentication Failed Users",
            titleBody: "Distribution of users (Top 25) with most frequent SSH login failures",
            statCount: "",
            method: "post",
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(` {
        "aggs": {
          "2": {
            "terms": {
              "field": "user.name",
              "order": {
                "_count": "desc"
              },
              "size": 25
            }
          }
        },
        "size": 0,
        "query": {
          "bool": {
            "must": [],
            "filter": [
              {
                "bool": {
                  "should": [
                    {
                      "bool": {
                        "should": [
                          {
                            "match": {
                              "system.auth.ssh.event": "Invalid"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    },
                    {
                      "bool": {
                        "should": [
                          {
                            "match": {
                              "event.outcome": "Failure"
                            }
                          }
                        ],
                        "minimum_should_match": 1
                      }
                    }
                  ],
                  "minimum_should_match": 1
                }
              },
              {
                "match_phrase": {
                  "event.action": "ssh_login"
                }
              },
              {
                "range": {
                  "@timestamp": {
                    "format": "strict_date_optional_time",
                    "gte": "2021-07-01T18:30:00.000Z",
                    "lte": "2022-07-02T03:16:43.056Z"
                  }
                }
              }
            ],
            "should": [],
            "must_not": []
          }
        }
      }
`)

          }
        },
        {
          type: 'donut',
          class: "col-md-6",
          analyticalData: {
            series: [],
            chart: {
              height: 230,
              type: 'donut',
            },
            labels: [],
            expectedValues: ["source.ip", "source.geo.country_name"],
            plotOptions: {
              pie: {
                donut: {
                  size: '25%'
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
            },
            type: 3,
            colors: [],
            name: "Top Source IP vs Country",
            title: "Top Source IP vs Country",
            titleBody: "Distribution of Source IPs and their respective country of origin",
            statCount: "",
            method: "post",
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(` {
        "aggs": {
          "2": {
            "terms": {
              "field": "source.ip",
              "order": {
                "_count": "desc"
              },
              "size": 25
            },
            "aggs": {
              "3": {
                "terms": {
                  "field": "source.geo.country_name",
                  "order": {
                    "_count": "desc"
                  },
                  "size": 20
                }
              }
            }
          }
        },
        "size": 0,
        "query": {
          "bool": {
            "must": [],
            "filter": [
              {
                "match_phrase": {
                  "event.action": "ssh_login"
                }
              },
              {
                "range": {
                  "@timestamp": {
                    "format": "strict_date_optional_time",
                    "gte": "2021-07-01T18:30:00.000Z",
                    "lte": "2022-07-02T03:16:43.056Z"
                  }
                }
              }
            ],
            "should": [],
            "must_not": []
          }
        }
      }`)
          }
        },
        {
          type: 'data',
          class: "col-md-12",
          analyticalData: {
            titleData: ["Sl No", "Name", "Hostname", "SSH Event", "Source IP", "SSH Method"],
            expectedValues: ["user.name", "host.hostname", "system.auth.ssh.event", "source.ip", "system.auth.ssh.method"],
            title: "Top SSH activities",
            titleBody: "Details of SSH events",
            statCount: "",
            method: "post",
            type: 1,
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(`{
        "aggs": {
          "2": {
            "terms": {
              "field": "user.name",
              "order": {
                "_count": "desc"
              },
              "size": 100
            },
            "aggs": {
              "3": {
                "terms": {
                  "field": "host.hostname",
                  "order": {
                    "_count": "desc"
                  },
                  "size": 100
                },
                "aggs": {
                  "4": {
                    "terms": {
                      "field": "system.auth.ssh.event",
                      "order": {
                        "_count": "desc"
                      },
                      "size": 100
                    },
                    "aggs": {
                      "6": {
                        "terms": {
                          "field": "source.ip",
                          "order": {
                            "_count": "desc"
                          },
                          "size": 100
                        },
                        "aggs": {
                          "7": {
                            "terms": {
                              "field": "system.auth.ssh.method",
                              "order": {
                                "_count": "desc"
                              },
                              "size": 5
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "size": 0,
        "query": {
          "bool": {
            "must": [],
            "filter": [
              {
                "match_phrase": {
                  "event.action": "ssh_login"
                }
              },
              {
                "range": {
                  "@timestamp": {
                    "format": "strict_date_optional_time",
                    "gte": "2021-07-01T18:30:00.000Z",
                    "lte": "2022-07-02T03:16:43.056Z"
                  }
                }
              }
            ],
            "should": [],
            "must_not": []
          }
        }
      }
`)
          }
        },
        {
          type: 'data',
          class: "col-md-12",
          analyticalData: {
            titleData: ["Sl No", "Name", "Hostname", "Action", "Source IP", "Country Name", "Method", "Event"],
            expectedValues: ["user.name", "host.hostname", "event.action", "source.ip", "source.geo.country_name", "system.auth.ssh.method", "system.auth.ssh.event"],
            title: "Recent SSH activities",
            titleBody: "Details of recent ssh activities performed",
            statCount: "",
            method: "post",
            type: 2,
            api: "https://datalakedev.active-bytes.com:9202/logs-*/_search",
            query: JSON.stringify(`{
        "version": true,
              "size": 25,
              "sort": [
              {
                "@timestamp": {
                  "order": "desc",
                  "unmapped_type": "boolean"
                }
              }
            ],
              "query": {
              "bool": {
                "must": [],
                "filter": [
                  {
                    "match_phrase": {
                      "event.action": "ssh_login"
                    }
                  },
                  {
                    "range": {
                      "@timestamp": {
                        "format": "strict_date_optional_time",
                        "gte": "2021-07-01T18:30:00.000Z",
                        "lte": "2022-07-02T03:16:43.056Z"
                      }
                    }
                  }
                ],
                "should": [],
                "must_not": []
              }
            }
      }`)
          }
        }
      ]
    });
  }
  ngOnDestroy() {
    console.log("ngOnDestroy")
    localStorage.setItem("selectedDonutVal", '');
    localStorage.setItem("selectedDonutElemet", '');
  }
  ngDestroy() {
    console.log("ngDestroy")
    localStorage.setItem("selectedDonutVal", '');
    localStorage.setItem("selectedDonutElemet", '');
  }

}
