import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SsoService } from 'src/app/core/services/sso.service';

@Component({
  selector: 'app-user-check',
  templateUrl: './user-check.component.html',
  styleUrls: ['./user-check.component.scss']
})
export class UserCheckComponent implements OnInit {
  isThreatHunt
  constructor(private SsoService: SsoService, private router: Router) { }

  ngOnInit(): void {
    this.SsoService.getLoggedInUserDetails().subscribe((data) => {
      console.log("getLoggedInUserDetails", data)
    })
    this.SsoService.getSystemvArs().subscribe((data) => {
      console.log("getSystemvArs", data)
      let userData: any = data
      if (userData.datalakeuname && userData.datalakepass) {
        localStorage.setItem("ACCESS_TOKEN",
          "Basic " + btoa(userData.datalakeuname + ":" + userData.datalakepass));
      }
      if (userData.datalakeurl) {
        localStorage.setItem("Datalake_Url", userData.datalakeurl);
      }
    })
    // this.SsoService.getDashboards().subscribe((data) => {
    //   console.log("getDashboards", data)
    // })
    let a1 = { "dash": [1, 2, 3] }
    // this.SsoService.setDashboards(a1).subscribe((data) => {
    //   console.log("getDashboards", data)
    // })
  }
  dashboards() {
    this.router.navigate(["/dashboard"], {});
  }
  settings() {
    this.router.navigate(["/user-settings"], {});
  }
  dashboardsList(){
    this.router.navigate(["/dashboard-list"], {});
  }
}
