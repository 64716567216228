<app-topbar></app-topbar>
<div class="page-content">
<div class="container" style="margin-top: 50px;">
    <div class="row">
        
        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h3 class="mb-0">Security Incidents Intelligence</h3>
                <!-- <div class="severity-section-container">

                <div class="severity-controll-btns">
                    <div class="severity-low-btn">
                        <button>Low</button>
                    </div>

                    <div class="severity-medium-btn">
                        <button (click)="filterMedium()">Medium <span class="severity-count">{{severityCounts.medium}}</span></button>
                    </div>

                    <div class="severity-high-btn">
                        <button>High</button>
                    </div>

                    <div class="severity-critical-btn">
                        <button>Critical</button>
                    </div>
                </div>
                </div> -->
                
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
            </div>
        </div>

        <div class="col-md-12 pb-2"> 
            <div class="card card-body h-95 mt-4">
                <h2 class="card-title mt-0 sub-heading-title">Security Incidents Intelligence Overview</h2>
                <p class="card-text paragraph-clr">This panel shows the recent news related to cybersecurity, they are split
                    into two categories, one for showing general news which maybe important from a business
                    perspective and the other which shows security events, including
                    recent vulnerabilities, patches etc. which may be important from a technical perspective.
                    For a more detailed view of the events, click the link beside this panel.
                </p>
            </div>
        </div>

        <div class="col-xl-12 pb-4">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title sub-heading-title">Security Events</h4>
                    <p class="card-discrp paragraph-clr">This panel shows the information regarding trending security events, these can include
                        recently found vulnerabilites or security breaches that took place in other organizations or
                        patches to the same and much more.</p>
                    <div class="table-responsive">
                        <div class="text-center" *ngIf="isSecurityData"><img class="m-auto" width="200"
                                src="assets/images/fileloading.gif" /></div>
                        <table datatable class="row-border hover scroll custWidth" [dtOptions]="dtOptions"
                            [dtTrigger]="dtTriggerSecurity">
                            <thead style="display: none;">
                                <tr>
                                    <th>Event Name</th>

                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!isSecurityData">
                                <tr *ngFor="let item of securityEventData">

                                    <td class="w95 data-table">

                                        <h6>{{item.event_name}}</h6>
                                        <div class="font-14 text-muted">
                                                <div class="description-section">
                                                    <!-- <p class="mb-0 "></p> -->
                                                    <span class="primary-clr">Description:</span>
                                                    <span class="paragraph-clr"
                                                    title="{{item.event_description}}">{{item.event_description.length > 183 ?
                                                        item.event_description.slice(0, 180) + '...' : item.event_description}}</span>
                                                </div>
                                        </div>

                                
                                        <div class="severity-container">
                                            <span class="hightlight-head">Severity :</span> <span class="custom-alert"
                                            [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium'}">{{item.severity}}</span> <span class="hightlight-head">TLP : </span> <span class="tlp-styles">{{item.tlp}}</span>
                                        </div>
                                        
                                        <p class="mb-0 py-3"><i class="fa fa-calendar white-text mr-3"></i> <span class="timestamp">{{item.attribute_timestamp}}</span></p>

                                        
                                        <!-- <span class="headFont"></span><br>
                                        <span title="{{item.event_description}}">
                                        </span> <br> Reference Link:<a [href]="item.reference_link"
                                            target="blank">{{item.reference_link}}</a>
                                        <div>Severity : <span
                                                [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium'}">{{item.severity}}</span>
                                        </div>
                                        <div>TLP : <span>{{item.tlp}}</span></div>
                                        <div>{{item.attribute_timestamp}}</div> -->

                                    </td>
                                    <td class="actionTd viewBtn">
                                        <a href="javascript:void(0);" class="" ngbTooltip="view"
                                            (click)="openModal(viewModal,item,1)"><i
                                                class="fa fa-eye font-size-18"></i> View</a>
                                    </td>


                                    <!-- <td class="w95">
                                        <span class="headFont"></span><br>
                                        Description: <span
                                            title="{{item.event_description}}">
                                        </span><br>
                                        <div>Severity : <span
                                                [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium'}">{{item.severity}}</span>
                                        </div>
                                        <div>TLP : <span>{{item.tlp}}</span></div>
                                        <div>{{item.attribute_timestamp}}</div>
                                    </td> -->

                                    <!-- <td class="actionTd viewBtn">
                                        <a href="javascript:void(0);" class="" ngbTooltip="view"
                                            (click)="openModal(viewModal,item,1)"><i
                                                class="fa fa-eye font-size-18"></i> View</a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewModal let-modal class="model-container">
    <div class="modal-header">
        <!-- <h5 class="primary-head">Event Details</h5> -->

        <div class="action-btns">
            <div class="save-btn">
                <button class="btn-default" (click)="captureScreenModelPrint()">Save <span>PDF</span></button>
            </div>
            <div class="close-btn" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                </svg>
            </div>
        </div>

        <!-- <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button> -->
    </div>


    <div class="modal-body p-3 pb-4">
        <div class="row" *ngIf="modalId==1">

            <div class="main-header col-md-12">
                <div class="event-title">
                    <h2 class="sub-heading">Event Details</h2>
                </div>
                <div>
                    <p class="event-description paragraph-clr-dark">{{selectedContent.event_name}}</p>
                </div>
            </div>

            <!-- ////////////////////////////////////// -->

            <div id="contentToConvert">
                <!-- <div class="col-md-12">
                    <h3 class="eventNameHead">{{selectedContent.event_name}}</h3>
                </div> -->

                <div class="tags col-md-12">
                    <div class="colm-1  taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low',
                        status_border_medium: selectedContent.severity == 'Medium',
                        status_border_high: selectedContent.severity == 'High',
                        status_border_critical: selectedContent.severity == 'Critical'
                    }">
                        <label>Severity</label>
                        <!-- <span class="span-head" >Severity</span>  -->
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low',
                            status_highLabel: selectedContent.severity == 'High',
                            status_mediumLabel: selectedContent.severity == 'Medium',
                            status_criticalLabel: selectedContent.severity == 'Critical'
                        }">{{ selectedContent.severity }}</span>
                    </div>
                    <div class="colm2 label-style">
                        <label>TLP</label>
                        <span class="tlp-styles">{{selectedContent.tlp}}</span>
                    </div>
                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                    </div>
                </div>

                <!-- <div class="timestamp col-md-6">
                    <span>{{selectedContent.attribute_timestamp}}</span>
                </div> -->


                <!-- map section -->
                <div class="map-container col-md-12 ">
                    
                <div class="map">
                    <app-geochart [countries]="countries"></app-geochart>
                </div>

                <div class="overview col-md-12">
                        <h2 class="sub-heading">Descriptions</h2>
                    
                    <div class="overview-description">
                        <p class="paragraph-clr-dark">
                            {{selectedContent.event_description}}
                        </p>
                    </div>

                    <!-- analitics comments -->
                    <h2 class="sub-heading">Analyst Comments</h2>
                    <ul class="list-unstyled mb-0">
                        <li>
                            <ul class="pl-2 commentsList">
                                <li *ngFor="let comment of selectedContent.comments" class="text-light "><b>{{comment}}</b></li>
                            </ul>
                        </li>
                    </ul>


                    <h2 class="sub-heading">Reference Link</h2>
                    <div class="links">
                        <ul>
                            <li *ngFor="let link of selectedContent.reference_link">
                                <a [href]="link" target="_blank">{{link}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>



        <div class="row" *ngIf="modalId==2">
            <div class="col-md-12">
                <h2 class="sub-heading">Headline</h2>
                <p class="event-description paragraph-clr-dark">{{selectedContent.event_name}}</p>
            </div>

            <div class="tags col-md-12">
                <div class="colm-1  taag label-style" [ngClass]="{
                    status_border_low: selectedContent.severity == 'Low',
                    status_border_high: selectedContent.severity == 'High',
                    status_border_medium: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                }">
                <label>Severity</label>
                    <!-- <span class="span-head" >Severity</span>  -->
                    <span class="custom-alert" [ngClass]="{
                        status_lowLabel: selectedContent.severity == 'Low',
                        status_highLabel: selectedContent.severity == 'High',
                         status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                    }">{{ selectedContent.severity }}</span>
                </div>
                <div class="colm2 label-style">
                    <label>Severity</label> 
                    <span class="tlp-styles">{{selectedContent.tlp}}</span>
                </div>

                <div class="colm3 label-style">
                    <label>Timestamp</label>
                    <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                </div>
            </div>

            
            <!-- <div class="timestamp col-md-6">
                <span>{{selectedContent.attribute_timestamp}}</span>
            </div> -->

            <!-- //////////////////// -->

            <!-- <div class="col-md-6">
                {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                                  status_lowLabel: selectedContent.severity == 'Low',
                                  status_highLabel: selectedContent.severity == 'High',
                                   status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                              }">{{ selectedContent.severity }}</span>
            </div>
            <div class="col-md-6">
                <div>TLP : <span>{{selectedContent.tlp}}</span></div>
            </div> -->

            
            <div id="contentToConvert1">
                <div class="col-md-12">
                    <app-geochart [countries]="countries"></app-geochart>
                </div>

                <div class="col-md-12">
                    <h2 class="sub-heading">Reference Link</h2>
                    <div class="links">
                        <ul>
                            <li>
                                <a [href]="selectedContent.reference_link" target="_blank">{{selectedContent.reference_link}}</a>
                            </li>
                        </ul>
                    </div>
                    <!-- <ul class="list-unstyled mb-0">
                        <li><span class="viewHead primary-sub-head">Reference Link:</span><br>
                            <ul>
                                <li ><b><a [href]="selectedContent.reference_link"
                                            target="_blank">{{selectedContent.reference_link}}</a></b></li>
                            </ul>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</ng-template>

</div>