<div class="modal-header mb-0" style="margin-bottom: 0 !important;">
    <h4 class="modal-title">{{objArr[0]}}</h4>
</div>
<div class="modal-body scrollModal pt-4">
    <form [formGroup]="addSoar" role="form" class="singleLineForm">
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Tenant ID*</label>
                <select formControlName="tenant_id" class="form-control" placeholder="Tenant ID">
                    <option hidden value="" selected>Tenant ID</option>
                    <option *ngFor="let organization of organizationList" [value]="organization.tenant_id">
                        {{organization.tenant_id}}
                    </option>
                </select>
            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.tenant_id.errors?.required && submit">
                    Tenant ID is required
                </small>
                <small class="text-danger" *ngIf="err">
                    Can't update exceptions some error happened.
                </small>
            </div>
        </div>
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Param Count*</label>
                <select formControlName="count" class="form-control" placeholder="param count">
                    <option hidden value="" selected>select</option>
                    <option value=1>1</option>
                    <option value=2>2</option>
                    <option value=3>3</option>
                    <option value=4>4</option>
                    <option value=5>5</option>
                </select>

            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.count.errors?.required && submit">
                    Count is required
                </small>
            </div>
        </div>
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Api Name*</label>
                <input formControlName="api_name" type="text" class="form-control" placeholder="Api Name">
            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.api_name.errors?.required && submit">
                    Api Name is required
                </small>
            </div>
        </div>
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Body*</label>
                <input formControlName="body" type="text" class="form-control" placeholder="Body">
            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.body.errors?.required && submit">
                    Body is required
                </small>
                <small class="text-danger"
                    *ngIf="(!addSoar.controls.body.errors?.required &&addSoar.controls.body.errors?.invalidJson) && submit">
                    Body must be JSON
                </small>
            </div>
        </div>
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Header*</label>
                <input formControlName="header" type="text" class="form-control" placeholder="Header">
            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.header.errors?.required && submit">
                    Header required
                </small>
                <small class="text-danger"
                    *ngIf="(!addSoar.controls.header.errors?.required &&addSoar.controls.header.errors?.invalidJson) && submit">
                    Header must be JSON
                </small>
            </div>
        </div>
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Method*</label>
                <select formControlName="method" class="form-control" placeholder="param count">
                    <option hidden value="" selected>select</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                </select>
            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.method.errors?.required && submit">
                    Method is required
                </small>
            </div>
        </div>
        <div class="form-group">
            <div class="sLine">
                <label for="category-name" class="control-label">Url*</label>
                <input formControlName="url" type="text" class="form-control" placeholder="Url">
            </div>
            <div class="error-div">
                <small class="text-danger" *ngIf="addSoar.controls.url.errors?.required && submit">
                    Url is required
                </small>
                <small class="text-danger" *ngIf="addSoar.controls.url.errors?.pattern && submit">
                    Not a URL
                </small>
            </div>
        </div>


        <!-- <div class="form-group">
            <label for="category-name" class="control-label">Comments</label>
            <textarea formControlName="comment" class="form-control" placeholder="Comment">
            </textarea>
        </div> -->
    </form>
</div>
<div class="modal-footer"> <button type="button" id="closebutton" class="btn btn-danger"
        (click)="clearForm()">Close</button>
    <button type="button" class="btn btn-success" (click)="exceptionsCreation()">{{btnName}}
        <i class="fab fa-telegram-plane ml-1"></i></button>
</div>