import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

import { LayoutComponent } from './layouts/layout/layout.component';
import { CommunityThreatComponent } from './pages/community/community-threat/community-threat.component';
import { CybotAnalyticsComponent } from './pages/cybot-analytics/cybot-analytics.component';
import { DashbordListComponent } from './pages/dashbord-list/dashbord-list.component';
import { UserCheckComponent } from './pages/user-check/user-check.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: '', redirectTo: '/home', pathMatch: "full" },
  { path: 'user-home', component: UserCheckComponent, canActivate: [AuthGuard] },
  { path: 'community-threat', component: CommunityThreatComponent, canActivate: [AuthGuard] },
  // { path: 'analytics', component: CybotAnalyticsComponent, canActivate: [AuthGuard] },
  // { path: 'compliance/:type', component: CybotAnalyticsComponent, canActivate: [AuthGuard] },  
  { path: 'analytics', redirectTo: '/home', pathMatch: "full"},
  { path: 'compliance/:type', redirectTo: '/home', pathMatch: "full" },  
  { path: 'dashboard-list', component: DashbordListComponent, canActivate: [AuthGuard] },
  { path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthGuard] },
  { path: 'home', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'dashboard', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'alert', component: LayoutComponent, loadChildren: () => import('./pages/alert/alert.module').then(m => m.AlertModule), canActivate: [AuthGuard] },
  { path: 'controlview', component: LayoutComponent, loadChildren: () => import('./pages/controlview/controlview.module').then(m => m.ControlviewModule), canActivate: [AuthGuard] }
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
