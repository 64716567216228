import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit {
  term: any;
  isLoader = true;
  titleArray: any;
  api: any;
  query: any;
  method: any;
  dataTemp: any = []
  filteredData: any = [];
  results = []
  expectedValues: any
  type: any
  titleCount: any
  @Input() set analyticalData(val) {
    // console.log("analyticalData", val)
    this.titleArray = val.titleData
    this.datas = val;
    this.api = val.api;
    this.query = val.query;
    this.method = val.method;
    this.type = val.type;
    this.expectedValues = val.expectedValues;
    this.titleCount = val.titleCount;
  }
  datas: any;
  constructor(public dashboardService: DashboardService) { }
  search(a) {
    this.filteredData = [];
    if (this.dataTemp && this.term) {
      this.dataTemp.forEach(it => {
        let isExist = false;
        it.map(element => {
          if (element.toLowerCase().includes(this.term.toLowerCase())) {
            isExist = true;
          }
        });
        if (isExist) {
          this.filteredData.push(it);
        }
      })
    }
    else {
      this.filteredData = this.dataTemp;
    }
  }
  ngOnInit(): void {
    this.query = this.query;
    this.dashboardService.dashboardApi(this.query, this.api, this.method).subscribe(
      (result: any) => {
        if (result) {
          if (this.type == 2) {
            // console.log("type 2 data", result)
            let data = result.hits.hits
            let k = 0
            data.forEach(data => {
              let dataSub = []
              dataSub.push(k + 1)
              this.expectedValues.forEach(element => {
                let tempData = data?._source
                // console.log("tempData", tempData)
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  // console.log("ex[i]", expectedValuesArray[i])
                  if (tempData[expectedValuesArray[i]]) {
                    tempData = tempData[expectedValuesArray[i]]
                  } else {
                    tempData = ''
                  }
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              // console.log("data1", dataSub)
              this.dataTemp.push(dataSub)
              k++
            });
            this.filteredData = this.dataTemp;
            this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
          } else if (this.type == 1) {
            let data = result.hits.hits
            // console.log("type 1 data", data)
            let k = 0
            data.forEach(data => {
              let dataSub = []
              dataSub.push(k + 1)
              this.expectedValues.forEach(element => {
                let tempData = data?._source
                let expectedValuesArray = element.split('.')
                for (let i = 0; i < expectedValuesArray.length; i++) {
                  tempData = tempData[expectedValuesArray[i]]
                }
                let data1 = tempData
                dataSub.push(data1)
              });
              this.dataTemp.push(dataSub)
              k++
            });
            this.filteredData = this.dataTemp;
            // console.log("dataTemp", this.dataTemp)
            this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
          } else if (this.type == 4) {
            // console.log("type 4 data", result)
            let data = result.aggregations
            // console.log("titleCount", this.titleCount)
            // console.log("aggregations", data)
            this.results = []
            if (data) {
              let keys = Object.keys(data)
              keys.forEach((k) => {
                this.recurring(1, data[k].buckets, '')
              })
              this.dataTemp = this.results;
              this.filteredData = this.dataTemp;
              // console.log("this.results", this.results)
              // data.forEach(data => {
              //   let dataSub = []
              //   dataSub.push(k + 1)
              //   this.expectedValues.forEach(element => {
              //     let tempData = data?._source
              //     let expectedValuesArray = element.split('.')
              //     for (let i = 0; i < expectedValuesArray.length; i++) {
              //       tempData = tempData[expectedValuesArray[i]]
              //     }
              //     let data1 = tempData
              //     dataSub.push(data1)
              //   });
              //   this.dataTemp.push(dataSub)
              //   k++
              // });
              this.datas.statCount = this.dataTemp.length != 0 ? this.dataTemp.length : "";
            }
          } else {
            console.log("DataGridComponent", result)
          }

        }
        this.isLoader = false;
      },
      (inerror) => {
        console.log("error", inerror);
      })
  }
  downloadCSV(data: any, heading: any) {
    data = [heading].concat(data)
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'myFile.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  recurring(index, buckets, values) {
    buckets.forEach(b => {
      let temp = values
      if (b[index + 1]) {
        if (Array.isArray(b[index + 1].buckets) && b[index + 1].buckets.length > 0) {
          if (temp !== '') temp += ` ${b.key}`
          else temp += `${b.key}`
          this.recurring(index + 1, b[index + 1].buckets, temp)
        }
        else {
          let k = temp + ` ${b.key}`
          this.results.push(k.split(' '))
        }
      }
      else {
        let k = temp + ` ${b.key}`
        this.results.push(k.split(' '))
      }
      // console.log(values)
    })
  }


}
