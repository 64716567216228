<div class="container pt-4">
    <div class="row dashboard-heading">
        <h3 class="d-flex justify-content-between">{{selectedMenus[0]=='Dashboard'?'My Dashboards':selectedMenus[0]}}
            <span *ngIf="routerUrlVal =='compliance/dashboard'"
                class="text-right printBtn mb-0 d-flex justify-content-between align-items-end back">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left"
                        aria-hidden="true"></i> Back
                </button>
            </span>
        </h3>

        <div *ngIf="routerUrlVal =='analytics'">
            <p>Security Analytics dashboards gives insight into data inside the organization from a security
                perspective. It helps the analyst to have an overview regarding various aspects of the organization and
                enables analysis of each of them.</p>
        </div>
        <div *ngIf="routerUrlVal =='compliance/dashboard'">
            <p>Compliance based dashboards including PCIDSS ,NIST, and ISO27001.</p>
        </div>

        <div *ngIf="selectedMenus?.includes('Alert')">
            <p>Alerts are triggered when logs matching specific criteria are met. Below listed are the alerts that have
                been triggered.
                It helps the analyst to continuously monitor events that are occuring in the organization.
                Control view description: Compliance control mapping of all policies and criterias to identify if that
                specific
                compliance is being followed in the organisation.(general desc )</p>
        </div>

        <div *ngIf="selectedMenus?.includes('Control View')">
            <p>Compliance control mapping of all policies and criterias to identify if that specific compliance is being
                followed in
                the organisation.</p>
        </div>
    </div>
    <div class="row mt-2 dashboard-menu">
        <div class="col-md-8 p-0">
            <div class="topbaritem">
                <div class="alert-secFilter d-flex justify-content-start">
                    <form class="search-container">
                        <input type="text" id="search-bar" [ngModelOptions]="{standalone: true}"
                            placeholder="Search My Dashboards" [(ngModel)]="searchVal" (ngModelChange)="search($event)">
                        <a href="#"><img class="search-icon"
                                src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png"></a>
                    </form>
                    <div *ngIf="routerUrlVal =='compliance/dashboard'">
                        <select [(ngModel)]="selectedOption" (change)="selectionChange(selectedOption)">
                            <!-- <option value="all">All</option> -->
                            <option value="iso">ISO-27001</option>
                            <option value="nist">NIST</option>
                            <option value="pci">PCI-DSS</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 text-right">
            <span [class]="isManage?'clicked-mngbtn':'manage-btn'" *ngIf="routerUrlVal =='analytics'"
                (click)="manageItems()">Dashboard Library
            </span>
            <ng-container *ngIf="routerUrlVal.includes('compliance')">
                <div class="filter-outer" style="padding: 0;">
                    <!-- <div [class]="selectedMenus?.includes('Dashboard')?'checkbox-div-clicked':'checkbox-div'"
                        *ngIf="selectedMenus?.includes('Dashboard')">
                        <h3>Dashboard Library</h3>
                    </div> -->

                    <!-- <div [class]="selectedMenus?.includes('Alert')?'checkbox-div-clicked':'checkbox-div'"
                        *ngIf="selectedMenus?.includes('Alert')">
                        <h3>Alert</h3>
                    </div> -->

                    <!-- <div [class]="selectedMenus?.includes('Control View')?'checkbox-div-clicked':'checkbox-div'"
                        *ngIf="selectedMenus?.includes('Control View')">
                        <h3>Control View</h3>
                    </div> -->
                </div>
            </ng-container>
        </div>

        <div class="topbaritem" *ngIf="routerUrlVal==='compliance'">
            <select>
                <option>ISO</option>
                <option>NIST</option>
                <option>PCI</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="cybot_loader" *ngIf="!menuList.length">
            <!-- <text x="150" y="50" fill="#ffffff"
                font-family="Helvetica Neue,Helvetica,Arial" font-size="18" text-anchor="middle">
                L O A D I N G ...
            </text> -->
        </div>
        <ng-container *ngFor="let list of menuList">
            <div class="col-md-12" *ngIf="selectedMenus?.includes(list.name)">
                <div class="row mt-3">
                    <ng-container *ngFor="let dash of list.list">
                        <div class="items-card" *ngIf="(checkSelect(dash.id)||isManage)"
                            [style.border]="checkSelect(dash.id)&&isManage?'1px solid #f98421':'px solid transparent'">
                            <!-- <div *ngIf="!isManage" class="overlay">
                            </div> -->
                            <!-- <div *ngIf="isManage" class="overlaymng" (click)="selectChange(dash.id)">
                                <h3>
                                    {{dash.title||dash.name||dash.label}}
                                </h3>
                                <p class="descDashboard">{{dash.description}}</p>
                                <div class="viewbtn" routerLink="/{{getRouter(list,dash)}}">View</div>
                            </div> -->

                            <div class="overlaymng">
                                <!-- <h3>
                                    {{dash.title||dash.name||dash.label}}
                                </h3> -->
                                <p class="descDashboard">{{dash.description}}</p>
                                <div class="viewbtn" routerLink="/{{getRouter(list,dash)}}">View</div>
                            </div>
                            <span class="checkboxBg" *ngIf="checkSelect(dash.id)&&isManage" style="margin-right: 4px;"
                                (click)="selectChange(dash.id)">&#10004;</span>
                            <span class="checkboxBg" *ngIf="!checkSelect(dash.id)&&isManage" style="margin-right: 4px;"
                                (click)="selectChange(dash.id)"></span>
                            <div>
                                <div *ngIf="routerUrlVal =='analytics'">

                                    <img *ngIf="isManage" class="item-img" src="assets/images/common-icon.png">
                                    <img *ngIf="!isManage" class="item-img" src="assets/images/common-icon.png"
                                        routerLink="/{{getRouter(list,dash)}}">
                                </div>


                                <div *ngIf="routerUrlVal =='compliance/dashboard'">
                                    <div *ngIf="selectedOpction=='iso'">
                                        <img *ngIf="isManage" class="item-img" src="assets/images/common-icon-iso.png">
                                        <img *ngIf="!isManage" class="item-img" src="assets/images/common-icon-iso.png"
                                            routerLink="/{{getRouter(list,dash)}}">

                                    </div>
                                    <div *ngIf="selectedOpction=='nist'">
                                        <img *ngIf="isManage" class="item-img" src="assets/images/common-icon-nist.png">
                                        <img *ngIf="!isManage" class="item-img" src="assets/images/common-icon-nist.png"
                                            routerLink="/{{getRouter(list,dash)}}">

                                    </div>
                                    <div *ngIf="selectedOpction=='pci'">
                                        <img *ngIf="isManage" class="item-img" src="assets/images/common-icon-pci.png">
                                        <img *ngIf="!isManage" class="item-img" src="assets/images/common-icon-pci.png"
                                            routerLink="/{{getRouter(list,dash)}}">

                                    </div>
                                </div>

                                <span class="hoverText" [class]="isManage?'titlemng':'titlenormal'"
                                    [style.background-color]="checkSelect(dash.id)&&isManage?'#f98421':''">
                                    {{dash.title||dash.name||dash.label}}
                                </span>

                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>

    </div>
    <div class="row" *ngIf="isManage">
        <div class="mt-4 text-center" style="margin: auto;">
            <button style="margin-right:20px;" class="btn btn-primary w-md waves-effect waves-light"
                (click)="backHome()" type="button">Back</button>
            <button (click)="saveDashbord()" class="btn btn-primary w-md waves-effect waves-light"
                type="submit">Save</button>
        </div>
    </div>
</div>