import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const apiAlertDataUrl = localStorage.getItem('cyboturl') + '/cybot_proxy';
const apiAlertDataUrl2 = 'https://52.53.139.65:9202';
// let url = localStorage.getItem('cyboturl');
// console.log("api", api)
// console.log("url", url)

const apiAlertDataUrl1 = localStorage.getItem('datalakeurl');
const userData = {
  datalakeuname: localStorage.getItem('datalakeuname'),
  datalakepass: localStorage.getItem('datalakepass')
}
const userData1 = {
  datalakeuname: localStorage.getItem('cybotuname'),
  datalakepass: localStorage.getItem('cybotpass')
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  HttpOptions: any;
  constructor(private http: HttpClient, private router: Router) { }

  getExceptionsListAll(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_exceptions*/_search",
      data,
      this.HttpOptions
    );
  }

  getExceptionsList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_exceptions_" + tenantID + "/_search",
      data,
      this.HttpOptions
    );
  }
  deletesExceptions(tenantID, id) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .delete<object>(apiAlertDataUrl + "/thp_exceptions_" + tenantID + "/_doc/" + id,
        this.HttpOptions)
      .pipe(catchError(this.handleError));
  }
  exceptionsUpdate(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_exceptions_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  exceptionsCreation(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_exceptions_" + tenantID + "/_doc",
      data,
      this.HttpOptions
    );
  }

  getLicenseDetailsList(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_license*/_search",
      data,
      this.HttpOptions
    ).pipe(catchError(this.handleError));;
  }
  scheduleList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.get<object>(
      apiAlertDataUrl + "/thp_schedule_" + tenantID + "/_search",

      this.HttpOptions
    );
  }
  handleError(error: HttpErrorResponse) {
    console.log("error", error);
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }



  getCYBOTHealth() {
    // this.HttpOptions = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json",
    //     "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
    //   }),
    // };
    let authorizationData: any =
      "Basic " + btoa(userData1.datalakeuname + ":" + userData1.datalakepass)

    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http.get<object>(
      apiAlertDataUrl2 + "/_cat/health?format=json",
      this.HttpOptions
    );
  }

  getDatalikeHealth() {
    let authorizationData: any =
      "Basic " + btoa(userData.datalakeuname + ":" + userData.datalakepass)

    this.HttpOptions = {
      headers: new HttpHeaders({
        "Authorization": authorizationData,
        "Content-Type": "application/json"
      })
    };
    return this.http
      .get<object>(
        apiAlertDataUrl1 + '/_cat/health?format=json',
        this.HttpOptions
      )
      .pipe(catchError(this.handleError));
  }



  getExceptionsListAllUeba(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/ueba_exceptions*/_search",
      data,
      this.HttpOptions
    );
  }

  getExceptionsListUaba(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/ueba_exceptions" + tenantID + "/_search",
      data,
      this.HttpOptions
    );
  }


  deletesExceptionsUEBA(tenantID, id) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .delete<object>(apiAlertDataUrl + "/ueba_exceptions_" + tenantID + "/_doc/" + id,
        this.HttpOptions)
      .pipe(catchError(this.handleError));
  }
  exceptionsUpdateUEBA(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/ueba_exceptions_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }

  exceptionsCreationUEBA(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/ueba_exceptions_" + tenantID + "/_doc",
      data,
      this.HttpOptions
    );
  }


  apiListAll(data) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_api*/_search",
      data,
      this.HttpOptions
    );
  }
  apiList(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_api_" + tenantID + "/_search",
      data,
      this.HttpOptions
    );
  }
  soarDelete(tenantID, id) {
    this.HttpOptions = {
      headers: new HttpHeaders({
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http
      .delete<object>(apiAlertDataUrl + "/thp_api_" + tenantID + "/_doc/" + id,
        this.HttpOptions)
      .pipe(catchError(this.handleError));
  }

  soarCreation(data, tenantID) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_api_" + tenantID + "/_doc",
      data,
      this.HttpOptions
    );
  }

  soarUpdate(data, tenantID, id) {
    data = JSON.stringify(data);
    this.HttpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user-token": localStorage.getItem('CYBOT_ACCESS_TOKEN')
      }),
    };
    return this.http.post<object>(
      apiAlertDataUrl + "/thp_api_" + tenantID + "/_update/" + id,
      data,
      this.HttpOptions
    );
  }
}
