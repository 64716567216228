<div id="layout-wrapper">
    <app-topbar>
    </app-topbar>

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">
        <div class="page-content">
            <app-dashbord-list [routerUrl]="routerUrl"></app-dashbord-list>
        </div>
        <app-footer></app-footer>
    </div>

</div>
<!-- END layout-wrapper -->