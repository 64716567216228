<app-topbar></app-topbar>
<div class="page-content huntingModule">

<div class="container">
    <!-- <app-pagetitle title="Management Summary" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
    <!-- <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">

        <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>

        <button class="btn btn-warning mb-2" (click)="openModalPrint(viewModalPrint,1)" [disabled]="isLoading">
            <i class="fa fa-print" [ngClass]="{'fa-spin fa-spinner': isLoading, 'ri-printer-line': !isLoading}"></i> {{button}}</button>
    </p> -->


    <div class="col-md-12 p-0">
        <div class="page-title-box d-flex align-items-center justify-content-between">
            <h3 class="mb-0">ActiveBytes Intelligence</h3>
            <p class="text-right printBtn mb-0 d-flex justify-content-between align-items-end">
                <button (click)="goToBack()" class="btn btn-themeBack"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back </button>
                <!-- <button class="btn btn-themePrint" (click)="openModalPrint(viewModalPrint,1)" [disabled]="isLoading"><i class="fa fa-print"
                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-file-pdf': !isLoading}"></i> {{button}}</button> -->
            </p>
        </div>
        
    </div>

    <div class="row mt-2">

        <div class="col-md-12 pb-2 p-0">
            <div class="card card-body h-95">
                <h2 class="card-title mt-0 sub-heading-title">ActiveBytes Intelligence overview</h2>
                <p class="card-text paragraph-clr">ActiveBytes threat feeds contain rich information about threat events,
                    vulnerabilities, and exploits along with recent news headlines, from curated sources. This can help
                    in keeping up to date with the latest developments in the cyber-space.
                </p>
            </div>
        </div>

        <!-- <div class="col-lg-6 pb-2">
            <div class="card card-body ">
                <h4 class="card-title mb-4 sub-heading-title">Event categories Distribution</h4>
                <p class="card-text  my-0 pb-4 paragraph-clr">Distribution of communities from which feeds are fetched</p>
                <div class="d-flex pabs100 mt-9em"><img class="m-auto file-loading" width="200" *ngIf="isEventDistribution"
                        src="assets/images/fileloading.gif" /></div>
                <apx-chart id="eventDistributionData" class="apex-charts" dir="ltr"
                    [series]="eventDistributionData.series" [chart]="eventDistributionData.chart"
                    [labels]="eventDistributionData.labels" [legend]="eventDistributionData.legend"
                    [colors]="eventDistributionData.colors" [responsive]="eventDistributionData.responsive">
                </apx-chart>
            </div>
        </div> -->

        <!-- orange bg design card 01 first card -->

        <!-- <div class="card-container col-md-6">
            <div class="card-contents">
                <div class="card">
                    <div class="icon-section">
                        <img src="../../../assets/images/Vulnerabilities1.png" alt="">
                    </div>
                    <div class="card-details">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto file-loading" width="100"
                            src="assets/images/fileloading.gif" /></div>
                        <span class="card-count" *ngIf="!isVulnerabilities">{{stats.vulnerabilities}}</span>
                        <h3 class="card-heading">Vulnerabilities</h3>
                    </div>
                </div>

                <div class="card">
                    <div class="icon-section">
                        <img src="../../../assets/images/Vulnerabilities Exploit2.png" alt="">
                    </div>
                    <div class="card-details">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto file-loading" width="100"
                            src="assets/images/fileloading.gif" /></div>
                        <span class="card-count" *ngIf="!isVulnerabilitiesExploit">{{stats.vulnerabilities_exploit}}</span>
                        <h3 class="card-heading">Vulnerabilities Exploit</h3>
                    </div>
                </div>

                <div class="card">
                    <div class="icon-section">
                        <img src="../../../assets/images/security3.png" alt="">
                    </div>
                    <div class="card-details">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto file-loading" width="100"
                            src="assets/images/fileloading.gif" /></div>
                        <span class="card-count" *ngIf="!isHeadlines">{{stats.security_events}}</span>
                        <h3 class="card-heading">Security Events</h3>
                    </div>
                </div>

                <div class="card">
                    <div class="icon-section">
                        <img src="../../../assets/images/headline4.png" alt="">
                    </div>
                    <div class="card-details">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto file-loading" width="100"
                            src="assets/images/fileloading.gif" /></div>
                        <span class="card-count" *ngIf="!isSecurity">{{stats.headlines}}</span>
                        <h3 class="card-heading">Headlines</h3>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- og card -->

        <div class="row">
            <div class="col-sm-3">
                <div class="card">
                    <div class="card-header text-center">Vulnerabilities</div>
                    <div class="card-body">
                        <div class="text-center" *ngIf="isVulnerabilities"><img class="m-auto file-loading" width="100"
                                src="assets/images/fileloading.gif" /></div>
                        <h4 class="text-center" *ngIf="!isVulnerabilities">{{stats.vulnerabilities}}</h4>
                    </div>
                    <div class="card-severity-btns">
                        <!-- routerLink="/vulnerabilities-summary" [queryParams]="{severity: 'low'}" -->
                        <button class="sev-btn seve-low" [ngClass]="{ zeroVal: severityCounts[0].low == 0 }">Low <br> <span class="severity-count">{{severityCounts[0].low}}</span></button>
                        <button class="sev-btn seve-medium" [ngClass]="{ zeroVal: severityCounts[0].medium == 0 }">Medium <span class="severity-count"> {{severityCounts[0].medium}}</span></button>
                        <button class="sev-btn seve-high" [ngClass]="{ zeroVal: severityCounts[0].high == 0 }">High <span class="severity-count">{{severityCounts[0].high}}</span></button>
                        <button class="sev-btn seve-critical" [ngClass]="{ zeroVal: severityCounts[0].critical== 0 }">Critical <span class="severity-count">{{severityCounts[0].critical}}</span></button>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="card">
                    <div class="card-header text-center">Vulnerabilities Exploit</div>
                    <div class="card-body">
                        <div class="text-center" *ngIf="isVulnerabilitiesExploit"><img class="m-auto file-loading" width="100"
                                src="assets/images/fileloading.gif" /></div>
                        <h4 class="text-center" *ngIf="!isVulnerabilitiesExploit">{{stats.vulnerabilities_exploit}}</h4>
                    </div>
                    <div class="card-severity-btns">
                        <!-- routerLink="/vulnerabilities-exploit" [queryParams]="{severity: 'low'}" -->
                        <button class="sev-btn seve-low" [ngClass]="{ zeroVal: severityCounts[1].low == 0 }" >Low <br> <span class="severity-count">{{severityCounts[1].low}}</span></button>
                        <button class="sev-btn seve-medium" [ngClass]="{ zeroVal: severityCounts[1].medium == 0 }" >Medium <span class="severity-count">{{severityCounts[1].medium}}</span></button>
                        <button class="sev-btn seve-high" [ngClass]="{ zeroVal: severityCounts[1].high == 0 }" >High <span class="severity-count">{{severityCounts[1].high}}</span></button>
                        <button class="sev-btn seve-critical" [ngClass]="{ zeroVal: severityCounts[1].critical == 0 }">Critical <span class="severity-count">{{severityCounts[1].critical}}</span></button>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="card">
                    <div class="card-header text-center">Security Events</div>
                    <div class="card-body">
                        <div class="text-center" *ngIf="isHeadlines"><img class="m-auto file-loading" width="100"
                                src="assets/images/fileloading.gif" /></div>
                        <h4 class="text-center" *ngIf="!isHeadlines">{{stats.security_events}}</h4>
                    </div>
                    <div class="card-severity-btns">
                        <!-- routerLink="/security-incidents" [queryParams]="{severity: 'low'}" -->
                        <button class="sev-btn seve-low" [ngClass]="{ zeroVal: severityCounts[2].low == 0 }" >Low <br> <span class="severity-count">{{severityCounts[2].low}}</span></button>
                        <button class="sev-btn seve-medium" [ngClass]="{ zeroVal: severityCounts[2].medium == 0 }">Medium <span class="severity-count">{{severityCounts[2].medium}}</span></button>
                        <button class="sev-btn seve-high" [ngClass]="{ zeroVal: severityCounts[2].high == 0 }" >High <span class="severity-count">{{severityCounts[2].high}}</span></button>
                        <button class="sev-btn seve-critical" [ngClass]="{ zeroVal: severityCounts[2].critical == 0 }">Critical <span class="severity-count">{{severityCounts[2].critical}}</span></button>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="card">
                    <div class="card-header text-center">Headlines</div>
                    <div class="card-body">
                        <div class="text-center" *ngIf="isSecurity"><img class="m-auto file-loading" width="100"
                                src="assets/images/fileloading.gif" /></div>
                        <h4 class="text-center" *ngIf="!isSecurity">{{stats.headlines}}</h4>
                    </div>
                </div>
            </div>
        </div>

        
    </div>

    <!-- tabs section -->

    

    <div class="management-tab-container">


        <div class="filter-severity-container">
            <button (click)="filterDatas('viewAll')" class="filter-sev-btn low">All</button>
            <button (click)="filterDatas('Low')" class="filter-sev-btn low">Low</button>
            <button (click)="filterDatas('Medium')" class="filter-sev-btn medium">Medium</button>
            <button (click)="filterDatas('High')" class="filter-sev-btn high">High</button>
            <button (click)="filterDatas('Critical')" class="filter-sev-btn critical">Critical</button>
        </div>
        
        
        <nav class="d-flex align-items-center justify-content-between mt-2">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-item nav-link active" id="vulnerabilities-tab" data-toggle="tab" href="#vulnerabilities" role="tab" aria-controls="vulnerabilities" aria-selected="true">Vulnerabilities</a>
              <a class="nav-item nav-link" id="vulnerabilities-exploit-tab" data-toggle="tab" href="#vulnerabilities-exploit" role="tab" aria-controls="vulnerabilities-exploit" aria-selected="false">Vulnerabilities Exploit</a>
              <a class="nav-item nav-link" id="security-events-tab" data-toggle="tab" href="#security-events" role="tab" aria-controls="security-events" aria-selected="false">Security Events</a>
              <a class="nav-item nav-link" id="headlines-tab" data-toggle="tab" href="#headlines" role="tab" aria-controls="headlines" aria-selected="false">Headlines</a>
            </div>

            <!-- fliter severity -->
            
            <div class="searching-bar-container">
                <input type="text" [(ngModel)]="dataTableSearch" placeholder="Search Here">
            </div>

          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="vulnerabilities" role="tabpanel" aria-labelledby="vulnerabilities-tab">

                <div class="row">
                    <div class="col-xl-12 pb-4">
                        <div class="card">

                            <!-- loader -->
                            <img *ngIf="isVulnerabilitiesData" class="loader" src="assets/images/fileloading.gif" alt="loading">


                            <div class="card-body" *ngIf="vulFilter.length != 0">
                                <div class="topp-section d-flex justify-content-between">
                                <!-- <div class="filter-severity-container">
                                    <button (click)="getVulnerabilitiesData()" class="filter-sev-btn">View All</button>
                                    <button (click)="filterData('low')" class="filter-sev-btn low">Low</button>
                                    <button (click)="filterData('medium')" class="filter-sev-btn medium">Medium</button>
                                    <button (click)="filterData('high')" class="filter-sev-btn high">High</button>
                                    <button (click)="filterData('critical')" class="filter-sev-btn critical">Critical</button>
                                </div> -->
                            </div>


                                <p class="card-discrp paragraph-clr">Vulnerabilities are weaknesses in information systems or security infrastructure that could be
                                    exploited by a threat source. The following table shows the breakdown of vulnerability
                                    information that were obtained from various sources
                                    in the given timeframe.
                                </p>

                                <div class="management-card-container">
                                    <div class="management-card-contents" *ngFor="let item of vulFilter | filter:dataTableSearch | paginate: { itemsPerPage: 5, currentPage: p }">
                                        <div class="leftside-card-section">
                                                <!-- card-headline -->
                                            <!-- <div class="management-card-headline">
                                                <h5>{{item.event_name}}</h5>
                                            </div> -->

                                            <!-- cve id -->
                                            <div class="management-card-cve-id" *ngIf="item.cve_id">
                                                <span class="primary-clr">CVE ID</span>
                                                <p>{{item.cve_id}}</p>
                                            </div>

                                            <!-- card description -->
                                            <div class="management-card-description">
                                                <span class="primary-clr" *ngIf="item.event_description != 'No description found'">Description</span>
                                                <p class="paragraph-clr" [ngClass]="{'no-data-style': item.event_description === 'No description found' }">{{item.event_description.length > 183 ? item.event_description.slice(0, 180) + '...' : item.event_description}}</p>
                                            </div>

                                                
                                            <div class="card-reference-section">
                                                <!-- card reference link -->
                                                <!-- <div class="management-card-link">
                                                    <span class="primary-clr">Reference Link</span>
                                                    <li><a href="{{item.reference_link[0]}}" target="_blank">{{item.reference_link[0]}}</a></li>
                                                </div> -->
                                                <div class="management-card-link" *ngIf="item.reference_link">
                                                    <span class="primary-clr">Reference Link</span>
                                                    <li><a href="{{item.reference_link}}" target="_blank">Click Here</a></li>
                                                </div>
                                            </div>

                                            <!-- card severity and tlp  -->
                                            <div class="management-card-severity-tlp" *ngIf="item.severity">
                                                <div class="severity-card">
                                                    <span class="primary-clr">Severity</span>
                                                    <span class="" [ngClass]="{'lowLabel': item.severity=='LOW','highLabel': item.severity=='HIGH','mediumLabel': item.severity=='MEDIUM','criticalLabel': item.severity == 'CRITICAL'}">{{item.severity}}</span>
                                                </div>
                                            </div>

                                            <!-- publish date section -->
                                            <div class="management-card-publish-date" *ngIf="item.published_date">
                                                <span class="primary-clr">Published Date</span>
                                                <p><i class="fa fa-calendar white-text mr-3"></i>{{item.published_date}}</p>
                                            </div>

                                            <!-- card timestamp  -->
                                            <!-- <div class="management-card-timestamp">
                                                <span class="primary-clr"><i class="fa fa-calendar white-text mr-3"></i> {{item.timestamp}}</span>
                                            </div> -->
                                        </div>

                                        <!-- view more btn -->
                                        <div class="rightside-card-section actionTd viewBtn">
                                            <a href="javascript:void(0);" ngbTooltip="view" (click)="openModal(viewModal,item,1)"><i class="fa fa-eye font-size-18"></i> View</a>
                                        </div>
                                    </div>

                                    <!-- pagination module section -->
                                    <pagination-controls class="ngx-pagination-section" (pageChange)="p = $event"></pagination-controls>
                                </div>

                            </div>

                            <div class="card-body" *ngIf="vulFilter.length === 0">
                                <h5 class="no-severity">No {{checkingSeverityFlag === 'Medium' ? 'Medium' : checkingSeverityFlag === 'Low' ? 'Low' : checkingSeverityFlag === 'High' ? 'High' : checkingSeverityFlag === 'Critical' ? 'Critical' : ''}} Severity  Data</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="tab-pane fade" id="vulnerabilities-exploit" role="tabpanel" aria-labelledby="vulnerabilities-exploit-tab">


                <div class="row">
                    <div class="col-xl-12 pb-4">
                        <div class="card">
                            <div class="card-body" *ngIf="vulnerExploit.length != 0">
                                <div class="topp-section d-flex justify-content-between">
                                <!-- <div class="filter-severity-container">

                                    <button (click)="getVulnerabilitiesExploitData()" class="filter-sev-btn">View All</button>
                                    <button (click)="filterDataEx('low')" class="filter-sev-btn low">Low</button>
                                    <button (click)="filterDataEx('medium')" class="filter-sev-btn medium">Medium</button>
                                    <button (click)="filterDataEx('high')" class="filter-sev-btn high">High</button>
                                    <button (click)="filterDataEx('critical')" class="filter-sev-btn critical">Critical</button>
                                </div> -->
                                </div>
                                
                                <p class="paragraph-clr">An exploit is a piece of software, data or sequence of commands that takes advantage of a
                                    vulnerability to cause unintended behavior or to gain unauthorized access to sensitive data. The
                                    following table shows the breakdown of some
                                    vulnerabilities with their exploit information including the CVE, CVSS etc. that were obtained
                                    from various sources in the given timeframe.
                                </p>

                                <div class="management-card-container">
                                    <div class="management-card-contents" *ngFor="let item of vulnerExploit | filter:dataTableSearch | paginate: { itemsPerPage: 5, currentPage: p2 }">
                                        <div class="leftside-card-section">
                                                <!-- card-headline -->
                                            <div class="management-card-headline">
                                                <h5>{{item.event_name}}</h5>
                                            </div>

                                            <!-- card description -->
                                            <div class="management-card-description">
                                                <span class="primary-clr" *ngIf="item.event_description != 'No description found'">Description</span>
                                                <p class="paragraph-clr" [ngClass]="{'no-data-style': item.event_description === 'No description found' }">{{item.event_description.length > 183 ? item.event_description.slice(0, 180) + '...' : item.event_description}}</p>
                                            </div>

                                            <div class="card-reference-section">
                                                <!-- vendor -->
                                                <div class="management-card-vendor"> 
                                                    <span class="primary-clr">Vendor</span>
                                                    <span class="paragraph-clr" [ngClass]="{'no-data-style': item.vendor === 'No Vendor found' }">{{item.vendor}}</span>
                                                </div>
                                            </div>

                                            <!-- card severity and tlp  -->
                                            <div class="management-card-severity-tlp">
                                                <div class="severity-card">
                                                    <span class="primary-clr">Severity</span>
                                                    <span class="" [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium', 'criticalLabel' : item.severity=='Critical'}">{{item.severity}}</span>
                                                </div>

                                                <div class="tlp-card" *ngIf="item.tlp">
                                                    <span class="primary-clr">TLP</span>
                                                    <span class="tlp-styles">{{item.tlp}}</span>
                                                </div>
                                            </div>

                                            <!-- card timestamp  -->
                                            <div class="management-card-timestamp">
                                                <span class="primary-clr"><i class="fa fa-calendar white-text mr-3"></i> {{item.attribute_timestamp * 1000| date: 'medium'}}</span>
                                            </div>
                                    
                                        </div>

                                        <!-- view more btn -->
                                        <div class="rightside-card-section actionTd viewBtn">
                                            <a href="javascript:void(0);" ngbTooltip="view" (click)="openModal(viewModal,item,1)"><i class="fa fa-eye font-size-18"></i> View</a>
                                        </div>
                                    </div>

                                    <!-- pagination module section -->
                                    <pagination-controls class="ngx-pagination-section" (pageChange)="p2 = $event"></pagination-controls>
                                </div>

                            </div>

                            <div class="card-body" *ngIf="vulnerExploit.length === 0">
                                <h5 class="no-severity">No {{checkingSeverityFlag === 'Medium' ? 'Medium' : checkingSeverityFlag === 'Low' ? 'Low' : checkingSeverityFlag === 'High' ? 'High' : checkingSeverityFlag === 'Critical' ? 'Critical' : ''}} Severity  Data</h5>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- oldddddd -->
                <!-- <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title sub-heading-title">Vulnerabilities with exploit</h4>
                            <p class="paragraph-clr">An exploit is a piece of software, data or sequence of commands that takes advantage of a
                                vulnerability to cause unintended behavior or to gain unauthorized access to sensitive data. The
                                following table shows the breakdown of some
                                vulnerabilities with their exploit information including the CVE, CVSS etc. that were obtained
                                from various sources in the given timeframe.</p>
                            <div class="table-responsive">
                                <div class="text-center" *ngIf="isVulnerabilitiesExploit"><img class="m-auto" width="200"
                                        src="assets/images/fileloading.gif" /></div>
                                <table datatable id="security_table" class="row-border hover scroll custWidth"
                                    [dtOptions]="dtOptions" [dtTrigger]="dtTriggerVulnerabilitiesExploit">
                                    <tbody *ngIf="!isVulnerabilitiesExploit && !high">
                                        <tr *ngFor="let item of vulnerabilitiesExploitData">
                                            <td class="w95">
                                                
                                                <h6>{{item.event_name}}</h6>
                
                                                <div class="font-14 text-muted">
                                                    <p class="mb-0"><span class="hightlight-head primary-clr">Description:</span><span class="paragraph-clr"
                                                        title="{{item.event_description}}">{{item.event_description.length > 140 ?
                                                            item.event_description.slice(0, 180) + '...' :
                                                            item.event_description}}</span>
                                                    </p>
                                                    <p class="mb-0 primary-clr">Vendor : <span class="paragraph-clr">{{item.vendor}}</span></p>
                                                    <p class="mb-0 py-3"><i class="fa fa-calendar mr-3 white-text "></i> <span class="timestamp">{{item.attribute_timestamp}}</span></p>
                                                </div>
                
                                                <div>
                                                    <span class="hightlight-head">Severity : </span>
                                                    <span class="custom-alert"
                                                        [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium', 'criticalLabel' : item.severity=='Critical'}">{{item.severity}}
                                                    </span> 
                
                                                    <span *ngIf="item.tlp" class="hightlight-head"> TLP :</span><span *ngIf="item.tlp" class="tlp-styles">{{item.tlp}}</span>
                                                </div>
                                            </td>
                                            <td class="actionTd viewBtn">
                                                <a href="javascript:void(0);" class="" ngbTooltip="view"
                                                    (click)="openModal(viewModal,item,4)"><i
                                                        class="fa fa-eye font-size-18"></i> View</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            </div>
            <div class="tab-pane fade" id="security-events" role="tabpanel" aria-labelledby="security-events-tab">

                <div class="row">
                    <div class="col-xl-12 pb-4">
                        <div class="card">
                            <div class="card-body" *ngIf="secuEvents.length != 0">
                                <div class="topp-section d-flex justify-content-between">
                                    <!-- <div class="filter-severity-container">
                                        <button (click)="getSecurityEventData()" class="filter-sev-btn">View All</button>
                                        <button (click)="filterDataSecu('low')" class="filter-sev-btn low">Low</button>
                                        <button (click)="filterDataSecu('medium')" class="filter-sev-btn medium">Medium</button>
                                        <button (click)="filterDataSecu('high')" class="filter-sev-btn high">High</button>
                                        <button (click)="filterDataSecu('critical')" class="filter-sev-btn critical">Critical</button>
                                    </div> -->
                                </div>

                                <!-- loader -->
                                <img *ngIf="isSecurityEventData" src="assets/images/fileloading.gif" alt="loading">

                                <p class="card-discrp paragraph-clr">This panel shows the information regarding trending security events, these can include
                                    recently found vulnerabilites or security breaches that took place in other organizations or
                                    patches to the same and much more.
                                </p>

                                <div class="management-card-container">
                                    <div class="management-card-contents" *ngFor="let item of secuEvents | filter:dataTableSearch | paginate: { itemsPerPage: 5, currentPage: p3 }">
                                        <div class="leftside-card-section">
                                                <!-- card-headline -->
                                            <div class="management-card-headline">
                                                <h5>{{item.event_name}}</h5>
                                            </div>

                                            <!-- card description -->
                                            <div class="management-card-description">
                                                <span class="primary-clr" *ngIf="item.event_description != 'No description found'">Description</span>
                                                <p class="paragraph-clr" [ngClass]="{'no-data-style': item.event_description === 'No description found' }">{{item.event_description.length > 183 ? item.event_description.slice(0, 180) + '...' : item.event_description}}</p>
                                            </div>

                                            <!-- card severity and tlp  -->
                                            <div class="management-card-severity-tlp">
                                                <div class="severity-card">
                                                    <span class="primary-clr">Severity</span>
                                                    <span class="" [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium', 'criticalLabel' : item.severity=='Critical'}">{{item.severity}}</span>
                                                </div>

                                                <div class="tlp-card" *ngIf="item.tlp">
                                                    <span class="primary-clr">TLP</span>
                                                    <span class="tlp-styles">{{item.tlp}}</span>
                                                </div>
                                            </div>

                                            <!-- card timestamp  -->
                                            <div class="management-card-timestamp">
                                                <span class="primary-clr"><i class="fa fa-calendar white-text mr-3"></i> {{item.attribute_timestamp}}</span>
                                            </div>
                                    
                                        </div>

                                        <!-- view more btn -->
                                        <div class="rightside-card-section actionTd viewBtn">
                                            <a href="javascript:void(0);" ngbTooltip="view" (click)="openModal(viewModal,item,1)"><i class="fa fa-eye font-size-18"></i> View</a>
                                        </div>
                                    </div>

                                    <!-- pagination module section -->
                                    <pagination-controls class="ngx-pagination-section" (pageChange)="p3 = $event"></pagination-controls>
                                </div>

                            </div>

                            <div class="card-body" *ngIf="secuEvents.length === 0">
                                <h5 class="no-severity">No {{checkingSeverityFlag === 'Medium' ? 'Medium' : checkingSeverityFlag === 'Low' ? 'Low' : checkingSeverityFlag === 'High' ? 'High' : checkingSeverityFlag === 'Critical' ? 'Critical' : ''}} Severity  Data</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="tab-pane fade" id="headlines" role="tabpanel" aria-labelledby="headlines-tab">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="headline-container col-md-12">
                            <div class="top-section">

                                <!-- <div class="search-bar-container">
                                    <input type="text" placeholder="Search" [(ngModel)]="headlineSeach">
                                </div> -->

                                <!-- <div class="news-filter">
                                    <ul>
                                        <li *ngFor="let time of setTime">
                                            <a (click)="filterTime(time)">{{time}}</a>
                                        </li>
                    
                                        <li>
                                            <a href="#">6H</a>
                                        </li>
                    
                                        <li>
                                            <a href="#">12H</a>
                                        </li>
                    
                                        <li>
                                            <a href="#">24H</a>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                            <div class="headline-contents">
                                <div class="single-head" *ngFor="let news of headlinesData | filter:dataTableSearch">
                                    <div class="timestamp">{{news.attribute_timestamp}}</div>
                                    <div class="news-heading">
                                        <h5>{{news.event_name}}</h5>
                                    </div>
                                    <div class="news-link">
                                        <a [href]="news.reference_link" target="_blank">Continue Reading</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
    </div>

    <!-- count card designing -->

    <!-- <div class="card-container col-md-4">
        <div class="card-contents">
            <div class="card">
                <div class="icon-section">
                    <img src="../../../assets/images/Vulnerabilities1.png" alt="">
                </div>
                <div class="card-details">
                    <span class="card-count">34</span>
                    <h3 class="card-heading">Vulnerabilities</h3>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="row">

    </div> -->

    <!-- security incidence section ///////////////////////////////////////////////// -->

    <!-- Vulnerabilities Intelligence section  /////////////////////////////////////////////////-->
    



    <!-- security incidence and vulnerabilities tab section code -->




    <!-- pdf section codes/////////////////////////////////////////////// -->

    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4>Community Feeds Information</h4>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Community IOCs</h4>
                    <p>Community events are the events provided by open source threat intelligence providers which will
                        contain a large number of IOCs related to any given security event that took place in a recent
                        time frame.</p>
                    <div class="table-responsive">
                        <div class="text-center" *ngIf="isIOCCommunityfeedsData"><img class="m-auto file-loading" width="150"
                                src="assets/images/fileloading.gif" /></div>
                        <table datatable class="row-border hover scroll w-100" datatable id="security_table"
                            class="row-border hover scroll custWidth" [dtOptions]="dtOptions"
                            [dtTrigger]="dtTriggerCommunityIOCs">
                            <thead style="display: none;">
                                <tr>
                                    <th>Event Name</th>
                                    <th>Feed Name</th>

                            </thead>
                            <tbody>
                                <tr *ngFor="let item of IOCCommunityfeedsData">
                                    <td class="w95">
                                        <h6>{{item.event_name}}</h6>
                                        <div class="font-14 text-muted">
                                            <p class="mb-0"> Feed Name:{{item.feed_name}} | Event ID:{{item.event_id}}</p>
                                            <p class="mb-0"><i class="fa fa-calendar"></i> {{item.attribute_timestamp}}</p>
                                        </div>
                                        <div>
                                            Severity : <span
                                                [ngClass]="{'lowLabel': item.severity=='Low','highLabel': item.severity=='High','mediumLabel': item.severity=='Medium'}">{{item.severity}}</span>  | TLP : <span>{{item.tlp}}</span>
                                        </div>
                                        
                                    </td>

                                    <td class="actionTd viewBtn">
                                        <a href="javascript:void(0);" class="" ngbTooltip="view"
                                            (click)="openModal(viewModal,item,5)"><i
                                                class="fa fa-eye font-size-18"></i> View</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

</div>

<ng-template #viewModal let-modal>

    <!-- action btns -->
    <div class="modal-header">
        <!-- <h5 class="primary-head">Event Details</h5> -->

        <div class="action-btns">
            <div class="save-btn">
                <button class="btn-default" (click)="captureScreenModelPrint()">Save <span>PDF</span></button>
            </div>
            <div class="close-btn" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                </svg>
            </div>
        </div>
        <!-- <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button> -->
    </div>

    <!-- ///////////////////////// old -->
    <!-- <div class="modal-header">
        <h5 class="modal-title">Details</h5>
        <p class="text-right pb-2 printBtn p-m-b-0">
            <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button> 
            <button class="btn btn-warning" (click)="captureScreenModelPrint()"><i
                    class="ri-printer-line"></i>Save PDF</button>
            <button class="btn btn-danger"  (click)="modal.dismiss('Cross click')"><i class="ri-mail-line"></i>Close</button>
            <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
        </p>
    </div> -->


    <div class="modal-body p-3 pb-4">

        <div *ngIf="modalId==1" class="row">
            <div id="contentToConvert" class="overflow-hidden">
                
                <div class="main-header col-md-12">
                    <div class="event-title">
                        <h2 class="sub-heading">Event Details</h2>
                    </div>
                    <div>
                        <p class="paragraph-clr-dark">{{selectedContent.event_name}}</p>
                    </div>
                </div>


                <div class="tags col-md-12 mt-4">
                    <div *ngIf="selectedContent.severity" class="colm-1  taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low' || selectedContent.severity == 'LOW',
                        status_border_medium: selectedContent.severity == 'Medium' || selectedContent.severity == 'MEDIUM',
                        status_border_high: selectedContent.severity == 'High' || selectedContent.severity == 'HIGH',
                        status_border_critical: selectedContent.severity == 'Critical' || selectedContent.severity == 'CRITICAL'
                    }">
                        <label>Severity</label>
                        <!-- <span class="span-head" >Severity</span>  -->
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low' || selectedContent.severity == 'LOW',
                            status_mediumLabel: selectedContent.severity == 'Medium' || selectedContent.severity == 'MEDIUM',
                            status_highLabel: selectedContent.severity == 'High' || selectedContent.severity == 'HIGH',
                             status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'CRITICAL'
                        }">{{ selectedContent.severity}}</span>
                    </div>
                    <div class="colm2 label-style" *ngIf="selectedContent.tlp">
                        <label>TLP</label>
                        <span>{{selectedContent.tlp}}</span>
                    </div>
                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp" >{{selectedContent.attribute_timestamp * 1000| date: 'medium'}}</span>
                        <span class="timestamp">{{selectedContent.published_date}}</span>
                        <span class="timestamp" >{{selectedContent.attribute_timestamp}}</span>

                    </div>

                    <div class="colm4 label-style" *ngIf="selectedContent.cve_id">
                        <label>CVE ID</label>
                        <span class="modal-title">{{selectedContent.cve_id}}</span>
                    </div>
                </div>


                <div class="tags col-md-12 mt-4 " *ngIf="selectedContent.base_Score">
                    
                    <div class="colm5 label-style col-md-12">
                        <label>Base Score</label>
                        <span class="modal-title">{{selectedContent.base_Score}}</span>
                    </div>

                </div>


                <div class="tags col-md-12 mt-4" *ngIf="selectedContent.attack_vector || selectedContent.attack_complexity">

                    <div class="colm6 label-style col-md-4">
                        <label>Attack Vector</label>
                        <span class="modal-title">{{selectedContent.attack_vector}}</span>
                    </div>

                    <div class="colm6 label-style col-md-4">
                        <label>Attack Complexity</label>
                        <span class="modal-title">{{selectedContent.attack_complexity}}</span>
                    </div>

                </div>


                <div class="tags col-md-12 mt-4" *ngIf="selectedContent.privileges_Required || selectedContent.exploitability_Score">

                    <div class="colm6 label-style col-md-6">    
                        <label>Privileges Required</label>
                        <span class="modal-title">{{selectedContent.privileges_Required}}</span>
                    </div>

                    <div class="colm6 label-style col-md-6">
                        <label>Exploitability Score </label>
                        <span class="modal-title">{{selectedContent.exploitability_Score}}</span>
                    </div>

                </div>


                <div class="tags col-md-12 mt-4" *ngIf="selectedContent.confidentiality_Impact || selectedContent.integrity_Impact">

                    <div class="colm6 label-style col-md-6">
                        <label>Confidentiality Impact</label>
                        <span class="modal-title">{{selectedContent.confidentiality_Impact}}</span>
                    </div>

                    <div class="colm6 label-style col-md-6">
                        <label>Integrity Impact</label>
                        <span class="modal-title">{{selectedContent.integrity_Impact}}</span>
                    </div>
                    
                </div>

                <div class="tags col-md-12 mt-4" *ngIf="selectedContent.availability_Impact || selectedContent.impact_Score">

                    <div class="colm6 label-style col-md-6" >
                        <label>Availability Impact</label>
                        <span class="modal-title">{{selectedContent.availability_Impact}}</span>
                    </div>


                    <div class="colm6 label-style col-md-6">
                        <label>Impact Score</label>
                        <span class="modal-title">{{selectedContent.impact_Score}}</span>
                    </div>
                    

                </div>

                <div class="tags col-md-12 mt-4" *ngIf="selectedContent.vectorString || selectedContent.exploitability_Score">

                    <div class="colm6 label-style col-md-6">
                        <label>Vector String</label>
                        <span class="modal-title">{{selectedContent.vectorString}}</span>
                    </div>

                    <div class="colm6 label-style col-md-6">
                        <label>Exploitability Score</label>
                        <span class="modal-title">{{selectedContent.exploitability_Score}}</span>
                    </div>

                </div>

                <!-- <p class="text-right pb-2 printBtn">
                                <button class="btn btn-secondary"><i class="ri-mail-line"></i>Emails</button>
                                <button class="btn btn-secondary" (click)="captureScreen()"><i class="ri-printer-line"></i>Print</button>
                            </p> -->
                <!-- <button class="btn btn-warning" (click)="captureScreen()" style="left: 93%;">print</button> -->

                <div class="col-md-12">
                    <p class="card-title-desc paragraph-clr-dark">
                        <span class="viewHead sub-heading">Description:</span> <br>{{selectedContent.event_description}}
                    </p>
                </div>

                <div class="col-md-12">
                    <ul class="list-unstyled mb-0" *ngIf="selectedContent.comments">
                        <li><span class="viewHead sub-heading">Comments</span><br>
                            <ul class="commentsList">
                                <li class="modal-title" *ngFor="let comment of selectedContent.comments">{{comment}}</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <!-- <div class="col-md-12" *ngIf="selectedContent.version || selectedContent.base_score">
                    <span class="sub-heading">CVSS</span><br>

                    <div class="cvss-container">
                        <div class="cvss-version">
                            <span class="value-dark">CVSS Version :</span>
                            <span class="value-dark">{{selectedContent.version}}</span>
                        </div>
    
                        <div class="base-score">
                            <span class="value-dark">Base Score :</span>
                            <span class="value-dark">{{selectedContent.base_score}}</span>
                        </div>
                    </div>
                    
                </div> -->

                <div class="col-md-12" *ngIf="selectedContent.cvss">
                    <p class="card-title-desc"><span class="viewHead sub-heading">CVSS</span><br>
                        <span class="value-dark ml-6">{{selectedContent.cvss}}</span>
                    </p>
                </div>

                <div class="col-md-12 p-0">
                    <span class="sub-heading" *ngIf="countries.length != 0">Impact Region</span>
                    <app-geochart [countries]="countries.length != 0 ? countries : 'N/A'"></app-geochart>
                </div>

                <div class="col-md-12" *ngIf="selectedContent.reference_link">
                    <ul class="list-unstyled mb-0">
                        <li><span class="viewHead text-light sub-heading">Reference Link</span><br>
                            <ul>
                                <li *ngFor="let item of selectedContent.reference_link">
                                    <a [href]="item" target="_blank">{{item}}</a>
                                </li>
                            </ul>
                        </li>   

                        <li *ngIf="countries.length === 0">
                            <ul>
                                <li>
                                    <a [href]="selectedContent.reference_link" target="_blank">{{selectedContent.reference_link}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="col-md-12" *ngIf="selectedContent.vendor">
                    <p class="card-title-desc">
                        <span class="viewHead sub-heading"> Vendor</span><br><span class="value-dark ml-6">{{selectedContent.vendor}}</span>
                    </p>
                </div>
                
            </div>
        </div>

        <!-- event details header -->
        <div class="row" *ngIf="modalId==2">
            <div id="contentToConvert1" class="overflow-hidden">


                <div class="tags col-md-12">
                    <div class="colm-1  taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low',
                        status_border_medium: selectedContent.severity == 'Medium',
                        status_border_high: selectedContent.severity == 'High',
                        status_border_critical: selectedContent.severity == 'Critical'
                    }">
                        <label>Severity</label>
                        <!-- <span class="span-head" >Severity</span>  -->
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low',
                            status_mediumLabel: selectedContent.severity == 'Medium',
                            status_highLabel: selectedContent.severity == 'High',
                             status_criticalLabel: selectedContent.severity == 'Critical'
                        }">{{ selectedContent.severity }}</span>
                    </div>
                    <div class="colm2 label-style">
                        <label>TLP</label>
                        <span>{{selectedContent.tlp}}</span>
                    </div>
                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                    </div>

                    
                </div>
                


                <!-- //////////////////////////////// old -->
                <!-- <div class="col-md-12">
                    <h3 class="eventNameHead">{{selectedContent.event_name}}</h3>
                </div>
                <div class="col-md-6">
                    {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                                  status_lowLabel: selectedContent.severity == 'Low',
                                  status_highLabel: selectedContent.severity == 'High',
                                   status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                              }">{{ selectedContent.severity }}</span>
                </div>
                <div class="col-md-6">
                    <div>TLP : <span>{{selectedContent.tlp}}</span></div>
                    <p class="text-right pb-2 printBtn">
                    <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                    <button class="btn btn-secondary" (click)="captureScreen1()"><i class="ri-printer-line"></i>Print</button>
                </p>
                </div> -->

                <!-- <p class="text-right pb-2 printBtn">
                                <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                                <button class="btn btn-secondary" (click)="captureScreen1()"><i class="ri-printer-line"></i>Print</button>
                            </p> -->
                <!-- <button class="btn btn-warning" (click)="captureScreen1()" style="left: 93%;">print</button> -->

                <div class="col-md-12">
                    <h2 class="sub-heading mt-4">Description</h2>
                    <p class="paragraph-clr-dark">{{selectedContent.event_description}}</p>
                </div>

                <div class="col-md-12">

                    <h2 class="sub-heading mt-4">Reference Link</h2>
                    <div class="links">
                        <ul>
                            <li *ngFor="let link of selectedContent.reference_link">
                                <a [href]="link" target="_blank">{{link}}</a>
                            </li>
                        </ul>
                    </div>



                    <!-- ////////////////////////////// old -->
                    <!-- <ul class="list-unstyled mb-0">
                        <li><span class="viewHead text-light">Reference Link</span><br>
                            <ul>
                                <li ><b><a [href]="selectedContent.reference_link"
                                            target="_blank">{{selectedContent.reference_link}}</a></b></li>
                            </ul>
                        </li>
                    </ul> -->


                </div>
            </div>
        </div>

        <div *ngIf="modalId==3" class="row">
            <div id="contentToConvert2" class="overflow-hidden  col-md-12"> 
                <!-- <div class="col-md-12">
                    <h3 class="eventNameHead">{{selectedContent.event_name}}</h3>
                </div> -->



                <div class="tags col-md-12">
                    <div class="colm-1  taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low',
                        status_border_medium: selectedContent.severity == 'Medium',
                        status_border_high: selectedContent.severity == 'High',
                        status_border_critical: selectedContent.severity == 'Critical'
                    }">
                        <label>Severity</label>
                        <!-- <span class="span-head" >Severity</span>  -->
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low',
                            status_mediumLabel: selectedContent.severity == 'Medium',
                            status_highLabel: selectedContent.severity == 'High',
                             status_criticalLabel: selectedContent.severity == 'Critical'
                        }">{{ selectedContent.severity }}</span>
                    </div>
                    <div class="colm2 label-style">
                        <label>TLP</label>
                        <span>{{selectedContent.tlp}}</span>
                    </div>
                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                    </div>
                    
                </div>



                <!-- /////////////////////////////// old -->
                <!-- <div class="col-md-6">
                    {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                                  status_lowLabel: selectedContent.severity == 'Low',
                                  status_highLabel: selectedContent.severity == 'High',
                                   status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                              }">{{ selectedContent.severity }}</span>
                </div>
                <div class="col-md-6">
                    <div>TLP : <span>{{selectedContent.tlp}}</span></div>
                </div> -->
                <!-- <p class="text-right pb-2 printBtn">
                <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                <button class="btn btn-secondary" (click)="captureScreen2()"><i class="ri-printer-line"></i>Print</button>
            </p> -->

                <!-- <p class="text-right pb-2 printBtn">
                                <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                                <button class="btn btn-secondary" (click)="captureScreen2()"><i class="ri-printer-line"></i>Print</button>
                            </p> -->
                <!-- <div class="col-md-12">
                    <app-geochart [countries]="countries"></app-geochart>
                </div> -->
                <!-- <button (click)="captureScreen2()" style="left: 93%;">print</button> -->



                <h2 class="sub-heading">Vulnerability description:</h2>
                    
                <div class="overview-description">
                    <p class="paragraph-clr-dark">
                        {{selectedContent.event_description}}
                    </p>
                </div>
                <!-- /////////////////////////// old -->
                <!-- <div class="col-md-6 mt-3">
                    <p class="card-title-desc">
                        <span class="viewHead">Vulnerability description:</span> <br>
                        {{selectedContent.event_description}}
                    </p>
                </div> -->
                <div class="col-md-6">
                

                    <!-- /////////////////////////// old  -->
                    <ul class="list-unstyled mb-0 col-md-12">
                        <li><span class="viewHead text-light sub-heading">Reference Link</span><br>
                            <!-- <ul>
                                <li *ngFor="let link of selectedContent.reference_link"><b><a [href]="link"
                                            target="_blank">{{link}}</a></b></li>
                            </ul> -->
                            <b>
                                <a [href]="selectedContent.reference_link" target="_blank">{{selectedContent.reference_link}}</a>
                            </b>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="modalId==4">
            <div id="contentToConvert3" class="overflow-hidden  col-md-12 ">
                <!-- <div class="col-md-12">
                    <h3 class="eventNameHead">{{selectedContent.event_name}}</h3>
                </div> -->


                <div class="tags col-md-12">
                    <div class="colm-1  taag label-style" [ngClass]="{
                        status_border_low: selectedContent.severity == 'Low',
                        status_border_medium: selectedContent.severity == 'Medium',
                        status_border_high: selectedContent.severity == 'High',
                        status_border_critical: selectedContent.severity == 'Critical'
                    }">
                    <label>Severity</label>
                        <!-- <span class="span-head" >Severity</span>  -->
                        <span class="custom-alert" [ngClass]="{
                            status_lowLabel: selectedContent.severity == 'Low',
                            status_mediumLabel: selectedContent.severity == 'Medium',
                            status_highLabel: selectedContent.severity == 'High',
                             status_criticalLabel: selectedContent.severity == 'Critical'
                        }">{{ selectedContent.severity }}</span>
                    </div>
                    <div class="colm2 label-style">
                        <label>Severity</label> 
                        <span>{{selectedContent.tlp}}</span>
                    </div>
    
                    <div class="colm3 label-style">
                        <label>Timestamp</label>
                        <span class="timestamp">{{selectedContent.attribute_timestamp}}</span>
                    </div>
                </div>

                <!-- ///////////////////////////////////////// old -->
                <!-- <div class="col-md-6">
                    {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                                  status_lowLabel: selectedContent.severity == 'Low',
                                  status_highLabel: selectedContent.severity == 'High',
                                   status_criticalLabel: selectedContent.severity == 'Critical'|| selectedContent.severity == 'Medium'
                              }">{{ selectedContent.severity }}</span>
                </div>
                <div class="col-md-6">
                    <div>TLP : <span>{{selectedContent.tlp}}</span></div>
                </div> -->
                <!-- <p class="text-right pb-2 printBtn">
                <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                <button class="btn btn-secondary" (click)="captureScreen3()"><i class="ri-printer-line"></i>Print</button>
            </p> -->
                <!-- <p class="text-right pb-2 printBtn">
                                <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                                <button class="btn btn-secondary" (click)="captureScreen3()"><i class="ri-printer-line"></i>Print</button>
                            </p> -->
                <!-- <button (click)="captureScreen3()" style="left: 93%;">print</button> -->

                <div class="col-md-12 mt-3">
                    <div class="card-title-desc">
                        <span class="viewHead sub-heading">Vulnerability description:</span>
                        <div class="paragg ml-3">
                            <span class="value-dark">{{selectedContent.event_description}}</span>
                        </div>
                    </div>
                  
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        <span class="viewHead sub-heading">CVE :</span><span class="value-dark ml-6">{{selectedContent.cve}}</span>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc"><span class="viewHead sub-heading">CVSS :</span> <span class="value-dark ml-6">{{selectedContent.cvss}}</span>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc">
                        <span class="viewHead sub-heading"> Vendor :</span><span class="value-dark ml-6">{{selectedContent.vendor}}</span>
                    </p>
                </div>
            </div>
        </div>


        <div class="row" *ngIf="modalId==5">
            <!-- <button (click)="captureScreen4()" style="left: 93%;">print</button> -->
            <div id="contentToConvert4" class="container overflow-hidden">
                <div class="col-md-6">
                    {{selectedContent.attribute_timestamp}} | Severity : <span [ngClass]="{
                        status_lowLabel: selectedContent.severity == 'Low',
                        status_mediumLabel: selectedContent.severity == 'Medium',
                        status_highLabel: selectedContent.severity == 'High',
                         status_criticalLabel: selectedContent.severity == 'Critical'
                              }">{{ selectedContent.severity }}</span>
                </div>
                <div class="col-md-6">
                    <div>TLP : <span>{{selectedContent.tlp}}</span></div>
                    <!-- <p class="text-right pb-2 printBtn">
                    <button class="btn btn-secondary"><i class="ri-mail-line"></i>Email</button>
                    <button class="btn btn-secondary" (click)="captureScreen4()"><i class="ri-printer-line"></i>Print</button>
                </p> -->
                </div>

                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Feed Name </span>: {{selectedContent.feed_name}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Event Id</span>:  {{selectedContent.event_id}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Event Description</span>: {{selectedContent.event_description}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Event Category</span>: {{selectedContent.event_category}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Attribute Category</span>: {{selectedContent.attribute_category}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Attribute Comment</span>: {{selectedContent.attribute_comment}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Attribute Id</span>:  {{selectedContent.attribute_id}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Attribute Timestamp</span>: {{selectedContent.attribute_timestamp}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Attribute Type</span>: {{selectedContent.attribute_type}}
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="card-title-desc-10 text-muted">
                        <span class="viewHead">Attribute Value</span>: {{selectedContent.attribute_value}}
                    </p>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<div class="col-xl-6" *ngIf="!printClick">
    <h5 class="mb-0 text-center">
        <img src="assets/images/loadingbar.gif" width="100%">
    </h5>
</div>

<ng-template #viewModalPrint let-modal>
    <div class="modal-header ngCustModal">

        <div class="col-xl-12">
            <div class="col-xl-12 printMsg">
                <div class="col-xl-6" *ngIf="printClick">
                    <h5 class="modal-title">Do you want to Save PDF ?</h5>

                    <button class="btn btn-danger" (click)="modal.dismiss('Cross click')">
                        <i class="ri-printer-line"></i>Cancel
                    </button>

                    <button class="btn btn-primary" (click)="printPdf()">
                        <i class="ri-printer-line"></i>Save
                    </button>
                </div>

                <div class="col-xl-6" *ngIf="!printClick">
                    <h5 class="mb-0 text-center">
                        <img src="assets/images/loadingbar.gif" width="100%">
                    </h5>
                </div>
                
            </div>
            <div class="col-xl-12" style="margin-top: 14px;">

            </div>
        </div>


    </div>
    <div class="modal-body p-3 pb-4 op-cass">
    </div>
</ng-template>

</div>